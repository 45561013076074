import React from "react";
import { useHistory } from "react-router-dom";
import Avatar from "./Avatar";
import BotResponse from "./BotResponse";
import Loading from "./Loading";
import Error from "./Error";
import "../../pages/FlowChat/Home.css";

const ProconMessages = ({ chatLogRef, idx, chat, err, toggle }) => {
  const history = useHistory();
  return (
    <div
      className="chatLog"
      key={idx}
      ref={chatLogRef}
      id={`navPrompt-${chat?.message?.replace(/[^a-zA-Z0-9]/g, "-")}`}
    >
      {chat.type === "user" ? (
        <div className="chatPromptMainContainer">
          <div className="chatPromptWrapper">
            <Avatar bg="#F89501" className="userSVG">
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth={1.9}
                viewBox="0 0 24 24"
                // strokeLinecap="round"
                // strokeLinejoin="round"
                className="h-6 w-6"
                height={40}
                width={40}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                <circle cx={12} cy={7} r={4} />
              </svg>
            </Avatar>
            <div id="chatPrompt">
              {toggle ? chat?.message.split("###")[0] : chat?.message}
            </div>
          </div>
        </div>
      ) : (
        <div className="botMessageMainContainer">
          <div className="botMessageWrapper">
            <Avatar bg="#fff" className="openaiSVG">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="60.000000pt"
                height="60.000000pt"
                viewBox="0 0 278.000000 278.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,278.000000) scale(0.100000,-0.100000)"
                  fill="#650098"
                  stroke="none"
                >
                  <path
                    d="M1220 2760 c-316 -44 -573 -167 -794 -381 -206 -198 -336 -431 -398
  -717 -31 -139 -31 -405 0 -544 30 -137 66 -237 127 -356 75 -143 153 -247 271
  -361 196 -190 413 -304 687 -363 145 -31 399 -31 544 0 274 59 491 173 687
  363 206 198 336 431 398 717 31 139 31 405 0 544 -30 137 -66 237 -127 356
  -75 143 -153 247 -271 361 -197 190 -418 306 -687 362 -116 24 -335 33 -437
  19z m313 -643 c183 -108 198 -363 29 -491 -54 -40 -69 -78 -36 -90 9 -3 83 10
  165 29 138 33 151 35 183 21 44 -18 62 -46 76 -122 11 -53 10 -66 -5 -97 -20
  -43 -46 -54 -209 -93 -204 -48 -205 -61 -20 -291 155 -191 170 -218 154 -269
  -13 -38 -94 -110 -136 -120 -60 -15 -79 1 -241 196 -47 56 -87 96 -100 98 -25
  4 -35 -6 -159 -159 -57 -71 -106 -121 -124 -129 -47 -19 -91 -5 -151 49 -50
  45 -54 52 -54 93 0 42 7 53 136 214 75 94 141 180 148 191 34 65 0 93 -162
  132 -70 17 -140 37 -156 45 -44 23 -57 66 -42 140 16 76 33 104 77 122 32 14
  45 12 183 -21 82 -19 156 -32 165 -29 32 12 18 51 -29 84 -85 59 -125 137
  -125 247 0 94 38 170 115 229 56 43 101 55 191 51 66 -3 89 -8 127 -30z"
                  />
                </g>
              </svg>
            </Avatar>
            {chat.type === "system" ? (
              <div id="botMessage">
                <BotResponse response={chat.message} chatLogRef={chatLogRef} />
              </div>
            ) : err ? (
              <Error err={err} />
            ) : (
              <Loading />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProconMessages;
