import React from "react";
import NavLinksContainer from "./NavLinksContainer";
import NavPrompt from "./NavPrompt";
import NewChat from "./NewChat";
import "../../pages/FlowChat/Home.css";

const NavContent = ({
  chatLog,
  setChatLog,
  setShowMenu,
  handleSaveChat,
  deleteChat,
  flowId,
  subscriptionId,
  onOpenChat,
  setReset
}) => {

  return (
    <>
      <NewChat
        flowId={flowId}
        subscriptionId={subscriptionId}
        setChatLog={setChatLog}
        setShowMenu={setShowMenu}
        handleSaveChat={handleSaveChat}
        setReset={setReset}
      />
      <div className="navPromptWrapper">
        {chatLog?.map(
          (chat, idx) =>
            chat?.history?.length > 0 && (
              <NavPrompt
                chatPrompt={chat?.history[chat.history?.length - 1]?.message}
                chatId={chat._id}
                subscriptionId={subscriptionId}
                flowId={flowId}
                key={idx}
                onOpenChat={onOpenChat}
              />
            )
        )}
      </div>
      <NavLinksContainer
        chatLog={chatLog}
        setChatLog={setChatLog}
        deleteChat={deleteChat}
      />
    </>
  );
};

export default NavContent;