import styled, { keyframes, css } from "styled-components";

const slideInAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOutAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

export const LeftMenuContainer = styled.div`
  display: flex;
  width: 100%;
  max-height: 538px;

  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const Button = styled.button`
  display: flex;
  width: 248px;
  gap: 8px;
  min-width: 104px;
  max-height: 48px;
  padding: 10.5px 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  border: 1.5px solid #609;
  cursor: pointer;
  .icon-add-more {
    color: #660099 !important;
  }
`;

export const TextButton = styled.p`
  color: #609;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export const ChatHistoryContainer = styled.div`
  display: flex;
  height: 688px;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: flex-start;
  margin-top: 32px;
  ::-webkit-scrollbar-track {
    background: #f6f6f6;
    padding-right: 30px;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: blue;
    background: #d9d9d9;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 0px 0px 28px;
  width: 235px;
  min-width: 104px;
  max-height: 48px;
`;

export const Icon = styled.img`
  cursor: pointer;
`;
export const IconTrash = styled.img`
  cursor: pointer;

`;

export const TextItem = styled.p`
  color: #86888c;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  &.text-trash {
    margin: 0 !important;
  }
  cursor: pointer;
`;

export const BottomContainer = styled.div`
  border-top: 1px solid #dddddd;
  width: 100%;
  padding: 72px 0px 160px 0px;
`;
export const WrapperSidebar = styled.div`
  position: fixed;
  background-color: #fff;
  border-right: 1px solid #ccc;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 60px 16px 0px;
  background-color: #fff;

  z-index: 1;
  display: ${(props) => (props.isOpenSidebarAnimation ? "block" : "none")};
  max-width: ${(props) => (props.isOpenSidebarAnimation ? "247px" : "0px")};
  height: 100%;
  ${(props) =>
    css`
      animation: ${props.isOpen ? slideInAnimation : slideOutAnimation} 0.5s
        forwards;
    `}
`;
