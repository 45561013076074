import { Button, TextField } from "@mui/material";
import styled from "@emotion/styled"
import { ButtonLink, IconAddMoreRegular, IconSubtractRegular } from "@telefonica/mistica";


export const Counter = ({ count, setCount, min, max }) => {
  const handleChange = (event) => {
    const inputValue = Number(event.target.value)
    if (inputValue < min) setCount(min)
    if (inputValue > max) setCount(max)
    if (inputValue < max && inputValue > min) setCount(Math.max(Number(event.target.value), 1))
  };

  return (

   <>
        <ButtonLink
          onPress={() => setCount((prev) => prev - 1)}
          disabled={count === min}
        >
          <IconSubtractRegular size={30} style={{margin: 7}} color={"#660099"}/>
        </ButtonLink>

        <TextField style={{ width: 50, backgroundColor: 'white'}} onChangeValue={handleChange} value={count} />
        
        <ButtonLink onPress={() => setCount((prev) => prev + 1)}  disabled={count === max}>
          <IconAddMoreRegular size={30} style={{margin: 7}} color={"#660099"} />
        </ButtonLink>
    </>
  );
}
