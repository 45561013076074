// import { IconEditRegular } from '@telefonica/mistica';
import styled, { keyframes } from "styled-components";

export const BackgroundContainer = styled.div`
  background-color: #f7f7f7;
  width: 100%;
  @media (min-width: 576px) {
    height: 100vh;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 576px) {
    flex-direction: row;
    height: calc(100vh - 51px);
  }
`;

export const ResponsiveContainer = styled.div`
  display: block;
  transition: all 0.5s;
  margin-top: 51px;
  margin-left: ${(props) => {
    return props.active ? "220" : "50";
  }}px;
  width: calc(
    100% -
      ${(props) => {
        return props.active ? "220" : "50";
      }}px
  );
`;

export const ShortcutContainer = styled.div`
  background-color: white;
  box-shadow: 0 0 4px 0 #ccc;
  margin: ${(props) =>
    " " +
    props.margin.top +
    "px " +
    props.margin.right +
    "px " +
    props.margin.bottom +
    "px " +
    props.margin.left +
    "px"};
  padding: 8px 12px;
  position: relative;
  width: calc(${(props) => props.width}% - 20px);
`;

export const ShortcutTitle = styled.h4`
  margin: 10px;
  display: inline-block;
`;

export const ShortcutRedirect = styled.a`
  display: inline-block;
  padding: 3px;
  background-color: #fff;
  box-shadow: 0 0 4px 0 #ccc;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  position: absolute;
  top: 16px;
  cursor: pointer;
`;

export const Title = styled.h1`
  font-size: 36px;
  font-weight: 300;
  margin: 0px 40px 0px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TableContainer = styled.div`
  border: 1px solid #ccc;
  margin: 8px;
`;

export const TableResponsive = styled.div`
  min-height: 0.01%;
`;
export const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 0px;
  max-width: 100%;
  width: 100%;
`;

export const TableHeader = styled.thead``;

export const TableBodies = styled.tbody``;

export const TableRow = styled.tr`
  ${(props) => (props.embedded ? "" : "color: #000")};
  box-sizing: border-box;
  cursor: ${(props) => props.cursor};
  &:hover > td {
    border-bottom: 2px solid #00edff;
    border-top: 2px solid #00edff;
  }
  &:nth-child(even) {
    background-color: #f2f2f2;
  }

  &:hover > td {
    border: initial;
  }
`;

export const TableHeaderItem = styled.th`
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.42857143;
  min-width: 190px;
  padding: 8px;
  text-align: left;
  vertical-align: bottom;
`;

export const TableBodiesItem = styled.td`
  border-bottom: 1px solid #dedede;
  font-size: 14px;
  line-height: 1.42857143;
  min-width: 190px;
  padding: 8px;
  vertical-align: ${(props) =>
    props.verticalAlign ? props.verticalAlign : "top"};
  width: 25%;

  path {
    fill: #660099;
  }

  svg {
    cursor: pointer;
  }
`;

export const CircleProgress = styled.div`
  display: inline-block;
  height: 50px;
  width: 50px;
`;
export const Loader = styled.div`
  position: relative;
  width: 56px;
  height: 56px;
  float: left;
  user-select: none;
  box-sizing: border-box;
`;
export const LoaderBg = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid #f0f0f0;
  box-sizing: border-box;
`;

export const Text = styled.div`
  text-align: center;
  padding-top: 32%;
  font-size: 12px;
`;

export const SpinnerOne = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 50%;
  height: 50%;
  background: 0 0;
  box-sizing: border-box;
  transform: rotate(${(props) => props.rotate}deg);
  transform-origin: 100% 100%;
`;

export const SpinnerTwo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: 0 0;
  box-sizing: border-box;
  transform-origin: 100% 100%;
  transform: rotate(${(props) => props.rotate}deg);
`;
export const LoaderSpinner = styled.div`
  width: 200%;
  height: 200%;
  border-radius: 50%;
  border: 6px solid ${(props) => props.color};
  box-sizing: border-box;
`;
export const LogoHeaderContainer = styled.div`
  height: 64px;
  position: relative;
`;

export const LogoHeaderImage = styled.img`
  height: 31px;
  position: absolute;
  top: 20px;
  padding-left: 5px;
`;
export const ContentContainer = styled.div`
  position: ${(props) => props.position};
  width: ${(props) =>
    props.width
      ? props.width
      : `calc(100% - ${props.active ? "220px" : "50px"})`};
  margin: 51px 0 0 ${(props) => (props.active ? "220px" : "50px")};
  transition: all 0.5s;
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex: ${(props) => props.flex};
  flex-direction: ${(props) => props.flexDirection};
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 40px;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
`;

export const Tabs = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 8px 40px;
`;
export const Tab = styled.li`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 15px 12px;
  margin: 8px 20px 8px 0;
  border-left: 6px solid transparent;
  border-radius: 4px;
  transition: 0.5s all;
  font-size: 18px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  min-width: 15%;
  cursor: pointer;
  font-size: 14px;
  ${(props) =>
    props.active ? `font-weight: 700; border-left-color: #660099;` : ""}

  +.active {
    border-left-color: #660099;
  }

  @media (min-width: 1400px) {
    gap: ${(props) => props.gap};
  }
`;

export const FormContainer = styled.div`
  position: ${(props) => props.position};
  width: ${(props) => props.width};
  bottom: ${(props) => props.bottom};
  padding: 12px 8px;
  background-color: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  margin: ${(props) => (props.margin ? props.margin : "8px 40px 25px;")};
  overflow: hidden;
  border-radius: 10px;
`;

export const SearchContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const SearchContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
`;

export const InputContainer = styled.div`
  height: ${(props) => (props.height ? props.height : "200px")};
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-bottom: 15px;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: ${(props) => props.marginLeft || "0"};
  width: ${(props) => props.width};
`;

export const SwitchInput = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-bottom: 15px;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: ${(props) => props.marginLeft || "0"};
  width: ${(props) => props.width};
`;

export const Label = styled.label`
  font-weight: 400;
  color: #4a4a4a;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  height: 36px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0 12px 0 12px;
  outline: 0;
  font-size: 16px;
`;

export const InputTextArea = styled.textarea`
  height: 100px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0 12px 0 12px;
  outline: 0;
  font-size: 16px;
`;

export const ActiveButton = styled.button`
  cursor: pointer;
  border: 2px solid #660099;
  color: #660099;
  background-color: #ffffff;
  border-radius: 8px;
  padding: ${(props) => props.padding || "8px 0px"};
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.95px;
  transition: 0.5s;
  text-transform: uppercase;
  width: ${(props) => props.width || 100}%;
  height: ${(props) => props.height};
  :hover {
    color: #ffffff;
    background-color: #660099;
  }
`;
export const MultiSelectListContainer = styled.div`
  position: absolute;
  background-color: white;
  width: 97%;
  top: 75px;
  z-index: 2;
  display: ${(props) => (props.opened ? "block" : "none")};
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
`;

export const MultiSelectListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 16px;
  color: #4a4a4a;
`;

export const MultiSelectSearchContainer = styled.div`
  margin: 0px;
  padding: 10px;
  padding-bottom: 0px;
  position: relative;
`;

export const SelectListAllContainer = styled.div`
  padding: 8px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const CheckBoxInputAll = styled.span`
  width: 16px;
  min-width: 16px;
  height: 16px;
  border: 1px solid ${(props) => (props.checked ? "#660099" : "#ccc")};
  ${(props) => (props.checked ? "background-color: #660099;" : "")}
  margin: 0;
  margin-right: 5px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
  ::after {
    content: " ";
    display: ${(props) => (props.checked ? "block" : "none")};
    position: absolute;
    left: 4px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: 1px solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(40deg);
    transition: opacity 0.3s ease-in;
  }
`;
export const CheckBoxInput = styled.input`
  width: 16px;
  min-width: 16px;
  height: 16px;
  border: 1px solid #4a4a4a;
  margin: 0;
  margin-right: 5px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
`;

export const MultiSelectList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  max-height: 200px;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 15px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
  }
  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 5px;
    box-shadow: inset 7px 10px 12px #f0f0f0;
  }
`;

export const ClearListButton = styled.button`
  background-color: white;
  border: none;
  color: #660099;
  cursor: pointer;
  padding: 8px;
  position: relative;
  text-align: right;
`;

export const MultiSelectListItem = styled.li`
  margin-bottom: 0;
  margin-right: 0px;
  padding: 8px 12px;
  background-color: white;
  color: #4a4a4a;
  border-radius: 2px;
  cursor: pointer;
  display: ${(props) => (props.visible ? "block" : "none")};
  :hover {
    color: #660099;
    background-color: #f2f2f2;
  }
`;

export const InputSearchMultiSelect = styled.input`
  height: 36px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0px 12px;
  outline: none;
  width: 95%;
`;

export const InputCounter = styled.span`
  height: 36px;
  position: absolute;
  margin: 0;
  padding: 6px;
  bottom: 0px;
  right: 16px;
  font-size: 16px;
`;

export const ListOptionItem = styled.div`
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
  :hover {
    background-color: #e9e9e9;
    color: #660099;
  }
`;

export const ListOptionLabel = styled.div`
  padding: 10px;
  cursor: default;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
`;

export const ListOptionContainer = styled.div`
  height: 150px;
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 70px;
  left: 15px;
  right: 0;
  max-height: 300px;
  overflow: auto;
  display: ${(props) => (props.open ? "block" : "none")};
  width: calc(100% - 30px);
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: #7d7d7d;
    border: 0 none #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #696969;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #878787;
  }
  ::-webkit-scrollbar-track {
    background: #fcfcfc;
    border: 0 none #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #dbdbdb;
  }
  ::-webkit-scrollbar-track:active {
    background: #e6e6e6;
  }
  ::-webkit-scrollbar-corner {
    background: 0 0;
  }
`;

export const Option = styled.option``;
export const Select = styled.select`
  height: 36px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0 12px 0 12px;
  outline: 0;
  font-size: 16px;
  ${(props) =>
    props.error ? `box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);` : ""}
`;

export const AbsoluteBox = styled.div`
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translateY(-39%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.25rem;
  gap: 0.3125rem;

  svg {
    width: 30px;
    height: 31px;
    margin-bottom: -8px;

    path {
      fill: #fff;
    }
  }
`;

export const Image = styled.img`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "auto"};
  object-fit: ${(props) => props.objectFit || "initial"};
  cursor: ${(props) => props.cursor};
  filter: ${(props) => props.filter};
`;

export const PdfWrap = styled.div`
  padding: 0.75rem 0.5rem;
  margin: ${(props) => (props.margin ? props.margin : "1.5rem 0 1.5625rem")};
  overflow: hidden;
  border: ${(props) => (props.border ? props.border : "solid .5px #d6d6d6")};
  background: ${(props) => (props.background ? props.background : "#f7f7f7")};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : "0 0 4px 2px rgba(0,0,0,0.1)"};

  p {
    color: #4a4a4a;
  }
`;

export const PdfBtn = styled.button`
  font-family: Telefonica;
  border: 0.5px solid #e5e5e5;
  display: flex;
  gap: 0.9375rem;
  width: 22.88rem;
  padding: 0.0625rem 1.125rem;
  align-items: center;
  cursor: pointer;
  word-break: break-word;
`;

export const PdfBtnWrap = styled.div`
  display: flex;
  gap: 0.9375rem;
`;

export const PdfLogo = styled.img`
  width: 10%;
`;

export const rotateAnimation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const LoadSpinner = styled.div`
  width: ${(props) => (props.width ? props.width : "50px")};
  height: ${(props) => (props.height ? props.height : "50px")};
  position: ${(props) => props.position};
  transform: ${(props) => props.transform};
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  margin: 0px auto 0 auto;
  border: solid 10px #8822aa;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  transition: all 0.5s ease-in;
  animation-name: ${rotateAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;
