import axios from 'axios'
import { User } from './interfaces/User'
import { HandlerError } from './HandlerError'


class AdminPDFService {
  private static readonly API_URL = process.env.REACT_APP_API_URL_QNA_PDF
  private static readonly API_URL_VALIDATION =
    process.env.REACT_APP_API_URL_SERVICE_LOGIN
  private static readonly SERVICE = 'QNA_MOBILE_PRODUCTS_ADMIN'
  private static getConfig = ({
    user_id,
    session_id,
    token,
    user_email,
    user_name,
    user_profile,
    oam_matricula,
    oam_gestor,
    oam_ccusto,
    subscriptionSelected,
  }: User) => {
    return {
      headers: {
        session_id: session_id,
        user_id: user_id,
        token: token,
        user_profile,
        user_name,
        user_email,
        oam_matricula,
        oam_gestor,
        oam_ccusto,
        subscription_id: subscriptionSelected,
        service: this.SERVICE,
      },
    }
  }

  static async uploadPdfQNA(
    userData: User,
    pdf: any,
    filename: string,
    subscription_id: string,
    // _id: string,
    // __v: string,
  ) {
    try {
      const CONFIG = this.getConfig(userData)
      const formData = new FormData()
      // formData.append('tenant', tenant)
      formData.append('filename', filename)
      formData.append('pdf', pdf)

      const headers = {
        ...CONFIG.headers,
        subscription_id: subscription_id,
      }

      const response = await axios.post(`${this.API_URL}/pdf`, formData, {
        headers,
      })

      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async uploadFilesQNA(
    userData: User,
    files: File[],
    nameMap: { [key: string]: string },
    subscription_id: string
  ) {
    try {
      const CONFIG = this.getConfig(userData)
      const formData = new FormData()
      formData.append('filename', JSON.stringify(nameMap))
      for (let i = 0, j = files.length; i < j; i++) {
        formData.append(`files[${i}]`, files[i])
      }

      const headers = {
        ...CONFIG.headers,
        subscription_id: subscription_id,
      }

      const response = await axios.post(`${this.API_URL}/pdf`, formData, {
        headers,
      })

      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async getAllPdfQNA(userData: User, subscription_id: string) {
    try {
      const CONFIG = this.getConfig(userData)
      const headers = {
        ...CONFIG.headers,
        subscription_id: subscription_id,
      }
      const response = await axios.get(`${this.API_URL}/pdf`, {
        headers,
      })
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async editPdfQNA(
    userData: User,
    newFile: string,
    fileName: string,
    subscription_id: string,
    // pdf: File,
  ) {
    try {
      const CONFIG = this.getConfig(userData)
      const headers = {
        ...CONFIG.headers,
        subscription_id: subscription_id,
      }
      const formData = new FormData()
      formData.append('pdf', newFile)

      const response = await axios.put(
        `${this.API_URL}/pdf/${fileName}`,
        formData,
        {
          headers,
        },
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async getPdfQNA(
    userData: User,
    _id: string,
    __v: string,
    tempUri: string,
    filename: string,
    subscription_id: string,
  ) {
    try {
      const CONFIG = this.getConfig(userData)
      const headers = {
        ...CONFIG.headers,
        subscription_id: subscription_id,
      }
      const response = await axios.get(`${this.API_URL}/pdf/${filename}`, {
        headers,
      })
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async deletePdfQNA(
    userData: User,
    _id: string,
    __v: string,
    tempUri: string,
    filename: string,
    subscription_id: string,
  ) {
    try {
      const CONFIG = this.getConfig(userData)
      const headers = {
        ...CONFIG.headers,
        subscription_id: subscription_id,
      }
      const response = await axios.delete(`${this.API_URL}/pdf/${filename}`, {
        headers,
      })
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async validatePermission(userData: User) {
    const CONFIG = this.getConfig(userData)
    try {
      const response = await axios.get(
        `${this.API_URL_VALIDATION}/auth`,
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }
}

export default AdminPDFService
