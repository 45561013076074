import { ButtonDanger } from '@telefonica/mistica';
import { ButtonsWrap, Form, FormWrap, ModalOverLay, ModalWrap, PermissionLabel, PermissionsContainer, ProfileButton, ProfileInput, ProfileLabel } from 'application/styled-components/Perfil';
import { Paragraph } from 'application/styled-components/chatGPT';
import AuthService from '../../services/AuthService'
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'

function ModalPermissionDelete({ closeModal, permissionSelected, getAllPermissions }) {
  const [showModal, setShowModal] = useState(true);
  const userDataState = useSelector((state: any) => state.user);

  const handleDelete = async (e) => {
    e.preventDefault();
    await AuthService.deletePermission(userDataState, permissionSelected['_id']);
    closeModal();
    getAllPermissions();
    toast.success("Perfil deletado com sucesso!", {
      autoClose: 1500
    });
  };

  return (
    <>
      {showModal && (
        <ModalOverLay>
          <ModalWrap>
            <Paragraph>
              <b>Tem certeza de que deseja excluir {permissionSelected.name}?</b>
            </Paragraph>
            <ButtonsWrap>
              <ProfileButton onClick={handleDelete}>
                Deletar
              </ProfileButton>
              <ProfileButton className='cancel' onClick={closeModal}>
                Cancelar
              </ProfileButton>
            </ButtonsWrap>
          </ModalWrap>
        </ModalOverLay>
      )}
    </>
  );
}

export default ModalPermissionDelete;
