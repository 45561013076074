import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import { useMemo } from "react";
import React from "react";
import styled from "@emotion/styled";
import "../../pages/FlowChat/Home.css";

const Code = styled.div`
  padding: 0;
  border-radius: 0.25rem;
  overflow: hidden;

  & > div {
    margin: 0 !important;
  }

  .fa {
    font-style: normal !important;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #191919;
  height: 2.5rem;
  padding: 0.1rem 0.1rem 0 0.5rem;

  .mantine-Button-label {
    display: flex;
    align-items: center;

    * {
      font-size: 90%;
    }
  }
`;

const ImagePreview = styled.div`
  text-align: center;

  img {
    max-width: 30rem !important;
    display: block;
  }
`;

export function Markdown({ content, className }) {
  const classes = useMemo(() => {
    const classes = ["prose", "dark:prose-invert"];

    if (className) {
      classes.push(className);
    }

    return classes;
  }, [className]);

  const elem = useMemo(() => {
    const remarkPlugins = [remarkGfm];
    const rehypePlugins = [];

    remarkPlugins.push(remarkMath);
    rehypePlugins.push(rehypeKatex);

    return <div>{content}</div>;
  }, [content, classes]);

  return elem;
}