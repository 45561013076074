import React from "react";
import { Image } from "../../../../styled-components";
import iconBox from "../../../../../assets/svg/ic-menu-box-filled-blue.svg";
import iconChecked from "../../../../../assets/svg/shield-checked-ok-regular.svg";
import iconShoppingCart from "../../../../../assets/svg/shopping-cart-regular.svg";
import iconTag from "../../../../../assets/svg/tag-regular.svg";

export const TagsFiltroComponent = ({total_itens, total_produtos, total_marcas, total_lojas, showHiddenTags}) => {
  
  return (
    <div className={showHiddenTags ? 'show-table' : 'hide-table'} >
      <div className="sub-title-tag">Mostrando:</div>
      <div >
        <div className="box-tag">
          <span className="icon-tag">
            <Image
              width={"20px"}
              height={"auto"}
              objectFit={"fill"}
              src={iconTag}
            />
          </span>
          <span className="text-tag">
            {" "}
            <b>{total_itens}</b> ofertas
          </span>
        </div>

        <div className="box-tag">
          <span className="icon-tag">
            <Image
              width={"20px"}
              height={"auto"}
              objectFit={"fill"}
              src={iconBox}
            />
          </span>
          <span className="text-tag">
            {" "}
            <b>{total_produtos}</b> produtos
          </span>
        </div>

        <div className="box-tag">
          <span className="icon-tag">
            <Image
              width={"20px"}
              height={"auto"}
              objectFit={"fill"}
              src={iconChecked}
            />
          </span>
          <span className="text-tag">
            {" "}
            <b>{total_marcas}</b> marcas
          </span>
        </div>

        <div className="box-tag">
          <span className="icon-tag">
            <Image
              width={"20px"}
              height={"auto"}
              objectFit={"fill"}
              src={iconShoppingCart}
            />
          </span>
          <span className="text-tag">
            {" "}
            <b>{total_lojas}</b> lojas
          </span>
        </div>
      </div>
    </div>
  );
};
