import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../../components/Layout";
import { useHistory } from "react-router-dom";
import { setSidebarOpen } from "providers/reducers/header";
import icTag from '../../../assets/svg/tag.svg';
import icChart from '../../../assets/svg/chart.svg';
import icGear from '../../../assets/svg/gear.svg';
// import AuthService from "application/services/AuthService";
import { toast } from "react-toastify";
import { BodyContainer } from "application/styled-components";
import { Card, Intro } from './styles';

export const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = (url: string) => {
    history.push(url);
  };

  const handleAdminClick = () => {
    const subAdmin = subscriptions.find(subs => subs.subscriptionName === "ADMIN LAB IA");

    const subscriptionId = subAdmin ? subAdmin.subscriptionId : null;

    if (!subscriptionId) {
      return toast.error('Desculpe, você não tem acesso à esse caso de uso. Se você acredita que isso seja um erro, contate o administrador do sistema.', {
        autoClose: 1500
      })
    }

    return handleClick(`/administration/users?subscription_id=${subscriptionId}`)
  }

  const expanded = useSelector((state: any) => state.header.sidebarOpen);
  const { user_name, token, session_id, subscriptionSelected, subscriptions } = useSelector((state: any) => state.user);
  const sidebar = useSelector((state: any) => state.sidebar);
  const items = sidebar.pages.length !== 0 ? JSON.parse(sidebar.pages) : []
  const [openWelcome, setOpenWelcome] = useState(false)

  function handleSidebar(state) {
    dispatch(setSidebarOpen(state));
  }

  const OpenModal = () => {
    setOpenWelcome(true)
  }
  const CloseModal = () => {
    setOpenWelcome(false)
  }


  React.useEffect(() => {
    if (history.location.search.includes('unauthorized_access')) {
      toast.error('Desculpe, você não tem acesso à esse caso de uso. Se você acredita que isso seja um erro, contate o administrador do sistema.', {
        autoClose: 1500
      })
    }

    if (history.location.search.includes('qna_subscription_id_not_found')) {
      toast.error('Desculpe, houve um erro ao acessar esse produto. Se você acredita que isso seja um erro, contate o administrador do sistema.', {
        autoClose: 1500
      })
    }

    if (token === '' || session_id === '') {
      history.push('/login')
    }
  }, [])

  return (
    <>
      <Layout
        expanded={expanded}
        setExpanded={handleSidebar}
        username={user_name}
        sidebarOptions={sidebar.pages.length !== 0 ? JSON.parse(sidebar.pages) : []}
      >
        <Intro>
          <h2 className="Intro">Olá, {user_name}!</h2>
        </Intro>
        <BodyContainer justifyContent={"left"}>
          <Card onClick={() => handleClick('/products')}>
            <div className={"title"}>
              <img src={icTag} alt="Icone de Produtos" />
              <p>Produtos</p>
            </div>
            <span>Aqui é possivel acessar todos os produtos disponíveis no seu perfil.</span>
          </Card>
          <Card onClick={() => {
            toast.info('Ops, nossos dashboards ainda estão em fase de construção :)', {
              autoClose: 1500
            })
          }}>
            <div className={"title"}>
              <img src={icChart} alt="Icone de Dashboard" />
              <p>Dashboards</p>
            </div>
            <span>Todos os dados da Aura estão aqui, KPIS e gráficos das interações dos usuários.</span>
          </Card>
          <Card onClick={handleAdminClick}>
            <div className={"title"}>
              <img src={icGear} alt="Icone de Configurações" />
              <p>Perfis e Configurações</p>
            </div>
            <span>Inclua, altere e faça edições nos perfis dos usuários e ainda saiba quem logou.</span>
          </Card>
        </BodyContainer>
      </Layout>
      {/* {
        openWelcome &&
        <Welcome closeModal={CloseModal}/>
      } */}

    </>
  );
};
