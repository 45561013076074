import React, { useEffect, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setSubscription } from "providers/reducers/user";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import iconTrash from "../../../../../assets/svg/trash-can-regular.svg";
import iconEdit from "../../../../../assets/svg/edit.svg";
import iconSearch from "../../../../../assets/svg/search-regular-white.svg";
import TextField from "@mui/material/TextField";
//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { styled } from "@mui/material/styles";

import {
  DialogComponent,
  PaginationComponent,
  TagsFiltroComponent,
  BtnExportComponent,
  Loader,
} from "../../components";
import PrecificadorService from "application/services/PrecificadorService";
import { Image } from "application/styled-components";
import { Button, Stack } from "@mui/material";

export const ProdutosManual = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const regex = /[?&]subscription_id=([^&#]*)/;
  const match = regex.exec(history.location.search);
  const subscription_id = match && match[1];
  dispatch(setSubscription(subscription_id));

  const [searchValue, setSearchValue] = useState('');
  const userDataState = useSelector((state) => state.user);
  const { subscriptionName } = userDataState.subscriptions.find(
    (subs) => subs.subscriptionId === subscription_id
  );

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!userDataState.user_profile_permissions.includes("READ")) {
      history.push("/?unauthorized_access");
    }
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#660099",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const [rows, setRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [resumo, setResumo] = useState({
    total_itens: 0,
    total_lojas: 0,
    total_marcas: 0,
    total_produtos: 0,
  });

  const handleDelete = async (id) => {
    try {
      const response = await PrecificadorService.crudOperation("delete", userDataState, subscriptionName, `cadastro_produto/${id}`);

      if (response.hasOwnProperty('error')) {
        toast.error(response.error);
      }
      else {
        toast.success("Produto deletado com sucesso");
      }
      setRows(rows.filter((row) => row.id !== id));
    } catch (error) {
      toast.error("Erro ao excluir produto");
      console.error("Erro ao excluir produto:", error);
    }
  };

  const fetchProdutosFromAPI = async (page = 1) => {
    try {
      setTableLoading(true);
      const produtos = await PrecificadorService.crudOperation("get",
        userDataState, subscriptionName, "cadastro_produto", page, 10, null, searchValue)
      const tableData = produtos.dados.map((item) => ({
        id: item.id,
        categoria: item.categoria,
        vertical: item.vertical,
        subcategoria: item.subcategoria,
        nomeComercial: item.nome_comercial,
        modelo: item.modelo,
        modelo_com_cor: item.modelo_com_cor,
        cor: item.cor,
        cod_sap: item.cod_sap,
        dpgc: item.dpgc
      }));

      setCurrentPage(produtos.current_page);
      setTotalPages(produtos.pages);
      setResumo(produtos.resumo);
      setRows(tableData);
    } catch (error) {
      console.error("Erro ao buscar dados da API:", error);
      toast.error("Erro ao buscar dados da API.");
    } finally {
      setTableLoading(false);
    }
  };

  const handleEdit = (id, nomeComercial, categoria, vertical, subcategoria, cor, modelo, dpgc, modelo_com_cor, cod_sap) => {
    history.push(
      `/cadastra-produto?subscription_id=${subscription_id}&id=${id}&nome_comercial=${nomeComercial}&categoria=${categoria}&vertical=${vertical}&subcategoria=${subcategoria}&cor=${cor}&modelo=${modelo}&dpgc=${dpgc}&modelo_com_cor=${modelo_com_cor}&cod_sap=${cod_sap}`
    );
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  useEffect(() => {
    fetchProdutosFromAPI(currentPage);
  }, [currentPage]);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <div>

      {tableLoading ? (
        <div className="centraliza-loader-table">
          <Loader
          />
        </div>
      ) : (
        <div>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              textColor="secondary"
              indicatorColor="secondary"
              onChange={handleChange}
              className="customStyleTab"
            >
              <Tab label="Categoria" {...a11yProps(0)} />
              <Tab label="Características" {...a11yProps(1)} />
              <Tab label="Dados internos Vivo" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div className="bottom-space">
              <TextField
                color="secondary"
                id="outlined-basic"
                label="Cod SAP"
                variant="outlined"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Button
                className="btnSearch"
                variant="contained"
                color="secondary"
                onClick={() => fetchProdutosFromAPI(1)}
                startIcon={<Image src={iconSearch} width={"25px"} height={"auto"} />}
              ></Button>
            </div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">
                      Nome comercial
                    </StyledTableCell>
                    <StyledTableCell align="center">Categoria</StyledTableCell>
                    <StyledTableCell align="center">Vertical</StyledTableCell>
                    <StyledTableCell align="center">
                      Subcategoria
                    </StyledTableCell>
                    <StyledTableCell align="center">Ações</StyledTableCell>
                  </TableRow>

                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        <p>{row.nomeComercial}</p>
                      </TableCell>
                      <TableCell align="center">
                        <p>{row.categoria}</p>
                      </TableCell>
                      <TableCell align="center">
                        <p>{row.vertical}</p>
                      </TableCell>
                      <TableCell align="center">
                        <p>{row.subcategoria}</p>
                      </TableCell>

                      <TableCell style={{ width: "250px" }} align="center">
                        <Stack direction="row" spacing={3}>
                          <Button
                            onClick={() => handleEdit(row.id, row.nomeComercial, row.categoria, row.vertical, row.subcategoria, row.cor, row.modelo, row.dpgc, row.modelo_com_cor, row.cod_sap)}
                            color="secondary"
                            variant="outlined"
                            startIcon={<Image src={iconEdit} />}
                          >
                            {"Editar"}
                          </Button>
                          <Button
                            onClick={() => handleDelete(row.id)}
                            color="secondary"
                            variant="outlined"
                            startIcon={<Image src={iconTrash} />}
                          >
                            {"Excluir"}
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <PaginationComponent
              totalPages={totalPages}
              currentPage={currentPage}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
              goToPage={setCurrentPage} 
              total_itens={totalPages}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Modelo</StyledTableCell>
                    <StyledTableCell align="center">Modelo com Cor</StyledTableCell>
                    <StyledTableCell align="center">Cor</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow>
                      <TableCell align="center">
                        <p>{row.modelo}</p>
                        <Image
                          width={"20px"}
                          height={"auto"}
                          objectFit={"fill"}
                        //src={iconClock}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <p>{row.modelo}</p>
                        <Image
                          width={"20px"}
                          height={"auto"}
                          objectFit={"fill"}
                        //src={iconClock}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Image
                          width={"20px"}
                          height={"auto"}
                          objectFit={"fill"}
                        //src={iconTrandUp}
                        />
                        <p>{row.cor}</p>

                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">ID DPGC</StyledTableCell>
                    <StyledTableCell align="center">COD SAP</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow>
                      <TableCell align="center">
                        <p>{row.dpgc}</p>
                        <Image
                          width={"20px"}
                          height={"auto"}
                          objectFit={"fill"}
                        //src={iconClock}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <p>{row.cod_sap}</p>
                        <Image
                          width={"20px"}
                          height={"auto"}
                          objectFit={"fill"}
                        //src={iconClock}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomTabPanel>
        </div>
      )}
    </div>
  );
};
