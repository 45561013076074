import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import ReplayIcon from "@mui/icons-material/Replay";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../pages/FlowChat/Home.css";
import { TextArea } from "application/styled-components/layout";
import AzureBlobDownloadButton from "./ButtonDownload";
import "../../pages/FlowChat/Home.css";

const AccordionProposals = ({
  callTreeFlow,
  callSecondFlow,
  flowId,
  subscriptionId,
  guid,
  messages,
  onSelectRegenerate,
  onChangeRegenerateMsg,
  setSecondFlow,
  toggle,

}) => {
  
  return (
    <div className="accordionWrapper">
      {!toggle && (
        <Accordion className="accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="text-pattern" fontSize="18px" fontWeight={400}>
              Por favor, selecione a proposta que gostaria de regenerar
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="accordionDetails">
            <FormGroup>
              {messages.map((item, index) => (
                <div className="accordionItemWrapper">
                  <FormControlLabel
                    fontSize="18px" fontWeight={400}
                    control={
                      <Checkbox
                        onChange={(e) =>
                          onSelectRegenerate(e.target.checked, index)
                        }
                        color="secondary"
                      />
                    }
                    label={`Proposta ${index + 1}`}
                  />
                  {item.regenarate && (
                    <TextArea
                      style={{ border: !item.regenarateMsg ? "1px solid red" : "" }}
                      placeholder={`Texto ${index + 1}`}
                      value={item.regenarateMsg}
                      fontSize="18px" fontWeight={400}
                      onChange={(e) =>
                        onChangeRegenerateMsg(e.target.value, index)
                      }
                    />
                  )}
                </div>
              ))}
            </FormGroup>
            <button className="input-file-regenerate" onClick={callSecondFlow} disabled={messages.filter((e) => e.regenarate && e.regenarateMsg.length > 2).length === 0}>
              <p className="input-file-text">
                <ReplayIcon />
                Regenerar
              </p>
            </button>
          </AccordionDetails>
        </Accordion>
      )}

      <AzureBlobDownloadButton
        guid={guid}
        flowId={flowId}
        subscriptionId={subscriptionId}
        callTreeFlow={callTreeFlow}
        setSecondFlow={setSecondFlow}
      />
    </div>
  );
};

export default AccordionProposals;
