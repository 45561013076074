import { HighlightedCard } from '@telefonica/mistica';
import styled from 'styled-components';

export const HomeBoxContainer = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin: 10px 5px;
    padding-bottom: 15px;
    position: relative;
    text-align: center;
    width: calc(33% - 10px);
    border-radius: 4px;
    border: 1px solid rgb(221, 221, 221);
    background-color: white;
`;

export const BoxText = styled.div`
    padding-left: 20px;
`

export const BoxImage = styled.div`
    display: flex;
    align-items: center;
`

export const ImageProduct = styled.img`
    display: flex;
    width: 100px;
    min-width: 100px;
    height: inherit;
    box-sizing: border-box;
`

export const TitleProduct = styled.p`
    text-transform: inherit;
    line-height: 1.5rem;
    font-size: 1.125rem;
    font-weight: 400;
    color: rgb(0, 0, 0);
    text-decoration: inherit;
    overflow-wrap: inherit;
`
export const DescriptionProduct = styled.span`
    text-transform: inherit;
    line-height: 1.25rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: rgb(102, 102, 102);
    text-decoration: inherit;
    overflow-wrap: inherit;
`