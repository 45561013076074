import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import headerReducer from './reducers/header';
import userReducer from './reducers/user';
import formReducer from './reducers/form';
import sidebarReducer from './reducers/sidebar';
import productsReducer from './reducers/products';

const reducers = combineReducers({
    header: headerReducer,
    user: userReducer,
    form: formReducer,
    sidebar: sidebarReducer,
    products: productsReducer
});

export type ReduxState = ReturnType<typeof reducers>
const persistConfig = {
    key: "root",
    storage,
    blacklist: [],
    transforms: [
        encryptTransform({
            secretKey: "my-secret"
        }),
    ],
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
});

export default store;