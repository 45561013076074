import React, { useState, useEffect } from "react";
import { Image } from "application/styled-components";
import iconImport from "../../../../../assets/svg/icon-import.svg";
import PrecificadorService from "application/services/PrecificadorService";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSubscription } from "providers/reducers/user";
import { toast } from 'react-toastify'
import "./CadastroProduto.css";


export const ImportExcel = () => {

  const query = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const history = useHistory();
  const regex = /[?&]subscription_id=([^&#]*)/;
  const match = regex.exec(history.location.search);
  const subscription_id = match && match[1];
  dispatch(setSubscription(subscription_id));
  const userDataState = useSelector((state) => state.user);
  const { subscriptionName } = userDataState.subscriptions.find(
    (subs) => subs.subscriptionId === subscription_id
  );

  const [confirmationMessage, setMessage] = useState("");

  const handleChange = async (event) => {
    try {
      setMessage("Enviando arquivo...");
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("arquivo", file);
      const response = await PrecificadorService.importExcel(userDataState, subscriptionName, formData);
  
      if (response.success) {
        console.log("planilha importada com sucesso:", response.data);
        toast.success("Arquivo enviado com sucesso");
        setMessage("Arquivo enviado com sucesso");
      } else {
        toast.error("Erro ao enviar arquivo");
        setMessage("Erro ao enviar arquivo: " + response.message);
      }
    } catch (error) {
      toast.error("Erro ao enviar arquivo");
      setMessage("Erro ao enviar arquivo: " + error.message);
      console.error("Erro ao importar planilha:", error);
    } finally {
      setTimeout(() => {
        setMessage("");
      }, 9000); 
    }
  };
  

  return (
    <div className="CadastroProduto">
      <div className="box-file">
          <div className="custom-file-upload">
             <p> 
               <Image src={iconImport} width={45} /> 
             </p> 
            <div className="both"></div>
            <input
              className="custom-file-upload border-file-none"
              type="file"
              name="image"
              onChange={handleChange}
            />
            <p>Clique aqui e insira os arquivo com os novos produtos.</p>
          </div>
          {confirmationMessage && (
          <p>{confirmationMessage}</p>
        )}
        </div>
    </div>
  );
};
