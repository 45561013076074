// import { EmailField, TextField } from '@telefonica/mistica';
import styled from 'styled-components';
import { FormContainer } from './index';
import { Button, Paragraph } from './chatGPT';


export const OptionsWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: ${props => props.alignItems};
    gap: ${props => props.gap};
`;

export const OptionButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: ${props => props.alignItems};
    gap: ${props => props.gap};
    font-size: ${(props) => props.fontSize ? props.fontSize : '.875rem'};
    padding: initial;
    border: none;
    background: transparent;
    cursor: pointer;

    path {
        fill: #660099;
    }
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: .5rem 2.8rem 1.5625rem;
    gap: .9375rem;
    width: 89%;

    > div {
        width: 50%;
    }
`;

export const LabelFullWidth = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: .5rem 2.8rem 1.5625rem;
    width: 89%;

    > div {
        width: 100%;
    }
`;

export const ModalWrap = styled.div`
    width: ${(props) => props.width ? props.width : '50rem'};
    margin: auto;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
    padding: 1.5625rem;
    border-radius: ${(props) => props.radius ? props.radius : '.625rem'};
    display: ${(props) => props.display};
    flex-direction: ${(props) => props.flexDirection};
    /* position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); */
    background: ${(props) => props.background ? props.background : '#ffffff'};
`;

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
    margin-top: ${(props) => props.marginTop};
`;

export const Wrapper = styled.div`
    width: ${(props) => props.width ? props.width : '30%'};
    display: flex;
    align-items: ${(props) => props.alignItems ? props.alignItems : 'center'};
    gap: .3125rem;
    justify-content: ${(props) => props.justifyContent};
    flex-direction: ${(props) => props.flexDirection};
    

    svg {
        width: 24%;
        height: 35px;

        path {
            fill: ${(props) => props.fill};
        }
    }

    ${Paragraph} {
        font-weight: bold;
        font-size: 1.25rem;
    }

`;



export const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow-y: scroll;
`;

export const TextInput = styled.textarea`
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 12.875rem;
    border-radius: 8px;
    border: none;
    padding: .9375rem;
    font-size: 1.125rem;
    box-sizing: border-box;
    font-weight: 500;
    background: #f2f2f2;

    &:focus-visible {
        outline: none;
        /* border: solid 2px #797979; */
    }
`;

export const Form = styled.form`
    display: ${(props) => props.display};
    flex-direction: ${(props) => props.flexDirection};
    gap: ${(props) => props.gap};
    height: fit-content;
    max-height: 40rem;
`;

export const ProfileInput = styled.input``;
export const ProfileLabel = styled.label``;
export const PermissionLabel = styled.label`
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
    padding: .625rem .9375rem;
    width: 45%;


    > ${FormContainer} {
        box-shadow: initial;
    }
`;
export const FormWrap = styled.div`
    padding: .75rem .5rem;
    background-color: #fff;
    margin: .5rem 2.5rem 1.5625rem;
    overflow: hidden;
`;
export const PermissionsContainer = styled.div`
    max-height: 250px;
    overflow:hidden;
`;

export const Permissions = styled.div`
    display: flex;
    flex-direction: row;
    gap: .400rem;
    margin-top: 1rem;
    flex-wrap: wrap;
    max-height: 11rem;
    overflow: auto;
    padding: 5px;
    height: fit-content;
`;
export const PermissionWrap = styled.div`
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
    padding: .625rem .9375rem;
    width: fit-content;
`;

export const ProfileButton = styled.button`
    cursor: pointer;
    border-radius: .5rem;
    padding: .3125rem 1.25rem;
    font-size: 1rem;
    border: .125rem solid #660099;
    color: #fff;
    background: #660099;
    
    +.cancel {
        border: .125rem solid #660099;
        color: #660099;
        background: transparent;
    
    }
`;

export const ButtonsWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: .625rem;
    margin-left: 2.5625rem;
    width: ${(props) => props.width ? props.width : '89%'};
    align-items: center;
    justify-content: end;
    margin-top: ${(props) => props.marginTop};

    ${Button} {
        
        padding: .3125rem .625rem;
        border-radius: .5rem;
        background: #797979;
        color: #fff;
    }

`;

export const FlexWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: .3125rem;
    background: #f7f7f7;
    padding: .9375rem;
    width: 100%;
    justify-content: left;
`;

export const UserButton = styled.button`
    padding: .3125rem .625rem;
    border: solid 0.5px #d6d6d6;
    display: flex;
    gap: 0.9375rem;
    width: 49%;
    padding: 0.625rem 0.625rem;
    align-items: center;
    cursor: pointer;
`;

export const DragAndDropWrap = styled.div`
    text-align: center;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: dashed 4px #d6d6d6;
    cursor: pointer;
    transition: all ease .3s;

    &:hover {
        border: dashed 4px #660099bd;
    }
`;