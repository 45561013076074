const brandToneMock = [
  {
    Tipodeconteudo: [
      "Tipo de conteúdo",
      "Blog",
      "Artigo",
      "E-book",
      "Questionário",
      "Relatório",
      "Script",
      "SEO",
      "Anúncio",
      "Comunicação",
      "Rede Social",
    ],
  },
  {
    Tom: [
      "Tom de voz",
      "Alegre",
      "Melancólico",
      "Irônico",
      "Sarcástico",
      "Ousado",
      "Calmo",
      "Enfático",
      "Indiferente",
      "Inspirador",
      "Amigável",
      "Fascinado",
      "Respeitoso",
    ],
  },
  {
    Personalidade: [
      "Personalidade",
      "Profissão",
      "Celebridade",
      "Casual",
      "Formal",
      "Humorístico",
      "Sério",
      "Didático",
      "Critico",
      "Impessoal",
      "Cínico",
      "Afetuoso",
      "Curioso",
    ],
  },
  {
    Linguagem: [
      "Linguagem",
      "Técnico",
      "Acadêmico",
      "Coloquial",
      "Arcaico",
      "Moderno",
      "Poético",
      "Jornalístico",
      "Diplomático",
      "Profissional",
      "Legal",
      "Médico",
      "Político",
    ],
  },
  {
    Estilo: [
      "Estilo",
      "Descritivo",
      "Persuasivo",
      "Narrativo",
      "Expositivo",
      "Dramático",
      "Lírico",
      "Diálogo",
      "Rimas",
      "Paródia",
      "Minimalista",
      "Sátira",
      "Simbólico",
    ],
  },
  {
    Proposito: [
      "Propósito",
      "Persuadir",
      "Entreter",
      "Opinar",
      "Ensinar",
      "Criticar",
      "Denunciar",
      "Exaltar",
      "Questionar",
      "Motivar",
      "Anunciar",
      "Provocar",
      "Reflexão",
    ],
  },

];
export default brandToneMock;
