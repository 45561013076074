import React from "react";
//import BotResponse from "./BotResponse";
import Bell from "../../../assets/img/bell.png";
import Light from "../../../assets/img/light.png";
import Talk from "../../../assets/img/talk.png";

import { Image } from "../../styled-components";
import {
  BoxChatGPT,
  ColumnChatGPT,
  ContainerChatGPT,
  ColumnChatGPTInfo,
} from "application/styled-components/chatGPT";

import "../../pages/FlowChat/Home.css";

const IntroSection = ({ flowName }) => {
  return (
    <div className="intro-section-container">
      <h1 className="title-intro">{flowName}</h1>
      <ContainerChatGPT>
        <ColumnChatGPT>
          <Image src={Talk} width="initial" height="35px" objectFit="contain" />
          <p>Exemplos de texto</p>
          <BoxChatGPT linkActive={true}>
            "Fale um pouco sobre a empresa Vivo"
          </BoxChatGPT>
          <BoxChatGPT linkActive={true}>
            “Liste os 10 desafios de telecom no Brasil"
          </BoxChatGPT>
          <BoxChatGPT linkActive={true}>
            "Crie uma proposta de e-mail marketing"
          </BoxChatGPT>
        </ColumnChatGPT>

        <ColumnChatGPTInfo>
          <Image
            src={Light}
            width="initial"
            height="35px"
            objectFit="contain"
          />
          <p>Dicas para o {flowName}</p>
          <BoxChatGPT>
            Seja claro e específico ao formular suas perguntas.
          </BoxChatGPT>
          <BoxChatGPT>
            Use palavras-chave em perguntas breves para obter respostas precisas
            e compreensíveis.
          </BoxChatGPT>
          <BoxChatGPT>
            Leia atentamente as respostas para garantir relevância ao seu
            contexto.
          </BoxChatGPT>
        </ColumnChatGPTInfo>

        <ColumnChatGPTInfo>
          <Image src={Bell} width="initial" height="35px" objectFit="contain" />
          <p>Dicas de segurança</p>
          <BoxChatGPT>
            Garanta a privacidade dos usuários. Use de forma crítica e
            consciente.
          </BoxChatGPT>
          <BoxChatGPT>
            As informações fornecidas pelo VivoGPT podem ser provenientes de
            fontes desconhecidas, desconfie.
          </BoxChatGPT>
          <BoxChatGPT>
            Ocasionalmente, pode produzir instruções prejudiciais, conteúdo
            tendencioso ou incorreto
          </BoxChatGPT>
        </ColumnChatGPTInfo>
      </ContainerChatGPT>
      {/*<h1 className="title-intro">Vivo Tradutor</h1>
      <div className="tips">
        <div className="tips-container">
          <Image src={light} className="icons-intro" width={45} height={50} />
          <h2> Dicas para o Vivo Tradutor</h2>
          <div className="tips-item">
            Seja claro e específico: evite traduções literais que possam soar
            estranhas no idioma de destino.
          </div>
          <div className="tips-item">
            Revise e corrija: sempre revise sua tradução para identificar erros.
          </div>
          <div className="tips-item">
            Atenção à cultura: Leve em consideração as diferenças culturais
            entre os idiomas.
          </div>
        </div>
        <div className="tips-container">
          <Image src={bell} className="icons-intro" width={45} height={50} />
          <h2> Dicas de segurança</h2>
          <div className="tips-item">
            Garanta a privacidade dos usuários. Use de forma crítica e
            consciente.
          </div>
          <div className="tips-item">
            As informações fornecidas pelo Vivo Tradutor podem ser imprecisas e
            não levar em conta o contexto.
          </div>
          <div className="tips-item">
            Consulte dicionários e glossários: Ao encontrar palavras
            desconhecidas ou termos técnicos, consulte dicionários ou glossários
            especializados
          </div>
        </div>
      </div>*/}
    </div>
  );
};

export default IntroSection;
