import styled from "@emotion/styled";

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 40px 0px 40px 0px;
  padding: 0px 40px;

  div {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  span {
    color: #57375D;
    font-size: 0.86rem;
  }



`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 40px;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.h1`
  color: #660099;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: -1px;
`;

export const WaveSoundIcon = styled.img`
  padding-top: 26px;
  width: 82px;
  height: 82px;
  filter: invert(12%) sepia(64%) saturate(4386%) hue-rotate(268deg) brightness(72%) contrast(105%);
`;