import React, {useState} from "react";
import { Image } from "../../../../styled-components";

import Button from "@mui/material/Button";
import iconBack from "../../../../../assets/svg/ic-nav-chevronleft.svg";
import iconforward from "../../../../../assets/svg/ic-nav-chevronright.svg";
import iconSearch from "../../../../../assets/svg/search-regular-white.svg";


export const PaginationComponent = ({
  totalPages,
  currentPage,
  handlePrevPage,
  handleNextPage,
  goToPage,
  total_itens
}) => {
  const [pageNumber, setPageNumber] = useState("");

  const handlePageNumberChange = (event) => {
    setPageNumber(event.target.value);
  };

  const handleGoToPage = () => {
    const pageNumberInt = parseInt(pageNumber, 10);
    if (!isNaN(pageNumberInt) && pageNumberInt >= 1 && pageNumberInt <= totalPages) {
      goToPage(pageNumberInt);
      setPageNumber("");
    }
  };
  return (
    <div className={total_itens  ? 'pagination show-table' : 'hide-table'}>
    <Button
      className={` ${currentPage === 1 ? "hide-arrow" : ""}`}
      onClick={handlePrevPage}
    >
      <Image
        width={"50px"}
        height={"auto"}
        objectFit={"fill"}
        src={iconBack}
      />
    </Button>
    <span className="numbers current">{currentPage}</span>
    de
    <span className="numbers">{totalPages}</span>
    <Button
      className={` ${currentPage === totalPages ? "hide-arrow" : ""}`}
      onClick={handleNextPage}
    >
      <Image
        width={"50px"}
        height={"auto"}
        objectFit={"fill"}
        src={iconforward}
      />
    </Button>
    <span className="txtInputPagination">Páginas: </span>  
    <input
      type="number"
      placeholder={currentPage}
      onChange={handlePageNumberChange}
      className="inputSearchPagination"
    />
    <Button 
    variant="contained"
    color="secondary"
    className="btnSearchPagination"
    onClick={handleGoToPage}><Image src={iconSearch}  height={"auto"} /></Button>
    
  </div>
);
};
