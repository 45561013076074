import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
`;
export const MainContainer = styled.div`
  width: 100%;
  margin-left: ${({ isOpenSidebar }) => (isOpenSidebar ? "280px" : "0")};
`;

export const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  padding: 0 80px;
  @media (max-height: 800px) {
    padding-bottom: 260px;
  }
`;
export const InlineCards = styled.div`
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
`;

export const SelectsInline = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 80px;
`;

export const SelectsToneWrapper = styled.div`
  padding-top: 32px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
