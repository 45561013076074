import React, { useState } from 'react'
import { TextField } from "@telefonica/mistica"
import { ButtonsWrap, Form, LabelFullWidth, ModalOverLay, ModalWrap, ProfileButton } from "application/styled-components/Perfil"

import AuthService from '../../services/AuthService'
import { toast } from 'react-toastify'

import { useSelector } from 'react-redux'


export const ModalCreateSubscription = ({ closeModal, getAllSubscriptions }) => {
  const userDataState = useSelector((state) => state.user)

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [url, setUrl] = useState('')
  const [permission, setPermission] = useState('')

  const handleSave = async (e) => {
    e.preventDefault();
    if (name === '' || description === '' || url === '') {
      toast.error('Preencha todos os campos', {
        autoClose: 1500
      })
    } else {
      try {
        await AuthService.createSubscription(userDataState, name, description, url, permission)
        getAllSubscriptions()
        closeModal()
        toast.success('Perfil criado com sucesso', {
          autoClose: 1500
        })
      } catch (err) {
        switch (err.data.code) {
          case 'SUBSCRIPTION_EXISTS':
            toast.error('Perfil já cadastrado no sistema', {
              autoClose: 1500
            })
            break
          default:
            toast.error(
              'Erro ao criar perfil, favor entrar em contato com o suporte do sistema.', {
                autoClose: 1500
              }
            )
        }
      }
    }
  }

  return (
    <>
      <ModalOverLay>
        <ModalWrap>
          <Form isOpen={true} onSubmit={handleSave}>
            <LabelFullWidth>
              <TextField
                label="Nome do produto"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </LabelFullWidth>
            <LabelFullWidth>
              <TextField
                label="Descrição do produto"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </LabelFullWidth>
            <LabelFullWidth>
              <TextField
                label="URL da Pagina"
                name="page"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </LabelFullWidth>
            <ButtonsWrap>
              <ProfileButton onClick={(e) => handleSave(e)}>Criar produto</ProfileButton>
              <ProfileButton className="cancel" onClick={closeModal}>
                Cancelar
              </ProfileButton>
            </ButtonsWrap>
          </Form>
        </ModalWrap>
      </ModalOverLay>
    </>
  )
}