import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { Image } from "application/styled-components";
import { Button, Stack } from "@mui/material";

import iconTrandUp from "../../../../../assets/svg/trend-up-regular.svg";
import iconTrandDown from "../../../../../assets/svg/trend-down-regular.svg";
import iconClock from "../../../../../assets/svg/clock-icon.svg";
import { DialogComponent } from "../DialogComponent/DialogComponent";
import iconTrash from "../../../../../assets/svg/trash-can-regular.svg";
import iconEdit from "../../../../../assets/svg/edit.svg";

import { Tooltip as ReactTooltip } from "react-tooltip";



export const TableRowComponent = ({
  name,
  produto,
  subscription_id,
  marca,
  modelo,
  loja,
  menorPrecoVista,
  menorPrecoPrazo,
  categoria,
  created_at,
  sellers,
  valor_vivo,
  preco_mercado,
  comparativo_porcentagem,
  preco_antigo_a_prazo,
  novo_preco_aprazo,
  cor_hex,
  id_produto,
  ranking,
  currentPage,
  data_recente_preco_avista,
  preco_antigo_a_vista,
  data_penultimo_preco_avista,
  index
}) => {

const menor = '-';

  return (
    <>
      {(() => {
        if (name === "produto") {
          return (
            <TableRow >
              <TableCell align="center">{categoria}</TableCell>
              <TableCell
                // onClick={handleDetalhesCompetitividade}
                style={{ width: "300px" }}
                align="center"
              >
                <Link
                  to={`/competitividade-detalhes?subscription_id=${subscription_id}&id_produto=${id_produto}&produto=${produto}&marca=${marca}&modelo=${modelo}&precoVivo=${valor_vivo}&precoMercado=${preco_mercado}&horaData=${created_at}`}
                  state={produto}
                >
                  {produto}
                </Link>
              </TableCell>
              <TableCell align="center">{marca}</TableCell>
              <TableCell style={{ width: "100px" }} align="center">
              {loja}
                <p>
                 <DialogComponent
                    loja={loja}
                    preco={valor_vivo}
                    produto={produto}
                    horaData={created_at}
                    id_produto={id_produto}
                  /> 
                </p>
              </TableCell>
              <TableCell align="center">{sellers}</TableCell>
              <TableCell className={loja == 'Vivo' ? 'bg-colum-vivo-melhor' : 'bg-colum-concorrencia'}  align="center">
                {menorPrecoVista}
              </TableCell>
              <TableCell className={loja == 'Vivo' ? 'bg-colum-vivo-melhor' : 'bg-colum-concorrencia'} align="center">
                <span className="preco_prazo msg-wrapper">{novo_preco_aprazo}</span>
              </TableCell>
            </TableRow>
          );
        } else if (name === "competitividade") {
          return (
            <TableRow >
              <TableCell style={{ width: "300px" }} align="center">
                <Link
                  to={`/competitividade-detalhes?subscription_id=${subscription_id}&id_produto=${id_produto}&produto=${produto}&marca=${marca}&modelo=${modelo}&precoVivo=${valor_vivo}&precoMercado=${preco_mercado}&horaData=${created_at}`}
                  state={produto}
                >
                  {produto}
                </Link>
              </TableCell>
              <TableCell style={{ width: "100px" }} align="center">
                {loja}
                <p>
                  <DialogComponent
                    loja={loja}
                    preco={valor_vivo}
                    produto={produto}
                    horaData={created_at}
                    id_produto={id_produto}
                  />
                </p>
              </TableCell>
              <TableCell align="center">
                {ranking ? ranking+"º" : '-'}
              </TableCell>
              <TableCell align="center">
              <b>{valor_vivo}</b>
              </TableCell>
              <TableCell align="center">
              <b>{menorPrecoVista}</b>
              </TableCell>
              <TableCell align="center">
                <span className="preco_prazo msg-wrapper"><b>{novo_preco_aprazo}</b></span>
              </TableCell>
              <TableCell align="center">

                <span className="preco_prazo">

                    <span className={comparativo_porcentagem != 'N/A' ? 'show-table' : 'hide-table'}>
                  {comparativo_porcentagem.includes(menor) ? (
                    <p>
                    <Image
                      width={"20px"}
                      height={"auto"}
                      objectFit={"fill"}
                      src={iconTrandDown}
                    />
                    </p>
                  ) : (
                    <p>
                    <Image
                      width={"20px"}
                      height={"auto"}
                      objectFit={"fill"}
                      src={iconTrandUp}
                    />
                    </p>
                  )}
                  </span>

                    <b>{comparativo_porcentagem}</b>
                  
                  </span>
                
              </TableCell>
            </TableRow>
          );
        } else if (name === "atualizacoes") { {/* Dados do Produto */}
          return (
            <TableRow >
              <TableCell  align="center">
                  {produto}
              </TableCell>
              <TableCell align="center">
                {marca}
              </TableCell>
              <TableCell align="center">
                {loja}
              </TableCell>
              <TableCell align="center">
                {sellers}
              </TableCell>
              <TableCell align="center">
                <b>{preco_antigo_a_vista}</b>
                  <p>
                  <ReactTooltip
                    className="msg-wrapper"
                    id="my-tooltip-1"
                    place="bottom"
                    content={"Última atualização \n" + data_penultimo_preco_avista  }
                  />
                  <Image 
                    data-tooltip-id="my-tooltip-1"
                    width={"20px"}
                    height={"auto"}
                    objectFit={"fill"}
                    src={iconClock}
                  />
                  </p>
                </TableCell>
                <TableCell align="center">
                <span className="preco_prazo msg-wrapper"><b>{preco_antigo_a_prazo}</b></span>
                <p>
                <ReactTooltip
                    className="msg-wrapper"
                    id="my-tooltip-2"
                    place="bottom"
                    content={"Última atualização \n" + data_penultimo_preco_avista}
                  />
                  <Image 
                    data-tooltip-id="my-tooltip-2"
                    width={"20px"}
                    height={"auto"}
                    objectFit={"fill"}
                    src={iconClock}
                  />
                  </p>
                </TableCell>
                <TableCell align="center">
                <b>{preco_mercado}</b>
                <p>
                <ReactTooltip
                    className="msg-wrapper"
                    id="my-tooltip-3"
                    place="bottom"
                    content={"Última atualização \n" + data_recente_preco_avista}
                  />
                  <Image 
                    data-tooltip-id="my-tooltip-3"
                    width={"20px"}
                    height={"auto"}
                    objectFit={"fill"}
                    src={iconClock}
                  />
                  </p>
                </TableCell>
                <TableCell align="center">
                <span className="preco_prazo msg-wrapper"><b>{menorPrecoPrazo}</b></span>
                <p>
                <ReactTooltip
                    className="msg-wrapper"
                    id="my-tooltip-4"
                    place="bottom"
                    content={"Última atualização \n" + data_recente_preco_avista}
                  />
                  <Image 
                    data-tooltip-id="my-tooltip-4"
                    width={"20px"}
                    height={"auto"}
                    objectFit={"fill"}
                    src={iconClock}
                  />
                  </p>
                </TableCell>
                <TableCell align="center">
                <b>{valor_vivo}</b>
                  <p>
                  <ReactTooltip
                    className="msg-wrapper"
                    id="my-tooltip-5"
                    place="bottom"
                    content={"Última atualização \n" + data_recente_preco_avista}
                  />
                  <Image 
                    data-tooltip-id="my-tooltip-5"
                    width={"20px"}
                    height={"auto"}
                    objectFit={"fill"}
                    src={iconClock}
                  />
                  </p>
                </TableCell>
                <TableCell align="center">
                
                  
                  <span className="preco_prazo">

                    <span className={comparativo_porcentagem != 'N/A' ? 'show-table' : 'hide-table'}>
                  {comparativo_porcentagem.includes(menor) ? (
                    <p>
                    <Image
                      width={"20px"}
                      height={"auto"}
                      objectFit={"fill"}
                      src={iconTrandDown}
                    />
                    </p>
                  ) : (
                    <p>
                    <Image
                      width={"20px"}
                      height={"auto"}
                      objectFit={"fill"}
                      src={iconTrandUp}
                    />
                    </p>
                  )}
                  </span>

                    <b>{comparativo_porcentagem}</b>
                  
                  </span>
                </TableCell>
            </TableRow>
          );
       

        } else if (name === "historico_de_precos") {
          return (
            <TableRow >
              <TableCell style={{ width: "300px" }} align="center">
                  {produto}
              </TableCell>
              <TableCell style={{ width: "100px" }} align="center">
                {loja}
                <p>
                  {/* <DialogComponent
                    loja={loja}
                    preco={valor_vivo}
                    produto={produto}
                    horaData={created_at}
                  /> */}
                </p>
              </TableCell>
              <TableCell align="center">
                {sellers}
              </TableCell>
              <TableCell align="center">
                <b>{valor_vivo}</b>
              </TableCell>
              <TableCell align="center">
                <b>{preco_mercado}</b>
              </TableCell>
            </TableRow>
          );
        } else if (name === "produtos_cadastrados_manual") {
          return (
            <TableRow >
              <TableCell align="center">
                <p>25/01/2024 às 09:30</p>
              </TableCell>
              <TableCell align="center">
                <p>Categoria</p>
              </TableCell>
              <TableCell align="center">
                <p>Vertical</p>
              </TableCell>
              <TableCell align="center">
                <p>Subcategoria</p>
              </TableCell>
              <TableCell align="center">
                <p>Nome comercial</p>
              </TableCell>
              <TableCell style={{ width: "250px" }} align="center">
                <Stack direction="row" spacing={3}>
                  <Button
                    onClick=""
                    color="secondary"
                    variant="outlined"
                    startIcon={<Image src={iconEdit} />}
                  >
                    {"Editar"}
                  </Button>
                  <Button
                    onClick=""
                    color="secondary"
                    variant="outlined"
                    startIcon={<Image src={iconTrash} />}
                  >
                    {"Excluir"}
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          );
        } else if (name === "cep_cadastrados_manual") {
          return (
            <TableRow>
              <TableCell align="center">
                <p>25/01/2024 às 09:30</p>
              </TableCell>
              <TableCell align="center">
                <p>04661300</p>
                São Paulo
              </TableCell>
              <TableCell style={{ width: "250px" }} align="center">
                <Stack direction="row" spacing={3}>
                  <Button
                    onClick=""
                    color="secondary"
                    variant="outlined"
                    startIcon={<Image src={iconEdit} />}
                  >
                    {"Editar"}
                  </Button>
                  <Button
                    onClick=""
                    color="secondary"
                    variant="outlined"
                    startIcon={<Image src={iconTrash} />}
                  >
                    {"Excluir"}
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          );
        }
      })()}
    </>
  );
};
