import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSidebarOpen } from "providers/reducers/header";
import { setSubscription } from "providers/reducers/user";
import { Title } from "../../../styled-components";
import Layout from "../../../components/Layout";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useAuthenticationQNA } from "application/routes/hooks/useAuthentication";
import queryString from "query-string";
import { Tab, Tabs } from "../../../styled-components";
import { ChatDocs } from "./admin";
import { Chat } from "./chat";
import { Aura } from "./aura";

function useQueryParam(paramName) {
  const search = window.location.search;
  const params = queryString.parse(search);

  return params[paramName] || null;
}

export const QandA = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const subscription_id = useQueryParam("subscription_id");
  dispatch(setSubscription(subscription_id));


  useEffect(() => {
    if (window.location.pathname === "/b2b/qa")
      history.push(
        `${process.env.PUBLIC_URL}/qna?subscription_id=${subscription_id}`
      );
  }, []);

  const expanded = useSelector((state) => state.header.sidebarOpen);
  const userDataState = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.products);
  const sidebar = useSelector((state) => state.sidebar);

  function handleSidebar(state) {
    dispatch(setSidebarOpen(state));
  }

  const [product, setProduct] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  useAuthenticationQNA(userDataState);

  const tabs = [];
  if (userDataState?.user_profile_permissions?.includes("READ")) {
    if (process.env.REACT_APP_AURA_VIVO_TRAVEL_SUBSCRIPTION_ID === subscription_id){
      tabs.push({
        label: "Aura",
        component: <Aura subscription_id={subscription_id} />,
      });
    } else {
      tabs.push({
        label: "Chat",
        component: <Chat subscription_id={subscription_id} />,
      });
    }
  }

  if (
    userDataState.user_profile_permissions.includes("CREATE") ||
    userDataState.user_profile_permissions.includes("UPDATE") ||
    userDataState.user_profile_permissions.includes("DELETE")
  ) {
    tabs.push({
      label: "Documentos",
      component: <ChatDocs subscription_id={subscription_id} />,
    });
  }

  useEffect(() => {
    dispatch(setSubscription(subscription_id));
    if (!userDataState?.user_profile_permissions?.includes("READ")) {
      return history.push("/?unauthorized_access");
    }

    if (subscription_id) {
      const product = products.find(
        (product) => product._id === subscription_id
      );
      setProduct(product);
    } else {
      return (window.location.href = "/?qna_subscription_id_not_found");
    }
  }, [subscription_id]);

  return (
    <>
      <Layout
        expanded={expanded}
        setExpanded={handleSidebar}
        username={userDataState.user_name}
        sidebarOptions={JSON.parse(sidebar.pages)}
      >
        <Title>CHAT {product?.name}</Title>

        <Tabs>
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              onClick={() => setActiveTab(index)}
              className={activeTab === index ? "active" : ""}
            >
              {tab.label}
            </Tab>
          ))}
        </Tabs>
        {tabs[activeTab].component}
      
      </Layout>
     
    </>
  );
};

