import React from "react";
import { Paragraph } from "application/styled-components/chatGPT";
import { BodyContainer, LoadSpinner } from "application/styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "providers/reducers/user";
import { setSubscription } from "providers/reducers/user";
import { setPages } from "providers/reducers/sidebar";
import { setProducts } from "providers/reducers/products";
import { toast } from "react-toastify";

import AuthService from "application/services/AuthService";

import { validatePayload } from 'application/utils/validatePayload'

export const Callback = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const [hasError, setHasError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");


  const handleAuthentication = async () => {
    const regex = /[?&]code=([^&#]*)/;
    const match = regex.exec(history.location.search);
    const token = match && match[1];
    const BASENAME = process.env.REACT_APP_API_URL_BASENAME

    try {
      const data = await AuthService.login(token);
      const userData = {
        user_profile: data.user_profile,
        user_profile_permissions: data.user_profile_permissions,
        user_id: data.user_id,
        user_name: data.user_name,
        user_email: data.user_email,
        oam_matricula: data.oam_matricula,
        oam_gestor: data.oam_gestor,
        oam_ccusto: data.oam_ccusto,
        session_id: data.session_id,
        token: data.token,
        subscriptions: data.subscriptions
      }

      await AuthService.createUserLogin(userData);
      await AuthService.createLoginHistory(userData);

      const subscriptionFilter = data.subscriptions.filter(
        (subs) => subs.subscriptionRoute !== "/administration/users"
      );

      dispatch(setUser(userData));
      dispatch(setProducts(data.products));
      dispatch(setPages({ products: subscriptionFilter }));

      if(BASENAME) {
        const queryParameters = new URLSearchParams(window.location.search)
        const subscriptionId = queryParameters.get("subscription_id")
        const redirectSubscriptionRoute = localStorage.getItem('redirectSubscriptionRoute')
  
        if(redirectSubscriptionRoute) {
          return setTimeout(function () {
            history.push(`${redirectSubscriptionRoute}?subscription_id=${subscriptionId}`);
          }, 2000);
        }
  
        return setTimeout(function () {
          history.push("/");
        }, 2000);
      }
    } catch (error) {
      console.log("error while trying to login with token OIM");
      console.log(error);
      switch (error.response.data.code) {
        case "PROFILE_NOT_FOUND":
          toast.error(
            "Você não possui acesso a ferramenta. Procure o seu SAL para realizar a liberação.",
            {
              autoClose: 1500,
            }
          );
          setErrorMessage(
            "Você não possui acesso a ferramenta. Procure o seu SAL para realizar a liberação."
          );
          break;
        case "ERROR_OIM":
          toast.error("Ocorreu um erro ao realizar login.", {
            autoClose: 1500,
          });
          setErrorMessage(
            "Desculpe, ocorreu um erro ao realizar login. Por favor, tente novamente e, se o problema persistir, contate o administrador do sistema."
          );
          break;
        default:
          toast.error("Ocorreu um erro ao realizar login.", {
            autoClose: 1500,
          });
          setErrorMessage(
            "Desculpe, ocorreu um erro ao realizar login. Por favor, tente novamente e, se o problema persistir, contate o administrador do sistema."
          );
      }
      setHasError(true);
    }
  };

  const handleTryAgain = async () => {
    setHasError(false);
    const authUrl = await AuthService.getAuthOIMUrl();
    window.location.href = authUrl;
  };

  React.useEffect(() => {
    handleAuthentication();
  }, []);

  return (
    <>
      <BodyContainer justifyContent={"center"}>
        {!hasError ? (
          <LoadSpinner />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Paragraph>{errorMessage}</Paragraph>
            <button
              onClick={() => handleTryAgain()}
              style={{
                backgroundColor: "#660099",
                color: "white",
                border: "2px solid white",
                borderRadius: 10,
                cursor: "pointer",
                fontWeight: "bold",
                padding: "9px 12px",
                textTransform: "uppercase",
              }}
            >
              tentar novamente
            </button>
          </div>
        )}
      </BodyContainer>
      {/* <CardSubscription subscriptions={subscriptions} /> */}
      <BodyContainer justifyContent={"center"}></BodyContainer>
    </>
  );
};
