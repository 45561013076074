import { createSlice } from '@reduxjs/toolkit';

export const user = createSlice({
	name: 'user',
	initialState: {
		user_profile: '',
		user_profile_permissions: '',
		user_id: '',
		user_name: '',
		user_email: '',
		session_id: '',
		oam_matricula: '',
    	oam_gestor: '',
    	oam_ccusto: '',
		token: '',
		subscriptions: [],
		subscriptionSelected: '',

	},
	reducers: {
		setUser: (state, action) => {
			state.user_profile = action.payload.user_profile
      		state.user_profile_permissions = action.payload.user_profile_permissions
			state.user_id = action.payload.user_id
			state.user_name = action.payload.user_name;
			state.user_email = action.payload.user_email;
			state.session_id = action.payload.session_id;
			state.oam_matricula = action.payload.oam_matricula
			state.oam_gestor = action.payload.oam_gestor
			state.oam_ccusto = action.payload.oam_ccusto
			state.token = action.payload.token;
			state.subscriptions = action.payload.subscriptions;
		},
		clearUser: (state) => { 
			state.user_profile = ''
			state.user_profile_permissions = ''
			state.user_id = '';
			state.user_name = '';
			state.user_email = '';
			state.oam_matricula = ''
			state.oam_gestor = ''
			state.oam_ccusto = ''
			state.session_id = ''
			state.token = '';
			state.subscriptions = [];
			state.subscriptionSelected = ''
		},		
		setSubscription: (state, action) => {
			state.subscriptionSelected = action.payload
		}
	},
});

export const { setUser, clearUser, setSubscription } = user.actions
export interface User {
  user_profile: string
  user_profile_permissions: string
  user_id: string
  user_name: string
  user_email: string
  session_id: string
  oam_matricula: string
  oam_gestor: string
  oam_ccusto: string
  token: string
  subscriptions: Array<Subscription>
  subscriptionSelected: string
}
export interface Subscription {
  _id: string
  tenantId: string
  tenantName: string
  subscriptionId: string
  subscriptionName: string
  subscriptionRoute: string
  permissions: Array<string>
}
export default user.reducer;