import { useRef } from "react";

export default function useDebounce(fn: Function, delay?: number) {
  const timeoutRef: any = useRef(null);

  function debouncedFn(...args) {
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      fn(...args);
    }, delay)
  }
  return debouncedFn;
}