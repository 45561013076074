import styled from 'styled-components';

export const Footer = styled.div`
  background-color: rgba(82, 67, 170, .09);
  padding: 14px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background-color: rgba(82, 67, 170, 0.9);
    color: white;
    border: none;
    padding: 7px 14px;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 4px;
    margin-left: 4px;
  }
`

