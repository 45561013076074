import styled from 'styled-components';

export const FormContainerSearch = styled.div`
    position: ${(props) => props.position};
    width: ${(props) => props.width};
    bottom: ${(props) => props.bottom};
    padding: 12px 8px;
    background-color: #fff;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
    margin: ${(props) => props.margin ? props.margin : "8px 40px 25px;"};
    overflow: hidden;
    height: 36vh;
    display: flex;
    align-items: stretch;
`

export const Content = styled.div`
  width: 98%;
  max-width: 100%;
  margin: 0 18px;
`;
