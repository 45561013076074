import { useEffect } from "react";

import AuthService from "application/services/AuthService";
import { useHistory } from "react-router-dom";
import { isDevelopment } from "application/utils/environment";
import { validatePayload } from 'application/utils/validatePayload'

export const Login = () => {
    const history = useHistory();

    const handleRedirect = async () => {
        if (isDevelopment) {
            const payload = validatePayload()

            if (payload) {
                return history.push(`/login/callback?code=dev_localhost&subscription_id=${payload?.subscriptionSelected}`)
            } else {
                return history.push(`/login/callback?code=dev_localhost`)
            }


        }

        const payload = validatePayload()
        if (payload) {
            return history.push(`/login/callback?code=${payload.code}&subscription_id=${payload?.subscriptionSelected}`)
        }

        const authUrl = await AuthService.getAuthOIMUrl()
        return void (window.location.href = authUrl)
    }

    useEffect(() => {
        handleRedirect()
    }, [])

    return (
        <></>
    )
};