import React, { useState } from "react";
import { TabPanel } from "../TabPanel";
import { FormContainer, Table, TableBodies, TableBodiesItem, TableHeader, TableHeaderItem, TableRow } from "application/styled-components";
import { Paragraph } from "application/styled-components/chatGPT";
import ModalSubUser from "../ModalSubUser";
import { OptionButton, OptionsWrap } from "application/styled-components/Perfil";
import { IconAddMoreCircleRegular } from "@telefonica/mistica";
import { useSelector, RootStateOrAny } from 'react-redux'
import { Subscription, User } from "../../../providers/reducers/user";
import AuthService from "application/services/AuthService";
import { ModalCreateSubscription } from "../ModalCreateSubscription";

interface SubscriptionDashboardProps { }

const SubscriptionDashboard: React.FC<SubscriptionDashboardProps> = () => {
    
    const [selectedSubscription, setSelectedSubscription]: any = useState(null);
    const [subscriptions, setSubscriptions] = useState<Array<Subscription>>([])
    const [usersFromSubscription, setUsersFromSubscription] = useState([])
    const [openModalCreateSubscription, setOpenModalCreateSubscription] = useState<boolean>(false)

    const userDataState: User = useSelector(
      (state: RootStateOrAny) => state.user,
    )

    const openModalCreate = () => {
        setOpenModalCreateSubscription(true);
        document.body.style.overflow = 'hidden';
    };

    const closeModalCreate = () => {
        setOpenModalCreateSubscription(false);
        document.body.style.overflow = '';
    };

    const handleModal = async (subscription: any) => {
        setSelectedSubscription(subscription);
        const usersFromSubscription = await AuthService.getUsersFromSubscription(userDataState, subscription._id)
        setUsersFromSubscription(usersFromSubscription.usersSubscription)
    };

    const closeModal = () => {
        setSelectedSubscription(null);
    };

    const getPermissionsForSelectedSubscription = (): string[] => {
        if (selectedSubscription) {
            return selectedSubscription.permissions;
        }
        return [];
    };

    const getAllSubscriptions = async () => {
        try {
            const subscriptions = await AuthService.getAllsubscriptions(
              userDataState,
            )
            setSubscriptions(subscriptions.subscriptions)
        } catch (error: any) {
            console.log('error getting subscriptions')
            console.log(error)
        }
    }

    React.useEffect(() => {
        getAllSubscriptions()
    }, [])

    return (
      <TabPanel active={true}>
        <FormContainer>
          <OptionsWrap gap={'.75rem'}>
            <OptionButton
              alignItems={'center'}
              gap={'.1875rem'}
              onClick={() => openModalCreate()}
            >
              <Paragraph
                fontSize={'.875rem'}
                color={'#660099'}
                fontWeight={'500'}
              >
                Novo Produto
              </Paragraph>
              <IconAddMoreCircleRegular />
            </OptionButton>
          </OptionsWrap>
          <Table>
            <TableHeader>
              <TableHeaderItem>
                <Paragraph margin={'0'} fontWeight={'700'}>
                  Produto:
                </Paragraph>
              </TableHeaderItem>
              <TableHeaderItem>
                <Paragraph margin={'0'} fontWeight={'700'}>
                  Descrição
                </Paragraph>
              </TableHeaderItem>
              <TableHeaderItem>
                <Paragraph margin={'0'} fontWeight={'700'}>
                  URL
                </Paragraph>
              </TableHeaderItem>
              <TableHeaderItem>
                <Paragraph margin={'0'} fontWeight={'700'}>
                  Permissões de API
                </Paragraph>
              </TableHeaderItem>
            </TableHeader>
            <TableBodies>
              {subscriptions.map((data: any) => (
                <TableRow
                  key={data.subscriptionId}
                  onClick={() => handleModal(data)}
                  cursor={'pointer'}
                >
                  <TableBodiesItem>{data.name}</TableBodiesItem>
                  <TableBodiesItem>{data.description}</TableBodiesItem>
                  <TableBodiesItem>{data.url}</TableBodiesItem>
                  <TableBodiesItem>
                    {data.permissions.map(
                      (permission: string, index: number) => (
                        <React.Fragment key={index}>
                          {permission.trim()}
                          {index !== data.permissions.length - 1 && ', '}
                        </React.Fragment>
                      ),
                    )}
                  </TableBodiesItem>
                </TableRow>
              ))}
            </TableBodies>
          </Table>
        </FormContainer>
        {selectedSubscription && (
          <ModalSubUser
            closeModal={closeModal}
            permissions={getPermissionsForSelectedSubscription()}
            selectedSubscription={selectedSubscription}
            userData={usersFromSubscription}
          />
        )}
        {openModalCreateSubscription && (
          <ModalCreateSubscription
            closeModal={closeModalCreate}
            getAllSubscriptions={getAllSubscriptions}
          />
        )}
      </TabPanel>
    )
};

export default SubscriptionDashboard;
