import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setSubscription } from "providers/reducers/user";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";


import {
  PaginationComponent,
  TagsFiltroComponent,
  BtnExportComponent,
  TableRowComponent,
  TableHeadComponent,
  Loader,
} from "../../components";
import PrecificadorService from "application/services/PrecificadorService";
import { ButtonGroup } from "@mui/material";
import { ProdutosManual } from "../Produto/ProdutosManual";
import { Cep } from "../Cep/Cep";

export const Main = ({ selectedFilters }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const regex = /[?&]subscription_id=([^&#]*)/;
  const match = regex.exec(history.location.search);
  const subscription_id = match && match[1];
  dispatch(setSubscription(subscription_id));

  const userDataState = useSelector((state) => state.user);
  const { subscriptionName } = userDataState.subscriptions.find(
    (subs) => subs.subscriptionId === subscription_id
  );

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!userDataState.user_profile_permissions.includes("READ")) {
      history.push("/?unauthorized_access");
    }
  });

  const [ordenacao, setOrdenacao] = useState('');
  const [rows, setRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [resumo, setResumo] = useState({
    total_itens: 0,
    total_lojas: 0,
    total_marcas: 0,
    total_produtos: 0,
  });

  const fetchProdutosFromAPI = async (page = 1, filters, tipoOrdenacao) => {
    try {
      setTableLoading(true);
      const produtos = await PrecificadorService.getProdutos(
        userDataState,
        subscriptionName,
        page,
        filters,
        tipoOrdenacao
      );


      if (produtos.produtos.length === 0) {
        setCurrentPage(1);
        setTotalPages(1);
        setResumo({
          total_itens: 0,
          total_lojas: 0,
          total_marcas: 0,
          total_produtos: 0,
        });
        setRows([]);
      } else {
         
        const tableData = produtos.produtos.map((item) => ({
          id_produto: item.id,
          categoria: item.categoria_nome,
          produto: item.nome_comercial,
          marca: item.marca_nome,
          modelo: item.modelo,
          loja: item.loja_nome,
          created_at: item.created_at,
          updated_at: item.updated_at,
          menorPrecoVista: item.valor_recente_preco_metodo_pagamento_display,
          menorPrecoPrazo: item.valor_recente_preco_parcelado_display,
          sellers: item.vendedor,
          comparativo_porcentagem: item.comparativo_porcentagem_vivo_display,
          valor_vivo: item.valor_vivo_display,
          preco_mercado: item.valor_recente_preco_avista_display,
          preco_antigo_a_vista: item.valor_penultimo_preco_avista_display,
          preco_antigo_a_prazo: item.valor_penultimo_preco_parcelado_display,
          novo_preco_aprazo: item.valor_recente_preco_parcelado_display,
          cor_hex: item.cor_hex,
          ranking: item.ranking,
          menorPrecoParceladoRaking: item.valor_recente_preco_parcelado_display,
          data_recente_preco_avista:  item.data_recente_preco_avista,
          data_penultimo_preco_avista:  item.data_penultimo_preco_avista

        }));
        setRows(tableData);
        setCurrentPage(produtos.current);
        setTotalPages(produtos.pages);
        setResumo(produtos.resumo);
      }      

    } catch (error) {
      console.error("Erro ao buscar dados da API:", error);
      toast.error("Erro ao buscar dados da API.");
    } finally {
      setTableLoading(false);
    }
  };

  const handleExportProdutos = async (tipoExportacao) => {
    try {
      const response = await PrecificadorService.exportProdutos(userDataState,subscriptionName, tipoExportacao, selectedFilters);
    } catch (error) {
      console.error("Erro ao exportar produtos:", error);
      toast.error("Erro ao exportar produtos.");
    }
  };

  const handleCloseOrdenacao = (tipoOrdenacao) => {
    setOrdenacao(tipoOrdenacao);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    fetchProdutosFromAPI(currentPage, selectedFilters, ordenacao);
  }, [currentPage, ordenacao]);

  useEffect(() => {
    if (currentPage === 1){
      fetchProdutosFromAPI(1, selectedFilters, ordenacao);
    }
    setCurrentPage(1); // Reinicia para a primeira página ao mudar os filtros
  }, [selectedFilters]);

  
  const [showHiddenTags, setshowHiddenTags] = useState(false);
  
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    
    if(value === 4 || value === 5 ){
      setshowHiddenTags(false);
    } else {
      setshowHiddenTags(true);
    }
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  

  return (
    <div>
      {tableLoading ? (
        <Loader />
      ) : (
        <div>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              textColor="secondary"
              indicatorColor="secondary"
              onChange={handleChange}
              className="customStyleTab"
            >
              <Tab label="Produto" {...a11yProps(0)} />
              <Tab label="Competitividade" {...a11yProps(1)} />
              <Tab label="Atualizações" {...a11yProps(2)} />
              <Tab label="Histórico de preços" {...a11yProps(3)} />
              <Tab label="Produtos cadastrados manual" {...a11yProps(4)} />
              <Tab label="CEPs cadastrados manual" {...a11yProps(5)} />
            </Tabs>
          </Box>

          <div className="top-head-tags">
            <TagsFiltroComponent
              showHiddenTags={showHiddenTags}
              total_itens={resumo.total_itens}
              total_produtos={resumo.total_produtos}
              total_marcas={resumo.total_marcas}
              total_lojas={resumo.total_lojas}
            />
          </div>

              
          <BtnExportComponent 
            showHiddenTags={showHiddenTags}
            total_itens={resumo.total_itens} 
            handleCloseOrdenacao={handleCloseOrdenacao} 
            handleExportProdutos={handleExportProdutos} 
          />

          <CustomTabPanel value={value} index={0}>{/* Produto */}
            <TableContainer className={resumo.total_itens ? 'show-table' : 'hide-table'}>
              <Table>
                <TableHeadComponent name="produto" />
                <TableBody>
                  {rows.map((row) => (
                    <TableRowComponent
                      name="produto"
                      id_produto={row.id_produto}
                      valor_vivo={row.valor_vivo}
                      produto={row.produto}
                      subscription_id={subscription_id}
                      marca={row.marca}
                      modelo={row.modelo}
                      loja={row.loja}
                      sellers={row.sellers}
                      menorPrecoVista={row.menorPrecoVista}
                      menorPrecoPrazo={row.menorPrecoPrazo}
                      categoria={row.categoria}
                      created_at={row.updated_at}
                      novo_preco_aprazo={row.novo_preco_aprazo}
                      cor_hex={row.cor_hex}
                      preco_mercado={row.preco_mercado}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <PaginationComponent
              total_itens={resumo.total_itens}
              totalPages={totalPages}
              currentPage={currentPage}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
              goToPage={setCurrentPage}
            />
            <div className={resumo.total_itens ? 'legenda show-table' : 'hide-table'} >
                <div className="m_preco">
                  <div className="leg_vivo_melhor">.</div>
                  Vivo Melhor
                </div>
                <div className="m_preco">
                  <div className="leg_concorrencia">.</div> 
                  Concorrência
              </div>
            </div>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>{/* Competitividade */}
            <TableContainer className={resumo.total_itens ? 'show-table' : 'hide-table'}>
              <Table>
                <TableHeadComponent name="competitividade" />
                <TableBody>
                  {rows.map((row) => (
                    <TableRowComponent
                      name="competitividade"
                      id_produto={row.id_produto}
                      valor_vivo={row.valor_vivo}
                      produto={row.produto}
                      subscription_id={subscription_id}
                      marca={row.marca}
                      modelo={row.modelo}
                      loja={row.loja}
                      sellers={row.sellers}
                      menorPrecoVista={row.menorPrecoVista}
                      comparativo_porcentagem={row.comparativo_porcentagem}
                      menorPrecoPrazo={row.menorPrecoPrazo}
                      created_at={row.created_at}
                      novo_preco_aprazo={row.novo_preco_aprazo}
                      bg_colum={row.cor_hex}
                      preco_mercado={row.preco_mercado}
                      ranking={row.ranking}
                      currentPage={currentPage}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <PaginationComponent 
              total_itens={resumo.total_itens}
              totalPages={totalPages}
              currentPage={currentPage}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
            />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>{/* Atualizacao */}     
            <div className="tableAtualizacoes">

              <TableContainer className={resumo.total_itens ? 'show-table' : 'hide-table'}>
              <Table  style={{ width: 1500 }}>
                <TableHeadComponent name="atualizacoes" />
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRowComponent
                      name="atualizacoes"
                      produto={row.produto}
                      subscription_id={subscription_id}
                      marca={row.marca}
                      modelo={row.modelo}
                      loja={row.loja}
                      created_at={row.created_at}
                      sellers={row.sellers}
                      bg_colum={row.cor_hex}
                      preco_mercado={row.preco_mercado}
                      valor_vivo={row.valor_vivo}
                      menorPrecoVista={row.menorPrecoVista}
                      preco_antigo_a_prazo={row.preco_antigo_a_prazo}
                      menorPrecoPrazo={row.menorPrecoPrazo}
                      comparativo_porcentagem={row.comparativo_porcentagem}
                      data_recente_preco_avista={row.data_recente_preco_avista}
                      preco_antigo_a_vista={row.preco_antigo_a_vista}   
                      data_penultimo_preco_avista={row.data_penultimo_preco_avista}
                      index={index}
                      />
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            </div>

            <PaginationComponent
              total_itens={resumo.total_itens}
              totalPages={totalPages}
              currentPage={currentPage}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
            />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={3}>{/* Historico de Precos */}
            <TableContainer className={resumo.total_itens ? 'show-table' : 'hide-table'}>
              <Table>
                <TableHeadComponent name="historico_de_precos" />
                <TableBody>
                  {rows.map((row) => (
                    <TableRowComponent
                      name="historico_de_precos"
                      produto={row.produto}
                      subscription_id={subscription_id}
                      marca={row.marca}
                      loja={row.loja}
                      sellers={row.sellers}
                      valor_vivo={row.valor_vivo}
                      preco_mercado={row.preco_mercado}
                      bg_colum={row.cor_hex}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <PaginationComponent
              total_itens={resumo.total_itens}
              totalPages={totalPages}
              currentPage={currentPage}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
            />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={4}>{/* Produtos Cadastrados Manual */}
            <ProdutosManual />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={5}> {/* CEPs Cadastrados Manual */}
            <Cep />
          </CustomTabPanel>
        </div>
      )}
    </div>
  );
};
