import { createSlice } from "@reduxjs/toolkit";
import mockSidebarOptions from "application/utils/mocks/SidebarOptions.mock";

export const header = createSlice({
  name: "header",
  initialState: {
    projects: [],
    sidebar: mockSidebarOptions,
    hasSidebar: true,
    sidebarOpen: false,
    curtainType: null,
    curtainOpen: false,
  },
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setSidebar: (state, action) => {
      if (action.payload) {
        state.sidebar = action.payload;
      }
    },
    setHasSidebar: (state, action) => {
      state.hasSidebar = action.payload;
    },
    setCurtainType: (state, action) => {
      state.curtainType = action.payload;
    },
    setSidebarOpen: (state, action) => {
      state.sidebarOpen = action.payload;
    },
    toggleSidebar: (state) => {
      state.sidebarOpen = !state.sidebarOpen;
    },
    toggleCurtain: (state) => {
      state.curtainOpen = !state.curtainOpen;
    },
    openCurtain: (state) => {
      state.curtainOpen = true;
    },
    closeCurtain: (state) => {
      state.curtainOpen = false;
    },
  },
});

export const {
  setProjects,
  setSidebar,
  setHasSidebar,
  toggleSidebar,
  setSidebarOpen,
  setCurtainType,
  toggleCurtain,
  openCurtain,
  closeCurtain,
} = header.actions;

export default header.reducer;
