import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSubscription } from "providers/reducers/user";
import { useHistory } from "react-router-dom";
import PrecificadorService from "application/services/PrecificadorService";

import { styled } from "@mui/material/styles";
import { Image } from "../../../../styled-components";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import iconTrash from "../../../../../assets/svg/trash-can-regular.svg";
import iconEdit from "../../../../../assets/svg/edit.svg";

//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { toast } from "react-toastify";


export const Cep = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const regex = /[?&]subscription_id=([^&#]*)/;
  const match = regex.exec(history.location.search);
  const subscription_id = match && match[1];
  dispatch(setSubscription(subscription_id));
  const userDataState = useSelector((state) => state.user);
  const { subscriptionName } = userDataState.subscriptions.find(
    (subs) => subs.subscriptionId === subscription_id
  );


  const [rows, setRows] = useState([]);
  const [cepData, setCepData] = useState(null);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await PrecificadorService.getProdutosByFilter(userDataState, subscriptionName, "ceps");
        const tableData = response.map(item => ({
          data_cadastro: item.created_at,
          cep: item.numero_cep,
          estado: item.estado,
          id: item.id
        }));
        setRows(tableData);
      } catch (error) {
        
      }
    }
    fetchData();
  }, []); 

  const handleEdit = (id, cep, estado) => {
    // Define os dados do CEP que serão editados
    setCepData({ id, cep, estado });
    history.push(
      `/cadastra-cep?subscription_id=${subscription_id}&id=${id}&cep=${cep}&estado=${estado}`
    );
  };

  const handleDelete = async (id) => {
    try {
      const response = await PrecificadorService.deleteForm(userDataState, subscriptionName, `delete_cep/${id}`);
      if (response.hasOwnProperty('error')) {
        toast.error(response.error);
      }
      else {
        toast.success("CEP deletado com sucesso");
      }
      setRows(rows.filter((row) => row.id !== id));
      //toast.success("CEP excluído com sucesso");
    } catch (error) {
      toast.error("Erro ao excluir CEP");
      console.error("Erro ao excluir CEP:", error);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#660099",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Data Cadastro</StyledTableCell>
              <StyledTableCell align="center">CEP</StyledTableCell>
              <StyledTableCell align="center">Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {rows.map((row) => (
            <TableRow>
              <TableCell align="center">
                <p>{row.data_cadastro}</p>
              </TableCell>
              <TableCell align="center">
              <p>{row.cep}</p>
              {row.estado}
              </TableCell>
              <TableCell style={{ width: "250px" }} align="center">
                <Stack direction="row" spacing={3}>
                  <Button
                    onClick={() => handleEdit(row.id, row.cep, row.estado)} 
                    color="secondary"
                    variant="outlined"
                    startIcon={<Image src={iconEdit} />}
                  >
                    {"Editar"}
                  </Button>
                  <Button
                    onClick={() => handleDelete(row.id)}
                    color="secondary"
                    variant="outlined"
                    startIcon={<Image src={iconTrash} />}
                  >
                    {"Excluir"}
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
             ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
