import { Document, Page, View, StyleSheet, Text } from "@react-pdf/renderer";
import { AudioPDFTable } from "./audioPDFTable";

interface TranscriptionPDFProps {
  transcription: string[];
  title: string;
}

const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 48,
    paddingHorizontal: 48,
    boxSizing: "border-box",
  },
  heading: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
});

export const AudioPDF = ({ transcription, title }: TranscriptionPDFProps ) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.heading}>Transcrição de áudio: {title} </Text>
          <AudioPDFTable
            data={transcription}
          />
        </View>
      </Page>
    </Document>
  );
};