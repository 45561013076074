const types = [
  'application/msword',
  'application/excel',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const pdf = ['application/pdf'];

const ppt = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

const excel = [
  'application/vnd.ms-excel',
  'application/excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const word = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
];

export const checkFileType = (file) => {
  const newFilePdf = pdf?.filter(
    (type) => type?.includes(file.type) === true,
  );
  if (newFilePdf.length > 0) return 'PDF';
  const newFilePpt = ppt?.filter(
    (type) => type?.includes(file.type) === true,
  );
  if (newFilePpt.length > 0) return 'PPT';
  const newFileWord = word?.filter(
    (type) => type?.includes(file.type) === true,
  );
  if (newFileWord.length > 0) return 'Word';
  const newFileExcel = excel?.filter(
    (type) => type?.includes(file.type) === true,
  );
  if (newFileExcel.length > 0) return 'Excel';
  return file.type;
};

export const checkFile = (formato) => {
  const newFile = types?.filter(
    (type) => type?.includes(formato) === true,
  );
  if (newFile.length > 0) return true;
  return false;
};
