import styled from "styled-components";

export const ChatContainer = styled.div`
  width: 100%;
  max-width: 1142px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 8px 8px 8px 8px;
`;

export const ContainerUserInput = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid var(--border, #ddd);
  height: 100% !important;

  border-radius: 8px 8px 0px 0px;
`;

export const UserInput = styled.textarea`
  resize: none;
  width: 100%;
  position: relative;
  border: none;
  max-height: ${(props) => (props.text === "" ? "48px" : "80px")};
  min-height: 48px;
  color: #86888c;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-family: Telefonica !important;
  outline: 0;
  overflow-y: auto;
  padding-top: 8px;
  padding-right: 50px;

  ::-webkit-scrollbar-track {
    background: #86888C;
    padding-right: 30px;
    border-radius: 20px;
  }
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #fff !important;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: blue;
    background: #d9d9d9;
  }
`;

export const ButtonFile = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid #609;
  background: #609;
  height: 60px;
  padding: 20px;
  cursor: pointer;
`;

export const Icon = styled.img`
  cursor: pointer;
  position: absolute;
  right: 0;
  margin-right: 16px;
  background: #fff;
  padding-top: 8px;
`;
export const AlignTexts = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
export const TextButton = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: Telefonica !important;
  font-style: normal;
  padding-left: 10px;
`;

export const TextSelectedFiles = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Telefonica !important;
  font-style: normal;
`;

export const ChatBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20;
  bottom: 60px;
  position: fixed;
  width: 100%;
  width: -webkit-fill-available;
  padding: 0px 80px;

  flex-grow: 1;
`;

export const Box = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-right: 12px;
  margin-left: 12px;
  align-items: flex-start;
  height: 100%;
`;

export const Attachments = styled.ul`
  color: #609;
  button {
    margin-left: 5px;
    border-radius: 8px;
    border: 1px solid rgb(221, 221, 221);
  }
`;

export const FileInput = styled.input`
  position: absolute;
  top: -9999px;
`;
