import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSidebarOpen } from "providers/reducers/header";
import { useHistory } from 'react-router-dom'
import { setSubscription } from "providers/reducers/user";
import Select from 'react-select';
import { Title, LoadSpinner, Image, FormContainer } from "../../styled-components";
import { TabPanel } from "../../components/TabPanel";
import Layout from "../../components/Layout";

import { toast } from 'react-toastify';
import CompanySummaryService from 'application/services/CompanySummaryService';
import { FeedBackWrapDark } from "application/styled-components/chatGPT";
import FeedBackService from "application/services/FeedBackService";
import FeedBackModal from "application/components/FeedBackModal";
import { checkAuthenticationSummary } from "application/routes/hooks/useAuthentication";
import useDebounce from './hooks/useDebounce'
/* like icons */
import likeblacktransparent from 'assets/img/likeblacktransparent.png'
import likeblackfilled from 'assets/img/likeblackfilled.png'
import dislikeblacktransparent from 'assets/img/dislikeblacktransparent.png'
import dislikeblackfilled from 'assets/img/dislikeblackfilled.png'
import { ReduxState } from "providers/store";
import { CompanySummaryResponse } from "interfaces/responses";
import { ColourOption, ICompany } from "./interfaces";
import { colourStyles } from "./utils";

import { FormContainerSearch, Content } from './styles';

export const ResumoEmpresa = () => {
  const dispatch = useDispatch();
  const history = useHistory()

  const regex = /[?&]subscription_id=([^&#]*)/;
  const match = regex.exec(history.location.search);
  const subscription_id = match && match[1];
  dispatch(setSubscription(subscription_id));

  const expanded = useSelector((state: ReduxState) => {
    return state.header.sidebarOpen
  });
  const userDataState = useSelector((state: ReduxState) => state.user);
  const sidebar = useSelector((state: ReduxState) => state.sidebar);

  function handleSidebar(state: boolean) {
    dispatch(setSidebarOpen(state));
  }

  const handleLoginUpdate = (event: any) => {
    setCompanySelected(event.value)
    handleSendPrompt(event.value);
  }

  const [companies, setCompanies] = useState<ICompany[]>([])

  const [loadingAwnser, setLoadingAwnser] = useState(false)
  const [showCard, setShowCard] = useState(false)
  const [answer, setAnswer] = useState<Partial<CompanySummaryResponse & { hasFeedback: boolean, feedback: string }>>({})
  const [isOpen, setIsOpen] = useState(false);
  const [feedBackMessage, setFeedbackmessage] = useState('')
  const [feedback, setFeedback] = useState('')
  const [clickArrow, setClickArrow] = useState(false)
  const [companySelected, setCompanySelected] = useState<number | null>(null)

  const loginOptions: readonly ColourOption[] = companies.map(company => ({
    value: String(company.key),
    label: `${company.value} - ${company.key}`,
    color: '#5243AA'
  }))

  const selectedOptionLogin = loginOptions.find(login => Number(login.value) === companySelected)

  const handleKeyPress = async (event: any) => {

    const query = event.target.value || '';

    await CompanySummaryService.getCompanies(userDataState, query).then((response: any) => {

      const c = response.companies.map((c) => ({
        key: c.name,
        value: c.cnpj
      }))

      setCompanies(c)
    })
  }
  useEffect(() => {
    checkAuthenticationSummary(userDataState)

    if (!userDataState.user_profile_permissions.includes('READ')) {
      history.push('/?unauthorized_access')
    }
  }, [userDataState])

  const handleSendPrompt = useCallback(async (companySelected: string) => {
    setClickArrow(!clickArrow)
    if (!companySelected) {
      toast.error('Para gerar o resumo da empresa, selecione alguma empresa', {
        autoClose: 1500
      })
    } else {
      setShowCard(true)
      setLoadingAwnser(true)

      try {
        const response = await CompanySummaryService.getCompanySummary(userDataState, companySelected);

        if (typeof response !== 'string') {
          const responseFormatted = {
            ...response,
            hasFeedback: false,
            feedback: ''
          }
          setAnswer(responseFormatted)
          setLoadingAwnser(false)
          setFeedback('')
        }
      } catch (error) {
        console.log('resumo-empresa catch')
        console.log(error)
        toast.error('Desculpe, houve um erro ao obter o resumo. Por favor, tente novamente e, se o problema persistir, contate o administrador do sistema.', {
          autoClose: 1500
        })
        setLoadingAwnser(false)
        setShowCard(false)
      }
    }
  }, [])

  const openModal = () => {
    setIsOpen(true);
    setFeedbackmessage('');
  };

  const closeModal = () => {
    setIsOpen(false);
    setFeedback('')
  };

  const valueMessage = (v: ChangeEvent) => {
    setFeedbackmessage((v.target as HTMLInputElement).value)
  }

  const handleModalSubmit = async () => {
    try {
      console.log('handleModalSubmit answer: ', answer)
      const correlator = answer.correlator || 'no-correlator'
      await FeedBackService.feedbackCompany(userDataState, feedback, correlator, feedBackMessage.trim());
      console.log(`feedback: ${feedback}, message: ${feedBackMessage}`)
      toast.success("Feedback enviado com sucesso!", {
        autoClose: 1500
      });
      const answerCopy = answer
      answerCopy.hasFeedback = true
      answerCopy.feedback = feedback
      setAnswer(answerCopy)
    } catch (error) {
      console.log("Failed to send feedback:", error);
      toast.error("Houve um erro ao enviar o feedback. Tente novamente.", {
        autoClose: 1500
      })
    }
    closeModal()
  };

  const handleFeedbackMessage = (feedback: string) => {
    setFeedback(feedback)
    openModal()
  }

  return (
    <Layout
      expanded={expanded}
      setExpanded={handleSidebar}
      username={userDataState.user_name}
      sidebarOptions={JSON.parse(sidebar.pages as unknown as string)}
    >
      <Title>B2B Resumo Empresarial</Title>
      <TabPanel active={true}>
        <FormContainerSearch>
          <Content>
            <p>Selecione uma empresa para gerar o resumo*</p>
            <Select
              options={loginOptions}
              styles={colourStyles}
              value={selectedOptionLogin}
              onChange={handleLoginUpdate}
              onKeyDown={useDebounce(handleKeyPress, 500)}
              placeholder="Digite o nome da empresa ou CNPJ"
            />
          </Content>
        </FormContainerSearch>
        {
          showCard && <>
            <FormContainer>
              <div style={{ padding: 10 }}>
                {
                  loadingAwnser
                    ? <LoadSpinner />
                    : Object.keys(answer.summary || {}).length !== 0 && <>
                      <FeedBackWrapDark justifyContent={'end'}>
                        {
                          answer.hasFeedback ?
                            answer.feedback === 'positive' ?
                              <Image width={'2%'} height={'auto'} objectFit={'fill'} src={likeblackfilled} className="active" />
                              : <Image width={'2%'} height={'auto'} objectFit={'fill'} src={dislikeblackfilled} className="active" />
                            :
                            <>
                              <Image cursor={'pointer'} width={'2%'} height={'auto'} objectFit={'fill'} src={likeblacktransparent} onClick={() => handleFeedbackMessage('positive')} className="active" />
                              <Image cursor={'pointer'} width={'2%'} height={'auto'} objectFit={'fill'} src={dislikeblacktransparent} onClick={() => handleFeedbackMessage('negative')} className="active" />
                            </>
                        }
                      </FeedBackWrapDark>
                      <h2>Informações da empresa {answer.summary?.dados_cadastrais.nome.toUpperCase()}</h2>
                      <p><b>Razão social:</b> {answer.summary?.dados_cadastrais.nome}</p>
                      <p><b>Abertura:</b> {answer.summary?.dados_cadastrais.abertura}</p>
                      <p><b>CNPJ:</b> {answer.summary?.cnpj}</p>
                      <p><b>Natureza Jurídica:</b> {answer.summary?.dados_cadastrais.natureza_juridica}</p>
                      <p><b>Holding:</b> {answer.summary?.holding}</p>
                      <p><b>Status do CNPJ:</b> {answer.summary?.dados_cadastrais.status_cnpj}</p>
                      <p><b>Resumo da Atuação:</b> {answer.summary?.resumo_informacoes}</p>
                      <p><b>Atividade principal:</b> {answer.summary?.dados_cadastrais.atividade_principal}</p>
                      <p><b>Atividade secundária:</b> {answer.summary?.dados_cadastrais.atividades_secundarias}</p>
                      {/* <p><b>Data de abertura:</b> {answer.summary.dados_cadastrais.abertura }</p> */}
                      <p><b>Último Faturamento:</b> {answer.summary?.ultimo_faturamento}</p>
                      <p><b>Quantidade Funcionários:</b> {answer.summary?.qtd_funcionarios}</p>
                      <p><b>Quantidade Filiais:</b> {answer.summary?.qtd_filiais}</p>
                      <p><b>Quantidade Matrizes:</b> {answer.summary?.qtd_matrizes}</p>
                      <p><b>Contato:</b> {answer.summary?.dados_cadastrais.telefone}</p>
                      <p><b>E-mail:</b> {answer.summary?.dados_cadastrais.email}</p>
                      <p><b>Endereço:</b> {answer.summary?.dados_cadastrais.endereco}</p>
                    </>
                }
              </div>
            </FormContainer>
          </>
        }

        {
          showCard && <>
            <FormContainer>
              <div style={{ padding: 10 }}>
                {
                  loadingAwnser
                    ? <LoadSpinner />
                    : Object.keys(answer.summary || {}).length !== 0 && <>
                      <h2>Segmento</h2>
                      <p>{answer.summary?.resumo_segmento}</p>
                      {
                        answer.summary?.desafios_segmento && answer.summary?.desafios_segmento.length > 0 && (<>
                          <h3>Principais Desafios</h3>
                          <ul>
                            {
                              answer.summary?.desafios_segmento.map(desafio => (
                                <li key={desafio}>{desafio}</li>
                              ))
                            }
                          </ul>
                        </>)
                      }
                    </>
                }
              </div>
            </FormContainer>
          </>
        }

        {isOpen && (
          <FeedBackModal
            closeModal={closeModal}
            isLike={feedback === 'positive'}
            change={valueMessage}
            submit={handleModalSubmit}
          />
        )}
      </TabPanel>

    </Layout>
  );
};
