import styled from "styled-components";
export const Card = styled.div`
  display: flex;
  width: 230px;
  height: 110px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #f6f6f6;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;
export const QuestionText = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #86888c;
  margin: 8px;
  text-align: center;
`;
