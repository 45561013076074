import React, { useState, useEffect } from 'react'
import { ButtonsWrap, Form, FormWrap, LabelFullWidth, ModalOverLay, ModalWrap, PermissionLabel, Permissions, ProfileButton, ProfileInput, ProfileLabel, PermissionsContainer } from "application/styled-components/Perfil"
import { Paragraph } from "application/styled-components/chatGPT"

import AuthService from '../../services/AuthService'
import { toast } from 'react-toastify'

import { useSelector } from 'react-redux'

export const ModalEditTenant = ({ isOpen, closeModal, onClose, userSelected }) => {

    const userDataState = useSelector((state) => state.user);
    let [subscriptionsToAdd, setSubscriptionsToAdd] = useState([])
    let [subscriptionsToRemove, setSubscriptionsToRemove] = useState([])


    let [subscriptionsAvaiableToGrant, setSubscriptionsAvaiableToGrant] = useState([])
    let [subscriptionsNotAvaiableToGrant, setSubscriptionsNotAvaiableToGrant] = useState([])

    const [read, setRead] = useState(userSelected.permissions.includes('READ'))
    const [create, setCreate] = useState(userSelected.permissions.includes('CREATE'))
    const [update, setUpdate] = useState(userSelected.permissions.includes('UPDATE'))
    const [deletee, setDeletee] = useState(userSelected.permissions.includes('DELETE'))

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;
        if (checked) {
            setSubscriptionsToAdd((prevPermissions) => [...prevPermissions, value]);
        } else {
            setSubscriptionsToAdd((prevPermissions) => prevPermissions.filter((p) => p !== value));
        }
    }


    const handleCheckboxChangeDelete = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;
        if (checked) {
            setSubscriptionsToRemove((prevPermissions) => [...prevPermissions, value]);
        } else {
            setSubscriptionsToRemove((prevPermissions) => prevPermissions.filter((p) => p !== value));
        }
    }

    const handleSave = async (e) => {
        e.preventDefault();
        try {

            let permissions = [];
            if (read) {
                permissions.push('READ')
            }

            if (create) {
                permissions.push('CREATE')
            }

            if (update) {
                permissions.push('UPDATE')
            }

            if (deletee) {
                permissions.push('DELETE')
            }

            await AuthService.updateProfile(userDataState, userSelected._id, permissions);

            if (subscriptionsToAdd.length > 0) {
                subscriptionsToAdd.map((subsToAdd) => {
                    AuthService.giveSubscriptionToAnUser(userDataState, userSelected._id, subsToAdd)
                })
            }
            if (subscriptionsToRemove.length > 0) {
                subscriptionsToRemove.map((subsToRemove) => {
                    AuthService.removeUserFromASubscription(userDataState, userSelected._id, subsToRemove)
                })
            }
            closeModal()
            toast.success('Perfis atualizados com sucesso', {
                autoClose: 1500
            })
        } catch (err) {
            console.log('error updating user subscriptions ', {
                autoClose: 1500
            })
            console.log(err)
        }

    }

    const getAllSubscriptions = async (subscriptionsFromUser) => {
        const allSubscriptions = await AuthService.getAllsubscriptions(userDataState)

        const avaiableToGrant = []
        const notAvaiableToGrant = []

        allSubscriptions.subscriptions.map((subscription, index) => {
            if (subscriptionsFromUser.find(subs => subs.subscriptionId === subscription._id) !== undefined) {
                notAvaiableToGrant.push(subscription)
            } else {
                avaiableToGrant.push(subscription)
            }
        })

        setSubscriptionsAvaiableToGrant(avaiableToGrant)
        setSubscriptionsNotAvaiableToGrant(notAvaiableToGrant)
    }

    const getSubscriptionsFromUser = async () => {
        const subscriptionsFromUser = await AuthService.getTenantSubscriptions(userDataState, userSelected._id)
        getAllSubscriptions(subscriptionsFromUser.subscriptions)
    }


    useEffect(() => {
        getSubscriptionsFromUser()
    }, [])

    return (
        <>
            <ModalOverLay>

                <ModalWrap>
                    <Paragraph style={{ textAlign: 'center' }}>
                        <b>{userSelected.name}</b>
                    </Paragraph>
                    <Paragraph style={{ textAlign: 'center' }}>
                        {userSelected._id}
                    </Paragraph>
                    <Form isOpen={isOpen} onClose={onClose} onSubmit={handleSave}>
                        <FormWrap>
                            <ProfileLabel>Produtos disponíveis para conceder ao Perfil:</ProfileLabel>
                            <PermissionsContainer>
                                <Permissions>
                                    {
                                        subscriptionsAvaiableToGrant.map((subscription, index) => {

                                            return (
                                                <PermissionLabel for={'profile-permission-' + subscription._id} key={index}>
                                                    <ProfileInput type="checkbox" id={'profile-permission-' + subscription._id} value={subscription._id} onChange={handleCheckboxChange} />
                                                    <ProfileLabel for={'profile-permission-' + subscription.name}>{subscription.name}</ProfileLabel>
                                                    <Paragraph>{subscription.description}</Paragraph>
                                                </PermissionLabel>
                                            )
                                        })
                                    }
                                </Permissions>
                            </PermissionsContainer>
                        </FormWrap>
                        <FormWrap>
                            <ProfileLabel>Produtos disponíveis para remover do Perfil:</ProfileLabel>
                            <PermissionsContainer>
                                <Permissions>
                                    {
                                        subscriptionsNotAvaiableToGrant.map((subscription, index) => {

                                            return (
                                                <PermissionLabel for={'profile-permission-' + subscription._id} key={index}>
                                                    <ProfileInput type="checkbox" id={'profile-permission-' + subscription._id} value={subscription._id} onChange={handleCheckboxChangeDelete} />
                                                    <ProfileLabel for={'profile-permission-' + subscription.name}>{subscription.name}</ProfileLabel>
                                                    <Paragraph>{subscription.description}</Paragraph>
                                                </PermissionLabel>
                                            )
                                        })
                                    }
                                </Permissions>
                            </PermissionsContainer>
                        </FormWrap>
                        <LabelFullWidth>
                            <PermissionsContainer>
                                <Permissions>
                                    <PermissionLabel for={'profile-permission-read'} key={"read"}>
                                        <ProfileInput type="checkbox" id={'profile-permission-read'} checked={read} value={read} onChange={() => setRead(state => !state)} />
                                        <ProfileLabel for={'profile-permission-read'}>LEITURA</ProfileLabel>
                                    </PermissionLabel>

                                    <PermissionLabel for={'profile-permission-create'} key={"create"}>
                                        <ProfileInput type="checkbox" id={'profile-permission-create'} checked={create} value={create} onChange={() => setCreate(state => !state)} />
                                        <ProfileLabel for={'profile-permission-create'}>CRIAÇÃO</ProfileLabel>
                                    </PermissionLabel>

                                    <PermissionLabel for={'profile-permission-update'} key={"update"}>
                                        <ProfileInput type="checkbox" id={'profile-permission-update'} checked={update} value={update} onChange={() => setUpdate(state => !state)} />
                                        <ProfileLabel for={'profile-permission-update'}>EDIÇÃO</ProfileLabel>
                                    </PermissionLabel>

                                    <PermissionLabel for={'profile-permission-delete'} key={"delete"}>
                                        <ProfileInput type="checkbox" id={'profile-permission-delete'} checked={deletee} value={deletee} onChange={() => setDeletee(state => !state)} />
                                        <ProfileLabel for={'profile-permission-delete'}>DELETAR</ProfileLabel>
                                    </PermissionLabel>

                                </Permissions>
                            </PermissionsContainer>
                        </LabelFullWidth>
                        <ButtonsWrap>
                            <ProfileButton onClick={handleSave}>
                                Aplicar
                            </ProfileButton>
                            <ProfileButton className='cancel' onClick={closeModal}>
                                Cancelar
                            </ProfileButton>
                        </ButtonsWrap>
                    </Form>
                </ModalWrap>
            </ModalOverLay>
        </>
    )
}