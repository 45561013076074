// import ServiceHelperLogin from "../helpers/ServiceHelperLogin";
import axios from 'axios'
import { User } from './interfaces/User'
import { HandlerError } from './HandlerError'
import { CompaniesResponse, CompanySummaryResponse } from 'interfaces/responses'

class LoginService {
  private static readonly API_URL =
    process.env.REACT_APP_API_URL_SERVICE_COMPANY_SUMMARY
  private static readonly API_URL_VALIDATION =
    process.env.REACT_APP_API_URL_SERVICE_LOGIN
  private static readonly SERVICE = 'COMPANY_SUMMARY'
  private static getConfig = ({
    user_id,
    user_name,
    user_email,
    user_profile,
    oam_matricula,
    oam_gestor,
    oam_ccusto,
    session_id,
    token,
    subscriptionSelected
  }: User) => {
    return {
      headers: {
        service: this.SERVICE,
        user_id,
        user_name,
        user_email,
        user_profile,
        oam_matricula,
        oam_gestor,
        oam_ccusto,
        session_id,
        token,
        subscription_id: subscriptionSelected
      },
    }
  }

  static async getCompanySummary(userData: User, company: string) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.get<CompanySummaryResponse>(
        `${this.API_URL}/summary/${company.replaceAll("/","%2F")}`,
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async getCompanies(userData: User, searchParam: string) {
    try {
      
      const params = {
        search: searchParam
      };
      
      const CONFIG = this.getConfig(userData)
      const response = await axios.get<CompaniesResponse>(`${this.API_URL}/summary/`, { params, ...CONFIG },)
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async validatePermission(userData: User) {
    const CONFIG = this.getConfig(userData)
    try {
      const response = await axios.get(
        `${this.API_URL_VALIDATION}/auth`,
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }
}

export default LoginService
