import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { ButtonsWrap, Form, ModalOverLay, ModalWrap } from "application/styled-components/Perfil";
import { Button } from "application/styled-components/chatGPT";
import { IconCloseRegular, IconFileMusicRegular } from "@telefonica/mistica";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DragAndDropWrap, FileList, Wrapper, Paragraph, ModalHeader } from "./styles";
import { FileItem } from "./file";

type FileNamesMap = {
    [key: string]: string
}

const nameReducer = (state: FileNamesMap, action: { type: 'set', payload: { id: string, newName: string, oldName: string } } | { type: 'refresh', payload: FileNamesMap }) => {
    const { type, payload } = action

    switch (type) {
        case 'set': {
            const { id, newName } = payload

            const newState = { ...state }

            for (const key in newState) {
                const n = newState[key]
    
                if (n === newName) {
                    return newState // no change, since the new name is already in use
                }
            }

            newState[id] = newName

            return newState
        }
        case 'refresh': {
            return payload
        }
    }
}

export const ModalUploadAudio = ({ closeModal, onFileSelect, amountLimit = 10 }) => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [uniqueNames, setUniqueNames] = useState<string[]>([])
    const [fileNames, dispatchFileName] = useReducer(nameReducer, {})
    const [amountLimitReached, setAmountLimitReached] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setAmountLimitReached(selectedFiles.length >= amountLimit)
    }, [selectedFiles])

    useEffect(() => {
        setUniqueNames(selectedFiles.map(f => fileNames[f.name] || f.name))
    }, [selectedFiles, fileNames])

    const processFiles = (filesArr: File[]) => {
        if (amountLimitReached) {
            toast.error('Você já selecionou o número máximo de arquivos permitidos.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500
            });

            return false
        }

        const supportedFileFormats = ['audio/mpeg', 'video/mp4', 'video/mpeg', 'vide/mpga', 'video/m4a','audio/wav', 'video/webm'];

        const incorrectFiles = filesArr.filter(file => !supportedFileFormats.includes(file.type))

        if (incorrectFiles.length > 0) {
            toast.error('Apenas arquivos de áudio são permitidos, no momento.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500
            });

            return false
        }

        const newFiles = filesArr.filter(file => !selectedFiles.find(f => f.name === file.name))

        const newSelectedFiles = [...selectedFiles, ...newFiles].splice(0, amountLimit)

        setSelectedFiles(newSelectedFiles)

        return true
    }

    const onDrop = useCallback((acceptedFiles, _, e) => {
        if (!acceptedFiles || acceptedFiles.length === 0) {
            e?.preventDefault?.()
            return
        }

        if (!processFiles(acceptedFiles)) {
            e?.preventDefault?.()
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
             "application/audio": [".mp3", ".mp4", ".mpeg", ".mpga", ".m4a",".wav", ".webm"]
        },
    });

    const handleUploadClick = async () => {
        if (!selectedFiles || selectedFiles.length === 0) {
            toast.error("Por favor, selecione ao menos um arquivo para ser enviado.", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500
            });

            return
        }

        setIsLoading(true)

        try {
            const nameMap = {}

            for (const file of selectedFiles) {
                const name = fileNames[file.name] || file.name

                nameMap[file.name] = name
            }

            await onFileSelect(selectedFiles, nameMap);

        } catch (err) {
            console.error(err)

            toast.error('Ocorreu um erro ao realizar o upload de arquivos', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500
            });
        } finally {
            setIsLoading(false)
        }

    };


    const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files

        if (!files || files.length === 0) {
            e.preventDefault()
            return
        }

        const filesArr = Array.from(files)

        if (!processFiles(filesArr)) {
            e.preventDefault()
        }
    };

    const openFileInput = () => {
        if (amountLimitReached) {
            return
        }

        const fileInput = fileInputRef.current;

        if (fileInput) {
            fileInput.click();
        }
    };

    const getFileName = (name: string) => {
        return fileNames[name] || name
    }

    const editFileName = ({ id, newName, oldName }) => {
        for (const file of selectedFiles) {
            const filename = getFileName(file.name)

            if (filename === newName) {
                toast.error('Já existe um arquivo com este nome.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1500
                });

                dispatchFileName({ type: 'set', payload: { id, newName: oldName, oldName } })
                return
            }
        }

        dispatchFileName({ type: 'set', payload: { id, newName, oldName } })
    }

    const removeFile = ({ id, name }) => {
        setSelectedFiles(selectedFiles.filter(f => f.name !== id))

        const { [id]: _, ...newFileNames } = fileNames
        dispatchFileName({ type: 'refresh', payload: newFileNames })
    }

    return (
        <>
            <ModalOverLay>
                <ModalWrap>
                    <ModalHeader>
                        <Wrapper fill={"#660099"}>
                            <div>
                                <IconFileMusicRegular />

                                <Paragraph margin={0} fontSize="1.25rem">
                                    Upload de áudio
                                </Paragraph>
                            </div>
                            <span><i>Formato de arquivo: .mp3, .mp4, .mpeg, .mpga, .m4a, .wav, .webm</i> </span>
                        </Wrapper>
                        <Button onClick={closeModal} disabled={isLoading}>
                            <IconCloseRegular color={"#8822aa"}/>
                        </Button>
                    </ModalHeader>
                    <Form>
                        <FileList>
                            {
                                selectedFiles.map((file) => <FileItem
                                    id={file.name}
                                    name={getFileName(file.name)}
                                    existing={uniqueNames}
                                    onEdit={editFileName}
                                    onRemove={removeFile}
                                    key={file.name}
                                />)
                            }
                        </FileList>
                        {/* <div style={{ marginBottom: "1.25rem" }}>
                            <TextField
                                label="nome do pdf"
                                name="name"
                                value={pdfName}
                                onChange={(e) => setPdfName(e.target.value)}
                                // @ts-ignore
                                required
                                fullWidth
                                disabled={isLoading} // Disable input while loading
                            />
                        </div> */}
                        <DragAndDropWrap {...getRootProps({ className: amountLimitReached ? 'limit' : '' })}
                            onClick={openFileInput}>
                            <input
                                {...getInputProps()}
                                id="fileInput"
                                style={{ display: "none" }}
                                accept="audio/mpeg,.mp4,.mpeg,.mpga,.m4a,.wav,.webm"
                                onChange={handleFileInputChange}
                                ref={fileInputRef}
                                disabled={isLoading || amountLimitReached}
                            />
                            {
                                amountLimitReached ? (
                                    <p>Arquivo de audio selecionado, faça o upload dos arquivo.</p>
                                ) : (
                                    <p>Arraste um arquivo de áudio para cá ou clique aqui para selecioná-lo <i>(25mb)</i></p>
                                )
                            }
                        </DragAndDropWrap>
                        <ButtonsWrap marginTop={"1.875rem"} width={"95%"}>
                            <span>{ selectedFiles.length }/{amountLimit}</span>
                            <Button onClick={handleUploadClick} disabled={isLoading}>
                                {isLoading ? "Carregando..." : "Fazer upload"}
                            </Button>
                        </ButtonsWrap>
                    </Form>
                </ModalWrap>
            </ModalOverLay>
        </>
    );
};
