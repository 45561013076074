import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../../components/Layout";
import { useHistory } from "react-router-dom";
import { setSidebarOpen } from "providers/reducers/header";
import { BoxOptions } from "application/components";
// import AuthService from "application/services/AuthService";
import { toast } from "react-toastify";
import { setSubscription } from "providers/reducers/user";

export const Products = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const expanded = useSelector((state) => state.header.sidebarOpen);
  const user = useSelector((state) => state.user);
  const sidebar = useSelector((state) => state.sidebar);
  const items = sidebar.pages.length !== 0 ? JSON.parse(sidebar.pages) : []
  const [openWelcome, setOpenWelcome] = useState(false)

  function handleSidebar(state) {
    dispatch(setSidebarOpen(state));
  }

  const OpenModal = () => {
    setOpenWelcome(true)
  }
  const CloseModal = () => {
    setOpenWelcome(false)
  }

  
  React.useEffect(() => {
    if (history.location.search.includes('unauthorized_access')) {
      toast.error('Desculpe, você não tem acesso à esse caso de uso. Se você acredita que isso seja um erro, contate o administrador do sistema.', {
        autoClose: 1500
      })
    }

    if (history.location.search.includes('qna_subscription_id_not_found')) {
      toast.error('Desculpe, houve um erro ao acessar esse produto. Se você acredita que isso seja um erro, contate o administrador do sistema.', {
        autoClose: 1500
      })
    }

    // if (history.location.search.includes('welcome')) {
    //   OpenModal()
    // }
console.log('sou produto')
    if (user.token === '' || user.session_id === '') {
      history.push('/login')
    }
  }, [])

  return (
    <>
      <Layout
        expanded={expanded}
        setExpanded={handleSidebar}
        username={user.user_name}
        sidebarOptions={sidebar.pages.length !== 0 ? JSON.parse(sidebar.pages): []}
      >
        <BoxOptions 
          user={user}
          items={items.length !== 0 ? items.find(object => object.title === 'Produtos').items : []} 
        />
      </Layout>
      {/* {
        openWelcome &&
        <Welcome closeModal={CloseModal}/>
      } */}

    </>
  );
};
