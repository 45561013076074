import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { LogoHeaderContainer, LogoHeaderImage } from "../../styled-components/layout"
import { IconMenuRegular, IconLogoutRegular, IconLockOpenFilled, IconUserAccountRegular } from "@telefonica/mistica";

import './Header.css';

import LogoVivo from '../../../assets/img/vivo-logo-1.png'
import iconUser from '../../../assets/svg/ic-account.svg';
import { useHistory } from "react-router-dom";
import { clearUser } from "../../../providers/reducers/user";

import { useSelector, useDispatch } from 'react-redux';
import ChangePasswordModal from '../ChangePasswordModal';
import { Paragraph } from 'application/styled-components/chatGPT';

const Header = ({ active, click, username }) => {
  const dispatch = useDispatch();
  const buttonClass = active ? "menu-button active" : "menu-button";
  const iconColor = active ? "#660099" : "#4a4a4a";
  const history = useHistory();
  const [openPasswordModal, setOpenPasswordModal] = useState(false)
  const { subscriptions, user_profile, user_profile_permissions } = useSelector((state) => state.user);
  // const { products } = useSelector((state) => state.products);

  const Logout = () => {
    localStorage.clear();
    dispatch(clearUser());
    history.push('/login')
  }

  const OpenModal = () => {
    setOpenPasswordModal(true)
  }
  const CloseModal = () => {
    setOpenPasswordModal(false)
  }

  const HandleSelectSubscription = (subscription) => {
    history.push(`${subscription.subscriptionRoute}?subscription_id=${subscription.subscriptionId}`)
  }

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27 && openPasswordModal) {
        CloseModal();
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [openPasswordModal]);

  return (
    <>
      <header>
        <div className="menu-button-container">
          <button className={buttonClass} onClick={click}>
            <IconMenuRegular color={iconColor} />
          </button>
        </div>
        <LogoHeaderContainer onClick={() => history.push('/')}>
          <LogoHeaderImage src={LogoVivo} alt="Logo Vivo" />
        </LogoHeaderContainer>
        <div className="header-right-align">
          <h4 style={{ position: 'relative', right: 10 }}>Versão <span style={{ color: '#660099' }}>BETA</span></h4>
          <div className="profile">
            <span className="header-user-name">{username}</span>
            <span className="header-user-name-profile">| {user_profile}</span>
          </div>
          <img src={iconUser} className="header-avatar-user" alt="Icone Perfil" />
          <ul className="header-avatar-options">
            {/* <li onClick={OpenModal}><IconLockOpenFilled size="16px" /> Alterar Senha</li> */}
            <li onClick={Logout}><IconLogoutRegular size="16px" /> Sair </li>
          </ul>
        </div>
      </header>
      {
        openPasswordModal &&
        <ChangePasswordModal closeModal={CloseModal} />
      }
    </>
  );
}

Header.propTypes = {
  active: PropTypes.bool,
  click: PropTypes.func.isRequired,
  username: PropTypes.string,
};

Header.defaultProps = {
  active: false,
  username: "Admin",
};

export default Header;