import React, { useState, useEffect  } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MultiSelect } from "react-multi-select-component";
import { useHistory } from "react-router-dom";
import { setSubscription } from "providers/reducers/user";
import { createSvgIcon } from "@mui/material/utils";
import RemoveDropDownIcon from "@mui/icons-material/Remove";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Image } from "application/styled-components";
import iconAdd from "../../../../../assets/svg/add-more-regular-black.svg";
import iconTrash from "../../../../../assets/svg/trash-white.svg";
import iconCheck from "../../../../../assets/svg/icon-check.svg";
import iconSearch from "../../../../../assets/svg/search-regular-white.svg";
import PrecificadorService from "application/services/PrecificadorService";
import 'dayjs/locale/en-gb';

const locales = ['en-gb'];


const CalendarVivoIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M7.03377 6.85544C7.13461 6.95908 7.32229 7.08513 7.62761 7.08513C7.93293 7.08513 8.12061 6.95908 8.22145 6.85544C8.32229 6.7546 8.43993 6.56973 8.43993 6.27281C8.43993 5.78541 8.1122 5.45768 7.62761 5.45768C7.14302 5.45768 6.81528 5.78541 6.81528 6.27281C6.81528 6.56973 6.93573 6.7546 7.03377 6.85544Z"
      fill="#0B2739"
    />
    <path
      d="M15.7873 6.85544C15.8881 6.95908 16.0758 7.08513 16.3811 7.08513C16.6864 7.08513 16.8741 6.95908 16.9749 6.85264C17.0758 6.7518 17.1934 6.56693 17.1934 6.27001C17.1934 5.95908 17.0646 5.77141 16.9553 5.66777C16.8545 5.57253 16.6724 5.45768 16.3811 5.45768C15.8965 5.45768 15.5688 5.78541 15.5688 6.27281C15.5688 6.56973 15.6892 6.7546 15.7873 6.85544Z"
      fill="#0B2739"
    />
    <path
      d="M10.0534 16.6482C10.0534 16.4689 10.0786 16.3092 10.1318 16.1692C10.185 16.0291 10.2747 15.8863 10.4007 15.735L11.5548 14.3288C11.9133 13.8947 12.1598 13.5641 12.2915 13.3344C12.4259 13.1047 12.4904 12.875 12.4904 12.6454C12.4904 12.3905 12.4119 12.2 12.2551 12.0711C12.0814 11.9199 11.7817 11.8442 11.3531 11.8442C10.9834 11.8442 10.594 11.8807 10.1906 11.9563V11.0347C10.3755 10.9871 10.594 10.9507 10.8461 10.9171C11.0982 10.8863 11.3391 10.8694 11.5716 10.8694C12.3867 10.8694 12.9806 11.0543 13.3503 11.4241C13.6332 11.707 13.7761 12.0935 13.7761 12.5781C13.7761 12.9423 13.6836 13.2924 13.4988 13.6257C13.3139 13.9591 12.9834 14.3849 12.51 14.9059L11.4763 16.0347H13.8741V16.9199H10.087C10.0646 16.861 10.0534 16.7686 10.0534 16.6482Z"
      fill="#0B2739"
    />
    <path
      d="M19.1458 20.2084H4.76767C3.33909 20.2084 2.17383 19.0459 2.17383 17.6145V6.38485C2.17383 4.95628 3.33629 3.79102 4.76767 3.79102H19.227C20.6556 3.79102 21.8209 4.95348 21.8209 6.38485V17.5333C21.8209 19.0067 20.6192 20.2084 19.1458 20.2084ZM4.76767 4.86665C3.93013 4.86665 3.24946 5.54732 3.24946 6.38485V7.64256H20.7453V6.38485C20.7453 5.54732 20.0646 4.86665 19.227 4.86665H4.76767ZM20.7453 8.71539H3.24946V17.6145C3.24946 18.4521 3.93013 19.1328 4.76767 19.1328H19.1458C20.0282 19.1328 20.7453 18.4157 20.7453 17.5333V8.71539Z"
      fill="#0B2739"
    />
  </svg>,
  "CalendarVivoIcon"
);

const ArrowRenderer = ({ expanded }) => (
  <>
    {expanded ? (
      <RemoveDropDownIcon />
    ) : (
      <Image width={"24px"} height={"auto"} objectFit={"fill"} src={iconAdd} />
    )}
  </>
);

export const MultiSelectComponent = ({ handleSearch }) => {

  const [locale, setLocale] = useState('en-gb');

  const dispatch = useDispatch();
  const history = useHistory();
  const regex = /[?&]subscription_id=([^&#]*)/;
  const match = regex.exec(history.location.search);
  const subscription_id = match && match[1];
  dispatch(setSubscription(subscription_id));
  const userDataState = useSelector((state) => state.user);
  const { subscriptionName } = userDataState.subscriptions.find(
    (subs) => subs.subscriptionId === subscription_id
  );

  //filtro data
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  //Filtros Categoria
  const [selectedCategoria, setSelectedCategoria] = useState([]);
  const [optionsCategoria, setOptionsCategoria] = useState([]);
  const labelCategoria = () => {
    return "Categoria";
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await PrecificadorService.getProdutosByFilter(userDataState, subscriptionName, "categorias");
        const options = response.map(item => ({
          label: item.nome,
          value: item.id
        }));
        setOptionsCategoria(options);
      } catch (error) {
        // console.error("Erro ao obter dados", error);
      }
    }
    fetchData();
  }, []);

  //Filtros SubCategoria
  const [selectedSubCategoria, setSelectedSubCategoria] = useState([]);
  const [optionsSubCategoria, setOptionsSubCategoria] = useState([]);
  const labelSubCategoria = () => {
    return "Sub Categoria";
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await PrecificadorService.getProdutosByFilter(userDataState, subscriptionName, "categorias_com_subcategorias");
        let options = [];
        response.forEach(categoria => {
          categoria.subcategorias.forEach(subcategoria => {
            options.push({
              label: subcategoria.nome,
              value: subcategoria.id
            });
          });
        });

        setOptionsSubCategoria(options);
      } catch (error) {
        // Lidar com o erro, se necessário
      }
    }
    fetchData();
  }, []);


  //Filtros Vertical
  const [selectedVertical, setSelectedVertical] = useState([]);
  const [optionsVertical, setOptionsVertical] = useState([]);
  const labelVertical = () => {
    return "Vertical";
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await PrecificadorService.getProdutosByFilter(userDataState, subscriptionName, "verticals");
        const options = response.map(item => ({
          label: item.nome,
          value: item.id
        }));
        setOptionsVertical(options);
      } catch (error) {
        // console.error("Erro ao obter dados", error);
      }
    }
    fetchData();
  }, []);


  //Filtros Características
  const [selectedCapacidades, setSelectedCapacidades] = useState([]);
  const [optionsCapacidades, setOptionsCapacidades] = useState([]);
  const [selectedCores, setSelectedCores] = useState([]);
  const [optionsCores, setOptionsCores] = useState([]);
  const labelCapacidade = () => {
    return "Capacidades";
  };
  const labelCores = () => {
    return "Cores";
  };


  useEffect(() => {
    async function fetchData() {
      try {

        const response = await PrecificadorService.getProdutosByFilter(userDataState, subscriptionName, "cores");
        const options = response.map(item => ({
          label: item.nome,
          value: item.id
        }));
        setOptionsCores(options);
      } catch (error) {
        console.error("Erro ao obter dados", error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {

        const response = await PrecificadorService.getProdutosByFilter(userDataState, subscriptionName, "capacidades");
        const options = response.map(item => ({
          label: item.nome,
          value: item.id
        }));
        setOptionsCapacidades(options);
      } catch (error) {
        console.error("Erro ao obter dados", error);
      }
    }
    fetchData();
  }, []);

  //Filtros Pagamento
  const [selectedPagamento, setSelectedPagamento] = useState([]);
  const optionsPagamento = [
    { label: "PIX ", value: "pix" },
    { label: "Cartão ", value: "cartao" },
    { label: "Boleto ", value: "boleto" },
  ];
  const labelPagamento = () => {
    return "Pagamento";
  };



  //Filtros Lojas
  const [selectedLojas, setSelectedLojas] = useState([]);
  const [optionsLojas, setOptionsLojas] = useState([]);
  const labelLojas = () => {
    return "Lojas";
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await PrecificadorService.getProdutosByFilter(userDataState, subscriptionName, "lojas");
        const options = response.map(item => ({
          label: item.nome,
          value: item.id
        }));
        setOptionsLojas(options);
      } catch (error) {
        // console.error("Erro ao obter dados", error);
      }
    }
    fetchData();
  }, []);


  //Filtros Sellers
  const [selectedSellers, setSelectedSellers] = useState([]);
  const [optionsSellers, setOptionsSellers] = useState([]);
  const labelSellers = () => {
    return "Sellers";
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await PrecificadorService.getProdutosByFilter(userDataState, subscriptionName, "vendedores");
        const options = response.map(item => ({
          label: item.vendedor,
          value: item.vendedor
        }));
        setOptionsSellers(options);
      } catch (error) {
        // console.error("Erro ao obter dados", error);
      }
    }
    fetchData();
  }, []);

  //Filtros Fabricantes
  const [selectedFabricantes, setSelectedFabricantes] = useState([]);
  const [optionsFabricantes, setOptionsFabricantes] = useState([]);
  const labelFabricantes = () => {
    return "Fabricantes";
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await PrecificadorService.getProdutosByFilter(userDataState, subscriptionName, "fabricante_vendedores");
        const options = response.map(item => ({
          label: item.nome,
          value: item.id
        }));
        setOptionsFabricantes(options);
      } catch (error) {
        // console.error("Erro ao obter dados", error);
      }
    }
    fetchData();
  }, []);



  //Filtros Disponibilidade
  const [selectedDisponibilidade, setSelectedDisponibilidade] = useState([]);
  const labelDisponibilidade = () => {
    return "Disponibilidade";
  };
  const optionsDisponibilidade = [
    { label: "Com estoque", value: "com_estoque" },
    { label: "Sem estoque ", value: "sem_estoque" },
  ];

  //Filtros Disponibilidade
  const [selectedCartao, setSelectedCartao] = useState([]);
  const labelCartao = () => {
    return "Cartão a prazo";
  };
  const optionsCartao = [
    { label: "Sem juros", value: "preco_prazo_sem_juros" },
    { label: "Com juros", value: "preco_prazo_com_juros" },
  ];

  const [selectedComSeller, setSelectedComSeller] = useState([]);
  const optionsComSeller = [
    { label: "Com Seller", value: "com_sellers" },
    { label: "Sem Seller", value: "sem_sellers" },
  ];
  const labelComSeller = () => {
    return "Com Seller";
  };


  const [searchValue, setSearchValue] = useState('');
  const handleFilterConfirmation = () => {
    const filters = {
      selectedCategoria,
      selectedSubCategoria,
      selectedVertical,
      selectedCapacidades,
      selectedCores,
      selectedPagamento,
      selectedLojas,
      selectedSellers,
      selectedFabricantes,
      selectedDisponibilidade,
      selectedComSeller,
      selectedCartao,
      startDate,
      endDate,
      searchValue,
    };

    handleSearch(filters);
    console.log("startDate", startDate)
  };

  const refreshPage = () => {
    setSelectedCategoria([]);
    setSelectedSubCategoria([]);
    setSelectedVertical([]);
    setSelectedCapacidades([]);
    setSelectedCores([]);
    setSelectedPagamento([]);
    setSelectedLojas([]);
    setSelectedSellers([]);
    setSelectedFabricantes([]);
    setSelectedDisponibilidade([]);
    setSelectedComSeller([]);
    setSelectedCartao([]);
    setStartDate(null);
    setEndDate(null);
    setSearchValue('');
  }

  const options = [
    {
      label: 'Capacidades',
      options: optionsCapacidades.map(option => ({
        value: option.value,
        text: option.label,
        selected: option.selected,
      })),
    },
    {
      label: 'Cores',
      options: optionsCores.map(option => ({
        value: option.value,
        text: option.label,
        selected: option.selected,
      })),
    },
  ];


  return (
    <div className="child side">
      <div className="bottom-space">
        <TextField
          color="secondary"
          id="outlined-basic"
          label="O que você procura?"
          variant="outlined"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)} // Atualiza o estado conforme o texto é digitado
        />
        <Button
          className="btnSearch"
          variant="contained"
          color="secondary"
          onClick={() => handleFilterConfirmation()}
          startIcon={<Image src={iconSearch} width={"25px"} height={"auto"} />}
        ></Button>
      </div>

      <div className="bottom-space">
        <span className="span-filtro">Filtrar Por:</span>
        <Button
          onClick={() => handleFilterConfirmation()}
          className="btnClear"
          color="secondary"
          size="medium"
          variant="contained"
          startIcon={<Image src={iconCheck} />}
        >
          Aplicar
        </Button>
        <Button
          onClick={() => refreshPage()}
          className="btnClear"
          color="secondary"
          size="medium"
          variant="contained"
          startIcon={<Image src={iconTrash} />}
        >
          Limpar
        </Button>

      </div>

      <div className="both"></div>

      <div className="bottom-space">
        <Stack direction="row" spacing={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}  adapterLocale={locale}>
            <DatePicker
              className="customDatePicker"
              slots={{ openPickerIcon: CalendarVivoIcon }}
              label="De"
              value={startDate}
              onChange={handleStartDateChange}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}  adapterLocale={locale}>
            <DatePicker
              className="customDatePicker"
              slots={{ openPickerIcon: CalendarVivoIcon }}
              label="Até"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </LocalizationProvider>
        </Stack>
      </div>

      <MultiSelect
        className="rmsc"
        overrideStrings={{ search: "O que você procura?" }}
        hasSelectAll={false}
        options={optionsCategoria}
        value={selectedCategoria}
        onChange={setSelectedCategoria}
        ArrowRenderer={ArrowRenderer}
        valueRenderer={labelCategoria}
      />


      <MultiSelect
        className="rmsc"
        overrideStrings={{ search: "O que você procura?" }}
        hasSelectAll={false}
        options={optionsSubCategoria}
        value={selectedSubCategoria}
        onChange={setSelectedSubCategoria}
        ArrowRenderer={ArrowRenderer}
        valueRenderer={labelSubCategoria}
      />

      <MultiSelect
        className="rmsc"
        overrideStrings={{ search: "O que você procura?" }}
        hasSelectAll={false}
        options={optionsVertical}
        value={selectedVertical}
        onChange={setSelectedVertical}
        ArrowRenderer={ArrowRenderer}
        valueRenderer={labelVertical}
      />


      <MultiSelect
        className="rmsc"
        overrideStrings={{ search: "O que você procura?" }}
        hasSelectAll={false}
        options={optionsCapacidades}
        value={selectedCapacidades}
        onChange={setSelectedCapacidades}
        ArrowRenderer={ArrowRenderer}
        valueRenderer={labelCapacidade}
      />

      <MultiSelect
        className="rmsc"
        overrideStrings={{ search: "O que você procura?" }}
        hasSelectAll={false}
        options={optionsCores} // Opções de cores
        value={selectedCores}
        onChange={setSelectedCores}
        ArrowRenderer={ArrowRenderer}
        valueRenderer={labelCores}
      />

      <MultiSelect
        className="rmsc"
        overrideStrings={{ search: "O que você procura?" }}
        hasSelectAll={false}
        options={optionsPagamento}
        value={selectedPagamento}
        onChange={setSelectedPagamento}
        ArrowRenderer={ArrowRenderer}
        valueRenderer={labelPagamento}
      />

      <MultiSelect
        className="rmsc"
        overrideStrings={{ search: "O que você procura?" }}
        hasSelectAll={false}
        options={optionsCartao}
        value={selectedCartao}
        onChange={setSelectedCartao}
        ArrowRenderer={ArrowRenderer}
        valueRenderer={labelCartao}
      />

      <MultiSelect
        className="rmsc"
        overrideStrings={{ search: "O que você procura?" }}
        hasSelectAll={false}
        options={optionsLojas}
        value={selectedLojas}
        onChange={setSelectedLojas}
        ArrowRenderer={ArrowRenderer}
        valueRenderer={labelLojas}
      />

      <MultiSelect
        className="rmsc"
        overrideStrings={{ search: "O que você procura?" }}
        hasSelectAll={false}
        options={optionsComSeller}
        value={selectedComSeller}
        onChange={setSelectedComSeller}
        ArrowRenderer={ArrowRenderer}
        valueRenderer={labelComSeller}
      />
      <MultiSelect
        className="rmsc"
        overrideStrings={{ search: "O que você procura?" }}
        hasSelectAll={false}
        options={optionsSellers}
        value={selectedSellers}
        onChange={setSelectedSellers}
        ArrowRenderer={ArrowRenderer}
        valueRenderer={labelSellers}
      />

      <MultiSelect
        className="rmsc"
        overrideStrings={{ search: "O que você procura?" }}
        hasSelectAll={false}
        options={optionsFabricantes}
        value={selectedFabricantes}
        onChange={setSelectedFabricantes}
        ArrowRenderer={ArrowRenderer}
        valueRenderer={labelFabricantes}
      />

      <MultiSelect
        className="rmsc"
        overrideStrings={{ search: "O que você procura?" }}
        hasSelectAll={false}
        options={optionsDisponibilidade}
        value={selectedDisponibilidade}
        onChange={setSelectedDisponibilidade}
        ArrowRenderer={ArrowRenderer}
        valueRenderer={labelDisponibilidade}
      />
    </div>
  );
};
