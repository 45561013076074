import React, { useState, useRef, useEffect } from "react";
import { IconClipRegular } from "@telefonica/mistica";
import iconSend from "../../../../src/assets/svg/icon-fill-business-send-plane-fill.svg";
import iconSendPurple from "../../../../src/assets/svg/icon-fill-business-send-plane-fill-purple.svg";
import * as S from "./styles";
 
const TextInputWithAttachment = ({
  textInputChat,
  emptyStateCardText,
  sendPrompt,
  setFiles,
  files
}) => {
 
  const [text, setText] = useState("");
  const [showPurpleIcon, setShowPurpleIcon] = useState(false);
  const textAreaRef = useRef(null);
 
  useEffect(() => {
    setText(emptyStateCardText);
    setShowPurpleIcon(true);
  }, [emptyStateCardText]);
  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    setShowPurpleIcon(newText.length > 0);
 
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  };
 
  const handleFileChange = async (e) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      setFiles(newFiles);
    }
  };
 
  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };
 
  const handleAttachFile = () => {
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };
 
  const handleSendText = () => {
    if (text.trim() !== "") {
      textInputChat(text);
      setText("");
      sendPrompt(text);
      // handleRemoveFile();
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey && text.trim() !== "") {
      handleSendText();
      event.preventDefault();
    }
    return;
  };
 
  return (
    <S.ChatBox>
      <S.ChatContainer>
        <S.ContainerUserInput>
          <S.Box>
            <S.UserInput
              text={text}
              ref={textAreaRef}
              placeholder="Como podemos te ajudar?"
              value={text}
              onKeyDown={handleKeyDown}
              onChange={handleTextChange}
            />
            <S.Icon
              onClick={handleSendText}
              src={showPurpleIcon ? iconSendPurple : iconSend}
              alt="send message icon"
            />
          </S.Box>
        </S.ContainerUserInput>
        <S.FileInput
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          multiple
          onChange={handleFileChange}
          accept=".doc,.docx, .pdf, .txt"
        />
        <S.ButtonFile htmlFor="fileInput" onClick={handleAttachFile}>
          <IconClipRegular color="#fff" size="18" />
          <S.AlignTexts>
            <S.TextButton>Inserir arquivos</S.TextButton>
            <S.TextSelectedFiles>{files.map(file => file.name).join(", ")}</S.TextSelectedFiles>
          </S.AlignTexts>
        </S.ButtonFile>
      </S.ChatContainer>
      {/* <S.Attachments>
        {files.map((file, index) => (
          <li key={index}>
            {file.name}
            <button onClick={() => handleRemoveFile(index)}> Remover</button>
          </li>
        ))}
      </S.Attachments> */}
    </S.ChatBox>
  );
};
 
export default TextInputWithAttachment;
 