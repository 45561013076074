import React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import "./CadastroProduto.css";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Image } from "application/styled-components";
import { FormProduto } from "./FormProduto";
import { ImportExcel } from "./ImportExcel";

import iconBarrow from "../../../../../assets/svg/arrow-back.svg";
import { useHistory } from "react-router-dom";




export const CadastroProduto = () => {
  const [versionPrompt, setVersionPrompt] = React.useState("");
  const [prompts, setPrompts] = useState([]);
  const query = new URLSearchParams(window.location.search);

  const history = useHistory();

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <div className="CadastroProduto">
      <p>
        <h3>Recomendação de preços</h3>
      </p>
      <div className="back-head">
      <Button
        onClick={() => history.goBack()}
        color="secondary"
        variant="outlined"
        startIcon={<Image src={iconBarrow} />}
      >
        {'Voltar'}
      </Button>
      </div>

      <Box sx={{ borderColor: "divider" }}>
        <Tabs
          value={value}
          textColor="secondary"
          indicatorColor="secondary"
          onChange={handleChange}
          className="customStyleTab"
        >
          <Tab label="Cadastro Manual" {...a11yProps(0)} />
          <Tab label="Cadastro por planilha" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>{/* Manual */}

        <FormProduto />
        
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ImportExcel/>
      </CustomTabPanel>
    </div>
  );
};
