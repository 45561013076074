import React, { useState, useRef } from "react";
import { endOfDay, format } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { ptBR } from 'date-fns/locale';
import { DateRange, DayPicker } from 'react-day-picker';
import { CSVLink } from "react-csv";
import Select from 'react-select';
import { toast } from 'react-toastify'

import AcessReportTablePagination from "../AcessReportTablePagination";
import { TabPanel } from "../TabPanel";

import { useLogins } from './hooks/useLogins';

import { ColourOption, ILoginHistory } from './interfaces';
import { colourOptions, colourStyles, formatCaption } from './utils';

import 'react-day-picker/dist/style.css';
import { Content, FormContainer, Search } from './styles';
import AuthService from "application/services/AuthService";
import { useSelector } from "react-redux";

const AcessReport: React.FC = () => {
  const { logins } = useLogins();
  const [loginHistory, setLoginHistory] = useState<ILoginHistory[]>([])
  const [selectedLogin, setSelectedLogin] = useState<number | null>(null)
  const [range, setRange] = useState<DateRange | undefined>();
  const [showCard, setShowCard] = useState(false);
  const [titleReport, setTitleReport] = useState<string>('Visualizar relatório');
  const csvDownloadRef: any = useRef(null)

  const handleLoginUpdate = (event: any) => {
    setSelectedLogin(event.value)
  }

  const handleReport = async () => {
    if (!showCard) {

      setLoginHistory([])

      if (!range || !range.from) {
        return toast.error('Selecione uma ou mais datas.', {
          autoClose: 1500
        })
      }

      const startDate = zonedTimeToUtc(new Date(range.from), 'America/Sao_Paulo');

      const endDate = range.to ? endOfDay(new Date(range.to)) : endOfDay(new Date(range.from))

      const parsedEndDate = zonedTimeToUtc(endDate, 'America/Sao_Paulo');

      if (!selectedLogin || String(selectedLogin) === 'allUsers') {
        await AuthService.getLoginHistory(userDataState, null, startDate, parsedEndDate).then((response) => {

          if (response.logins.length == 0) {
            return toast.error('Não há dados para essa data.', {
              autoClose: 1500
            })
          }

          setLoginHistory(response.logins)
          setTitleReport('Fechar relatório')
          setShowCard(state => !state)
        })
      } else {
        await AuthService.getLoginHistory(userDataState, String(selectedLogin), startDate, parsedEndDate).then((response) => {
          if (response.logins.length == 0) {
            return toast.error('Não há dados para essa data.', {
              autoClose: 1500
            })
          }

          setLoginHistory(response.logins)
          setTitleReport('Fechar relatório')
          setShowCard(state => !state)
        })
      }
    } else {
      setTitleReport('Visualizar relatório')
      setLoginHistory([])
      setShowCard(state => !state)
    }
  }

  const handleExtractReport = async () => {

    if (!showCard) {
      setLoginHistory([])

      if (!range || !range.from) {
        return toast.error('Selecione uma ou mais datas.', {
          autoClose: 1500
        })
      }

      const startDate = zonedTimeToUtc(new Date(range.from), 'America/Sao_Paulo');

      const endDate = range.to ? endOfDay(new Date(range.to)) : endOfDay(new Date(range.from))

      const parsedEndDate = zonedTimeToUtc(endDate, 'America/Sao_Paulo');


      if (!selectedLogin || String(selectedLogin) === 'allUsers') {
        await AuthService.getLoginHistory(userDataState, null, startDate, parsedEndDate).then((response) => {

          if (response.logins.length == 0) {
            return toast.error('Não há dados para essa data.', {
              autoClose: 1500
            })
          }

          setLoginHistory(response.logins)
          csvDownloadRef.current.link.click();
        })
      } else {
        await AuthService.getLoginHistory(userDataState, String(selectedLogin), startDate, parsedEndDate).then((response) => {
          if (response.logins.length == 0) {
            return toast.error('Não há dados para essa data.', {
              autoClose: 1500
            })
          }

          setLoginHistory(response.logins)
          csvDownloadRef.current.link.click();
        })
      }

    } else {
      csvDownloadRef.current.link.click();
    }
  }

  const loginOptions: readonly ColourOption[] = logins.map(login => ({
    value: String(login.user_id),
    label: `${login.user_id} - ${login.user_name}`,
    color: '#5243AA'
  }))

  const selectedOptionLogin = loginOptions.find(login => Number(login.value) === selectedLogin)

  const userDataState = useSelector((state: any) => state.user)

  return (
    <TabPanel active={true}>
      <FormContainer>
        <Content>

          <Select
            defaultValue={colourOptions}
            options={[colourOptions, ...loginOptions,]}
            styles={colourStyles}
            value={selectedOptionLogin}
            onChange={handleLoginUpdate}
          />

          <DayPicker
            defaultMonth={new Date()}
            mode="range"
            min={0}
            max={31}
            selected={range}
            onSelect={setRange}
            formatters={{ formatCaption }}
            locale={ptBR}
          />

          <Search>
            <button onClick={handleReport}>{titleReport}</button>
            <CSVLink
              filename={`relatorio_acessos_labia_${format(new Date(), "dd_MM_yyyy")}`}
              data={loginHistory}
              separator=";"
              target="_blank"
              ref={csvDownloadRef}
            >
            </CSVLink>
            <button onClick={handleExtractReport}>Extrair relatório</button>
          </Search>


        </Content>
      </FormContainer>
      {
        showCard && <>
          <AcessReportTablePagination data={loginHistory} rowsPerPage={20} />
        </>}
    </TabPanel>
  )
};

export default AcessReport;
