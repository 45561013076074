import styled, { keyframes } from "styled-components";

export const BackdropModal = styled.div`
  background-color: rgba(11, 39, 57, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const modalAppear = keyframes`
  from {
    width: 0;
    height: 0;
  }
  to {
    width: 543px;
    height: 328px; 
  }`;

const modalClose = keyframes`
  from {
    width: 543px;
    height: 328px; 
   
  }
  to {
     width: 0;
    height: 0;
  }
`;
export const ModalFeedbackBox = styled.div`
  z-index: 20;
  width: 543px;
  height: 328px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  padding: 0px 24px;
  animation: ${modalAppear} 0.3s;

  &.close {
    animation: ${modalClose} 0.3s;
  }
`;
export const TextAreaForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-end;
`;
export const InlineLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const TextAreaFeedback = styled.textarea`
  width: 95%;
  height: 184px;
  outline: 0;
  padding: 8px 14px 8px 12px;
  resize: none;
  font-family: Telefonica !important;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  margin-bottom: 12px;
  /* text */
  overflow: hidden;
  color: #86888c;
  text-overflow: ellipsis;
  font-family: Vivo Type;
  font-size: 16px;
  font-weight: 500;
`;
export const ButtonSendFeedback = styled.button`
  color: #fff;
  text-align: center;
  font-family: Telefonica !important;
  font-size: 18px;
  font-weight: 500;
  background: #609;
  height: 40px;
  width: 104px;
  padding: 12px 16px;
  border-radius: 4px;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  &:disabled {
    opacity: 0.5;
  }
`;
