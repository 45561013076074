// import ServiceHelperLogin from ../helpers/ServiceHelperLogin;
import axios from 'axios'
import { User } from './interfaces/User'
import { HandlerError } from './HandlerError'


class AudioManagerService {
  private static readonly API_URL = process.env.REACT_APP_API_URL_SERVICE_AUDIO_MANAGER
  private static readonly SERVICE = 'ADMIN'

  private static getConfig = ({
    user_id,
    session_id,
    token,
    user_profile,
    subscriptionSelected,
  }: User) => {
    return {
      headers: {
        session_id: session_id,
        user_id: user_id,
        token: token,
        service: this.SERVICE,
        subscription_id: subscriptionSelected,
        user_profile: user_profile
      },
    }
  }

  static async getAllAudios(userData: User) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.get(`${this.API_URL}/audio`, CONFIG)
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async uploadAudio(
    userData: User,
    filename: string,
    file: File,
  ) {
    try {
      const CONFIG = this.getConfig(userData)

      const formData = new FormData()
      formData.append('filename', filename)
      formData.append('file', file)

      const response = await axios.post(
        `${this.API_URL}/audio`,
        formData,
        CONFIG,
      )
      return response.data;
    } catch (error: any) {
      return null;
    }
  }

}



export default AudioManagerService;