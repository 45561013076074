import {  useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSidebarOpen } from "providers/reducers/header";
import { useHistory } from 'react-router-dom'
import { setSubscription } from "providers/reducers/user";
import Layout from "../../components/Layout";
import WaveSoundIconSvg from '../../../assets/svg/noun-sound-waves.svg';

import { MainContainer, MenuContainer, TitleContainer, Title, WaveSoundIcon } from "./styles";
import { useAuthenticationQNA } from "application/routes/hooks/useAuthentication";
import { AudioList } from "./components/audios";
import { Tabs } from "./components/styles";

export const Whisper = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  
  const regex = /[?&]subscription_id=([^&#]*)/;
  const match = regex.exec(history.location.search);
  const subscription_id = match && match[1];
  dispatch(setSubscription(subscription_id));
  const [selectedIndex, setSelectedIndex] = useState(0)

  const expanded = useSelector((state: any) => state.header.sidebarOpen)
  const userDataState = useSelector((state: any) => state.user)
  const sidebar = useSelector((state: any) => state.sidebar)

  function handleSidebar(state) {
    dispatch(setSidebarOpen(state))
  }

  useAuthenticationQNA(userDataState);

  const tabs = ["Audios"];

  return (
    <Layout
      expanded={expanded}
      setExpanded={handleSidebar}
      username={userDataState.user_name}
      sidebarOptions={JSON.parse(sidebar.pages as unknown as string)}
    >
      <TitleContainer>
          <WaveSoundIcon src={WaveSoundIconSvg} />
          <div>
            <Title>{"Whisper"}</Title>
            <span><i>faça uma transcrição de áudio para texto</i></span>
          </div>
          
      </TitleContainer>
      <MainContainer>
        <MenuContainer>
          <Tabs
            selectedIndex={selectedIndex}
            onChange={setSelectedIndex}
            tabs={tabs.map((text) => ({
                text
            }))}
          />
        </MenuContainer>
        {selectedIndex === 0 && <AudioList subscription_id={subscription_id} />}
      </MainContainer>

    </Layout>
  );
};
