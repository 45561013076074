import styled from 'styled-components';

export const Card = styled.div`
  background-color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 14px 12px;
  padding: 14px;
  text-align: left;
  width: calc(30% - 10px);
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);

  .title {
    display: flex;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;

    p {
      margin-left: 14px;
      font-size: 22px;
      font-weight: bold;
    }

    img {
      width: 68px;
    }
  }

  span {
    padding: 14px;
    font-size: 18px;
    color: #B0B0B0;
    width: 80%;
    word-break: break-word;
  }

  &:hover {
    box-shadow: 0px 0px 4px 4px rgba(82, 67, 170, 0.2);
    transition: filter 0.4s ease;
  }
`

export const Intro = styled.div`
  margin: 10px 52px;
`