"use client";
import * as S from "./styles";
import iconVivo from "../../../assets/svg/icon-vivo.svg";
import {
  IconLikeRegular,
  IconThumbDownRegular,
  IconCopyRegular,
} from "@telefonica/mistica";
import React, { useState, useEffect } from "react";

const ChatResponseGpt = ({
  question,
  responseArray,
  isLast,
  handleLikeFeedback,
  index,
  initials,
  isPulsingLike,
  isPulsingDislike,
  tones,
  isFirstMessage,
}) => {
  const [typedText, setTypedText] = useState("");
  const [charIndex, setCharIndex] = useState(0);
  const [copied, setCopied] = useState(false);
  const [isPulsing, setIsPulsing] = useState(false);

  const handleCopyText = () => {
    const textArea = document.createElement("textarea");
    textArea.value = responseArray.response;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);

    setCopied(true);
    setIsPulsing(true);

    setTimeout(() => {
      setCopied(false);
      setIsPulsing(false);
    }, 1000);
  };

  useEffect(() => {
    if (!responseArray) {
      return;
    }

    const typingTimer = setTimeout(() => {
      if (charIndex < responseArray.length) {
        setTypedText((prevText) => prevText + responseArray[charIndex]);
        setCharIndex((prevIndex) => prevIndex + 1);
      }
    }, 0.5);

    return () => clearTimeout(typingTimer);
  }, [charIndex, responseArray]);
  const formatText = (text) => {
    let isCodeBlock = false;
    text = text.split("\n").map((line, key) => {
      if (line.trim() === "```") {
        isCodeBlock = !isCodeBlock;
        return null;
      }
      if (isCodeBlock) {
        return (
          <pre key={key}>
            <code>{line}</code>
          </pre>
        );
      } else {
        line = line.replace(/\*\*(.*?)\*\*/g, "$1");
        line = line.replace(/\* (.*?)(\n|$)/g, "<li>$1</li>");
        return <p key={key} dangerouslySetInnerHTML={{ __html: line }} />;
      }
    });

    return text;
  };

  const textoFormatado = responseArray ? (
    formatText(responseArray.response)
  ) : (
    <div className="loading-dots">
      <div className="loading-dot"></div>
      <div className="loading-dot"></div>
      <div className="loading-dot"></div>
    </div>
  );

  React.useEffect(() => {
    const scrollToBottom = () => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    };
    scrollToBottom();
  }, [question, responseArray])

  const formatTone = (tones) => { 
    return <>
      <p><span style={{ color: "#660099", fontWeight: "600" }}>Tom Selecionado: </span>[Tipo de conteúdo: {tones.Tipodeconteudo}], [Tom de voz: {tones.Tom}], [Personalidade: {tones.Personalidade}], [Linguagem: {tones.Linguagem}], [Estilo: {tones.Estilo}], [Propósito: {tones.Proposito}].</p>
    </>
  }

  return (
    <S.ChatResponseContainer>
      <S.Container>
        <S.ChatQuestion>
          <S.BoxQuestion>
            <S.IconUser>{initials}</S.IconUser>
            <S.Question>{isFirstMessage ?
              <>
                {question}
                {formatTone(tones)}  
              </> : question}</S.Question>
          </S.BoxQuestion>
        </S.ChatQuestion>
        <S.ChatResponse isLast={isLast}>
          <S.Icon src={iconVivo} alt="Icone Perfil" color="#fff" />
          {responseArray ? (
            <div className="typing-text"> {textoFormatado}</div>
          ) : (
            <div className="loading-dots">
              <div className="loading-dot"></div>
              <div className="loading-dot"></div>
              <div className="loading-dot"></div>
            </div>
          )}

          {
            responseArray !== undefined && <S.Feedback>
              <IconCopyRegular
                color={isPulsing ? "#800080" : "#86888C"}
                size="24"
                onClick={handleCopyText}
              />

              {copied && <span>Copiado</span>}
              {
                !responseArray.hasFeedback ?
                  <>
                    <IconLikeRegular
                        color={isPulsingLike ? "#5CB615" : "#86888C"}
                        size="24"
                        onClick={() => handleLikeFeedback("positivo", `${responseArray.correlator}`)}
                      />
                      <IconThumbDownRegular
                        color={isPulsingDislike ? "#FF374A" : "#86888C"}
                        size="24"
                        onClick={() => handleLikeFeedback("negativo", `${responseArray.correlator}`)}
                      />
                  </> : <>
                    {responseArray.feedback === 'positive'? <IconLikeRegular color={"#5CB615"} size="24" /> : <IconThumbDownRegular color={"#FF374A"} size="24" />}
                  </>
              }
            </S.Feedback> 
          }
        </S.ChatResponse>
      </S.Container>
    </S.ChatResponseContainer>
  );
};

export default ChatResponseGpt;
