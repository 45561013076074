import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import Button from "@mui/material/Button";
import "./CadastraCep.css";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSubscription } from "providers/reducers/user";
import PrecificadorService from "application/services/PrecificadorService";
import iconBarrow from "../../../../../assets/svg/arrow-back.svg";
import { Image } from "application/styled-components";
import iconMsgSuccess from "../../../../../assets/svg/ic-msg-success.svg";
import Stack from "@mui/material/Stack";


export const CadastraCep = () => {
  const query = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const history = useHistory();
  const regex = /[?&]subscription_id=([^&#]*)/;
  const match = regex.exec(history.location.search);
  const subscription_id = match && match[1];
  dispatch(setSubscription(subscription_id));
  const userDataState = useSelector((state) => state.user);
  const { subscriptionName } = userDataState.subscriptions.find(
    (subs) => subs.subscriptionId === subscription_id
  );

  const cepId = query.get('id');
  const [isEditing, setIsEditing] = useState(cepId !== null);
  const [valueCEP, setValueCEP] = useState(query.get('cep'));
  const [valueEstado, setValueEstado] = useState(query.get('estado'));
  const [confirmationMessage, setMessage] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);


  const Submit = async () => {
    if (!valueCEP || !valueEstado) {
      setMessage("Por favor, preencha todos os campos.");
      setTimeout(() => {
        setMessage("");
      }, 6000);
      return;
    }
    if (isEditing) {
      setMessage("Editando cadastro...");
      try {
        const formData = {
          estado: valueEstado,
          numero_cep: valueCEP.replace(/[-]/g, "")
        };
        console.log(formData)
        const response = await PrecificadorService.editForm(userDataState, subscriptionName, formData, `update_cep/${cepId}`);
        if (response.hasOwnProperty('error')) {
          setMessage(response.error);
        }
        else {
          setMessage(response.message);

        }
      } catch (error) {
        console.error("Erro ao editar CEP:", error);
        setMessage("Erro ao realizar edição");
      }
      finally {
        setTimeout(() => {
          setMessage("");
        }, 9000); // hide apos tempo
      }
    } else {
      setMessage("Realizando cadastro...");
      try {
        const formData = {
          estado: valueEstado,
          numero_cep: valueCEP.replace(/[-]/g, "")
        };
        console.log(formData)
        const response = await PrecificadorService.createForm(userDataState, subscriptionName, formData, "create_cep");
        setValueCEP("");
        setValueEstado("");
        if (response.hasOwnProperty('error')) {
          setMessage(response.error);
        }
        else {
          setMessage(response.message);
          setSubmitSuccess(true);
        }
      } catch (error) {
        console.error("Erro ao criar produto cadastra:", error);
        setMessage("Erro ao realizar cadastro");
      } finally {
        setTimeout(() => {
          setMessage("");
        }, 6000); // hide apos tempo
      }
    }
  };

  const handleNewProduct = () => {
    setSubmitSuccess(false);
  };

  return (
    <div className="CadastraCep">


      <p>
        <h3>Consulta de preços</h3>
      </p>
      <div className="back-head">
        <Button
          onClick={() => history.goBack()}
          color="secondary"
          variant="outlined"
          startIcon={<Image src={iconBarrow} />}
        >
          {'Voltar'}
        </Button>
      </div>
      <div className={submitSuccess ? 'hide-table' : 'show-table'}>
        <div className="box-cadastra-cep">
          <h3>Dados do CEP</h3>
          <p>
            <InputMask mask="99999-999"
              value={valueCEP}
              onChange={(e) => setValueCEP(e.target.value)}
              disabled={false} maskChar=" ">
              {() => (
                <TextField
                  color="secondary"
                  id="outlined-basic"
                  label="Digite o CEP (04571-936)"
                  variant="outlined"
                />
              )}
            </InputMask>
          </p>

          <TextField
            color="secondary"
            id="outlined-basic"
            label="Digite o Estado"
            variant="outlined"
            value={valueEstado}
            onChange={(e) => setValueEstado(e.target.value)}
          />
          <p style={{ textAlign: "right" }}>
            <Button variant="contained" color="secondary" onClick={Submit}>
              {isEditing ? "Salvar" : "Cadastrar"} { }
            </Button>
          </p>
          {confirmationMessage && (
            <p>{confirmationMessage}</p>
          )}
        </div>
      </div>


      <div className={submitSuccess ? 'show-table' : 'hide-table'}>
        <div className="box-file">
          <div >
            <p>
              <Image src={iconMsgSuccess} width={45} />
            </p>
            <div className="both"></div>

            <p> <h3>Cadastro realizado!</h3> </p>
            <p>
              <div className="op2">
                <Stack direction="row" spacing={3}>
                  <Button
                    onClick={() => history.goBack()}
                    color="secondary"
                    variant="contained"
                  >
                    {'Visualizar CEPs'}
                  </Button>
                  <Button
                    onClick={handleNewProduct}
                    color="secondary"
                    variant="outlined"
                  >
                    {'Novo cadastro'}
                  </Button>
                </Stack >
              </div>
            </p>
          </div>

        </div>
      </div>
    </div>

  );
};
