import { useHistory } from "react-router-dom";

import { User } from "application/services/interfaces/User";
import AuthService from "application/services/AuthService";
import { validatePermissionGPT } from 'application/services/ChatGPTService'
import CompanySummaryService from 'application/services/CompanySummaryService'
import QandaService from 'application/services/QandaService'
import QandaAdminService from 'application/services/QandaAdminService'
import ContentGeneratorService from 'application/services/ContentGeneratorService'
import PrecificadorService from 'application/services/PrecificadorService'

import { validatePayload } from '../../utils/validatePayload'

interface Payload {
  code: string
  subscriptionSelected: string
}

const redirectUserIsNotToken = async (payload?: Payload) => {
  return window.location.href = payload ? `${process.env.REACT_APP_API_URL_BASENAME}/login?subscription_id=${payload?.subscriptionSelected}&payload=${payload.code}` :  '/login'
}

const redirectUserIsValidationPermission = (payload?: Payload) => {
  return window.location.href = `${window.location.pathname}?subscription_id=${payload?.subscriptionSelected}`
}

export const useAuthenticationAdmin = async (user: User) => {
  const payload = validatePayload(window.location.pathname)

  if (user.token === '') {
    return redirectUserIsNotToken(payload)
  }

  await AuthService.validatePermission(user)

  if (payload) {
    redirectUserIsValidationPermission(payload)
  }
}

export const useAuthenticationQNA = async (user: User) => {
  const payload = validatePayload(window.location.pathname)

  if (user.token === '') {
    return redirectUserIsNotToken(payload)
  }

  await QandaService.validatePermission(user)

  if (payload) {
    redirectUserIsValidationPermission(payload)
  }
}

export const useAuthenticationQNAAdmin = async (user: User) => {
  const payload = validatePayload(window.location.pathname)

  if (user.token === '') {
    return redirectUserIsNotToken(payload)
  }

  await QandaAdminService.validatePermission(user)

  if (payload) {
    redirectUserIsValidationPermission(payload)
  }
}

export const checkAuthenticationSummary = async (user: User) => {
  const payload = validatePayload(window.location.pathname)

  if (user.token === '') {
    return redirectUserIsNotToken(payload)
  }

  await CompanySummaryService.validatePermission(user)

  if (payload) {
    redirectUserIsValidationPermission(payload)
  }
}

export const useAuthenticationGPT = async (user: User) => {
  const payload = validatePayload(window.location.pathname)

  if (user.token === '') {
    return redirectUserIsNotToken(payload)
  }

  await validatePermissionGPT(user)

  if (payload) {
    redirectUserIsValidationPermission(payload)
  }
}

export const useAuthenticationContentGenerator = async (user: User) => {
  const payload = validatePayload(window.location.pathname)

  if (user.token === '') {
    return redirectUserIsNotToken(payload)
  }

  await ContentGeneratorService.validatePermission(user)

  if (payload) {
    redirectUserIsValidationPermission(payload)
  }
}

export const useAuthenticationPrecificador = async (user: User) => {
  const payload = validatePayload(window.location.pathname)

  if (user.token === '') {
    return redirectUserIsNotToken(payload)
  }

  await PrecificadorService.validatePermission(user)

  if (payload) {
    redirectUserIsValidationPermission(payload)
  }
}
