import React, { useState, useEffect } from 'react'
import { ButtonsWrap, Form, FormWrap, LabelFullWidth, ModalOverLay, ModalWrap, PermissionLabel, Permissions, ProfileButton, ProfileInput, ProfileLabel, PermissionsContainer } from "application/styled-components/Perfil"
import { Paragraph } from "application/styled-components/chatGPT"

import AuthService from '../../services/AuthService'
import { toast } from 'react-toastify'

import { useSelector } from 'react-redux'

export const ModalPermissionSubscription = ({ isOpen, closeModal, onClose, permissionSelected }) => {

    const userDataState = useSelector((state) => state.user);
    let [subscriptionsToAdd, setSubscriptionsToAdd] = useState([])
    let [subscriptionsToRemove, setSubscriptionsToRemove] = useState([])


    let [subscriptionsAvaiableToGrant, setSubscriptionsAvaiableToGrant] = useState([])
    let [subscriptionsNotAvaiableToGrant, setSubscriptionsNotAvaiableToGrant] = useState([])

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;
        if (checked) {
            setSubscriptionsToAdd((prevPermissions) => [...prevPermissions, value]);
        } else {
            setSubscriptionsToAdd((prevPermissions) => prevPermissions.filter((p) => p !== value));
        }
    }


    const handleCheckboxChangeDelete = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;
        if (checked) {
            setSubscriptionsToRemove((prevPermissions) => [...prevPermissions, value]);
        } else {
            setSubscriptionsToRemove((prevPermissions) => prevPermissions.filter((p) => p !== value));
        }
    }

    const handleSave = async (e) => {
        e.preventDefault();
        try {
            if (subscriptionsToAdd.length > 0) {
                subscriptionsToAdd.map((subsToAdd) => {
                    AuthService.givePermissionToASubscription(userDataState, permissionSelected._id, subsToAdd)
                })
            }
            if (subscriptionsToRemove.length > 0) {
                subscriptionsToRemove.map((subsToRemove) => {
                    AuthService.removePermissionFromASubscription(userDataState, permissionSelected._id, subsToRemove)
                })
            }
            closeModal()
            toast.success('Permissoes atualizadas com sucesso', {
                autoClose: 1500
            })
        } catch (err) {
            console.log('error updating subscription permissions')
            console.log(err)
        }

    }

    const getAllSubscriptions = async () => {
        const subscriptions = await AuthService.getAllsubscriptions(userDataState)
        const avaiableToGrant = subscriptions.subscriptions.filter(subcription => !subcription.permissions.includes(permissionSelected.name))
        const notAvaiableToGrant = subscriptions.subscriptions.filter(subcription => subcription.permissions.includes(permissionSelected.name))

        setSubscriptionsAvaiableToGrant(avaiableToGrant)
        setSubscriptionsNotAvaiableToGrant(notAvaiableToGrant)
    }


    useEffect(() => {
        getAllSubscriptions()
    }, [])

    return (
        <>
            <ModalOverLay>

                <ModalWrap>
                    <Paragraph style={{ textAlign: 'center' }}>
                        <b>{permissionSelected.name}</b>
                    </Paragraph>
                    <Paragraph style={{ textAlign: 'center' }}>
                        {permissionSelected.description}
                    </Paragraph>
                    <Form isOpen={isOpen} onClose={onClose} onSubmit={handleSave}>
                        <FormWrap>
                            <ProfileLabel>Perfis disponíveis para conceder a permissão:</ProfileLabel>
                            <PermissionsContainer>
                                <Permissions>
                                    {
                                        subscriptionsAvaiableToGrant.map((subscription, index) => {

                                            return (
                                                <PermissionLabel for={'profile-permission-' + subscription._id} key={index}>
                                                    <ProfileInput type="checkbox" id={'profile-permission-' + subscription._id} value={subscription._id} onChange={handleCheckboxChange} />
                                                    <ProfileLabel for={'profile-permission-' + subscription.name}>{subscription.name}</ProfileLabel>
                                                    <Paragraph>{subscription.description}</Paragraph>
                                                </PermissionLabel>
                                            )
                                        })
                                    }
                                </Permissions>
                            </PermissionsContainer>
                        </FormWrap>
                        <FormWrap>
                            <ProfileLabel>Perfis disponíveis para remover a permissão:</ProfileLabel>
                            <Permissions>
                                {
                                    subscriptionsNotAvaiableToGrant.map((subscription, index) => {

                                        return (
                                            <PermissionLabel for={'profile-permission-' + subscription._id} key={index}>
                                                <ProfileInput type="checkbox" id={'profile-permission-' + subscription._id} value={subscription._id} onChange={handleCheckboxChangeDelete} />
                                                <ProfileLabel for={'profile-permission-' + subscription.name}>{subscription.name}</ProfileLabel>
                                                <Paragraph>{subscription.description}</Paragraph>
                                            </PermissionLabel>
                                        )
                                    })
                                }
                            </Permissions>
                        </FormWrap>
                        <ButtonsWrap>
                            <ProfileButton onClick={handleSave}>
                                Aplicar
                            </ProfileButton>
                            <ProfileButton className='cancel' onClick={closeModal}>
                                Cancelar
                            </ProfileButton>
                        </ButtonsWrap>
                    </Form>
                </ModalWrap>
            </ModalOverLay>
        </>
    )
}