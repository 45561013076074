import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// import { setUser } from "providers/reducers/user";
import { setSidebarOpen } from "providers/reducers/header";
import { setSubscription } from "providers/reducers/user";

import { Tab, Tabs } from "../../../styled-components";
import Layout from "../../../components/Layout";

import { PerfilDashBoard } from "application/components/PerfilDashBoard";
import { AdministrationTenants } from "application/components/AdministrationTenants";
import { Title } from "application/styled-components";
import { useAuthenticationAdmin } from "application/routes/hooks/useAuthentication";
import SubscriptionDashboard from "application/components/SubscriptionDashboard";
import AcessReport from "application/components/AcessReport";
import { useHistory } from "react-router-dom";

export const UserAndPermissions = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const regex = /[?&]subscription_id=([^&#]*)/;
  const match = regex.exec(history.location.search);
  const subscription_id = match && match[1];
  dispatch(setSubscription(subscription_id));

  let expanded = useSelector((state) => state.header.sidebarOpen);
  const userDataState = useSelector((state) => state.user);
  const sidebar = useSelector((state) => state.sidebar);
  useAuthenticationAdmin(userDataState);

  // let [user, setUserData] = useState({});
  // let [sidebarOptions, setSidebar] = useState([]);
  let [activeTab, setActiveTab] = useState(0);

  function handleSidebar(state) {
    dispatch(setSidebarOpen(state));
  }

  const tabs = [];
  if (["localhost", "dev"].includes(process.env.REACT_APP_ENV)) {
    tabs.push(
      {
        label: "Perfis OAM",
        component: <AdministrationTenants />,
      },
      {
        label: "Produtos",
        component: <SubscriptionDashboard />,
      },
      {
        label: "Permissões de API",
        component: <PerfilDashBoard />,
      },
      {
        label: "Relatório de acesso",
        component: <AcessReport />,
      }
    );
  } else {
    if (userDataState.user_profile === "VGPT_ADMIN_LAB_IA") {
      tabs.push(
        {
          label: "Produtos",
          component: <SubscriptionDashboard />,
        },
        {
          label: "Permissões de API",
          component: <PerfilDashBoard />,
        },
        {
          label: "Relatório de acesso",
          component: <AcessReport />,
        }
      );
    }

    if (userDataState.user_profile === "VGPT_ADMIN_ID") {
      tabs.push({
        label: "Perfis OAM",
        component: <AdministrationTenants />,
      });
    }
  }

  return (
    <Layout
      expanded={expanded}
      setExpanded={handleSidebar}
      username={userDataState.user_name}
      sidebarOptions={JSON.parse(sidebar.pages)}
      position={"relative"}
    >
      <Title>Perfis, produtos e permissões LAB.IA</Title>
      <Tabs>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            onClick={() => setActiveTab(index)}
            className={activeTab === index ? "active" : ""}
          >
            {tab.label}
          </Tab>
        ))}
      </Tabs>
      {tabs[activeTab].component}
    </Layout>
  );
};
