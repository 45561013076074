// import ServiceHelperLogin from "../helpers/ServiceHelperLogin";
import axios from 'axios'
import { User } from './interfaces/User'
import { HandlerError } from './HandlerError'
class FeedBackService {
  private static readonly API_URL =
    process.env.REACT_APP_API_URL_SERVICE_FEEDBACK
  private static readonly SERVICE_CHAT_GPT = 'CHAT_GPT'
  private static readonly COMPANY_SUMMARY = 'COMPANY_SUMMARY'
  private static readonly QNA_MOBILE_PRODUCTS = 'QNA_MOBILE_PRODUCTS'
  private static readonly CONTENT_GENERATOR = 'API_CONTENT_GENERATOR'
  private static getConfig = (
    {
      user_id,
      session_id,
      token,
      user_email,
      user_name,
      subscriptionSelected,
    }: User,
    service: string,
  ) => {
    return {
      headers: {
        session_id: session_id,
        user_id: user_id,
        token: token,
        service: service,
        subscription_id: subscriptionSelected,
      },
    }
  }

  static async feedbackChatGPT(
    userData: User,
    feedback: string,
    correlator: string,
    message: string,
  ) {
    try {
      const CONFIG = this.getConfig(userData, this.SERVICE_CHAT_GPT)
      const response = await axios.post(
        `${this.API_URL}/feedback`,
        {
          feedback,
          message,
          correlator,
        },
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async feedbackQNA(
    userData: User,
    feedback: string,
    correlator: string,
    message: string,
  ) {
    try {
      const CONFIG = this.getConfig(userData, this.QNA_MOBILE_PRODUCTS)
      const response = await axios.post(
        `${this.API_URL}/feedback`,
        {
          feedback,
          message,
          correlator,
        },
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async feedbackCompany(
    userData: User,
    feedback: string,
    correlator: string,
    message: string,
  ) {
    try {
      const CONFIG = this.getConfig(userData, this.COMPANY_SUMMARY)
      const response = await axios.post(
        `${this.API_URL}/feedback`,
        {
          feedback,
          message,
          correlator,
        },
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }
  static async feedbackContentGenerator(
    userData: User,
    feedback: string,
    correlator: string,
    message: string,
  ) {
    try {
      const CONFIG = this.getConfig(userData, this.CONTENT_GENERATOR)
      const response = await axios.post(
        `${this.API_URL}/feedback`,
        {
          feedback,
          message,
          correlator,
        },
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }
}

export default FeedBackService
