import React, { useState } from "react";
import { ModalHeader, ModalOverLay, ModalWrap, Wrapper } from "application/styled-components/Perfil";
import { Button, Paragraph } from "application/styled-components/chatGPT";
import { IconCloseRegular, IconFileMusicRegular } from "@telefonica/mistica";
import "react-toastify/dist/ReactToastify.css";
import { ItensModal, LoadTranscription, PdfText, PdfWrap, TabsModal } from "application/pages/Whisper/components/styles";
import { getNameDiarization } from "application/pages/Whisper/data/audios";

interface ModalAudioViewerProps {
    closeModal: () => void;
    transcription: string;
    originalTranscription: string;
    audioUrl: string;
    diarization: any;
    title: string;
}

const ModalAudioViewer: React.FC<ModalAudioViewerProps> = ({ closeModal, transcription, originalTranscription, audioUrl, diarization, title }) => {
    const [isImprovedGpt, setIsImprovedGpt] = useState(true);
    const [loadTranscription, setLoadTranscription] = useState(false);

    const handleSwitchTranscription = () => {
        setLoadTranscription(true);
        setIsImprovedGpt(!isImprovedGpt);

        setTimeout(() => {
            setLoadTranscription(false);
        }, 1400);
    }
    
    return (
        <>
            <ModalOverLay>
                <ModalWrap>
                    <ModalHeader>
                        <Wrapper fill={"#660099"}>
                            <IconFileMusicRegular />
                            <Paragraph margin={0} fontSize="1.25rem">
                                Transcrição de áudio
                            </Paragraph>
                        </Wrapper>
                        <Button onClick={closeModal} key={"closeModal"}>
                            <IconCloseRegular color={"#8822aa"}/>
                        </Button>
                    </ModalHeader>
                    <PdfWrap>
                    <span>{title}</span>
                    
                    {loadTranscription && (
                        <LoadTranscription>
                        <div></div>
                        </LoadTranscription>
                    )}

                    {!loadTranscription && isImprovedGpt && transcription?.split("\n").map((line, index) => {

                        const name = getNameDiarization(line);

                        const lineText = line.substring(line.indexOf("[") + 1).split("]: ");

                        if (!name) {
                            return (<PdfText float={"left"} background={"#ede7d5"} key={index}>{line}</PdfText>)   
                        }

                        if (diarization[name] % 2 === 0) {
                            return (
                                <PdfText float={"right"} background={"#402E75"} color={"#fff"} key={index}>
                                    <span> {lineText[0]}</span>
                                    {lineText[1]}
                                </PdfText>
                            )
                        } else {
                            return (
                                
                                <PdfText float={"left"} background={"#ede7d5"} backgroundSpan={"#B2BCE0"} colorSpan={"#fdfcfe"} key={index}>
                                    <span> {lineText[0]}</span>
                                    {lineText[1]}
                                </PdfText>
                            )   
                        }
                             
                    })}

                    {!loadTranscription && !isImprovedGpt && originalTranscription?.split("\n").map((line, index) => {
                        return (<PdfText float={"left"} background={"#ede7d5"} key={index}>{line}</PdfText>)   
                    })}
                    </PdfWrap>
                    <ItensModal>
                        <audio controls src={audioUrl}></audio>
                        <div>
                            <span>Transcrição formatada (GPT):</span>
                            <TabsModal>
                                <input onChange={handleSwitchTranscription} type="checkbox" id="improved-transcription" defaultChecked/>
                                <label htmlFor="improved-transcription"></label>
                            </TabsModal>
                        </div>
                    </ItensModal>
                </ModalWrap>
            </ModalOverLay>
        </>
    );
};

export default ModalAudioViewer;
