import styled from 'styled-components';

export const FormContainer = styled.div`
    position: ${(props) => props.position};
    width: ${(props) => props.width};
    bottom: ${(props) => props.bottom};
    padding: 12px 8px;
    background-color: #fff;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
    margin: ${(props) => props.margin ? props.margin : "8px 40px 25px;"};
    align-items: stretch;

    table {
      border-collapse: collapse;
      border: none;
      width: 100%;
    }

    thead {
      background-color: transparent;
      transition: all 0.25s ease;
    }

    th {
      background-color: rgba(82, 67, 170, .09);
      padding: 14px;
      font-size: 14px;
      text-align: left;
      color: rgba(82, 67, 170);
      font-weight: normal;
    }

    td {
      padding: 14px;
      font-size: 14px;
      text-align: left;
      border-top: 2px solid rgba(82, 67, 170, .09);
      white-space: nowrap;
    }
    tr:has(~ tr:hover),

    tr:hover ~ tr {
    filter: blur(1px);
    transition: filter 0.4s ease; //cosmetic
}

`

