import { validatePayload } from "../utils/validatePayload"

export const HandlerError = async (error: any) => {
  const payload = validatePayload(window.location.pathname)

  switch (error?.data?.code) {
    case 'TOKEN_INVALID':
      return (window.location.href = payload ? `/login?subscription_id=${payload?.subscriptionSelected}` : '/login?token_expired')

    case 'SUBSCRIPTION_UNAUTHORIZED_ACCESS':
      return (window.location.href = payload ? `/login?subscription_id=${payload?.subscriptionSelected}` : '/?unauthorized_access')

    default:
      throw error
  }
}