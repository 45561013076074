import React from "react";
import { IconCloseRegular, IconUserAccountRegular, IconBrainRegular } from "@telefonica/mistica";
import {
  // FlexWrap,
  ModalHeader,
  ModalOverLay,
  ModalWrap,
  // UserButton,
  Wrapper,
  Permissions,
  PermissionLabel,
  ProfileLabel,
  PermissionsContainer
} from 'application/styled-components/Perfil'
import { Button, Paragraph } from "application/styled-components/chatGPT";
// import { User, Subscription } from "../../../providers/reducers/user";
// import AuthService from '../../services/AuthService'

interface ModalSubUserProps {
  closeModal: () => void
  permissions: string[]
  selectedSubscription: {
    _id: string
    bu: string
    description: string
    name: string
    project: string
    url: string
    permission: string
    permissions: Array<string>
    updated_at: string
    created_at: string
  }
  userData: Array<{
    userEmail: string
    userId: string
    userName: string
  }>
}

const ModalSubUser: React.FC<ModalSubUserProps> = ({ closeModal, permissions, selectedSubscription, userData }) => {
  const hasUsers = userData?.length !== 0
  return (
    <ModalOverLay>
      <ModalWrap>
        <ModalHeader>
          <Wrapper fill="#660099">
            <Paragraph margin={0} fontSize="1.13rem">
              {selectedSubscription.name}
            </Paragraph>
          </Wrapper>
          <Button onClick={closeModal}>
            <IconCloseRegular />
          </Button>
        </ModalHeader>
        <p>URL da Pagina: {selectedSubscription.url}</p>
        <PermissionsContainer>
          <Permissions>
            {hasUsers &&
              userData.map((tenant: any) => (
                // <UserButton key={user.userId}>
                //   <IconUserAccountRegular size="16px" />
                //   <Paragraph>
                //     <span>{user.userName}</span>
                //     <br />
                //     <span style={{ fontSize: 14 }}>{user.userEmail}</span>
                //   </Paragraph>
                //   {/* <Paragraph>{user.userEmail}</Paragraph> */}
                // </UserButton>
                <PermissionLabel
                  for={'profile-permission-' + tenant.tenantName}
                  key={tenant.tenantName}
                >
                  <ProfileLabel for={'profile-permission-' + tenant.userEmail}>
                    <b>Perfil: {tenant.tenantName}</b>
                  </ProfileLabel>
                  <br />
                  <span>{tenant.subscriptionId}</span>
                </PermissionLabel>
              ))}
          </Permissions>
        </PermissionsContainer>
        <ModalHeader marginTop="1.875rem">
          <Wrapper fill="#660099">
            <Paragraph margin={0} fontSize="1.13rem">
              Permissões de API
            </Paragraph>
          </Wrapper>
        </ModalHeader>
        <Permissions>
          {permissions.map((permission, index) => (
            // <UserButton key={index}>
            //   <Paragraph>{permission}</Paragraph>
            // </UserButton>
            <PermissionLabel
              for={'profile-permission-' + permission}
              key={index}
            >
              <ProfileLabel for={'profile-permission-' + permission}>
                <IconBrainRegular style={{ position: 'relative', top: 5 }} />
                <b>{permission}</b>
              </ProfileLabel>
              <br />
              {/* <span>{permission.subscriptionDescription}</span> */}
            </PermissionLabel>
          ))}
        </Permissions>
      </ModalWrap>
    </ModalOverLay>
  )
};

export default ModalSubUser;
