import React from "react";
import { HighlightedCard } from "@telefonica/mistica";
import { HomeBoxContainer, TitleProduct, DescriptionProduct, BoxText, ImageProduct, BoxImage } from "./styles";
import { useHistory } from "react-router-dom";

export const Card = ({ title, description, icon, url, newTab }) => {
  const history = useHistory();
  const handleClick = () => {
    if (newTab) {
      window.open(url, "_blank");
    } else {
      history.push(url);
    }
  };

  return (
    <>
      <HomeBoxContainer onClick={handleClick} style={{ textAlign: "left" }}>
        {/* <HighlightedCard
          title={title}
          // description={description}
          // imageUrl={icon}
          // imageFit="fit"
        /> */}
        <BoxText>
          <TitleProduct>{title}</TitleProduct>
          <DescriptionProduct>{description}</DescriptionProduct>
        </BoxText>
        <BoxImage>
          <ImageProduct src={icon} alt="Icone produto" />
        </BoxImage>
      </HomeBoxContainer>
    </>
  );
};