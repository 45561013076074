import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import { setSidebarOpen } from "providers/reducers/header";
import NavContent from "../../components/FlowChat/NavContent";
import { Button, Dropdown, Input } from "antd";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChatBox from "../../components/FlowChat/ChatBox";
import { dataLocations } from "../../utils/mock";
import ModalUploadFile from "../../components/FlowChat/ModalUploadFile";
import queryString from "query-string";
import "./Home.css";
import UploadFile from "application/components/FlowChat/UploadFile";
import { useAuthenticationGPT } from "application/routes/hooks/useAuthentication";

function useQueryParam(paramName) {
  const search = window.location.search;
  const params = queryString.parse(search);

  return params[paramName] || null;
}

const FlowChat = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const flowId = useQueryParam("flow_id");
  const chat_id = useQueryParam("chat_id");
  const subscription_id = useQueryParam("subscription_id");
  const [guid, setGuid] = useState("");
  const userDataState = useSelector((state) => state.user);
  const expanded = useSelector((state) => state.header.sidebarOpen);
  const { user_name, user_id, token, session_id } = useSelector(
    (state) => state.user
  );
  const sidebar = useSelector((state) => state.sidebar);
  const [chats, setChats] = useState([]);
  const [chatObj, setChatObj] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [reset, setReset] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [flowName, setFlowName] = useState("");
  const [inputData, setInputData] = useState([]);
  const [selectInputData, setSelectInputData] = useState([]);
  const [chatLog, setChatLog] = useState([]);
  const [err, setErr] = useState(false);
  const [responseFromAPI, setReponseFromAPI] = useState(false);
  const [configurationAPI, setConfigurationAPI] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [toggle, setToggle] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  function handleSidebar(state) {
    dispatch(setSidebarOpen(state));
  }

  const chatLogRef = useRef(null);

  const onChange = (e, inputKey) => {
    setSelectInputData({
      ...selectInputData,
      [inputKey]: e.value,
    });
  };

  async function postChat(e, flow_id) {
    try {
      const response = await fetch(
        `https://ai-br-pro.telefonicabigdata.com/flow/chat/${flow_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: user_id,
            token: token,
            session_id: session_id,
            subscription_id: subscription_id,
          },
          body: JSON.stringify(e),
        }
      );
      const data = await response.json();
      history.push(
        `${process.env.PUBLIC_URL}/chat?flow_id=${flowId}&subscription_id=${subscription_id}&chat_id=${data.chats[0]._id}`
      );
      //setSecondFlow(true)
      setErr(false);
      getChats(flowId);
    } catch (err) {
      setErr(err);
    }
  }

  async function deleteChat() {
    try {
      const response = await fetch(
        `https://ai-br-pro.telefonicabigdata.com/flow/chat/${flowId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            user_id: user_id,
            token: token,
            session_id: session_id,
            subscription_id: subscription_id,
            _id: chatObj._id,
          },
        }
      );

      setChats([]);
      setChatObj(null);
      history.push(
        `${process.env.PUBLIC_URL}/chat?flow_id=${flowId}&subscription_id=${subscription_id}`
      );
      setToggle(false);
      setErr(false);
      getChats(flowId);
    } catch (err) {
      setErr(err);
    }
  }

  async function putChat(e, flow_id) {
    try {
      const response = await fetch(
        `https://ai-br-pro.telefonicabigdata.com/flow/chat/${flow_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            user_id,
            token,
            session_id,
            subscription_id,
            _id: chatObj._id,
          },
          body: JSON.stringify(e),
        }
      );
      const responseData = await response.json();

      history.push(
        `${process.env.PUBLIC_URL}/chat?flow_id=${flowId}&subscription_id=${subscription_id}&chat_id=${responseData._id}`
      );
      setErr(false);
      getChats(flowId);
      //setSecondFlow(true)
    } catch (err) {
      setErr(err);
    }
  }

  const handleSaveChat = async (history) => {
    if (flowId !== "210518977112120068895753852418954182334") {
      if (chats.length === 0) {
        const payload = {
          chats: [
            {
              date: new Date().toLocaleDateString(),
              history,
            },
          ],
        };
        postChat(payload, flowId);
      } else {
        const payload = {
          chat: {
            _id: chat_id || undefined,
            date: new Date().toLocaleDateString(),
            history,
          },
        };
        putChat(payload, flowId);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!responseFromAPI) {
      if (inputPrompt.trim() !== "") {
        // Set responseFromAPI to true before making the fetch request
        setReponseFromAPI(true);

        setChatLog([
          ...chatLog,
          {
            type: "user",
            message: inputPrompt,
          },
        ]);
        callAPI();

        // hide the keyboard in mobile devices
        e.target.querySelector("input").blur();
      }
      async function callAPI() {
        try {
          const inputs = {
            ...selectInputData,
            text: inputPrompt,
            teste: "teste",
          };
          const response = await fetch(
            `${process.env.REACT_APP_API_URL_FLOW}/process/${flowId}`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ inputs: inputs }),
            }
          );
          const data = await response.json();
          const newChatLog = [
            ...chatLog,
            {
              type: "user",
              message: inputPrompt,
            },
            {
              type: "system",
              message: data.result.text,
            },
          ];
          setChatLog(newChatLog);
          handleSaveChat(newChatLog);
          setErr(false);
        } catch (err) {
          
          setErr(err);
        }
        //  Set responseFromAPI back to false after the fetch request is complete
        setReponseFromAPI(false);
      }
    }

    setInputPrompt("");
  };

  const callConfiguration = async (flow_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL_FLOW}/flows/${flow_id}`,
        {
          method: "GET",
        }
      );

      const responseJson = await response.json();
      const data = JSON.parse(responseJson);
      setFlowName(data?.name);

      setConfigurationAPI(data);
      const inputs = [];
      if (data.data.nodes && data.data.nodes.length > 0) {
        data.data.nodes.forEach((node) => {
          if (
            node.data &&
            node.data.node &&
            node.data.node.custom_fields &&
            node.data.node.custom_fields.prompt
          ) {
            for (
              let i = 0;
              i < node.data.node.custom_fields.prompt.length;
              i++
            ) {
              if (node.data.node.custom_fields.prompt[i] !== "text") {
                const type = [];
                Object.entries(node.data.node.template).forEach(
                  ([key, value], index) => {
                    if (value.name === node.data.node.custom_fields.prompt[i])
                      type.push(value.type);
                  }
                );
                inputs.push({
                  label: node.data.node.custom_fields.prompt[i],
                  type: type[0],
                });
              }
            }
            setInputData(inputs);
          }
        });
      }

      setInputData(inputs);
    } catch (err) {
      setErr(err);
    }
  };

  const getChats = async (flow_id) => {
    try {
      const response = await fetch(`https://ai-br-pro.telefonicabigdata.com/flow/chat/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          user_id: user_id,
          token: token,
          session_id: session_id,
          subscription_id: subscription_id,
          flow_id: flow_id,
        },
      });
      const data = await response.json();

      if (data._id) {
        setChatObj(data);
        setChats(data.chats);
      }

      setErr(false);
    } catch (err) {
      setErr(err);
    }
  };

  const getChatItem = async (flow_id) => {
    try {
      const response = await fetch(
        `https://ai-br-pro.telefonicabigdata.com/flow/chat/${flow_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            user_id: user_id,
            token: token,
            session_id: session_id,
            subscription_id: subscription_id,
            _id: chat_id,
          },
        }
      );
      const data = await response.json();
      setChatLog([]);
      if (data?.history) {
        setMessages(data?.history);
        setChatLog(data?.history);
        setGuid(data?.history[0].message.split("###")[1]);
        //setSecondFlow(true);
        setToggle(true);
      }

      setErr(false);
    } catch (err) {
      setErr(err);
    }
  };

  useEffect(() => {
    if (chatLogRef.current) {
      chatLogRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
    callConfiguration(flowId);
    getChats(flowId);
    return () => {};
  }, []);

  useEffect(() => {
    if (chat_id && flowId !== "210518977112120068895753852418954182334") {
      getChatItem(flowId);
    }
  }, [chat_id]);

  const onOpenChat = () => {
    getChatItem(flowId);
  };

  //useAuthenticationGPT(userDataState);

  return (
    <Layout
      expanded={expanded}
      setExpanded={handleSidebar}
      username={user_name}
      sidebarOptions={
        sidebar.pages.length !== 0 ? JSON.parse(sidebar.pages) : []
      }
    >
      <div className="container-principal">
        {showMenu && (
          <nav>
            <div className="navItems">
              <NavContent
                setReset={(e) => setReset(e)}
                chatLog={chats}
                onOpenChat={onOpenChat}
                setChatLog={setChatLog}
                setShowMenu={setShowMenu}
                handleSaveChat={handleSaveChat}
                deleteChat={deleteChat}
                flowId={flowId}
                subscriptionId={subscription_id}
              />
            </div>
            <div className="navCloseIcon">
              <svg
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                xmlSpace="preserve"
                stroke="#fff"
                width={42}
                height={42}
                onClick={() => setShowMenu(false)}
              >
                <path d="m53.691 50.609 13.467-13.467a2 2 0 1 0-2.828-2.828L50.863 47.781 37.398 34.314a2 2 0 1 0-2.828 2.828l13.465 13.467-14.293 14.293a2 2 0 1 0 2.828 2.828l14.293-14.293L65.156 67.73c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L53.691 50.609z" />
              </svg>
            </div>
          </nav>
        )}

        <div className="sideMenu">
          <NavContent
            setReset={(e) => setReset(e)}
            chatLog={chats}
            onOpenChat={onOpenChat}
            setChatLog={setChatLog}
            setShowMenu={setShowMenu}
            handleSaveChat={handleSaveChat}
            deleteChat={deleteChat}
            flowId={flowId}
            subscriptionId={subscription_id}
          />
        </div>
        <div className="inputCustomPromptWrapper">
          {inputData &&
            Object.entries(inputData).map(([key, value], index) => (
              <>
                {value.type === "str" ? (
                  <>
                    <div
                      style={{
                        marginLeft:
                          value.label === "input_language" ? "8%" : "",
                      }}
                    >
                      <Dropdown
                        key={index}
                        menu={{
                          items: dataLocations.locations,
                          selectable: true,
                          disabledOverflow: false,
                          onClick: (e) =>
                            onChange(
                              {
                                value:
                                  dataLocations.locations[e.key.slice("4")]
                                    .label,
                              },
                              value.label
                            ),
                          height: "800px",
                        }}
                        placement="top"
                        className="dropdown"
                      >
                        <Button>
                          {selectInputData[value.label] || "Idioma"}
                        </Button>
                      </Dropdown>
                    </div>

                    {value.label === "input_language" && (
                      <ArrowForwardIcon className="icon-arrow" />
                    )}
                  </>
                ) : (
                  <Input
                    className="dropdown"
                    type={value.type === "list" ? "string" : "number"}
                  />
                )}
              </>
            ))}
        </div>
        {configurationAPI &&
        configurationAPI.id === "210518977112120068895753852418954182334" ? (
          <UploadFile
            chatLogRef={chatLogRef}
            chatLog={chatLog}
            err={err}
            flowId={flowId}
            subscriptionId={subscription_id}
            chats={chats}
            putChat={putChat}
            postChat={postChat}
            chat_id={chat_id}
            messages={messages}
            setMessages={setMessages}
            toggle={toggle}
            setGuid={setGuid}
            guid={guid}
            reset={reset}
            setReset={setReset}
          />
        ) : (
          <ChatBox
            chatLog={chatLog}
            showModal={showModal}
            handleSubmit={handleSubmit}
            setInputPrompt={setInputPrompt}
            inputPrompt={inputPrompt}
            chatLogRef={chatLogRef}
            flowName={flowName}
            err={err}
          />
        )}

        <ModalUploadFile
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          inputData={inputData}
          chatLog={chatLog}
        />
      </div>
    </Layout>
  );
};

export default FlowChat;
