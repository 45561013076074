export const dataLocations = {
    locations: [
      { label: "Inglês", value: "ingles" },
      { label: "Chinês (Mandarim)", value: "chines_mandarim" },
      { label: "Hindi", value: "hindi" },
      { label: "Espanhol", value: "espanhol" },
      { label: "Francês", value: "frances" },
      { label: "Árabe", value: "arabe" },
      { label: "Bengali", value: "bengali" },
      { label: "Russo", value: "russo" },
      { label: "Português (Brasil)", value: "portugues_brasil" },
      { label: "Indonésio", value: "indonesio" },
      { label: "Urdu", value: "urdu" },
      { label: "Alemão", value: "alemao" },
      { label: "Japonês", value: "japones" },
      { label: "Punjabi", value: "punjabi" },
      { label: "Marathi", value: "marathi" },
      { label: "Telugu", value: "telugu" },
      { label: "Wu", value: "wu" },
      { label: "Turco", value: "turco" },
      { label: "Tâmil", value: "tamil" },
      { label: "Coreano", value: "coreano" },
      { label: "Vietnamita", value: "vietnamita" },
      { label: "Javanês", value: "javanês" },
      { label: "Italiano", value: "italiano" },
      { label: "Yue (Cantonês)", value: "yue_cantones" },
      { label: "Guajarati", value: "guajarati" },
      { label: "Persa", value: "persa" },
      { label: "Sundanês", value: "sundanes" },
      { label: "Hausa", value: "hausa" },
      { label: "Português (Portugal)", value: "portugues_portugal" },
      { label: "Árabe Egípcio", value: "arabe_egipcio" },
      { label: "Bhojpuri", value: "bhojpuri" },
      { label: "Filipino (Tagalo)", value: "filipino_tagalo" },
      { label: "Jin", value: "jin" },
      { label: "Burmês", value: "burmes" },
      { label: "Hakka", value: "hakka" },
      { label: "Ucraniano", value: "ucraniano" },
      { label: "Urdo", value: "urdo" },
      { label: "Igbo", value: "igbo" },
      { label: "Gujarati", value: "gujarati" },
      { label: "Bamanankan", value: "bamanankan" },
      { label: "Xangai", value: "xangai" },
      { label: "Thai", value: "tailandes" },
      { label: "Kannada", value: "kannada" },
      { label: "Kazakh", value: "cazaque" },
      { label: "Sindhi", value: "sindhi" },
      { label: "Tibetano", value: "tibetano" },
    ],
  };