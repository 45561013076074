const mockSidebarOptions = [
  { title: "Home", items: [], url: "/" },
  {
    title: "Base de Respostas",
    items: [
      {
        title: "Intenções e Canais",
        url: "/answer-store/answer",
      },
      {
        title: "Notificações",
        url: "/answer-store/notifications",
      },
    ],
  },
  {
    title: "Dashboards",
    items: [{ title: "Indicadores", url: "/dashboards/indicators" }],
  },
  {
    title: "Sala de Análises",
    items: [
      {
        title: "Pesquisa de Conversa",
        url: "/dashboards/user-interactions",
      },
    ],
  },
  {
    title: "Relatórios",
    items: [{ title: "Histórico", url: "/dashboards/conversation-history" }],
  },
  {
    title: "Curadoria",
    items: [
      { title: "Assertividade", url: "/curatorship/assertiviness" },
      { title: "Monitor & Insights", url: "/curatorship/monitor" },
    ],
  },
  {
    title: "Ferramentas",
    items: [
      { title: "Teste A/B", url: "/tools/ab-test" },
      { title: "Handover", url: "/tools/handover" },
      { title: "Avaliação de UC", url: "/tools/uc-assessment" },
      { title: "Sandbox", url: "/tools/sandbox" },
    ],
  },
];

export default mockSidebarOptions;
