import React from 'react';

const PDFViewer = ({ url, initialPage }) => {
  const handleLoad = (event) => {
    const { target } = event;
    const numPages = target.contentDocument?.querySelector('.react-pdf__Page')?.children?.length;
      
    if (numPages) {
      const pageNumber = Math.min(initialPage, numPages); // Ensure the initial page is within the valid range
      target.contentWindow.postMessage({ page: pageNumber }, '*');
    }
  };

  return (
    <div>
      <iframe
        src={url}
        title="PDF Viewer"
        width="100%"
        height="800"
        onLoad={handleLoad}
      />
    </div>
  );
};

export default PDFViewer;
