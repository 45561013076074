import { createSlice } from "@reduxjs/toolkit";
interface ISideBar {
  permissions: string[];
  profile: string;
  subscriptionSelected: any;
  products: Array<{
    _id: string;
    name: string;
    description: string;
    url: string;
    permission: string;
  }>;
}

// const getSideBarProducts = ({ permissions, profile, products, subscriptionSelected }: ISideBar) => {
// 	let routesPermitted: any = []
// 	if (permissions.includes('ADMIN')) {
// 		routesPermitted = products
// 	} else {
// 		routesPermitted = products.filter((route: any) =>
// 		permissions.includes(route.permission),
// 	)
// 	}

// 	if (subscriptionSelected.subscriptionName !== 'ADMIN LAB IA' && subscriptionSelected.permissions.includes('QNA_MOBILE_PRODUCTS')) {
// 		const copy = routesPermitted.filter((route: any) => route.permission === 'QNA_MOBILE_PRODUCTS' && route.name === subscriptionSelected.subscriptionName)
// 		routesPermitted = copy
// 	}

//   	return routesPermitted
// }

export const sidebar = createSlice({
  name: "sidebar",
  initialState: {
    pages: [],
  },
  reducers: {
    setPages: (state, action) => {
      // const products = getSideBarProducts(action.payload)
      const { products } = action.payload;

      const sidebar = [
        { id: 1, title: "Home", items: [], subscriptionRoute: "/" },
        {
          id: 2,
          title: "Produtos",
          items: products,
          subscriptionRoute: "/products",
        },
        {
          id: 3,
          title: "Dashboards",
          items: [],
          subscriptionRoute: "/dashboards",
        },
        {
          id: 4,
          title: "Perfis e Configurações",
          items: [],
          subscriptionRoute: "/administration/users",
        },
      ];
      //@ts-ignore
      state.pages = JSON.stringify(sidebar);
    },
  },
});

export const { setPages } = sidebar.actions;
export default sidebar.reducer;
