import { IconCloseRegular, IconLockOpenFilled, PasswordField } from "@telefonica/mistica";
import { ButtonsWrap, Form, ModalHeader, ModalOverLay, ModalWrap, Wrapper } from "application/styled-components/Perfil";
import { Button, Paragraph } from "application/styled-components/chatGPT";
import { useState } from "react";
import ChangePasswordService from "application/services/ChangePasswordService";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

interface ChangePasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  closeModal: () => void;
}

interface ErrorResponse {
  data: {
    code: string
  };
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ isOpen, onClose, closeModal }) => {
  const userDataState = useSelector((state: any) => state.user);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handleSavePassword = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await ChangePasswordService.changePassWord(
        userDataState,
        currentPassword,
        newPassword,
        confirmNewPassword
      );

      // Handle success
      toast.success('Senha alterada com sucesso', {
        autoClose: 1500
      });

      // Clear form inputs

      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      closeModal()

    } catch (error) {
      console.error('Error changing password:', error);

      // Other possible messages

      const { data } = error as ErrorResponse;
      if (data && data.code) {

        const { code } = data;

        switch (code) {
          case 'INVALID_PASSWORD':
            toast.error('Senha atual inválida', {
              autoClose: 1500
            });
            break;
          case 'WEAK_PASSWORD':
            toast.error('A nova senha deve conter no mínimo 8 caracteres, uma letra maiúscula, um número e um caractere especial', {
              autoClose: 1500
            });
            break;
          case 'NEW_PASSWORD_DOESNT_MATCH':
            toast.error(
              'A nova senha que você informou não coincide com a confirmação. Por favor, certifique-se de digitar a mesma senha duas vezes.', {
              autoClose: 1500
            }
            )
            break;
          default:
            toast.error('Erro ao alterar a senha, favor entrar em contato com o suporte do sistema.', {
              autoClose: 1500
            });
            console.log('Unhandled error code:', code);
        }
      }
    }
  };

  return (
    <>
      <ModalOverLay>
        <ModalWrap background={'#D9D9D9'} width={'33.125rem'} radius={'20px'}>
          <ModalHeader>
            <Wrapper fill={'#660099'}>
              <IconLockOpenFilled />
              <Paragraph margin={0} fontSize={'1.13rem'}>Trocar senha</Paragraph>
            </Wrapper>
            <Button onClick={closeModal}><IconCloseRegular /></Button>
          </ModalHeader>
          <Paragraph fontSize={'0.875rem'}>A senha deve conter no mínimo 8 caracteres, uma letra maiúscula, um número e um caractere especial</Paragraph>
          <Form
            isOpen={isOpen}
            onClose={onClose}
            display={'flex'}
            flexDirection={'column'}
            gap={'.625rem'}
          >
            <PasswordField
              fullWidth
              label="Senha atual"
              name="Senha atual"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />

            <PasswordField
              fullWidth
              label="Nova senha"
              name="Nova senha"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />

            <PasswordField
              fullWidth
              label="Repetir nova senha"
              name="Repetir nova senha"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />

            <ButtonsWrap>
              <Button onClick={handleSavePassword}>Trocar senha</Button>
            </ButtonsWrap>
          </Form>
        </ModalWrap>
      </ModalOverLay>
    </>
  );
};

export default ChangePasswordModal;