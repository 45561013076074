import {
  IconAddMoreCircleRegular,
  IconCloseRegular,
  IconEditRegular,
} from "@telefonica/mistica";
import { ModalPdfCrud } from "application/components/ModalPdfCrud";
import { ModalPdfEdit } from "application/components/ModalPdfEdit";
import ModalPdfViewer from "application/components/ModalPdfViewer";
import {
  OptionButton,
  OptionsWrap,
} from "application/styled-components/Perfil";
import { Paragraph } from "application/styled-components/chatGPT";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AdminPDFService from "../../../services/QandaAdminService";
import {
  FormContainer,
  LoadSpinner,
  Table,
  TableBodies,
  TableBodiesItem,
  TableHeader,
  TableHeaderItem,
  TableRow,
} from "../../../styled-components";
import { ModalContentCrud } from "application/components/ModalContentCrud";

export const ChatDocs = ({ subscription_id }) => {
  const PDF_API_URL = process.env.REACT_APP_API_URL_QNA_PDF
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [data, setData] = useState([]);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [isPdfViewerOpen, setIsPdfViewerOpen] = useState(false);
  const [editFile, setEditFile] = useState(null);
  const [pdfName, setPdfName] = useState(null);

  const userDataState = useSelector((state) => state.user);
  // useAuthenticationQNAAdmin(userDataState);

  const openCrudModal = () => {
    setIsOpen(true);
    setSelectedFile(null);
    setPdfName("");
    setEditIndex(null);
  };

  const closeCrudModal = () => {
    setIsOpen(false);
  };

  const openEditModal = (file, index) => {
    setEditIndex(index);
    setEditFile(file);
  };

  const closeEditModal = () => {
    setEditIndex(null);
    setEditFile(null);
  };

  const handleFileSelect = async (files, nameMap) => {
    try {
      const response = await AdminPDFService.uploadFilesQNA(
        userDataState,
        files,
        nameMap,
        subscription_id
      );

      // console.log("Files uploaded:", response);
      closeCrudModal();

      const warnings = response.warning ?? [];
      const warningsAmount = warnings.length;
      const successAmount = response.success?.length ?? 0;

      if (warningsAmount === 0) {
        toast.success("Upload realizado com sucesso!", {
          autoClose: 1500,
        });
      } else if (warningsAmount > 0) {
        if (successAmount > 0) {
          toast.success("Upload realizado com sucesso, porém com exceções.", {
            autoClose: 1500,
          });
        }

        for (const warning of warnings) {
          if (warning.code === 'PDF_ALREADY_EXISTS') {
            toast.warning(`Arquivo já existente: ${warning.subject}`, {
              autoClose: 5000,
            })
          } else {
            toast.warning(`Tente enviar o arquivo novamente: ${warning.subject}`, {
              autoClose: 7000,
            })

            console.warn(`File ${warning.subject} was not processed.`, warning)
          }
        }
      }
    } catch (error) {
      toast.error(
        "Desculpe, houve um erro durante o upload. Tente novamente e, se o problema persistir, contate o administrador do sistema.",
        {
          autoClose: 1500,
        }
      );
      console.error("Error uploading file:", error);
    }
    getAllPDF();
  };

  const handleEditFile = async (newFile, fileName) => {
    try {
      const response = await AdminPDFService.editPdfQNA(
        userDataState,
        newFile,
        fileName,
        subscription_id
      );
      console.log("PDF edited:", response);
      toast.success("Arquivo atualizado com sucesso!", {
        autoClose: 1500,
      });
    } catch (error) {
      console.log("Error editing PDF:");
      console.log(error);
      toast.error(
        "Desculpe, houve um erro ao editar o arquivo. Por favor, tente novamente e, se o problema persistir, contate o administrador do sistema.",
        {
          autoClose: 1500,
        }
      );
    }
    getAllPDF();
    closeEditModal();
  };

  const handleDeleteFile = async (file) => {
    try {
      const response = await AdminPDFService.deletePdfQNA(
        userDataState,
        file._id,
        file.__v,
        file.tempUri,
        file.filename,
        subscription_id
      );
      console.log("PDF deleted:", response);
      toast.success("Arquivo deletado com sucesso!", {
        autoClose: 1500,
      });
    } catch (error) {
      console.log("Error deleting PDF:", error);
      toast.error(
        "Desculpe, houve um erro ao deletar o arquivo. Tente novamente e, se o problema persistir, contate o administrador do sistema.",
        {
          autoClose: 1500,
        }
      );
    }
    getAllPDF();
  };

  const openPdfViewer = ({filename, tenant}) => {
    setSelectedPdf({filename, tenant});
    setIsPdfViewerOpen(true);
  };

  const getAllPDF = async () => {
    try {
      const response = await AdminPDFService.getAllPdfQNA(
        userDataState,
        subscription_id
      );
      setData(response);
    } catch (error) {
      toast.error(
        "Desculpe, houve um erro ao recuperar os arquivos. Recarregue a página e tente novamente. Se o problema persistir, contate o administrador do sistema.",
        {
          autoClose: 1500,
        }
      );
      console.log("error get all pdf");
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPDF();
  }, [subscription_id]);

  return (
    <>
      <FormContainer>
        {userDataState.user_profile_permissions.includes("CREATE") && (
          <OptionsWrap gap={".75rem"}>
            <OptionButton
              alignItems={"center"}
              gap={".1875rem"}
              onClick={openCrudModal}
            >
              <Paragraph
                fontSize={".875rem"}
                color={"#660099"}
                fontWeight={"500"}
              >
                Novo PDF
              </Paragraph>
              <IconAddMoreCircleRegular />
            </OptionButton>
          </OptionsWrap>
        )}
        <Table>
          <TableHeader>
            <TableHeaderItem>
              <Paragraph margin={"0"} fontWeight={"700"}>
                Nome
              </Paragraph>
            </TableHeaderItem>
            <TableHeaderItem>
              <Paragraph margin={"0"} fontWeight={"700"}>
                Ações
              </Paragraph>
            </TableHeaderItem>
          </TableHeader>
          <TableBodies>
            {data.length === 0 ? (
              <LoadSpinner
                position={"absolute"}
                transform={"translateX(-50%)"}
                left={"50%"}
                top={"50%"}
              />
            ) : (
              data.map((pdf, index) => (
                <TableRow
                  key={index}
                  cursor={"pointer"}
                  embedded={pdf.embedded}
                >
                  <TableBodiesItem
                    verticalAlign={"middle"}
                    onClick={() => openPdfViewer({tenant: pdf.tenant, filename: pdf.filename})}
                  >
                    {pdf.filename}
                  </TableBodiesItem>
                  <TableBodiesItem>
                    {userDataState.user_profile_permissions.includes(
                      "UPDATE"
                    ) && (
                      <IconEditRegular
                        onClick={() => openEditModal(pdf, index)}
                      />
                    )}
                    {userDataState.user_profile_permissions.includes(
                      "DELETE"
                    ) && (
                      <IconCloseRegular onClick={() => handleDeleteFile(pdf)} />
                    )}
                    {/* <IconEditRegular onClick={() => openEditModal(pdf, index)} /> */}
                    {/* <IconCloseRegular onClick={() => handleDeleteFile(pdf)} /> */}
                  </TableBodiesItem>
                </TableRow>
              ))
            )}
          </TableBodies>
        </Table>
      </FormContainer>

      {isOpen && (
        <ModalContentCrud
          closeModal={closeCrudModal}
          onFileSelect={handleFileSelect}
        />
      )}

      {editIndex !== null && (
        <ModalPdfEdit
          closeModal={closeEditModal}
          onFileSelect={handleEditFile}
          initialFile={editFile}
        />
      )}

      {isPdfViewerOpen && (
        <ModalPdfViewer
          closeModal={() => setIsPdfViewerOpen(false)}
          pdfUrl={`${PDF_API_URL}/pdfs/${selectedPdf.tenant}/${selectedPdf.filename}`}
        />
      )}
    </>
  );
};
