// import ServiceHelperLogin from "../helpers/ServiceHelperLogin";
import axios from 'axios'
// import { User } from './interfaces/User'
import { HandlerError } from './HandlerError'

const API_URL = process.env.REACT_APP_API_URL_SERVICE_CHAT_GPT
const API_URL_VALIDATION = process.env.REACT_APP_API_URL_SERVICE_LOGIN
const SERVICE = 'CHAT_GPT'
  
const getConfig = ({ user_id, session_id, token, user_email, user_name, user_profile, oam_matricula, oam_gestor, oam_ccusto, subscriptionSelected }) => {
  return {
    headers: {
      session_id: session_id,
      user_id: user_id,
      user_name,
      user_email,
      user_profile,
      oam_matricula,
      oam_gestor,
      oam_ccusto,
      token: token,
      service: SERVICE,
      subscription_id: subscriptionSelected,
      gpt_api: 'chat',
    },
  }
}

export const ask = async (userData, messages, question) => {
 
  console.log(`ask chat userData: ${JSON.stringify(userData)}`)
 
  try {
    const CONFIG = getConfig(userData)
    const response = await axios.post(
      `${API_URL}/chat`,
      {
        messages,
        question
      },
      CONFIG,
    )
    return response.data
  } catch (error) {
    return HandlerError(error.response)
  }
}

export const validatePermissionGPT = async (userData) => {
  try {
    const CONFIG = getConfig(userData)
    const response = await axios.get(
      `${API_URL_VALIDATION}/auth`,
      CONFIG,
    )
    return response.data
  } catch (error) {
    return HandlerError(error.response)
  }
}
