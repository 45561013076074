import React, { useContext } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase.config";
import { AuthContext } from "../../context/AuthContext";
import "../../pages/FlowChat/Home.css";

const NavLinks = ({ svg, link, text, setChatLog, deleteChat }) => {
  const { dispatch } = useContext(AuthContext);

  const handleClick = async (text) => {
    if (text === "Limpar conversas") {
      deleteChat();
      setChatLog([]);
    }
    if (text === "Log out") {
      try {
        let logOut = await signOut(auth);
        console.log("logOut", logOut);
        dispatch({ type: "LOGOUT" });
      } catch (error) {
        console.log("error happen during sign out", error);
      }
    }
  };

  return (
    <div style={{ textDecoration: "none" }} onClick={() => handleClick(text)}>
      <div className="navPrompt">
        {svg}
        <p>{text}</p>
      </div>
    </div>
  );
};

export default NavLinks;
