import React, { useEffect, useState } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import "./CompetitividadeDetalhes.css";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useSelector, useDispatch } from "react-redux";
import PrecificadorService from "application/services/PrecificadorService";
import Button from "@mui/material/Button";
import { Image } from "application/styled-components";
import { Loader } from "../../components";

import { setSubscription } from "providers/reducers/user";
import { useAuthenticationPrecificador } from "application/routes/hooks/useAuthentication";

import iconBarrow from "../../../../../assets/svg/arrow-back.svg";

export const CompetitividadeDetalhes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const regex = /[?&]subscription_id=([^&#]*)/;
  const match = regex.exec(history.location.search);
  const subscription_id = match && match[1];
  dispatch(setSubscription(subscription_id));

  let expanded = useSelector((state) => state.header.sidebarOpen);
  const userDataState = useSelector((state) => state.user);

  const { subscriptionName } = userDataState.subscriptions.find(
    (subs) => subs.subscriptionId === subscription_id
  );

  useAuthenticationPrecificador(userDataState)
  useEffect(() => {
    if (!userDataState.user_profile_permissions.includes("READ")) {
      history.push("/?unauthorized_access");
    }
  });


  const [connectNulls] = React.useState(true);
  const query = new URLSearchParams(window.location.search);
  const idProduto = query.get("id_produto");
  const NomeProduto = query.get("produto");
  const NomeMarca = query.get("marca");
  const NomeModelo = query.get("modelo");

  const [precoVivo, setPrecoVivo] = useState('R$ -');
  const [precoMercado, setPrecoMercado] = useState('-');

  const [concorrenciaData, setConcorrenciaData] = useState([]);
  const [vivoData, setVivoData] = useState([]);
  const [mesesLabels, setMesesLabels] = useState([]);

  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        setTableLoading(true);
        const response = await PrecificadorService.getChart(
          userDataState,
          subscriptionName,
          idProduto
        );

        setPrecoVivo(response.menor_valor_vivo_display);
        setPrecoMercado(response.menor_valor_concorrencia_display);
        setConcorrenciaData(response.preco_concorrencia);
        setVivoData(response.preco_vivo);
        setMesesLabels(response.month_names);
       
      } catch (error) {
        console.error("Erro ao obter dados", error);
      } finally {
        setTableLoading(false);
      }
    }
    fetchData();
  }, []);

  const dataHora = () => {
    if (query.get("horaData")) {
      var horaData = new Date(query.get("horaData"));
      var newHoraData = format(horaData, "dd/MMMM/yyyy", { locale: ptBR });
      return newHoraData;
    }
  };

  return (
    <div className="CompetitividadeDetalhes">
      <p>
        <h3>Consulta de preços</h3>
      </p>
      <div className="back-head">
        <b>
          <span className="link" >
            <Button
              onClick={() => history.goBack()}
              color="secondary"
              variant="outlined"
              startIcon={<Image src={iconBarrow} />}
            >
              {'Voltar'}
            </Button>
          </span>
        </b>

      </div>
      <h3>Detalhes do produto</h3>
      <div className="row">
        <div className="column">
          <p>
            <b>Produto: </b> {NomeProduto}
          </p>
          <p>
            <b>Marca: </b> {NomeMarca}
          </p>
          <p>
            <b>Modelo: </b> {NomeModelo}
          </p>
        </div>
        <div className="column last">
          <p>
            <b>Menor preço mercado: </b>
          </p>
          <p>
            <b>Preço Vivo: </b>
          </p>
          <p>
            <b>Última modificação: </b>
          </p>
        </div>
        <div className="column">
          <span>
            <div className="box-tag-competividade color-blue">
              <span className="text-tag">
                <b>R$ {precoMercado}</b>
              </span>
            </div>
          </span>

          <span>
            <div className="box-tag-competividade">
              <span className="text-tag">
                <b>{precoVivo}</b>
              </span>
            </div>
          </span>
          <span>{dataHora()}</span>
        </div>
      </div>

      {tableLoading ? (
        <Loader />
      ) : (
        <div className="row" style={{ borderTop: "1px solid #eeeeee" }}>
          <h3>Histórico de preços por produto</h3>

          <div className="column fifty">
            <div className="box-chart">
              <LineChart
                height={400}
                series={[
                  {
                    data: vivoData,
                    color: "#b692c8",
                    connectNulls,
                    area: true,
                    label: "Vivo Melhor",
                  },
                  {
                    data: concorrenciaData,
                    color: "#019df4",
                    label: "Concorrência",
                  },
                ]}
                xAxis={[{ scaleType: "point", data: mesesLabels }]}
              />
            </div>
          </div>
        </div>
      )}

    </div>
  );
};
