import { Card } from "../Card";
import { BodyContainer } from "../../styled-components/index";
import { getIconByTitle } from "../Sidebar/getIconByTitle";
import JSONCrush from '../../utils/JSONCrush.js'

export const BoxOptions = ({ items, user }) => {
  const generateUrl = (product, newTab) => {
    let encodedPayload = null
    if (newTab) {
      const payload = {
        user_id: user.user_id,
        token: user.token,
        session_id: user.session_id,
        oam_matricula: user.oam_matricula,
        oam_ccusto: user.oam_ccusto,
        oam_gestor: user.oam_gestor,
        user_name: user.user_name,
        user_profile: user.user_profile,
        user_email: user.user_email,
      };

      encodedPayload = encodeURIComponent(btoa(JSONCrush.crush(JSON.stringify(payload))));
    }

    const parts = product.subscriptionRoute.split("?");
    const separator = parts.length > 1 && parts[0] === "/chat" ? "&" : "?";
    const payloadParam = encodedPayload ? `&payload=${encodedPayload}` : '';
    const url = `${product.subscriptionRoute}${separator}subscription_id=${product.subscriptionId}${payloadParam}`;
    return url;
  }
  return (
    <>
      <BodyContainer justifyContent={"left"}>
        {items.length > 0 ? (
          items.map((product) => {
            const icon = getIconByTitle(product.subscriptionName);
            const newTab = product.subscriptionRoute.slice(0, 4) === "http";
            return (
              <Card
                title={product.subscriptionName}
                key={product._id}
                description={product.subscriptionDescription}
                icon={icon}
                newTab={newTab}
                url={generateUrl(product, newTab)}
              />
            );
          })
        ) : (
          <div style={{ textAlign: "center", width: "100%" }}>
            <p>Você ainda não tem nenhum produto atrelado à seu perfil</p>
            {/* <p>Abra uma demanda no LAB IA para acesso</p> */}
          </div>
        )}
      </BodyContainer>
    </>
  );
};