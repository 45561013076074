import React, { useContext } from "react";
import { createPortal } from "react-dom";

import { ModalContext } from './ModalContext';

const ModalFragment = () => {
  let { modalContent, modal } = useContext(ModalContext);
  if (modal) {
    return createPortal(<div> { modalContent } </div>, document.querySelector("#modal-root"))
  } else return null;
}

export default ModalFragment;