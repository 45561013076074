import axios, { AxiosResponse } from "axios";
import { User } from "./interfaces/User";
import { HandlerError } from "./HandlerError";
import { Ask } from "./interfaces/Ask";
import dayjs from "dayjs";

interface PaginatedResponseProdutos {
  current: number;
  next: boolean;
  pages: number;
  per_page: number;
  prev: boolean;
  itens_por_pagina: number;
  produtos: Record<string, any>[];
  resumo: Record<string, any>[];
}
const filterMappings = {
  selectedCategoria: "categorias_ids",
  selectedLojas: "lojas_ids",
  selectedFabricantes: "fabricante_vendedor_ids",
  selectedPagamento: "metodo_pagamento",
  selectedSellers: "vendedor_nome",
  selectedSubCategoria: "subcategoria_id",
  selectedCapacidades: "capacidade_ids",
  selectedCores: "cor_ids",
  selectedVertical: "vertical_ids",
  selectedDisponibilidade: "estoque",
  selectedComSeller: "seller",
  selectedCartao: "cartao"
};

class PrecificadorService {
  private static readonly API_URL = process.env.REACT_APP_API_URL_SERVICE_PRECIFICADOR; //"http://localhost:8080/precificador";
  private static readonly API_URL_VALIDATION = process.env.REACT_APP_API_URL_SERVICE_LOGIN;
  private static readonly SERVICE = "API_PRECIFICADOR";
  private static getConfig = ({
    user_id,
    session_id,
    token,
    user_email,
    user_name,
    user_profile,
    oam_matricula,
    oam_gestor,
    oam_ccusto,
    subscriptionSelected,
  }: User) => {
    return {
      headers: {
        session_id,
        sessionid: session_id,
        user_id,
        userid: user_id,
        token: token,
        service: this.SERVICE,
        subscription_id: subscriptionSelected,
        subscriptionid: subscriptionSelected,
        "Content-Type": "application/json",
      },
    };
  };

  static async validatePermission(userData: User) {
    const CONFIG = this.getConfig(userData);
    try {
      const response = await axios.get(
        `${this.API_URL_VALIDATION}/auth`,
        CONFIG
      );
      return response.data;
    } catch (error: any) {
      return HandlerError(error.response);
    }
  }

  static async getProdutos(
    userData: User,
    subscriptionName: string,
    page = 1,
    filters = {},
    ordenacao = "",
    perPage = 10
  ) {
    try {
      const CONFIG = this.getConfig(userData);
      const headers = {
        ...CONFIG.headers,
        subscription_name: subscriptionName,
      };
      const params = {
        page,
        per_page: perPage,
      };
      if (ordenacao) {
        params["ordem"] = ordenacao;
      }
      if (filters) {
        const filterParams = this.buildFilterParams(filters);
        Object.assign(params, filterParams); //Mescla os parâmetros de filtro
      }

      const response = await axios.get(`${this.API_URL}/produtos`, {
        params,
        headers,
      });

      if (response.status === 204) {
        return {
          produtos: [],
          resumo: {
            total_itens: 0,
            total_lojas: 0,
            total_marcas: 0,
            total_produtos: 0,
          },
        };
      }
      return response.data;

    } catch (error: any) {
      return HandlerError(error.response);
    }

  }

  //Filtro para produtos
  static buildFilterParams(filters: any) {
    const params: any = {};

    if (filters["eh_manual"] !== undefined && filters["eh_manual"] !== "") {
      params["eh_manual"] = filters["eh_manual"];
    }
    if (filters["searchValue"] !== undefined && filters["searchValue"] !== "") {
      params["search_term"] = filters["searchValue"];
    }
    if (filters["startDate"] !== undefined && filters["startDate"] !== null) {
      params["data_inicio"] = dayjs(filters["startDate"]).format("YYYY-MM-DD");
    }
    if (filters["endDate"] !== undefined && filters["endDate"] !== null) {
      params["data_fim"] = dayjs(filters["endDate"]).format("YYYY-MM-DD");
    }
    Object.keys(filters).forEach((filterKey) => {
      const filterValue = filters[filterKey];
      if (
        filterValue !== undefined &&
        filterMappings[filterKey] &&
        filterValue.length > 0
      ) {
        const mappedFilterValues = filterValue.map((filter: any) => filter.value);
        params[filterMappings[filterKey]] = mappedFilterValues.join(",");
      }
    });

    return params;
}
  static async crudOperation(
    operation: string,
    userData: User,
    subscriptionName: string,
    endpoint: string,
    page = 1,
    perPage = 10,
    body?: any,
    filter?
  ): Promise<any> {
    try {
      const CONFIG = this.getConfig(userData);
      const headers = {
        ...CONFIG.headers,
        subscription_name: subscriptionName,
      };

      let response: AxiosResponse<any>;

      switch (operation.toLowerCase()) {
        case 'post':
          response = await axios.post(`${this.API_URL}/${endpoint}`, body, { headers });
          break;
        case 'get':
          const params = {
            page,
            perPage: perPage,
          };
          if (filter) {
            params["cod_sap"] = filter;
          }
          response = await axios.get(`${this.API_URL}/${endpoint}`, { params, headers });
          break;
        case 'put':
          response = await axios.put(`${this.API_URL}/${endpoint}`, body, { headers });
          break;
        case 'delete':
          response = await axios.delete(`${this.API_URL}/${endpoint}`, { headers });
          break;
        default:
          throw new Error('Operação inválida');
      }

      if (response.status === 200 || response.status === 201) {
        return response.data;
      } else {
        console.error('Erro na requisição:', response.data);
        return response.data;
      }
    } catch (error: any) {
      console.error('Erro na requisição:', error);
      return error.response.data;
    }
  }

  static async getProdutosByFilter(
    userData: User,
    subscriptionName: string,
    filter
  ) {
    try {
      const CONFIG = this.getConfig(userData);
      const headers = {
        ...CONFIG.headers,
        subscription_name: subscriptionName,
      };
      const response = await axios.get(`${this.API_URL}/${filter}`, {
        headers,
      });
      if (response.status === 200 && response.data.status === "success") {
        return response.data[filter];
      } else {
        console.error("Erro na requisição:", response.data);
        return null;
      }
    } catch (error) {
      console.error("Erro na requisição:", error);
      return null;
    }
  }

  static async exportProdutos(
    userData: User,
    subscriptionName: string,
    type: "csv" | "excel",
    filters
  ) {
    try {
      const endpoint =
        type === "csv" ? "export_produtos_csv" : "export_produtos_excel";
      const CONFIG = this.getConfig(userData);
      const headers = {
        ...CONFIG.headers,
        subscription_name: subscriptionName,
      };
      let params: any = {};
      if (filters) {
        const filterParams = this.buildFilterParams(filters);
        params = { ...params, ...filterParams };
      }
      const url = `${this.API_URL}/${endpoint}?${new URLSearchParams(params).toString()}`;
      const newTab = window.open(url, "_blank");

    } catch (error: any) {
      return HandlerError(error.response);
    }
  }

  static async getProdutosByRanking(
    userData: User,
    subscriptionName: string,
    idProduto: string
  ) {
    try {
      const CONFIG = this.getConfig(userData);
      const headers = {
        ...CONFIG.headers,
        subscription_name: subscriptionName,
      };
      const response = await axios.get(`${this.API_URL}/ranking/${idProduto}`, {
        headers,
      });
      if (response.status === 200) {
        return response.data;
      } else {
        // console.error("Erro na requisição:", response.data);
        return null;
      }
    } catch (error) {
      // console.error("Erro na requisição:", error);
      return null;
    }
  }

  static async getChart(
    userData: User,
    subscriptionName: string,
    idProduto: string
  ) {
    try {
      const CONFIG = this.getConfig(userData);
      const headers = {
        ...CONFIG.headers,
        subscription_name: subscriptionName,
      };
      const response = await axios.get(`${this.API_URL}/graficos/${idProduto}`, {
        headers,
      });
      if (response.status === 200) {
        return response.data;
      } else {
        // console.error("Erro na requisição:", response.data);
        return null;
      }
    } catch (error) {
      // console.error("Erro na requisição:", error);
      return null;
    }
  }

  static async createForm(userData: User, subscriptionName: string, formData: any, endpoint) {
    try {
      const CONFIG = this.getConfig(userData);
      const headers = {
        ...CONFIG.headers,
        subscription_name: subscriptionName,
      };
      const response = await axios.post(
        `${this.API_URL}/${endpoint}`,
        formData,
        { headers }
      );
      if (response.status === 200 || response.status === 201) {
        return response.data;
      } else {
        console.error("Erro na requisição:", response.data);
        return response.data;
      }
    } catch (error: any) {
      console.log("Erro na requisição response:", error);
      return error.response.data;
    }
  }

  static async editForm(userData: User, subscriptionName: string, formData: any, endpoint) {
    try {
      const CONFIG = this.getConfig(userData);
      const headers = {
        ...CONFIG.headers,
        subscription_name: subscriptionName,
      };
      const response = await axios.put(
        `${this.API_URL}/${endpoint}`,
        formData,
        { headers }
      );

      if (response.status === 200 || response.status === 201) {
        return response.data;
      } else {
        console.error("Erro na requisição:", response.data);
        return response.data;
      }
    } catch (error: any) {
      console.log("Erro na requisição response:", error);
      return error.response.data;
    }
  }

  static async deleteForm(userData: User, subscriptionName: string, endpoint) {
    try {
      const CONFIG = this.getConfig(userData);
      const headers = {
        ...CONFIG.headers,
        subscription_name: subscriptionName,
      };
      console.log("aqui", endpoint)
      const response = await axios.delete(
        `${this.API_URL}/${endpoint}`,
        { headers }
      );

      if (response.status === 200 || response.status === 201) {
        return response.data;
      } else {
        console.error("Erro na requisição:", response.data);
        return response.data;
      }
    } catch (error: any) {
      console.log("Erro na requisição response:", error);
      return error.response.data;
    }
  }

  static async importExcel(userData, subscriptionName, formData) {
    try {
      const CONFIG = this.getConfig(userData);
      const headers = {
        ...CONFIG.headers,
        subscription_name: subscriptionName,
        "Content-Type": "multipart/form-data",
      };

      const response = await axios.post(
        `${this.API_URL}/upload_planilha`,
        formData,
        { headers }
      );

      if (response.status === 200 || response.status === 201) {
        return { success: true, data: response.data };
      } else {
        console.error("Erro na requisição:", response.data);
        return { success: false, message: "Erro na requisição" };
      }
    } catch (error) {
      console.error("Erro na requisição response:", error);
      return { success: false, message: "Erro na requisição" };
    }
  }


}

export default PrecificadorService;
