import { FormContainer, Table, TableBodies, TableBodiesItem, TableHeader, TableHeaderItem, TableRow } from "application/styled-components";
import React, { useState, useEffect } from "react";
import { TabPanel } from "../TabPanel";
import { Paragraph } from "application/styled-components/chatGPT";
import AuthService from '../../services/AuthService'
import { useSelector } from "react-redux";

import { IconEditRegular, IconAddMoreCircleRegular, IconBatteryMediumRegular, IconExitFullscreenRegular, IconAlertRegular, IconAdnRegular, IconCancelRegular } from "@telefonica/mistica";
import { ModalPermissionSubscription } from "../ModalPermissionSubscription";
import { ModalPermissionCreate } from "../ModalPermissionCreate";
import { OptionButton, OptionsWrap } from "application/styled-components/Perfil";
import ModalPermissionDelete from "../ModalPermissionDelete";


export const PerfilDashBoard = () => {

    const userDataState = useSelector((state) => state.user);

    const [isOpenCreate, setIsOpenCreate] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [isOpenRemove, setIsOpenRemove] = useState(false);
    const [permissions, setPermissions] = useState([])
    const [permissionSelected, setPermissionSelected] = useState({})


    const openModalCreate = () => {
        setIsOpenCreate(true);
        document.body.style.overflow = 'hidden';
    };

    const closeModalCreate = () => {
        setIsOpenCreate(false);
        document.body.style.overflow = '';
    };

    const openModalEdit = () => {
        setIsOpenEdit(true);
        document.body.style.overflow = 'hidden';
    };
    const closeModalEdit = () => {
        setIsOpenEdit(false);
        document.body.style.overflow = '';
    };

    const openModalRemove = () => {
        setIsOpenRemove(true);
        document.body.style.overflow = 'hidden';
    };
    const closeModalRemove = () => {
        setIsOpenRemove(false);
        document.body.style.overflow = '';
    };

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.keyCode === 27 && isOpenCreate) {
                closeModalCreate();
            }
        };
        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [isOpenCreate]);

    const getAllPermissions = async () => {
        await AuthService.getAllPermissions(userDataState).then((response) => {
            setPermissions(response.permission)
        })
    }

    useEffect(() => {
        getAllPermissions()
    }, [])

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.keyCode === 27 && isOpenEdit) {
                closeModalEdit();
            }
        };
        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [isOpenEdit]);

    return (
        <>
            <TabPanel active={true}>
                <FormContainer>
                    <OptionsWrap gap={'.75rem'}>
                        <OptionButton alignItems={'center'} gap={'.1875rem'} onClick={openModalCreate}>
                            <Paragraph fontSize={'.875rem'} color={'#660099'} fontWeight={'500'}>Nova permissão</Paragraph>
                            <IconAddMoreCircleRegular />
                        </OptionButton>
                    </OptionsWrap>
                    <Table>
                        <TableHeader>
                            <TableHeaderItem>
                                <Paragraph margin={'0'} fontWeight={'700'}>Permissões de API</Paragraph>
                            </TableHeaderItem>
                            <TableHeaderItem>
                                <Paragraph margin={'0'} fontWeight={'700'}>Descrição</Paragraph>
                            </TableHeaderItem>
                            <TableHeaderItem>
                                <Paragraph margin={'0'} fontWeight={'700'}>Ações</Paragraph>
                            </TableHeaderItem>
                        </TableHeader>
                        <TableBodies>
                            {
                                permissions.map((prmsn, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableBodiesItem>{prmsn.name}</TableBodiesItem>
                                            <TableBodiesItem>{prmsn.description}</TableBodiesItem>
                                            <TableBodiesItem>
                                                <IconEditRegular onClick={() => {
                                                    setPermissionSelected(prmsn)
                                                    openModalEdit(true)
                                                }} />
                                                <IconCancelRegular onClick={() => {
                                                    setPermissionSelected(prmsn)
                                                    openModalRemove(true)
                                                }} />
                                            </TableBodiesItem>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBodies>
                    </Table>
                </FormContainer>

                {isOpenCreate && (
                    <ModalPermissionCreate
                        closeModal={closeModalCreate}
                        getAllPermissions={getAllPermissions}
                    />
                )}

                {isOpenEdit && (
                    <ModalPermissionSubscription
                        closeModal={closeModalEdit}
                        permissionSelected={permissionSelected}
                    // data={data}
                    // dataEdit={dataEdit}
                    />
                )}

                {isOpenRemove && (
                    <ModalPermissionDelete
                        closeModal={closeModalRemove}
                        getAllPermissions={getAllPermissions}
                        permissionSelected={permissionSelected}
                    />
                )}


            </TabPanel>
        </>
    )
}