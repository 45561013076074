import React from "react";
import { useHistory } from "react-router-dom";
import "../../pages/FlowChat/Home.css";

const NewChat = ({ setChatLog, setShowMenu, flowId, subscriptionId, setReset }) => {
  const history = useHistory();
  return (
    <div
      className="sideMenuButton"
      onClick={() => {
        history.push(
          `${process.env.PUBLIC_URL}/chat?flow_id=${flowId}&subscription_id=${subscriptionId}`
        );
        setChatLog([]);
        setReset(true);
        setShowMenu(false);
      }}
    >
      <span className="sideMenuIcon">+</span>
      Novo chat
    </div>
  );
};

export default NewChat;
