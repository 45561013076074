import PropTypes from 'prop-types';

import { BackgroundContainer, FlexContainer, ContentContainer } from "../../styled-components/layout";
import Sidebar from '../Sidebar';
import Header from '../Header';

type Props = {
    username: string,
    sidebarOptions: Array<Object>,
    children?: any,
    expanded: boolean,
    setExpanded?: (expand: boolean) => void,
    position?: string,
    height?: string,
    display?: string,
    flex?: string,
    justifyContent?: string,
    mobileWidth?: string,
    mobileMargin?: string
}

const Layout = ({ expanded = false, setExpanded, username = 'Admin', sidebarOptions = [], children, position, height, display, flex, justifyContent, mobileWidth, mobileMargin }: Props) => {

    const expandSidebar = (ev: MouseEvent) => {
        ev.stopPropagation();
        setExpanded?.(!expanded);
    }

    return (
        <>
            <BackgroundContainer>
                <Header active={expanded} username={username} click={expandSidebar} />
                <FlexContainer>
                    <Sidebar active={expanded} sideMenuOptions={sidebarOptions} />
                    <ContentContainer className="scroll" active={expanded} position={position} height={height} display={display} flex={flex} justifyContent={justifyContent} mobileWidth={mobileWidth} mobileMargin={mobileMargin}>
                        {children}
                    </ContentContainer>
                </FlexContainer>
            </BackgroundContainer>
        </>
    )
}

Layout.propTypes = {
    username: PropTypes.string,
    sidebarOptions: PropTypes.arrayOf(PropTypes.object),
    children: PropTypes.any,
    expanded: PropTypes.bool,
    setExpanded: PropTypes.func
};

Layout.defaultProps = {
    username: "Admin",
    sidebarOptions: [],
    expanded: false,
    setExpanded: () => { }
};

export default Layout