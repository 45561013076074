import {
  IconAddMoreCircleRegular,
  IconEyeFilled,
  IconFilePdfRegular,
  IconMusicFilled,
} from "@telefonica/mistica";
import {
  OptionButton,
  OptionsWrap,
} from "application/styled-components/Perfil";
import { Paragraph } from "application/styled-components/chatGPT";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  FormContainer,
  Table,
  TableBodies,
  TableBodiesItem,
  TableHeader,
  TableHeaderItem,
  TableRow,
  TableIcons,
  TagStatus,
} from "./styles";
import { ModalUploadAudio } from "application/components/ModalUploadAudio";
import { useMutation, useQuery } from "@tanstack/react-query";
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ModalAudioViewer from "application/components/ModalAudioViewer";
import {pdf} from "@react-pdf/renderer";
import { AudioPDF } from "./audioPDF";
import { saveAs } from "file-saver";
import { queryClient } from "lib/react-query";
import { getAudioMaxFileSize, getAudios, getDiarization, getLogins, getStatus, uploadAudio } from "../data/audios";

export const AudioList = ({ subscription_id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTranscription, setSelectedTranscription]: any = useState(null);
  const [selectedOriginalTranscription, setSelectedOriginalTranscription]: any = useState(null);
  const [selectedAudioUrl, setSelectedAudioUrl]: any = useState(null);
  const [selectedAudioTitle, setSelectedAudioTitle]: any = useState(null);
  const [selectedDiarization, setSelectedDiarization]: any = useState(null);
  const [isTranscriptionViewerOpen, setIsTranscriptionViewerOpen] = useState(false);

  const userDataState = useSelector((state: any) => state.user);

  const openCrudModal = () => {
    setIsOpen(true);
  };

  const closeCrudModal = () => {
    setIsOpen(false);
  };

  const { data: audios } = useQuery({
    queryKey: ['audios'],
    queryFn: () => getAudios(userDataState),
  })

  const { data: logins } = useQuery({
    queryKey: ['logins'],
    queryFn: () => getLogins(userDataState),
  })
 
  const { mutateAsync: uploadAudioFn } = useMutation({
    mutationFn: uploadAudio,
    onSuccess(_, variables) {
      queryClient.setQueryData(['audios'], (data: any) => {
        return [{
          _id: crypto.randomUUID(),
          filename: variables.filename,
          improvedTranscribedText: "",
          status: "CREATED",
          user: variables.userDataState?.user_id,
          metadata: {
            duration: "",
            fileSize: ""
          },
          createdAt: new Date()
        }, ...data]
      })
    }
  })

  const handleFileSelect = async (files: File[], nameMap) => {
    const file = files[0];

    const audioMaxFileSize = getAudioMaxFileSize();

    if (file.size > audioMaxFileSize) {
      return toast.error("Audio inserido ultrapassa 25mb.", {
        autoClose: 1500,
      });
    }

    const filename = Object.values<string>(nameMap)[0];

    try {
      await uploadAudioFn({userDataState, filename, file})

      closeCrudModal();

      return toast.success("Audio adicionado, a transcrição está em processamento...", {
        autoClose: 1500,
      });
      
    } catch (err) {

      closeCrudModal();

      return toast.error("Erro ao adicionar novo audio.", {
        autoClose: 1500,
      });
    }
  };

  const handleOpenTranscription = (transcription: string, originalTranscription: string, audioUrl: string, audioTitle: string) => {
    if (!transcription) {
      return toast.error("Transcrição ainda não disponível.", {
        autoClose: 1500,
      });
    }

    setSelectedAudioTitle(audioTitle);
    setSelectedDiarization(getDiarization(transcription));
    setSelectedTranscription(transcription);
    setSelectedOriginalTranscription(originalTranscription)
    setSelectedAudioUrl(audioUrl);
    setIsTranscriptionViewerOpen(true);
  }

  const handleTranscriptionDownload = async (transcriptionName: string, transcription: string) => {
    if (!transcription) {
      return toast.error("Transcrição ainda não disponível.", {
        autoClose: 1500,
      });
    }

    const name = transcriptionName.split('.')[0];
    const transcriptionNameFormat = `audio-${name}.pdf`

    const linesTranscription = transcription.split('\n').filter(line => line.length > 0);

    const blob = await pdf(
      <AudioPDF transcription={linesTranscription} title={name} />
    ).toBlob();
  
    saveAs(blob, transcriptionNameFormat);
  }

  return (
    <>
      <FormContainer>
        {userDataState.user_profile_permissions.includes("CREATE") && (
          <OptionsWrap gap={".75rem"}>
            <OptionButton
              alignItems={"center"}
              gap={".1875rem"}
              onClick={openCrudModal}
            >
              <Paragraph
                fontSize={".875rem"}
                color={"#660099"}
                fontWeight={"500"}
              >
                Novo áudio
              </Paragraph>
              <IconAddMoreCircleRegular />
            </OptionButton>
          </OptionsWrap>
        )}
        <Table>
          <TableHeader>
            <TableHeaderItem>
              <Paragraph margin={"0"} fontWeight={"700"}>
                N°
              </Paragraph>
            </TableHeaderItem>
            <TableHeaderItem>
              <Paragraph margin={"0"} fontWeight={"700"}>
                Áudio
              </Paragraph>
            </TableHeaderItem>
            <TableHeaderItem>
              <Paragraph margin={"0"} fontWeight={"700"}>
                Ações
              </Paragraph>
            </TableHeaderItem>
            <TableHeaderItem>
              <Paragraph margin={"0"} fontWeight={"700"}>
                Situação
              </Paragraph>
            </TableHeaderItem>
            <TableHeaderItem>
              <Paragraph margin={"0"} fontWeight={"700"}>
                Autor
              </Paragraph>
            </TableHeaderItem>

            {userDataState.user_profile === "VGPT_ADMIN_LAB_IA" && (
              <TableHeaderItem>
                <Paragraph margin={"0"} fontWeight={"700"}>
                  Perfil
                </Paragraph>
              </TableHeaderItem>
            )}

            {/* <TableHeaderItem>
              <Paragraph margin={"0"} fontWeight={"700"}>
                Duração
              </Paragraph>
            </TableHeaderItem> */}
            {/* <TableHeaderItem>
              <Paragraph margin={"0"} fontWeight={"700"}>
                Tamanho
              </Paragraph>
            </TableHeaderItem> */}
            <TableHeaderItem>
              <Paragraph margin={"0"} fontWeight={"700"}>
                Criação
              </Paragraph>
            </TableHeaderItem>
          </TableHeader>
          <TableBodies>
            {audios?.map((audio, index) => {
              return (
                <TableRow key={audio._id}>
                  <TableBodiesItem verticalAlign={"middle"}  width={"4%"}>{index + 1}</TableBodiesItem>
                  <TableBodiesItem verticalAlign={"middle"}>{audio.filename.toLowerCase()}</TableBodiesItem>
                  <TableBodiesItem verticalAlign={"middle"} width={"10%"}>
                    <TableIcons>

                      <div title="visualizar transcrição" onClick={() => handleOpenTranscription(audio.improvedTranscribedText, audio.transcribedText, audio.blobUrl, audio.filename)}> 
                        <IconEyeFilled 
                           />
                      </div>

                      <div title="exportar transcrição" onClick={() => handleTranscriptionDownload(audio.filename, audio.improvedTranscribedText)}>
                        <IconFilePdfRegular/>
                      </div>

                      <div title="visualizar áudio" onClick={() => window.open(audio.blobUrl)}> 
                        <IconMusicFilled/>
                      </div>
                    </TableIcons>
                  </TableBodiesItem>
                  <TableBodiesItem verticalAlign={"middle"} width={"18%"}>
                    <TagStatus background={getStatus[audio.status]?.color}>{getStatus[audio.status]?.title}</TagStatus>
                  </TableBodiesItem>
                  <TableBodiesItem verticalAlign={"middle"} width={"16%"}>{logins?.find((login) => login.user_id === audio.user)?.user_name || audio.user}</TableBodiesItem>
               
                  {userDataState.user_profile === "VGPT_ADMIN_LAB_IA" && (
                    <TableBodiesItem verticalAlign={"middle"} width={"10%"}>{audio.userProfile}</TableBodiesItem>
                  )}

                  {/* <TableBodiesItem verticalAlign={"middle"}>{audio.metadata?.duration}</TableBodiesItem> */}
                  {/* <TableBodiesItem verticalAlign={"middle"}>{audio.metadata?.fileSize}</TableBodiesItem> */}
                  <TableBodiesItem verticalAlign={"middle"}>{format(new Date(audio.createdAt), "dd/MM/yyyy' | ' HH:mm", { locale: ptBR })}</TableBodiesItem>
                </TableRow>
              )
            })}
          </TableBodies>
        </Table>
      </FormContainer>

      {isOpen && (
        <ModalUploadAudio
          closeModal={closeCrudModal}
          onFileSelect={handleFileSelect}
          amountLimit={1}
        />
      )}

      {isTranscriptionViewerOpen && (
        <ModalAudioViewer
          closeModal={() => setIsTranscriptionViewerOpen(false)}
          transcription={selectedTranscription}
          originalTranscription={selectedOriginalTranscription}
          audioUrl={selectedAudioUrl}
          diarization={selectedDiarization}
          title={selectedAudioTitle}
        />
      )}
    </>
  );
};
