import { createSlice } from '@reduxjs/toolkit';

export const products = createSlice({
	name: 'products',
	initialState: {
		products: [],

	},
	reducers: {
		setProducts: (state, action) => {
			state.products = action.payload
		},
		clearProducts: (state) => { 
			state.products = []
		}
	},
});

export const { setProducts, clearProducts } = products.actions
export default products.reducer