import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSidebarOpen } from "providers/reducers/header";
import { Title, FormContainer, SearchContainerRow, PdfWrap, PdfBtnWrap, PdfBtn, PdfLogo, Image, LoadSpinner } from "../../../styled-components";
import { TabPanel } from "../../../components/TabPanel";
import { useHistory } from 'react-router-dom'
import { DefaultInput } from "../../../components/DefaultInput";
import { FilterButton } from "../../../components/Buttons";
import { BoxChatGPT, Button, ColumnChatGPT, ColumnChatGPTInfo, ContainerChatGPT, FeedBackWrapDark, IconSendVivo, Paragraph } from "application/styled-components/chatGPT";
import pdfIcon from '../../../../assets/img/pdf.png'
import PDFViewer from "application/components/PdfViewer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QandaService from 'application/services/QandaService';
import { useAuthenticationQNA } from "application/routes/hooks/useAuthentication";
import FeedBackService from "application/services/FeedBackService";
import FeedBackModal from "application/components/FeedBackModal";
import Bell from "assets/img/bell.png"
import Light from "assets/img/light.png"
import Talk from "assets/img/talk.png"

/* like icons */
import likeblacktransparent from 'assets/img/likeblacktransparent.png'
import likeblackfilled from 'assets/img/likeblackfilled.png'
import dislikeblacktransparent from 'assets/img/dislikeblacktransparent.png'
import dislikeblackfilled from 'assets/img/dislikeblackfilled.png'
/*  */


export const Chat = ({ subscription_id }) => {
  const history = useHistory()
  const userDataState = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [prompt, setPrompt] = useState("")
  const [loadingAwnser, setLoadingAwnser] = useState(false)
  const [showCard, setShowCard] = useState(false)
  const [answer, setAnswer] = useState(false)
  const [correlator, setCorrelator] = useState('')
  const [supportingSnippets, setSupportingSnippets] = useState([])
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfOpen, setPdfOpen] = useState(false)
  const [feedbackMessage, setFeedbackMessage] = useState('')
  const [feedback, setFeedback] = useState('')
  const [hasFeedback, setHasFeedback] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [tipsAndExamples, setTipsAndExamples] = useState(null)

  const openModal = () => {
    setIsOpen(true);
    setFeedbackMessage('');
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleModalSubmit = async () => {
    try {
      await FeedBackService.feedbackQNA(userDataState, feedback, correlator, feedbackMessage);
      console.log("Feedback sent successfully!");
      toast.success('Feedback enviado com sucesso', {
        autoClose: 1500
      })
      setHasFeedback(true)
    } catch (error) {
      console.log("Failed to send feedback:", error);
      toast.error('Erro ao enviar feedback', {
        autoClose: 1500
      })
    }
    closeModal();
  };


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendPrompt()
    }
  };

  const handlePdfs = (pdfs) => {
    const formattedPdfs = [];
    const uniquePdfs = [];
    const uniqueSet = new Set();

    for (const item of pdfs) {
      const name = item.document.name;
      if (!uniqueSet.has(name)) {
        uniquePdfs.push(name);
        uniqueSet.add(name);
      }
    }

    uniquePdfs.forEach((fileName) => {
      const pages = []
      const pdfMatch = pdfs.filter((pdf) => pdf.document.name === fileName)
      pdfMatch.map((pdfMatched) => pages.push(pdfMatched.page_number))
      pages.sort((a, b) => a - b)
      const pdfContent = {
        document: {
          name: pdfMatch[0].document.name,
          url: pdfMatch[0].document.url
        },
        pages: pages
      }
      formattedPdfs.push(pdfContent)
    })

    return formattedPdfs
  }

  const handleSendPrompt = async (message) => {
    let mesageString = ''
    if (!message) {
      mesageString = prompt
    } else {
      mesageString = message
    }
    if (!mesageString) {
      toast.error('Preencha o campo de pergunta', {
        autoClose: 1500
      })
    } else {
      setShowCard(true)
      setLoadingAwnser(true)
      setPdfOpen(false)
      try { 

        const response = await QandaService.ask(userDataState, mesageString, subscription_id);
        const pdfsHandlded = handlePdfs(response.supporting_snippets)
        setAnswer(response.content)
        setCorrelator(response.correlator)
        setSupportingSnippets(pdfsHandlded)
        setLoadingAwnser(false)
        setFeedback('')
        setHasFeedback(false)
      } catch (error) {
        console.log('catch qna');
        console.log(error);
        switch (error.status) {
          case 400:
            toast.error('Desculpe, houve um erro ao processar a sua pergunta. Por favor, tente novamente e, se o problema persistir, contate o administrador do sistema.', {
              autoClose: 1500
            })
            break;
          case 401:
            toast.error('Desculpe, você não tem acesso à esse caso de uso. Se você acredita que isso seja um erro, contate o administrador do sistema.', {
              autoClose: 1500
            })
            break;
          case 429:
            toast.error('Você atingiu o limite de perguntas em um curto período. Por favor, aguarde um momento e pergunte novamente.', {
              autoClose: 1500
            })
            break;
          case 500:
          case 502:
            toast.error('Desculpe, houve um erro interno. Por favor, entre em contato com o administrador do sistema.', {
              autoClose: 1500
            })
            break;
          default:
            toast.error('Desculpe, houve um erro ao enviar sua pergunta. Tente novamente e, se o problema persistir, contate o administrador do sistema.', {
              autoClose: 1500
            })
        }
        setLoadingAwnser(false)
        setShowCard(false)
      }

    }
  }

  const isPdfOpen = (url, page) => {
    setPdfUrl(`${url}#page=${page}`);
    setPdfOpen(true);
  };

  const isPdfClose = () => {
    setPdfOpen(false)
    console.log(pdfOpen)
  }

  const getTipsAndExamples = async () => {
    await QandaService.tipsAndExamples(userDataState, subscription_id).then((response) => {
      setTipsAndExamples(response);
    })
  }

  const handleSendMessageByClickButton = (messageByBox) => {
    if (messageByBox !== '') {
      handleSendPrompt(messageByBox)
    }
  }

  const handleFeedbackMessage = (feedbackString) => {
    setFeedback(feedbackString)
    openModal()
  }

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27 && isOpen) {
        closeModal();
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen]);

  useEffect(() => {
    getTipsAndExamples()
  }, [subscription_id])

  return (<>
    <TabPanel active={true}>
      <FormContainer>
        <SearchContainerRow>
          <DefaultInput onKeyDown={handleKeyDown} label="Pergunta" change={setPrompt} width="100%" require={true} value={prompt} placeholder="Pergunte algo sobre o produto..."></DefaultInput>

          {/* <ButtonContainer> */}
          <Button position={'absolute'} top={'59%'} right={'33px'} transform={'translateY(-50%)'} onClick={() => handleSendPrompt()}>
            <IconSendVivo />
          </Button>
          {/* <FilterButton click={() => handleSendPrompt()} width="10%" height="36px">perguntar</FilterButton> */}
          {/* <FilterButton click={() => handleDeletePrompt()} width="10%" height="36px">Limpar</FilterButton> */}
        </SearchContainerRow>
      </FormContainer>
      {!showCard && tipsAndExamples? (
        <ContainerChatGPT>
          <ColumnChatGPT>
            <Image src={Talk} width="initial" height="35px" objectFit="contain" />
            <p>{tipsAndExamples.examples_title}</p>
            {
              tipsAndExamples.examples.map((example) => {
                return (
                  <BoxChatGPT linkActive={true} onClick={() => handleSendMessageByClickButton(example)}>
                    {example}
                  </BoxChatGPT>
                )
              })
            }
          </ColumnChatGPT>

          <ColumnChatGPTInfo>
            <Image src={Light} width="initial" height="35px" objectFit="contain" />
            <p>{tipsAndExamples.usage_tips_title}</p>
            {
              tipsAndExamples.usage_tips.map((usageTips) => {
                return (
                  <BoxChatGPT linkActive={true} onClick={() => handleSendMessageByClickButton(usageTips)}>
                    {usageTips}
                  </BoxChatGPT>
                )
              })
            }
          </ColumnChatGPTInfo>

          <ColumnChatGPTInfo>
            <Image src={Bell} width="initial" height="35px" objectFit="contain" />
            <p>{tipsAndExamples.safety_tips_title}</p>
            {
              tipsAndExamples.safety_tips.map((usageTips) => {
                return (
                  <BoxChatGPT linkActive={true} onClick={() => handleSendMessageByClickButton(usageTips)}>
                    {usageTips}
                  </BoxChatGPT>
                )
              })
            }
          </ColumnChatGPTInfo>
        </ContainerChatGPT>
      ) : (
        <div></div>
      )
      }
      {/* <TablePanel align="end" width="20%" download={ () => keywordHandleDownloadCSV() } data={ formatDateToViewInTable(interactions.interactions) } columns={columns.map(c => c.key)} columnsName={columns.map(c => c.name)} /> */}
      {
        showCard && <>

          {
            loadingAwnser
              ?
              <FormContainer>
                <div style={{ padding: 10 }}>
                  <LoadSpinner />
                </div>
              </FormContainer>

              : <>
                <FormContainer>
                  <div style={{ padding: 10 }}>

                  </div>
                  <FeedBackWrapDark justifyContent={'end'}>
                    {
                      hasFeedback ?
                        feedback === 'positive' ?
                          <Image width={'2%'} height={'auto'} objectFit={'fill'} src={likeblackfilled} className="active" />
                          : <Image width={'2%'} height={'auto'} objectFit={'fill'} src={dislikeblackfilled} className="active" />
                        :
                        <>
                          <Image cursor={'pointer'} width={'2%'} height={'auto'} objectFit={'fill'} src={likeblacktransparent} onClick={() => handleFeedbackMessage('positive')} className="active" />
                          <Image cursor={'pointer'} width={'2%'} height={'auto'} objectFit={'fill'} src={dislikeblacktransparent} onClick={() => handleFeedbackMessage('negative')} className="active" />
                        </>
                    }
                  </FeedBackWrapDark>
                  <div dangerouslySetInnerHTML={{ __html: answer }} className="pre-wrap" />
                  {/* {
                      answer
                    } */}
                </FormContainer>
                <PdfWrap boxShadow={'none'} margin={'.5rem 2.5rem 1.5625rem'} background={'initial'} border={'none'}>
                  <Paragraph fontSize={'.875rem'}>REFERÊNCIA</Paragraph>
                  <PdfBtnWrap>
                    {
                      supportingSnippets.map((pdf, index) => {
                        return (
                          <PdfBtn key={index} onClick={() => isPdfOpen(pdf.document.url, pdf.pages[0])}>
                            <PdfLogo src={pdfIcon} />
                            <Paragraph textAlign={'left'}>{`${pdf.document.name} - Página(s): ${pdf.pages.toString()}`}</Paragraph>
                          </PdfBtn>
                        )
                      })
                    }
                  </PdfBtnWrap>
                </PdfWrap>
              </>
          }
        </>
      }
    </TabPanel>
    {
      pdfOpen && (
        <PdfWrap>
          <FilterButton width={'20%'} click={isPdfClose} height={'auto'}>Fechar</FilterButton>
          <PDFViewer url={pdfUrl} />
        </PdfWrap>

      )
    }

    {isOpen && (
      <FeedBackModal
        closeModal={closeModal}
        isLike={feedback === 'positive'}
        change={(e) => setFeedbackMessage(e.target.value)}
        submit={handleModalSubmit}
      />
    )}
  </>);
};

