import styled from 'styled-components';

export const SidebarContainer = styled.aside`
    height: 100%;
    width: ${ props => props.active ? "220px" : "50px"};
    overflow-x: hidden;
    transition: .5s;
    background: #fff;
    display: block;
    top: 51px;
    left: 0;
    position: fixed;
    box-shadow: 0 4px 4px 0 #ccc;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    z-index: 5;
    border-top: solid #ccc;
    border-width: 1px;
`;

export const SidebarItem = styled.div`
    position: relative;
    padding-left: 15px;
    cursor: pointer;
    line-height: 42px;
    margin-bottom: 10px;
    margin-top: 5px;
    display: block;
    box-sizing: border-box;
    transition: 0.5s;
    ${ props => props.active ? "" : "height: 43px;" }
    ${ props => props.hover ? "" : "border-right: 3px solid #660099;" }
    ${ props => props.open ? "" : "background-color: #f2f2f2;" }
`;

export const SidebarItemOptionContainer = styled.div`
    margin-right: -15px;
    margin-left: -15px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
`;
