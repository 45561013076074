import { Switch, BrowserRouter, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Home,
  Products,
  Login,
  ChatGPT,
  QandA,
  ResumoEmpresa,
  UserAndPermissions,
  NotFoundPage,
  Callback,
  ContentGenerator,
  Precificador,
  Whisper,
  MonitoramentoPrecificador,
  RegrasPrecificador,
} from "../pages";
import FlowChat from "application/pages/FlowChat/Home";

export const Routes = () => {
  const BASENAME = process.env.REACT_APP_API_URL_BASENAME
  return (
    <BrowserRouter basename={BASENAME ? BASENAME : "/"}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/login/callback" component={Callback} />
        <Route path="/" exact component={Home} />
        <Route path="/products" exact component={Products} />
        <Route path="/qna" exact component={QandA} />
        <Route path="/b2b/qa" exact component={QandA} />
        <Route path="/b2b/resumo-empresa" exact component={ResumoEmpresa} />
        <Route path="/whisper" exact component={Whisper} />
        <Route path="/chat-gpt" exact component={ChatGPT} />
        <Route path="/chat" exact component={FlowChat} />
        <Route path="/gerador-conteudo" exact component={ContentGenerator} />
        <Route path="/precificador" exact component={Precificador} />
        <Route path="/monitoramento-precificador" exact component={MonitoramentoPrecificador} />
        <Route path="/regras-precificador" exact component={RegrasPrecificador} />
        <Route
          path="/administration/users"
          exact
          component={UserAndPermissions}
        />
        <Route path="*" component={NotFoundPage} />
        {/* <Route path="/b2b/qa/admin" exact component={QandAAdmin} /> */}
      </Switch>
      <ToastContainer />
    </BrowserRouter>
  );
};
