import React, { useState } from 'react'
import { TextField } from "@telefonica/mistica"
import { ButtonsWrap, Form, LabelFullWidth, ModalOverLay, ModalWrap, ProfileButton } from "application/styled-components/Perfil"

import AuthService from '../../services/AuthService'
import { toast } from 'react-toastify'

import { useSelector } from 'react-redux'

export const ModalPermissionCreate = ({ data, setData, dataEdit, isOpen, closeModal, onClose, getAllPermissions }) => {

    const userDataState = useSelector((state) => state.user);

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    const handleSave = async (e) => {
        e.preventDefault();
        if (name === '' || description === '') {
            toast.error('Preencha todos os campos', {
                autoClose: 1500
            })
        } else {
            try {
                await AuthService.createPermission(userDataState, name, description);
                closeModal()
                getAllPermissions()
                toast.success('Permissão criada com sucesso', {
                    autoClose: 1500
                })
            } catch (err) {
                switch (err.data.code) {
                    case 'PERMISSION_EXISTS':
                        toast.error('Permissão já cadastrado no sistema', {
                            autoClose: 1500
                        });
                        break
                    default:
                        toast.error('Erro ao realizar cadastro, favor entrar em contato com o suporte do sistema.', {
                            autoClose: 1500
                        });
                }
            }
        }

    }

    return (
        <>
            <ModalOverLay>

                <ModalWrap>
                    <Form isOpen={isOpen} onClose={onClose} onSubmit={handleSave}>
                        <LabelFullWidth>
                            <TextField
                                label='Nome da permissão'
                                name='name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </LabelFullWidth>
                        <LabelFullWidth>
                            <TextField
                                label='Descrição da permissão'
                                name='description'
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </LabelFullWidth>
                        <ButtonsWrap>
                            <ProfileButton onClick={handleSave}>
                                Criar
                            </ProfileButton>
                            <ProfileButton className='cancel' onClick={closeModal}>
                                Cancelar
                            </ProfileButton>
                        </ButtonsWrap>
                    </Form>
                </ModalWrap>
            </ModalOverLay>
        </>
    )
}