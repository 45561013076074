import React, { useState, useEffect } from "react";
import { FormContainer, Table, TableBodies, TableBodiesItem, TableHeader, TableHeaderItem, TableRow } from "application/styled-components";
import { TabPanel } from "../TabPanel";
import { OptionButton, OptionsWrap } from "application/styled-components/Perfil";
import { Paragraph } from "application/styled-components/chatGPT";
import { IconAddMoreCircleRegular, IconEditRegular, IconEyeRegular } from "@telefonica/mistica";
import { ModalTenant } from "../ModalTenant";
// import { ModalViewUser } from "../ModalViewUser";
import { ModalEditTenant } from "../ModalEditTenant";

import AuthService from '../../services/AuthService'

import { useSelector } from "react-redux";

export const AdministrationTenants = (user) => {

    const userDataState = useSelector((state) => state.user);

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [data, setData] = useState([])
    const [dataEdit, setDataEdit] = useState({})
    
    const [viewUser, setViewUser] = useState(false)
    // const [editUser, setEditUser] = useState(false)
    const [userSelected, setUserSelected] = useState({})


    const openModal = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
        setIsOpen(false);
        document.body.style.overflow = '';
    };

    const openModalEdit = () => {
        setIsOpenEdit(true);
        document.body.style.overflow = 'hidden';
    };

    const closeModalEdit = () => {
        setIsOpenEdit(false);
        document.body.style.overflow = '';
        getAllTenants()
    };

    const closeModalViewUser = () => {
        setViewUser(false);
        document.body.style.overflow = '';
    };

    const getAllTenants = async () => {
        await AuthService.getAllTenants(userDataState).then((response) => {
            setData(response.tenants)
        })
    }

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.keyCode === 27 && isOpen) {
                closeModal();
                closeModalViewUser();
            }
        };
        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [isOpen]);

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.keyCode === 27 && isOpen) {
                closeModalEdit();
                closeModalViewUser();
            }
        };
        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [isOpenEdit]);


    useEffect(() => {
        getAllTenants()
    }, [])

    return (
        <>
            <TabPanel active={true}>
                <FormContainer>
                    <OptionsWrap gap={'.75rem'}>
                        <OptionButton alignItems={'center'} gap={'.1875rem'} onClick={openModal}>
                            <Paragraph fontSize={'.875rem'} color={'#660099'} fontWeight={'500'}>Novo perfil</Paragraph>
                            <IconAddMoreCircleRegular />
                        </OptionButton>
                    </OptionsWrap>
                    <Table>

                        <TableHeader>
                            <TableHeaderItem>
                                <Paragraph margin={'0'} fontWeight={'700'}>Nome</Paragraph>
                            </TableHeaderItem>
                            <TableHeaderItem>
                                <Paragraph margin={'0'} fontWeight={'700'}>Permissões</Paragraph>
                            </TableHeaderItem>
                            <TableHeaderItem>
                                <Paragraph margin={'0'} fontWeight={'700'}>Ações</Paragraph>
                            </TableHeaderItem>
                        </TableHeader>
                        <TableBodies>
                            {
                                data.map((user, index) => {

                                    return (
                                        <TableRow key={index}>
                                            <TableBodiesItem>{user.name}</TableBodiesItem>
                                            <TableBodiesItem>
                                                {user.permissions.map((permission, index) => (<>
                                                    {permission.trim()}
                                                    {index !== user.permissions.length - 1 && ', '}
                                                </>))}
                                            </TableBodiesItem>
                                            <TableBodiesItem>
                                                {/* <IconEyeRegular onClick={() => {
                                                    setUserSelected(user)
                                                    setViewUser(true)
                                                }} /> */}
                                                <IconEditRegular onClick={() => {
                                                    setUserSelected(user)
                                                    openModalEdit(true)
                                                }} />
                                                {/* <Tooltip
                                                    targetLabel="help text"
                                                    target={<IconEditRegular />}
                                                    description={'Em breve'}
                                                    position="top"

                                                /> */}
                                                
                                                {/* <IconTrashCanRegular onClick={handleDeleteItem}/> */}
                                            </TableBodiesItem>
                                        </TableRow>
                                    )
                                })
                            }

                        </TableBodies>
                    </Table>


                    {isOpen && (
                        <ModalTenant
                            closeModal={closeModal}
                            setData={setData}
                            data={data}
                            dataEdit={dataEdit}
                            getAllUsers={getAllTenants}
                        />
                    )}

                    {/* {viewUser && (
                        <ModalViewUser
                            closeModal={closeModalViewUser}
                            data={userSelected}
                        />
                    )} */}

                    {isOpenEdit && (
                        <ModalEditTenant
                            closeModal={closeModalEdit}
                            userSelected={userSelected}
                        />
                    )}
                    
                </FormContainer>


            </TabPanel>
        </>
    )
}