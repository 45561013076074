import styled from "styled-components";

export const ChatResponseContainer = styled.div`
  width: 100%;
  font-family: Telefonica !important;
  color: #0b2739;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.27px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 47px;
`;

export const ChatQuestion = styled.div`
  width: 100%;
  background-color: white;
`;

// CHAT RESPONSE
// ::-webkit-scrollbar-track {
//   background: #f6f6f6;
//   padding-right: 30px;
// }
// ::-webkit-scrollbar {
//   width: 8px;
// }
// ::-webkit-scrollbar-thumb {
//   border-radius: 20px;
//   background: blue;
//   background: #d9d9d9;
// }
export const ChatResponse = styled.div`
  width: 100%;
  background-color: #f6f6f6;
  display: flex;
  overflow-y: auto;
  height: auto;
  padding: 24px 0;
  margin-bottom: ${(props) => (props.isLast ? "240px" : "0")};
  .typing-text {
    animation: typing 1s steps(20, end), blink-caret 0.5s step-end infinite;
    overflow-wrap: anywhere !important;
    width: 100%;
  }
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: black;
    }
  }
  .loading-dots {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading-dot {
    width: 8px;
    height: 8px;
    background-color: #888;
    border-radius: 50%;
    margin: 0 3px;
    animation: dot-jump 1s infinite;
  }

  .loading-dot:nth-child(2) {
    animation-delay: 0.2s;
  }

  .loading-dot:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes dot-jump {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
      background-color: #660099;
    }
  }
`;

export const Icon = styled.img`
  padding-right: 32px;
  padding-left: 80px;
  width: 56px;
  height: 56px;
`;
export const IconUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 56px;
  min-height: 56px;
  background-color: #e63780;
  border-radius: 50%;
  margin-right: 32px;
  margin-left: 80px;
  /* Text  */
  color: #fff;
  text-align: center;
  font-family: Telefonica !important;
  font-size: 24px;
  font-weight: 500;
`;
export const Question = styled.p`
  word-wrap: anywhere;
  padding-top: 12px;
  margin: 0;
  padding-right: 100px;
`;
export const Feedback = styled.div`
  display: flex;
  padding-left: 8px;
  padding-right: 12px;
  gap: 8px;
  overflow: visible;
`;
export const BoxQuestion = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 64px;
  align-items: flex-start;
`;
