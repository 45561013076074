// import ServiceHelper from "../helpers/ServiceHelper";
import axios from 'axios'
import { User } from './interfaces/User'
import { HandlerError } from './HandlerError'
import { ITipsAndExamples } from './interfaces/ITipsAndExamples'

class QandaService {
  private static readonly API_URL = process.env.REACT_APP_API_URL_SERVICE_QNA
  private static readonly API_AURA_URL = process.env.REACT_APP_API_AURA_URL_SERVICE_QNA
  private static readonly AURA_SUBSCRIPTION_IDS = process.env.REACT_APP_AURA_SUBSCRIPTION_IDS
  private static readonly API_URL_VALIDATION = process.env.REACT_APP_API_URL_SERVICE_LOGIN
  private static readonly SERVICE = 'QNA_MOBILE_PRODUCTS'
  private static getConfig = ({
    user_id,
    session_id,
    token,
    user_email,
    user_name,
    user_profile,
    oam_matricula,
    oam_gestor,
    oam_ccusto,
    subscriptionSelected
  }: User) => {
    return {
      headers: {
        session_id: session_id,
        user_id: user_id,
        token: token,
        service: this.SERVICE,
        user_profile,
        user_name,
        user_email,
        oam_matricula,
        oam_gestor,
        oam_ccusto,
        subscription_id: subscriptionSelected,
      },
    }
  }

  static async askV2(userData: User, question: string, subscription_id: string, paramsPrompt: object) {
    try {
      const CONFIG = this.getConfig(userData)

      const response = await axios({
        url: this.API_AURA_URL + "/prompts-models/ask",
        method: "POST",
        data: paramsPrompt,
        headers: {
          ...CONFIG.headers,
          subscription_id: userData.subscriptionSelected
        }
      })

      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async ask(userData: User, question: string, subscription_id: string) {
    try {
      const CONFIG = this.getConfig(userData)
      const headers = {
        ...CONFIG.headers,
        subscription_id: subscription_id,
      }

      const url = (this.AURA_SUBSCRIPTION_IDS as string)
        .split("|")
        .includes(subscription_id)
        ? `${this.API_AURA_URL}/aura-qna`
        : `${this.API_URL}/qna-mobile-products`;

      const response = await axios.get(
        `${url}/?question=${question}`,
        { headers },
      )

      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }
  static async tipsAndExamples(userData: User, subscription_id: string): Promise<ITipsAndExamples | any> {
    try {
      const CONFIG = this.getConfig(userData)
      const headers = {
        ...CONFIG.headers,
        subscription_id: subscription_id,
      }

      const response = await axios.get(
        `${this.API_URL}/examples/`,
        { headers },
      )
      return response.data;
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }


  static async validatePermission(userData: User) {
    const CONFIG = this.getConfig(userData)
    try {
      const response = await axios.get(
        `${this.API_URL_VALIDATION}/auth`,
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }
}

export default QandaService
