import AudioManagerService from "application/services/AudioManagerService";
import AuthService from "application/services/AuthService";

interface UploadAudioRequest {
  userDataState: any;
  filename: string,
  file: File,
}

export async function getAudios(userDataState: any) {
  const audios = await AudioManagerService.getAllAudios(userDataState);
  return audios.toReversed();
}

export async function getLogins(userDataState: any) {
  const allLogins = await AuthService.getAllLogins(userDataState);
  return allLogins.logins;
}

export async function uploadAudio({ userDataState, filename, file }: UploadAudioRequest) {
  await AudioManagerService.uploadAudio(userDataState, filename, file);
  return;
}

export function getAudioMaxFileSize() {
  const audioMaxFileSize = Number(process.env.REACT_APP_API_AUDIO_MAX_FILE_SIZE) || 25;
  const audioMaxFileSizeFormatted = 1048576 * audioMaxFileSize;
  return audioMaxFileSizeFormatted;
}

export const getStatus = {
  "FAILED": {
    title: "falha na transcrição",
    color: "#C75F86"
  },
  "CREATED": {
    title: "criando transcrição...",
    color: "#65A9ED"
  },
  "SUCCESS": {
    title: "processando transcrição...",
    color: "#19bc8b"
  },
  "COMPLETED": {
    title: "disponível",
    color: "#402E75"
  },
};

export function getNameDiarization(line) {
  return line.substring(line.indexOf("[") + 1, line.lastIndexOf("]"));
}

export function getDiarization(transcription) {

  const diarization = {}
  let countDiarization = 0;

  transcription?.split("\n").map((line) => {
      const name = getNameDiarization(line);

      if (!diarization[name]) {
          Object.assign(diarization, {[name]: `${countDiarization}`});
          countDiarization += 1;
      }
  })

  return diarization;
}


