import React, { useEffect } from "react";

import { Footer } from './styles';

const AcessReportTableFooter = ({ range, setPage, page, slice }: any) => {
  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);
  return (
    <Footer>
      {range.map((el, index) => (
            <button
              key={index}
              className={"button"}
              onClick={() => setPage(el)}
            >
              {el}
            </button>
          ))}
    </Footer>
 
  );
};

export default AcessReportTableFooter;
