import React from 'react';

interface AdminPdfViewerProps {
  url: string;
  initialPage?: number;
}

const AdminPdfViewer: React.FC<AdminPdfViewerProps> = ({ url, initialPage }) => {
  const handleLoad = (event: React.SyntheticEvent<HTMLIFrameElement>) => {
    const { target } = event;

    if (target instanceof HTMLIFrameElement) {
      const contentDocument = target.contentDocument;

      if (contentDocument) {
        const numPages = contentDocument.querySelector('.react-pdf__Page')?.children.length;
        if (numPages) {
          const pageNumber = Math.min(initialPage || 1, numPages);
          target.contentWindow?.postMessage({ page: pageNumber }, '*');
        }
      }
    }
  };

  return (
    <div>
      <iframe
        src={url}
        title="PDF Viewer"
        width="100%"
        height="600"
        onLoad={handleLoad}
      />
    </div>
  );
};

export default AdminPdfViewer;
