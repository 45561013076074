import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { Image } from "../../../../styled-components";
import iconExportRegular from "../../../../../assets/svg/export-regular.svg";
import iconSwapVertIcon from "../../../../../assets/svg/SwapVertIcon.svg";
import PrecificadorService from "application/services/PrecificadorService";

export const BtnExportComponent = ({ total_itens, handleCloseOrdenacao, handleExportProdutos, showHiddenTags }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (tipoOrdenacao) => {
    handleCloseOrdenacao(tipoOrdenacao);
  };

  const handleCloseNone = () => {
    setAnchorEl(null); // Ou faça qualquer ação necessária após a seleção da ordenação
  };


  const [anchorE2, setAnchorE2] = React.useState(null);
  const open2 = Boolean(anchorE2);

  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleExport = (tipoExportacao) => {
    handleExportProdutos(tipoExportacao); 
    setAnchorE2(null); // Fecha o menu
  };

  return (
    <div className={showHiddenTags ? 'show-table' : 'hide-table'}>
    <div className={'ctnFlex'}>
      <div className="op1"></div>
      <div className="op2">
        <Stack direction="row" spacing={3}>
          <Button
            id="ordenar-button"
            aria-controls={open ? "ordenar-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            color="secondary"
            variant="outlined"
            startIcon={<Image src={iconSwapVertIcon} />}
          >
            Ordenar
          </Button>
          <Menu
            id="ordenar-menu"
            MenuListProps={{
              "aria-labelledby": "ordenar-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleCloseNone()}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={() => handleClose("ordenar-menor-preco")}>Menor preço</MenuItem>
            <MenuItem onClick={() => handleClose("ordenar-maior-preco")}>Maior preço</MenuItem>
          </Menu>

          <Button
            id="fade-button"
            aria-controls={open2 ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open2 ? "true" : undefined}
            onClick={handleClick2}
            color="secondary"
            variant="outlined"
            startIcon={<Image src={iconExportRegular} />}
          >
            Exportar
          </Button>
          <Menu
            id="ordenar-menu"
            MenuListProps={{
              "aria-labelledby": "ordenar-button",
            }}
            anchorEl={anchorE2}
            open={open2}
            onClose={handleExport}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={() => handleExport("excel")}>Excel</MenuItem>
            <MenuItem onClick={() => handleExport("csv")}>CSV</MenuItem>
          </Menu>
        </Stack>
      </div>
    </div>
    </div>
  );
};
