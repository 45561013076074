import React from "react";
import ArrowIconSvg from "../../../assets/svg/ArrowIcon.svg";
import {
  CustomSelectWrapper,
  SelectBox,
  OptionsList,
  Option,
  ScrollList,
  ArrowIcon,
  LabelTextWhenSelected,
  OptionsListSubMenu
} from "./styles";

export const SelectsTone = ({
  options,
  categoryName,
  selectedTone,
  setSelectedTone,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenSubMenu, setIsOpenSubMenu] = React.useState(false);
  const [optionsSubMenu, setOptionsSubMenu] = React.useState([]);

  const toggleOptionsList = () => {
    setIsOpen(!isOpen);
  };
  
  const optionsSub = {
    "Rede Social": ["Facebook", "Instagram", "Twitter", "LinkedIn", "YouTube"],
    "Comunicação": ["Newsletter", "E-mail","SMS","Notícia"]
  }

  const handleOptionClick = (option) => {

    if (option === 'Rede Social' || option === 'Comunicação') {
      setOptionsSubMenu(optionsSub[option]);
      setIsOpenSubMenu(true);
    }else{
      setSelectedTone(option); 
      setIsOpen(false);
      setIsOpenSubMenu(false);
    }

  };

  const formatText = (category) => {
    switch (category) {
      case "Tipodeconteudo":
        return "Tipo de conteúdo"
      case "Tom":
        return "Tom"
      case "Personalidade":
        return "Personalidade"
      case "Linguagem":
        return "Linguagem"
      case "Estilo":
        return "Estilo"
      case "Proposito":
        return "Propósito"
      default:
        return ""
    }
  }

  return (
    <CustomSelectWrapper>
      <SelectBox onClick={toggleOptionsList}>
        {selectedTone || options[0]}
        <ArrowIcon src={ArrowIconSvg} isOpen={isOpen} />
      </SelectBox>
      {
        selectedTone && (<LabelTextWhenSelected>{formatText(categoryName)}</LabelTextWhenSelected>)
      }
      <OptionsList isOpen={isOpen}>
        <ScrollList>
          {options.map((option, index) => (
            <React.Fragment key={index}>
              <Option
                onClick={() => handleOptionClick(option)}
                className={selectedTone === option ? "selected" : ""}
              >
                {option}
                </Option>
            </React.Fragment>
            ))
          }
        </ScrollList>
      </OptionsList>
      <OptionsListSubMenu isOpen={isOpenSubMenu}>
        <ScrollList>
          {optionsSubMenu.map((option, index) => (
            <React.Fragment key={index}>
              {option === "Tom de voz" || option === "Personalidade" || option === "Linguagem" || option === "Estilo" || option === "Tipo de conteúdo" || option === "Propósito" ? (
                <Option className="title" isTitle>{option}</Option>
              ) : (
                <Option
                  onClick={() => handleOptionClick(option)}
                  className={selectedTone === option ? "selected" : ""}
                >
                  {option}
                </Option>
              )}
            </React.Fragment>
          ))
          }
        </ScrollList>
      </OptionsListSubMenu>
    </CustomSelectWrapper>
  );
};