import { useState, useEffect } from "react";
import AuthService from '../../../services/AuthService'
import { useSelector } from 'react-redux'

export interface ILogins {
  _id: number;
  user_id: string;
  user_name: string
  created_at: Date;
  updatedAt: Date ;
  __v: number;
}

export const useLogins = () => {
  const userDataState = useSelector((state: any) => state.user)

  const getAllLogins = async () => {
    await AuthService.getAllLogins(userDataState).then((response) => {
      setLogins(response.logins)
    })
  }

  const [logins, setLogins] = useState<ILogins[]>([])

useEffect(() => {
  getAllLogins()
}, [])
  return {
    logins
  }
};

