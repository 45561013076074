import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import iconAdd from "../../../../../assets/svg/add-more-regular.svg";
import { Image } from "application/styled-components";
import { useHistory } from "react-router-dom";

export const HeadTitleComponent = () => {
  const history = useHistory();
  const handleClick = (url) => {
    history.push(url);
  };

  const query = new URLSearchParams(window.location.search);
  const subscription_id = query.get("subscription_id");

  const handleAdminClickCep = () => {
    return handleClick(`/cadastra-cep?subscription_id=${subscription_id}`);
  };

  const handleAdminClickProduto = () => {
    return handleClick(`/cadastra-produto?subscription_id=${subscription_id}`);
  };

  return (
    <div className="top-head-title">
      <div className="ctnFlex">
        <div className="op1">
          <h3>{'Consulta de preços'}</h3>
        </div>
        <div className="op2">
          <Stack direction="row" spacing={3}>
            <Button
              onClick={handleAdminClickProduto}
              color="secondary"
              variant="outlined"
              startIcon={<Image src={iconAdd} />}
            >
              {'Cadastro Produto'}
            </Button>
            <Button
              onClick={handleAdminClickCep}
              color="secondary"
              variant="outlined"
              startIcon={<Image src={iconAdd} />}
            >
              {'Cadastrar Cep'}
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
};
