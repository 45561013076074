import React from "react";
import { Image } from "application/styled-components";
import { MoonLoader } from "react-spinners";
import iconVivo from "../../../../../assets/svg/icon-vivo.svg";

export const Loader = () => {
  return (
    <div className="centraliza-loader-table">
      <MoonLoader
        color="#660099"
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <Image
        width={"50px"}
        height={"auto"}
        objectFit={"fill"}
        src={iconVivo}
        className="icon_vivo_loader"
      />
    </div>
  );
};
