import React, { useState, useEffect } from 'react'
import { EmailField, PasswordField, TextField } from "@telefonica/mistica"
import { ButtonsWrap, FlexRow, Form, FormWrap, LabelFullWidth, ModalOverLay, ModalWrap, PermissionLabel, Permissions, ProfileButton, ProfileInput, ProfileLabel, PermissionsContainer } from "application/styled-components/Perfil"
// import { Paragraph } from "application/styled-components/chatGPT"

import AuthService from '../../services/AuthService'
import { toast } from 'react-toastify'

import { useSelector } from 'react-redux'

export const ModalTenant = ({ data, setData, dataEdit, isOpen, closeModal, onClose, getAllUsers }) => {

    const userDataState = useSelector((state) => state.user);

    let [name, setName] = useState('')

    const [read, setRead] = useState(false)
    const [create, setCreate] = useState(false)
    const [update, setUpdate] = useState(false)
    const [deletee, setDeletee] = useState(false)

    const handleSave = async (e) => {
        e.preventDefault();
        try {
            let permissions = [];
            if (read) {
                permissions.push('READ')
            }

            if (create) {
                permissions.push('CREATE')
            }

            if (update) {
                permissions.push('UPDATE')
            }

            if (deletee) {
                permissions.push('DELETE')
            }

            await AuthService.createProfile(userDataState, name, permissions);
            getAllUsers()
            closeModal()
            toast.success('Perfil criado com sucesso', {
                autoClose: 1500
            })

        } catch (err) {
            console.log(err)
            switch (err.data.code) {
                case 'TENANT_EXISTS':
                    toast.error('Perfil já cadastrado no sistema', {
                        autoClose: 1500
                    });
                    break
                default:
                    toast.error('Erro ao realizar cadastro, favor entrar em contato com o suporte do sistema.', {
                        autoClose: 1500
                    });
            }
        }
    }

    return (
        <>
            <ModalOverLay>

                <ModalWrap>
                    <Form isOpen={isOpen} onClose={onClose} onSubmit={handleSave}>
                        <LabelFullWidth>
                            <TextField
                                label='Nome do perfil no OAM'
                                name='name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </LabelFullWidth>
                        <LabelFullWidth>
                            <PermissionsContainer>
                                <Permissions>
                                    <PermissionLabel for={'profile-permission-read'} key={"read"}>
                                        <ProfileInput type="checkbox" id={'profile-permission-read'} value={read} onChange={() => setRead(state => !state)} />
                                        <ProfileLabel for={'profile-permission-read'}>LEITURA</ProfileLabel>
                                    </PermissionLabel>

                                    <PermissionLabel for={'profile-permission-create'} key={"create"}>
                                        <ProfileInput type="checkbox" id={'profile-permission-create'} value={create} onChange={() => setCreate(state => !state)} />
                                        <ProfileLabel for={'profile-permission-create'}>CRIAÇÃO</ProfileLabel>
                                    </PermissionLabel>

                                    <PermissionLabel for={'profile-permission-update'} key={"update"}>
                                        <ProfileInput type="checkbox" id={'profile-permission-update'} value={update} onChange={() => setUpdate(state => !state)} />
                                        <ProfileLabel for={'profile-permission-update'}>EDIÇÃO</ProfileLabel>
                                    </PermissionLabel>

                                    <PermissionLabel for={'profile-permission-delete'} key={"delete"}>
                                        <ProfileInput type="checkbox" id={'profile-permission-delete'} value={deletee} onChange={() => setDeletee(state => !state)} />
                                        <ProfileLabel for={'profile-permission-delete'}>DELETAR</ProfileLabel>
                                    </PermissionLabel>

                                </Permissions>
                            </PermissionsContainer>
                        </LabelFullWidth>
                        <ButtonsWrap>
                            <ProfileButton onClick={handleSave}>
                                Aplicar
                            </ProfileButton>
                            <ProfileButton className='cancel' onClick={closeModal}>
                                Cancelar
                            </ProfileButton>
                        </ButtonsWrap>
                    </Form>
                </ModalWrap>
            </ModalOverLay>
        </>
    )
}