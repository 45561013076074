import axios, { AxiosRequestConfig } from 'axios'

export interface IActivities {
    type: string;
    timestamp: string;
    from: { id: string; name?: string };
    conversation: { id: string };
    text: string;
    channelData: object;
    id: string;
    channelId?: string;
}
  
export class DirectlineService {
    
    static directLineURL = process.env.REACT_APP_DIRECTLINE as string;
    static auraAuthURL = process.env.REACT_APP_AURA_AUTH as string;
    static apiKey = process.env.REACT_APP_AURA_APP_TOKEN as string;

    static async startConversation(token: string) {
        try {
            const request = {
                method: 'POST',
                url: `${DirectlineService.directLineURL}/v3/directline/conversations`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `bearer ${token}`
                },
            } as AxiosRequestConfig;

            const response = await axios(request);

            return {
                token: response.data?.token,
                conversationId: response.data?.conversationId,
                status: response.status,
            };
          } catch (error: any) {
            return {
                status: error.response?.status || 500,
                data: error.response?.data,
                code: error.code,
            };
        }
    }

    static async setToken() {
        try {
            const request = {
                method: 'GET',
                url: `${DirectlineService.auraAuthURL}/aura-services/v1/token`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `APIKEY ${DirectlineService.apiKey}`
                },
            } as AxiosRequestConfig;

            const response = await axios(request);

            return {
              status: response.status,
              token: response.data?.token,
            };
          } catch (error: any) {
            return {
              status: error.response?.status || 500,
              data: error.response?.data,
              code: error.code,
            };
        }
    }

    static async sendMessage(text: string, conversationId: string, token: string, userData: any, phone: string, customPrompt: string) {
        try {
            const request = {
                method: 'POST',
                url: `${DirectlineService.directLineURL}/v3/directline/conversations/${conversationId}/activities`,
                data: {
                    type: "message",
                    text: 'vivo travel: ' + text,
                    from: {
                        id: phone
                    },
                    channelData: {
                        appContext: {
                            channel: {
                                appId: "06320f84-a9a6-4112-bdb8-7bc5bc8e1f91"
                            }
                        },
                        userData,
                        customPrompt
                    }
                },
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `bearer ${token}`
                },
            } as AxiosRequestConfig;

            const response = await axios(request);

            return {
                id: response.data?.id,
                status: response.status,
            };
          } catch (error: any) {
            return {
                status: error.response?.status || 500,
                data: error.response?.data,
                code: error.code,
            };
        }
    }

    static async getMessage(conversationId: string, token: string) {
        try {
            const request = {
                method: 'GET',
                url: `${DirectlineService.directLineURL}/v3/directline/conversations/${conversationId}/activities`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `bearer ${token}`
                },
            } as AxiosRequestConfig;

            const response = await axios(request);

            return {
                activities: response.data?.activities,
                watermark: response.data?.watermark,
                status: response.status,
            };
          } catch (error: any) {
            return {
                status: error.response?.status || 500,
                data: error.response?.data,
                code: error.code,
            };
        }
    }

}