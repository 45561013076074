import React, { useState, useEffect } from "react";
import axios from "axios";
import Dropzone, { useDropzone } from "react-dropzone";
import { toast } from 'react-toastify';
import { checkFile } from "../../utils/checkFiles";
import { LoadSpinner } from "../../styled-components";
import ProconMessages from "./ProconMessages";

import "../../pages/FlowChat/Home.css";
import AccordionProposals from "./Accordion";

const UploadFile = ({
  chatLogRef,
  err,
  flowId,
  subscriptionId,
  chats,
  postChat,
  putChat,
  chat_id,
  messages,
  setMessages,
  toggle,
  guid,
  setGuid,
  reset,
  setReset
}) => {
  const [secondFlow, setSecondFlow] = useState(false);
  const [messagesString, setMessagesString] = useState("");
  const [loading, setLoading] = useState(false);

  const [uploadFile, setUploadFile] = useState();
  const [fileName, setFileName] = useState();

  const onVerify = (type) => {
    return checkFile(type);
  };
  const onError = () => {
    setUploadFile(undefined);
    setLoading(false);
  };

  useEffect(() => {
    if (reset) {
      setUploadFile(null);
      setSecondFlow(false);
      setLoading(false);
      setTimeout(() => {
        setReset(false)
      }, 500)
    }
  }, [reset])

  async function callAPI() {
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_FLOW}/upload/${flowId}`,
        uploadFile
      );

      const inputs = {
        question: "",
        session_id: "",
        guid: response.data.idFile
      };
      setTimeout(async () => {
        const proposalsResponse = await fetch(
          `${process.env.REACT_APP_API_URL_FLOW}/process/${flowId}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ inputs: inputs }),
          }
        );

        const proposals = await proposalsResponse.json();
        
        if (proposals.detail || proposals === undefined || proposals === null) {
          setUploadFile(null);
          setSecondFlow(false);
          setLoading(false);
          if(proposals.detail === "Error: Template could not be identified.") {
            toast.error('Desculpe, houve um erro ao gerar as propostas. Por favor, tente novamente e, se o problema persistir, contate o administrador do sistema.', {
              autoClose: 1500
            })
          }
          
          toast.error('Desculpe, houve um erro ao gerar as propostas. Por favor, tente novamente e, se o problema persistir, contate o administrador do sistema.', {
            autoClose: 1500
          })
          return
        }
        setMessagesString(
          proposals.result.text.slice(0, proposals.result.text.length - 38)
        );
        const paragraphs = proposals.result.text.split("\n\n");

        setGuid(paragraphs[3]);

        setMessages(
          paragraphs.slice(0, 3).map((item) => {
            return {
              type: "system",
              message: item,
              regenarate: false,
              regenarateMsg: "",
            };
          })
        );

        setLoading(false);
        handleSaveChat(
          paragraphs[3],
          paragraphs.slice(0, 3).map((item) => {
            return {
              type: "system",
              message: item,
              regenarate: false,
              regenarateMsg: "",
            };
          })
        );
        setSecondFlow(true);
      }, 1000)

    } catch (err) { setLoading(false); }
    //  Set responseFromAPI back to false after the fetch request is complete
  }

  const handleSaveChat = async (guidId, messagesObj) => {
    let history = [];
    history.push({
      type: "user",
      message: `${fileName}###${guidId}`,
    });
    messagesObj.forEach((item) => {
      history.push({ type: "system", message: item.message });
    });

    setSecondFlow(true);

    if (chats.length === 0) {
      const payload = {
        chats: [
          {
            date: new Date().toLocaleDateString(),
            history,
          },
        ],
      };
      postChat(payload, flowId);
    } else {
      const payload = {
        chat: {
          _id: chat_id || undefined,
          date: new Date().toLocaleDateString(),
          history,
        },
      };
      putChat(payload, flowId);
    }
  };

  async function callSecondFlow() {


    const validate = messages.filter((e) => e.regenarate && !e.regenarateMsg).map((e, i) =>
      i
    )


    if (validate.length === 0) {
      setSecondFlow(false);
      setLoading(true);
      try {
        const proposeNumbers = messages
          .map((e, index) => {
            if (e.regenarate) return index + 1;
            else {
              return undefined;
            }
          })
          .filter((e) => e !== undefined);
        const regenerateMsg = messages
          .map((e) => {
            if (e.regenarate) return e.regenarateMsg;
            else {
              return undefined;
            }
          })
          .filter((e) => e !== undefined);

        const inputs = {
          guid,
          propose_number: proposeNumbers,
          regenerate_msg: regenerateMsg,
        };

        const proposalsResponse = await fetch(
          `${process.env.REACT_APP_API_URL_FLOW}/process/292270820268847681614215445998289479036`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ inputs: inputs }),
          }
        );

        const proposals = await proposalsResponse.json();
        setMessagesString(proposals.result.text);
        const paragraphs = proposals.result.text.split("\n\n");

        setMessages(
          paragraphs.slice(0, 3).map((item) => {
            return {
              ...item,
              type: "system",
              message: item,
              regenarate: false,
            };
          })
        );

        setSecondFlow(true);

        let history = [];
        history.push({
          type: "user",
          message: `${fileName}###${guid}`,
        });

        paragraphs.slice(0, 3).forEach((item) => {
          history.push({ type: "system", message: item });
        });

        const payload = {
          chat: {
            _id: chat_id || undefined,
            date: new Date().toLocaleDateString(),
            history,
          },
        };
        await putChat(payload, flowId);
        setLoading(false);
      } catch (err) { }
    }

    //  Set responseFromAPI back to false after the fetch request is complete
  }

  async function callTreeFlow() {
    try {
      const inputs = {
        guid,
        messages: messagesString,
        regenerate_msg: [],
      };

      await fetch(
        `${process.env.REACT_APP_API_URL_FLOW}/process/292270820268847681614215445998289479036`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ inputs: inputs }),
        }
      );
      setUploadFile(null);
    } catch (err) { }
    //  Set responseFromAPI back to false after the fetch request is complete
  }
  const onDrop = (files) => {
    if (onVerify(files[0].type)) {
      const formData = new FormData();
      formData.append("file", files[0]);
      setFileName(files[0].name);

      setUploadFile(formData);
      setLoading(false);
    } else {
      onError();
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const onChangeRegenerateMsg = (value, index) => {
    setMessages(
      messages.map((i, n) => {
        if (n === index) {
          return {
            ...i,
            regenarateMsg: value,
          };
        } else {
          return i;
        }
      })
    );
  };

  const onSelectRegenerate = (value, index) => {
    setMessages(
      messages.map((i, n) => {
        if (n === index) {
          return {
            ...i,
            regenarate: value,
          };
        } else {
          return i;
        }
      })
    );
  };

  useEffect(() => {
    if (toggle) setSecondFlow(true);
  }, [toggle]);

  return (
    <div className="dropzone" id="dropzone-element">
      {!secondFlow ? (
        <>
          {uploadFile ? (
            <div className="flex-description">
              <p className="description" data-testid="description">
                {fileName}
              </p>
            </div>
          ) : (
            <Dropzone onDrop={!loading ? onDrop : null} multiple={false} maxSize={157286400} >
              {() => (
                <div {...getRootProps()}>
                  <input
                    {...getInputProps()}
                    className="inputs-upload"
                    data-testId="input-file"
                  />


                  <div className="flex-description">
                    <p>Clique aqui ou arraste o seu arquivo .docx ou .doc</p>
                  </div>

                </div>
              )}
            </Dropzone>
          )}

          {!loading ? (
            <div className="input-file" onClick={() => callAPI()}>
              <p className="input-file-text">Gerar propostas</p>
            </div>
          ) : (
            <>
              <p className="text-pattern">Gerando propostas, Aguarde!</p> <LoadSpinner />
            </>
          )}
        </>
      ) : (
        <div className="chatLogWrapperUpload">
          {messages?.length > 0 &&
            messages.map((chat, idx) => (
              <ProconMessages
                chatLogRef={chatLogRef}
                idx={idx}
                chat={chat}
                err={err}
                toggle={toggle}
              />
            ))}
          <AccordionProposals
            toggle={toggle}
            callTreeFlow={callTreeFlow}
            callSecondFlow={callSecondFlow}
            flowId={flowId}
            subscriptionId={subscriptionId}
            guid={guid}
            messages={messages}
            onSelectRegenerate={onSelectRegenerate}
            onChangeRegenerateMsg={onChangeRegenerateMsg}
            setSecondFlow={setSecondFlow}
          />
        </div>
      )}
    </div>
  );
};

export default UploadFile;
