import * as React from "react";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import "./DialogComponent.css";

import iconEye from "../../../../../assets/svg/icon-eye.svg";
import { Image } from "application/styled-components";

//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import PrecificadorService from "application/services/PrecificadorService";
import { useSelector, useDispatch } from "react-redux";
import { setSubscription } from "providers/reducers/user";
import { useHistory } from "react-router-dom";
import { Loader } from "../../components";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const DialogComponent = (props) => {
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  const [quantidade_lojas, setQuantidadeLojas] = useState("");
  const [valor_vivo, setValorVivo] = useState("");

  // var idProduto =  props.id_produto;

  // const [ idProduto, setIdProduto] = React.useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#660099",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const nomeProduto = () => {
    return props.produto;
  };

  const precoProduto = () => {
    return props.preco;
  };

  const lojaProduto = () => {
    return props.loja;
  };

  const dataHora = () => {
    if (props.horaData) {
      var horaData = new Date(props.horaData);
      var newHoraData = format(horaData, "dd/MMMM/yyyy", { locale: ptBR });
      return newHoraData;
    }
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const regex = /[?&]subscription_id=([^&#]*)/;
  const match = regex.exec(history.location.search);
  const subscription_id = match && match[1];
  dispatch(setSubscription(subscription_id));

  const userDataState = useSelector((state) => state.user);
  const { subscriptionName } = userDataState.subscriptions.find(
    (subs) => subs.subscriptionId === subscription_id
  );



  const fetchProdutosFromAPI = async () => {
    try {
      //setTableLoading(true);
      const produtos = await PrecificadorService.getProdutosByRanking(
        userDataState,
        subscriptionName,
        props.id_produto
      );

      setQuantidadeLojas(produtos.precos.quantidade_lojas);
      setValorVivo(produtos.precos.valor_vivo);

      const tableData = produtos.precos.precos.map((item) => ({
        ranking: item.ranking,
        seller: item.nome_loja,
        preco: item.preco_avista,
        precoParcelado: item.valor_recente_preco_parcelado_display
      }));
      setRows(tableData);
    } catch (error) {
      console.error("Erro ao buscar dados da API Ranking:", error);
    } finally {
      setTableLoading(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    setTableLoading(true); // Ativa o carregamento enquanto os dados estão sendo buscados
    fetchProdutosFromAPI(); // Chamada para buscar os dados quando o diálogo é aberto
  };

  const handleClose = () => {
    setOpen(false);
  };



  return (
    <React.Fragment>
      <Image
        width={"20px"}
        height={"auto"}
        objectFit={"fill"}
        src={iconEye}
        onClick={handleClickOpen}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {nomeProduto()}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="left-title">
            <p>
              <b>Quantidade de lojas:</b> {quantidade_lojas}
            </p>
            <p>
              <b>Data:</b> {dataHora()}
            </p>
          </div>

          <div className="right-title">
            <span><b>Preço Vivo:</b> {precoProduto()}</span>
          </div>

          {tableLoading ? (
            <div className="floatLoaderRanking">
              <Loader />
            </div>
          ) : (
            <div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 684 }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Ranking</StyledTableCell>
                      <StyledTableCell align="center">Loja / Seller </StyledTableCell>
                      <StyledTableCell align="center">Menor Preço à vista</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow>
                        <TableCell align="center">{row.ranking}º</TableCell>
                        <TableCell align="center">{row.seller}</TableCell>
                        <TableCell align="center">{row.preco}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
};
