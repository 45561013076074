import { IconCloseRegular, IconLikeFilled, IconThumbDownFilled } from "@telefonica/mistica";
import { ButtonsWrap, ModalHeader, ModalOverLay, ModalWrap, TextInput, Wrapper } from "application/styled-components/Perfil";
import { Button, Paragraph } from "application/styled-components/chatGPT";


export default function FeedBackModal({ closeModal, isLike, change, submit }) {


    return (
        <>
            <ModalOverLay>
                {isLike
                    ?
                    <ModalWrap background={'#D9D9D9'} width={'53.125rem'} radius={'20px'}>
                        <ModalHeader>
                            <Wrapper fill={'#81a526'}>
                                {/* <Image src={''} /> */}
                                <IconLikeFilled />
                                <Paragraph margin={0}>Feedback Positivo</Paragraph>
                            </Wrapper>
                            <Button onClick={closeModal}><IconCloseRegular /></Button>
                        </ModalHeader>
                        <TextInput type='text' placeholder={'O que você gosta na resposta?'} onChange={change} />
                        <ButtonsWrap width={'95%'}>
                            <Button onClick={submit}>Enviar</Button>
                        </ButtonsWrap>
                    </ModalWrap>
                    :
                    <ModalWrap background={'#D9D9D9'} width={'53.125rem'} radius={'20px'}>
                        <ModalHeader>
                            <Wrapper fill={'#a52626'}>
                                {/*<Image src={''} /> */}
                                <IconThumbDownFilled />
                                <Paragraph margin={0}>Feedback Negativo</Paragraph>
                            </Wrapper>

                            <Button onClick={closeModal}><IconCloseRegular /></Button>
                        </ModalHeader>
                        <TextInput type='text' placeholder={'O que você não gosta na resposta?'} onChange={change} />
                        <ButtonsWrap width={'95%'}>
                            <Button onClick={submit}>Enviar</Button>
                        </ButtonsWrap>
                    </ModalWrap>
                }
            </ModalOverLay>
        </>
    )
}