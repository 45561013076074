import styled from 'styled-components';

export const BackgroundContainer = styled.div`
  background-color: #FFFFFF;
  width: 100%;
  height: max-content;
  min-height: 100vh;
  /* margin-left: 30px; */
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  min-height: 100vh;
  width: 100%;
`;

export const ResponsiveContainer = styled.div`
  display: block;
  transition: all 0.5s;
  margin-top: 51px;
  margin-left: ${(props) => {
    return props.active ? '220' : '50';
  }}px;
  width: calc(
    100% -
      ${(props) => {
        return props.active ? '220' : '50';
      }}px
  );
`;

export const ShortcutContainer = styled.div`
  background-color: white;
  box-shadow: 0 0 4px 0 #ccc;
  margin: ${(props) =>
    ' ' +
    props.margin.top +
    'px ' +
    props.margin.right +
    'px ' +
    props.margin.bottom +
    'px ' +
    props.margin.left +
    'px'};
  padding: 8px 12px;
  position: relative;
  width: calc(${(props) => props.width}% - 20px);
`;

export const ShortcutTitle = styled.h4`
  margin: 10px;
  display: inline-block;
`;

export const ShortcutRedirect = styled.a`
  display: inline-block;
  padding: 3px;
  background-color: white;
  box-shadow: 0 0 4px 0 #ccc;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  position: absolute;
  top: 16px;
  cursor: pointer;
`;

export const Title = styled.h1`
  font-size: 36px;
  font-weight: 300;
  margin: 25px 40px 0px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TableHeader = styled.thead``;

export const TableBodies = styled.tbody``;

export const CircleProgress = styled.div`
  display: inline-block;
  height: 50px;
  width: 50px;
`;
export const Loader = styled.div`
  position: relative;
  width: 56px;
  height: 56px;
  float: left;
  user-select: none;
  box-sizing: border-box;
`;
export const LoaderBg = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid #f0f0f0;
  box-sizing: border-box;
`;

export const Text = styled.div`
  text-align: center;
  padding-top: 32%;
  font-size: 12px;
`;

export const SpinnerOne = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 50%;
  height: 50%;
  background: 0 0;
  box-sizing: border-box;
  transform: rotate(${(props) => props.rotate}deg);
  transform-origin: 100% 100%;
`;

export const SpinnerTwo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: 0 0;
  box-sizing: border-box;
  transform-origin: 100% 100%;
  transform: rotate(${(props) => props.rotate}deg);
`;
export const LoaderSpinner = styled.div`
  width: 200%;
  height: 200%;
  border-radius: 50%;
  border: 6px solid ${(props) => props.color};
  box-sizing: border-box;
`;
export const LogoHeaderContainer = styled.div`
  height: 64px;
  position: relative;
`;

export const LogoHeaderImage = styled.img`
  height: 31px;
  position: absolute;
  top: 20px;
  padding-left: 5px;
  cursor: pointer;
`;
export const ContentContainer = styled.div`
  position: ${(props) => props.position};
  width: calc(100% - ${(props) => (props.active ? '220px' : '50px')});
  height: ${(props) => props.height};
  margin: 51px 0 0 ${(props) => (props.active ? '220px' : '50px')};
  /* margin-left: 20px */
  transition: all 0.5s;
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justifyContent};
  flex: ${(props) => props.flex};

  @media (max-width: 767px) {
    width: ${(props) => props.mobileWidth};
    margin: ${(props) => props.mobileMargin};
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 40px;
  justify-content: center;
`;
export const ActiveButton = styled.button`
  cursor: pointer;
  color: #660099;
  background-color: #ffffff;
  border-radius: 8px;
  position: relative;
  border: ${(props) => props.border || '2px'} solid #660099;
  padding: ${(props) => props.padding || '8px 0px'};
  margin: ${(props) => props.margin || '0px'};
  width: ${(props) => props.width || '100'}%;
  height: ${(props) => props.height || '36px'};
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.95px;
  transition: 0.5s;
  text-transform: uppercase;
  @media (max-width: 600px) {
    width: auto;
    padding: 0px 12px;
  }
  :hover {
    color: #ffffff;
    background-color: #660099;
    ${(props) => {
      if (props.tooltip) {
        return `
                    &::after {
                        display:block;
                    }
                    &::before {
                        display:block;
                    }
                `;
      }
    }}
  }
  ${(props) => {
    if (props.tooltip) {
      return `
                &::after {
                    display:none;
                    content: '${props.tooltip}';
                    background: #000;
                    color: white;
                    font-size: 12px;
                    position:absolute;
                    padding: 2px 6px;
                    top: -65px;
                    left: -50px;
                    width: 250px;
                    border-radius: 5px;
                }
                &::before {
                    display:none;
                    content: "";
                    position: absolute;
                    top: -7px;
                    left: 50%;
                    margin-left: 0px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: #000 transparent transparent transparent;
                }
            `;
    }
  }}
`;
export const InactiveButton = styled.button`
  cursor: pointer;
  border: ${(props) => props.border || '2px'} solid #676767;
  padding: ${(props) => props.padding || '8px 0px'};
  margin: ${(props) => props.margin || '0px'};
  width: ${(props) => props.width || '100'}%;
  height: ${(props) => props.height || '36px'};
  color: #676767;
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.95px;
  transition: 0.5s;
  text-transform: uppercase;
  :hover {
    color: #ffffff;
    background-color: #676767;
  }
`;
export const LinkActiveButton = styled.button`
  color: ${(props) => (props.disable ? '#ccc' : '#660099')};
  position: relative;
  background-size: 30px;
  height: 30px;
  margin-bottom: ${(props) => props.marginBottom || '16px'};
  font-size: ${(props) => props.fontSize || '14px'};
  padding-top: 5px;
  border: none;
  background: transparent;
  ${(props) => props.disable && 'pointer-events: none;'}
  cursor:  ${(props) => (props.disable ? 'inherit' : 'pointer')};
  ${(props) =>
    props.disable
      ? ''
      : `
        :hover {
            color: #0a6480; 
        }`}
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom :'15px')};
  position: relative;
  padding-right: ${(props) => props.paddingSide || '15px'};
  padding-left: ${(props) => props.paddingSide || '15px'};
  margin-left: ${(props) => props.marginLeft || '0'};
  width: ${(props) => props.width || '100%'};

  @media (max-width: 767px) {
    padding-left: ${(props) => props.mobilePaddingLeft};
    padding-right: ${(props) => props.mobilePaddingRight};
  }
`;
export const Label = styled.label`
  font-weight: 400;
  color: #4a4a4a;
  margin-bottom: 5px;
  display: ${(props) => (props.displayLabel ? props.displayLabel : 'initial')};
`;
export const Input = styled.input`
  height: 36px;
  border-radius: 4px;
  border: ${(props) => (props.border ? props.border : '1px solid #ccc')};
  padding: 0 12px 0 12px;
  outline: 0;
  font-size: 16px;
`;
export const Select = styled.select`
  height: 36px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0 12px 0 12px;
  outline: 0;
  font-size: 16px;
  ${(props) =>
    props.error ? `box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);` : ''}
`;

export const Option = styled.option``;
export const TextArea = styled.textarea`
  height: 80px;
  border-radius: 3px;
  border: 1.5px solid #ccc;
  padding: 8px 14px;
  outline: 0;
  background-color: #fff;
  resize: none;
  overflow: hidden;
`;
export const TextAreaCounter = styled.span`
  font-size: 10px;
`;
export const InputCounter = styled.span`
  height: 36px;
  position: absolute;
  margin: 0;
  padding: 6px;
  bottom: 0px;
  right: 16px;
  font-size: 16px;
`;
export const MultiSelectListContainer = styled.div`
  position: absolute;
  background-color: white;
  width: 250px;
  top: 75px;
  z-index: 2;
  display: ${(props) => (props.opened ? 'block' : 'none')};
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
`;

export const MultiSelectListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 16px;
  color: #4a4a4a;
`;

export const MultiSelectSearchContainer = styled.div`
  margin: 0px;
  padding: 10px;
  padding-bottom: 0px;
  position: relative;
`;
export const ClearListButton = styled.button`
  background-color: white;
  border: none;
  color: #660099;
  cursor: pointer;
  padding: 8px;
  position: relative;
  text-align: right;
`;
export const CheckBoxInputAll = styled.span`
  width: 16px;
  min-width: 16px;
  height: 16px;
  border: 1px solid ${(props) => (props.checked ? '#660099' : '#ccc')};
  ${(props) => (props.checked ? 'background-color: #660099;' : '')}
  margin: 0;
  margin-right: 5px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
  ::after {
    content: ' ';
    display: ${(props) => (props.checked ? 'block' : 'none')};
    position: absolute;
    left: 4px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: 1px solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(40deg);
    transition: opacity 0.3s ease-in;
  }
`;
export const MultiSelectList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  max-height: 200px;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 15px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
  }
  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 5px;
    box-shadow: inset 7px 10px 12px #f0f0f0;
  }
`;
export const InputSearchMultiSelect = styled.input`
  height: 36px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0px 12px;
  outline: none;
  width: 100%;
`;
export const SelectListAllContainer = styled.div`
  padding: 8px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const MultiSelectListItem = styled.li`
  margin-bottom: 0;
  margin-right: 0px;
  padding: 8px 12px;
  background-color: white;
  color: #4a4a4a;
  border-radius: 2px;
  cursor: pointer;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  :hover {
    color: #660099;
    background-color: #f2f2f2;
  }
`;

export const ListOptionContainer = styled.div`
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 70px;
  left: 15px;
  right: 0;
  max-height: 300px;
  overflow: auto;
  display: ${(props) => (props.open ? 'block' : 'none')};
  width: calc(100% - 30px);
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: #7d7d7d;
    border: 0 none #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #696969;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #878787;
  }
  ::-webkit-scrollbar-track {
    background: #fcfcfc;
    border: 0 none #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #dbdbdb;
  }
  ::-webkit-scrollbar-track:active {
    background: #e6e6e6;
  }
  ::-webkit-scrollbar-corner {
    background: 0 0;
  }
`;

export const ListOptionItem = styled.div`
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
  :hover {
    background-color: #e9e9e9;
    color: #660099;
  }
`;

export const TableResponsive = styled.div`
  min-height: 0.01%;
  overflow-x: auto;
`;
export const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid #ccc;
  margin-bottom: 0px;
  max-width: 100%;
  width: 100%;
`;

export const TableRow = styled.tr`
  box-sizing: border-box;
  &:hover {
    button {
      overflow: auto;
      opacity: 1;
      cursor: pointer;
    }
  }
  &:hover > td {
    border-bottom: 2px solid #00edff;
    border-top: 2px solid #00edff;
  }
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;
export const TableHeaderItem = styled.th`
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 8px;
  padding-left: 10px;
  text-align: ${(props) => props.align || 'left'};
  vertical-align: bottom;
  word-break: break-word;
  width: ${(props) => props.fieldWidth || '100%'}!important;
  min-width: ${(props) => props.minWidth || '100px'};
  max-width: ${(props) => props.maxWidth || '150px'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const TableBodiesItem = styled.td`
  border-bottom: 1px solid #dedede;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 15px;
  vertical-align: top;
  text-align: ${(props) => props.align || 'left'};
  word-break: break-word;
  width: ${(props) => props.fieldWidth || '100%'}!important;
  min-width: ${(props) => props.minWidth || '100px'};
  max-width: ${(props) => props.maxWidth || '150px'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const TableContainer = styled.div`
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  background: #fff;
  margin: 8px 40px;
`;

export const TopFixedButtons = styled.div`
  top: ${(props) => props.top || '0px'};
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 1;
  background-color: #fff;
  padding: 10px 20px;
  ${(props) => {
    if (props.align === 'between') {
      return `
                justify-content: space-between;
                text-align: right;`;
    } else if (props.align === 'end') {
      return `
                justify-content: flex-end;
                text-align: right;`;
    } else {
      return `
                justify-content: left;
            `;
    }
  }}
`;

export const TopFixedHeader = styled.div`
  top: ${(props) => props.top || '0px'};
  position: sticky;
  z-index: 1;
  background-color: #fff;
  padding: 0 20px;
`;

export const TopBody = styled.div`
  padding: 0 20px 20px;
  background-color: #fff;
`;

export const ErrorContainer = styled.div`
  padding: 12px 8px;
  background-color: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  margin: 8px 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ErrorTitle = styled.h1`
  font-size: 60px;
  color: #606060;
  font-weight: 400;
`;

export const ErrorSubtitle = styled.p`
  font-size: 18px;
  color: #606060;
  text-align: center;
`;

export const CheckBoxInputTable = styled.span`
  width: 16px;
  min-width: 16px;
  height: 16px;
  border: 1px solid ${(props) => (props.checked ? '#660099' : '#ccc')};
  ${(props) => (props.checked ? 'background-color: #660099;' : '')}
  margin: 0;
  margin-right: 5px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  ::after {
    content: ' ';
    display: ${(props) => (props.checked ? 'block' : 'none')};
    position: absolute;
    left: 4px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: 1px solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(40deg);
    transition: opacity 0.3s ease-in;
  }
`;
export const CheckBoxInputAllTable = styled.span`
  min-width: 18px;
  height: 18px;
  border: 2px solid ${(props) => (props.checked ? '#660099' : '#000')};
  ${(props) => (props.checked ? 'background-color: #660099;' : '')}
  margin: 0;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
  vertical-align: sub;
  ::after {
    content: ' ';
    display: ${(props) => (props.checked ? 'block' : 'none')};
    position: absolute;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: 1px solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(40deg);
    transition: opacity 0.3s ease-in;
  }
`;
