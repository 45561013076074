import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../../components/Layout";
import { useHistory } from "react-router-dom";
import { setSidebarOpen } from "providers/reducers/header";

export const NotFoundPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const expanded = useSelector((state) => state.header.sidebarOpen);
  const { user_name } = useSelector((state) => state.user);
  const sidebar = useSelector((state) => state.sidebar);

  function handleSidebar(state) {
    dispatch(setSidebarOpen(state));
  }

  useEffect(() => {
    history.push("/");
  }, []);

  return (
    <>
      <Layout
        expanded={expanded}
        setExpanded={handleSidebar}
        username={user_name}
        sidebarOptions={
          sidebar.pages.length !== 0 ? JSON.parse(sidebar.pages) : []
        }
      ></Layout>
    </>
  );
};
