// import ServiceHelperLogin from ../helpers/ServiceHelperLogin;
import axios from 'axios'
import { User } from './interfaces/User'
import { HandlerError } from './HandlerError'


class AuthService {
  private static readonly API_URL = process.env.REACT_APP_API_URL_SERVICE_LOGIN
  private static readonly SERVICE = 'ADMIN'

  private static getConfig = ({
    user_id,
    session_id,
    token,
    user_email,
    user_name,
    subscriptionSelected,
  }: User) => {
    return {
      headers: {
        session_id: session_id,
        user_id: user_id,
        token: token,
        service: this.SERVICE,
        subscription_id: subscriptionSelected,
      },
    }
  }

  static async login(token: string) {
    const response = await axios.post(`${this.API_URL}/auth`, {
      token,
    })
    return response.data
  }

  static async createProfile(
    userData: User,
    name: string,
    permissions: Array<string>,
  ) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.post(
        `${this.API_URL}/auth/tenant`,
        {
          name,
          permissions,
        },
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async updateProfile(
    userData: User,
    tenantId: string,
    permissions: Array<string>,
  ) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.put(
        `${this.API_URL}/auth/tenant`,
        {
          tenantId,
          permissions,
        },
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async getAllUsers(userData: User) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.get(`${this.API_URL}/auth/users`, CONFIG)
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async getOneUserInfo(userData: User, userId: string) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.get(
        `${this.API_URL}/auth/user/${userId}`,
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async createPermission(
    userData: User,
    name: string,
    description: string,
  ) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.post(
        `${this.API_URL}/auth/permission`,
        {
          name,
          description,
        },
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async createSubscription(
    userData: User,
    name: string,
    description: string,
    // project: string,
    // bu: string,
    url: string,
    permission: string,
  ) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.post(
        `${this.API_URL}/auth/subscription`,
        {
          name,
          description,
          // project,
          // bu,
          url,
          permission
        },
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async getAllPermissions(userData: User) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.get(
        `${this.API_URL}/auth/permission`,
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async validatePermission(userData: User) {
    const CONFIG = this.getConfig(userData)
    try {
      const response = await axios.get(`${this.API_URL}/auth`, CONFIG)
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async getTenantSubscriptions(userData: User, tenantId: string) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.get(
        `${this.API_URL}/auth/subscription/tenant/${tenantId}`,
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async getUsersFromSubscription(
    userData: User,
    subscriptionId: string,
  ) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.get(
        `${this.API_URL}/auth/subscription/${subscriptionId}/users`,
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async getAllsubscriptions(userData: User) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.get(
        `${this.API_URL}/auth/subscription`,
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async getAllTenants(userData: User) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.get(`${this.API_URL}/auth/tenant`, CONFIG)
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async giveSubscriptionToAnUser(
    userData: User,
    tenantId: string,
    subscriptionId: string,
  ) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.post(
        `${this.API_URL}/auth/subscription/tenant`,
        {
          tenantId,
          subscriptionId,
        },
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async givePermissionToASubscription(
    userData: User,
    permissionId: string,
    subscriptionId: string,
  ) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.post(
        `${this.API_URL}/auth/subscription/permission`,
        {
          permissionId,
          subscriptionId,
        },
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async removePermissionFromASubscription(
    userData: User,
    permissionId: string,
    subscriptionId: string,
  ) {
    try {
      // const CONFIG = this.getConfig(userData)
      const response = await axios.delete(
        `${this.API_URL}/auth/subscription/permission`,
        {
          headers: {
            session_id: userData.session_id,
            user_id: userData.user_id,
            token: userData.token,
            service: this.SERVICE,
            subscription_id: userData.subscriptionSelected,
          },
          data: {
            permissionId,
            subscriptionId,
          },
        },
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async getAuthOIMUrl() {
    try {
      const response = await axios.get<string>(`${this.API_URL}/auth/url`)
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async removeUserFromASubscription(
    userData: User,
    tenantId: string,
    subscriptionId: string,
  ) {
    try {
      // const CONFIG = this.getConfig(userData)
      const response = await axios.delete(
        `${this.API_URL}/auth/subscription/tenant`,
        {
          headers: {
            session_id: userData.session_id,
            user_id: userData.user_id,
            token: userData.token,
            service: this.SERVICE,
            subscription_id: userData.subscriptionSelected,
          },
          data: {
            tenantId,
            subscriptionId,
          },
        },
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async createUserLogin(
    userData: User,
  ) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.post(
        `${this.API_URL}/auth/loginHistory/login`,
        {
          user_id: userData.user_id,
          user_name: userData.user_name
        },
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async createLoginHistory(
    userData: User,
  ) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.post(
        `${this.API_URL}/auth/loginHistory`,
        {

          user_id: userData.user_id || "Não encontrado",
          user_name: userData.user_name || "Não encontrado",
          user_email: userData.user_email || "Não encontrado",
          user_profile: userData.user_profile || "Não encontrado",
          oam_matricula: userData.user_id || "Não encontrado",
          oam_gestor: userData.oam_gestor || "Não encontrado",
          oam_ccusto: userData.oam_ccusto || "Não encontrado",
          session_id: userData.session_id || "Não encontrado",
        },
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async getAllLogins(userData: User) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.get(
        `${this.API_URL}/auth/loginHistory/login`,
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async getLoginHistory(userData: User, userId: string | null, startDate: Date, endDate: Date) {

    const params = {
      user_id: userId,
      start_date: startDate,
      end_date: endDate,
    };

    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.get(
        `${this.API_URL}/auth/loginHistory`,
        { params, ...CONFIG },
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }

  static async deletePermission(
    userData: User,
    permissionId: string
  ) {
    try {
      // const CONFIG = this.getConfig(userData)
      const response = await axios.delete(
        `${this.API_URL}/auth/permission/${permissionId}`,
        {
          headers: {
            session_id: userData.session_id,
            user_id: userData.user_id,
            token: userData.token,
            service: this.SERVICE,
            subscription_id: userData.subscriptionSelected,
          }
        },
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }
}



export default AuthService;