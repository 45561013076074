import React, { createContext } from "react";

import useModal from "./useModal";
import ModalFragment from "./ModalFragment";

let ModalContext;

let { Provider } = (ModalContext = createContext());

let ModalProvider = ({ children }) => {
    let { modal, handleModal, modalContent } = useModal();
    return (
        <Provider value={{ modal, handleModal, modalContent }}>
            <ModalFragment />
            { children }
        </Provider>
    )
};

export { ModalContext, ModalProvider };