import styled from 'styled-components';

export const CustomSelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  height: 40px;
  width: 175px;
  margin-top: 15px;
`;

export const SelectBox = styled.div`
  border: 1.5px solid #609;
  background: #FFF;
  padding: 5px;
  border-radius: 5px;
  font-size: 16px;
  color: #86888C;
  font-weight: 600;
  height: 28px;
  font-family: Telefonica !important;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
 

`;

export const OptionsList = styled.ul`
  display: ${props => props.isOpen ? 'block' : 'none'};
 position: absolute;
  border-radius: 4px;
background: #FFF;
box-shadow: 0px -6px 2px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
border-top: none;
list-style: none;
width: 100%;
height: 130px;
padding: 0;
margin: 0;
z-index: 1;

`;

export const OptionsListSubMenu = styled.ul`
  display: ${props => props.isOpen ? 'block' : 'none'};
  position: absolute;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px -6px 2px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-top: none;
  list-style: none;
  width: 100%;
  height: 130px;
  padding: 0;
  margin: 0;
  z-index: 1;
  margin-left: 179px;
`;

export const ScrollList = styled.div`
overflow-y: scroll;
height: 115px;
margin:3% 4% 0 0 ;
&::-webkit-scrollbar {
  width:7px;
  background:#F6ECF6;


}
&::-webkit-scrollbar-thumb{
  background: #d9d9d9;
  border-radius: 20px;
  
}
&::-webkit-scrollbar-track{
  background: #86888C;
  border-radius: 20px;

}
`;
export const ArrowIcon = styled.img`
width: 16px;
height: 10px;
  transition: transform 0.2s;
  transform: ${props => props.isOpen ? 'rotate(180deg)' : 'rotate(0)'};
  `

export const LabelTextWhenSelected = styled.span`
  font-size: 12px;
`
export const Option = styled.li`
  padding: 5px;
  cursor: pointer;
 
  font-weight: ${(props) => (props.isTitle ? "bold" : "normal")};
  color: ${(props) => (props.isTitle ? "#000000" : " #86888C")};
 
  &:hover {
    background: ${(props) => (props.isTitle ? "#FFF" : "#F6ECF6")};
    color: ${(props) => (props.isTitle ? "#000000" : "#609")};
    font-weight: bold;
  }
 
  &.selected {
    background: #f6ecf6;
    color: #609;
  }
`;