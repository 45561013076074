import axios from "axios";
import { User } from "./interfaces/User";
import { HandlerError } from "./HandlerError";
import { Ask } from "./interfaces/Ask";

class ContentGeneratorService {
  private static readonly API_URL = process.env.REACT_APP_API_URL_SERVICE_CONTENT_GENERATOR;
  private static readonly API_URL_VALIDATION = process.env.REACT_APP_API_URL_SERVICE_LOGIN
  private static readonly SERVICE = "API_CONTENT_GENERATOR";
  private static getConfig = ({
    user_id,
    session_id,
    token,
    user_email,
    user_name,
    user_profile,
    oam_matricula,
    oam_gestor,
    oam_ccusto,
    subscriptionSelected,
  }: User) => {
    return {
      headers: {
        sessionid: session_id,
        session_id,
        userid: user_id,
        user_id,
        token: token,
        service: this.SERVICE,
        userprofile: user_profile,
        username: user_name,
        useremail: user_email,
        oammatricula: oam_matricula,
        oamgestor: oam_gestor,
        oamccusto: oam_ccusto,
        subscriptionid: subscriptionSelected,
        subscription_id: subscriptionSelected,
        'Content-Type': 'application/json',
      },
    }
  };

  static async ask(userData: User, subscriptionName: string, talkId: string, askData: Ask) {
    try {
      const CONFIG = this.getConfig(userData);
      const headers = {
        ...CONFIG.headers,
        subscriptionname: subscriptionName,
      }
      const response = await axios.post(
        `${this.API_URL}/talks/${talkId}/generate/`,
        askData,
        { headers }
      );
      return response.data;
    } catch (error: any) {
      return HandlerError(error.response);
    }
  }

  static async createTalk(userData: User, subscriptionName: string, userId: String, selectedTones: any) {
    try {
      const CONFIG = this.getConfig(userData);
      const headers = {
        ...CONFIG.headers,
        subscriptionname: subscriptionName,
      }
      const response = await axios.post(
        `${this.API_URL}/users/${userId}/talks`,
        {
          personalidade: selectedTones.Personalidade,
          tom: selectedTones.Tom,
          linguagem: selectedTones.Linguagem,
          estilo: selectedTones.Estilo,
          proposito: selectedTones.Proposito,
          tipo_conteudo: selectedTones.Tipodeconteudo,
        },
        { headers },
      )
      return response.data;
    } catch (error: any) {
      return HandlerError(error.response);
    }
  }

  static async uploadFile(userData: User, subscriptionName: string, form: any, talkId: string) {
    try {
      const CONFIG = this.getConfig(userData);
      const response = await axios.post(
        `${this.API_URL}/talks/${talkId}/upload`,
        form,
        {
          headers: {
            ...CONFIG.headers,
            subscriptionname: subscriptionName,
            "content-type": "multipart/form-data;",
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return HandlerError(error.response);
    }
  }

  static async loadAllChats(userData: User, subscriptionName: string, userId: string) {
    try {
      const CONFIG = this.getConfig(userData);
      const headers = {
        ...CONFIG.headers,
        subscriptionname: subscriptionName,
      }
      const response = await axios.get(
        `${this.API_URL}/users/${userId}/talks`,
        {headers}
      );
      return response.data;
    } catch (error: any) {
      return HandlerError(error.response);
    }
  }
  static async loadOneChat(userData: User, subscriptionName: string, talkIdHistory: string) {
    try {
      const CONFIG = this.getConfig(userData);
      const headers = {
        ...CONFIG.headers,
        subscriptionname: subscriptionName,
      }
      const response = await axios.get(
        `${this.API_URL}/talks/${talkIdHistory}`,
        {headers}
      );
      return response.data;
    } catch (error: any) {
      return HandlerError(error.response);
    }
  }
  static async clearChatHistory(userData: User, subscriptionName: string, talkId: string) {
    try {
      const CONFIG = this.getConfig(userData);
      const headers = {
        ...CONFIG.headers,
        subscriptionname: subscriptionName,
      }
      const response = await axios.delete(
        `${this.API_URL}/talks/${talkId}/`,
        {headers}
      );
      return response.data;
    } catch (error: any) {
      return HandlerError(error.response);
    }
  }
  static async updateMessageFeedback(userData: User, subscriptionName: string, feedback: string, correlator: String, talkId: string) {
    try {
      const CONFIG = this.getConfig(userData);
      const headers = {
        ...CONFIG.headers,
        subscriptionname: subscriptionName,
      }
      const response = await axios.patch(
        `${this.API_URL}/talks/${talkId}/${correlator}`,
        {feedback},
        { headers }
      );
      return response.data;
    } catch (error: any) {
      return HandlerError(error.response);
    }
  }
  static async validatePermission(userData: User) {
    const CONFIG = this.getConfig(userData)
    try {
      const response = await axios.get(
        `${this.API_URL_VALIDATION}/auth`,
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }
}

export default ContentGeneratorService;