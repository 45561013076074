import { IconTrashCanRegular } from "@telefonica/mistica"
import React, { useEffect, useState } from "react"

export const FileItem = ({ name, existing, onEdit, onRemove, id }) => {
    const [isEditing, setIsEditing] = useState(true)

    const [fileName, setFileName] = useState('')
    const [fileExtension, setFileExtension] = useState(name)

    useEffect(() => {
        const regex = /^(.+)(\..+)$/gm.exec(name)

        if (regex) {
            setFileName(regex[1])
            setFileExtension(regex[2])
        }
    }, [name])

    const updateName = (e: React.ChangeEvent<HTMLSpanElement & ElementContentEditable>) => {
        const newName = (e.target.innerText || '').substring(0, 200)
        const duplicate = (existing as Array<string>)?.includes(newName + fileExtension) ?? false

        if (!newName || newName === fileName || duplicate) {
            if (fileName) {
                e.target.innerText = fileName
            }

            if (duplicate && newName !== fileName) {
                onEdit?.({ id, newName: name, oldName: name })
            }
            
            return
        }

        e.target.innerText = newName

        requestAnimationFrame(() => {
            onEdit?.({ id, newName: newName + fileExtension, oldName: name })
        })
    }

    const remove = () => {
        onRemove?.({ id, name })
    }

    return <li>
        <div className="file-info">
            <h3><span contentEditable={isEditing} onChange={updateName} onBlur={updateName} suppressContentEditableWarning={true}>{ fileName }</span>{ fileExtension }</h3>
        </div>
        <div className="file-remove">
            <button type="button" onClick={remove}><IconTrashCanRegular /></button>
        </div>
    </li>
}