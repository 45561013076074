// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCn2wU3Dj76MtGXaMSUXVDJXvQeUtr1t_c",
  authDomain: "teste-gpt-61971.firebaseapp.com",
  projectId: "teste-gpt-61971",
  storageBucket: "teste-gpt-61971.appspot.com",
  messagingSenderId: "695113061532",
  appId: "1:695113061532:web:3982aca8a15108f341c959"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const goggleAuthProvider = new GoogleAuthProvider();

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(firebaseApp);

export { auth, goggleAuthProvider, db };
