import React, { useState } from "react";
import PropTypes from "prop-types";

import { SidebarItemOptionContainer } from "./styles";
import "./Sidebar.css";
import { useHistory } from "react-router-dom";
import { getIconByTitle } from "./getIconByTitle";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Sidebar = function ({ active, sideMenuOptions }) {
  let [dropdown, setDropdown] = useState("Home");

  const openDropdown = (ev) => {
    setDropdown(ev.target.innerText);
  };

  const history = useHistory();
  const { subscriptions } = useSelector((state) => state.user);
  const sidebarClass = active ? "sidebar active" : "sidebar";

  const verifyActiveOption = (option) => {
    const lengthUrl = window.location.pathname.split("/");

    if (lengthUrl.length > 3) {
      lengthUrl.length = 3;
    }

    return option.items
      .map((item) => item.subscriptionRoute)
      .includes(lengthUrl.join("/"));
  };

  const factoryMenuContainer = (option, idx) => {
    const classDowndown = "sidebar-item";
    const currentURL = verifyActiveOption(option) ? " open current" : ""; // fechado
    // const currentURL = verifyActiveOption(option) ? " open current" : " open current"; // aberto
    return (
      <div className={classDowndown + currentURL} key={option._id}>
        <SidebarItemOptionContainer>
          <img
            className="img-gray"
            title={option.subscriptionName}
            data-url={option.subscriptionRoute}
            onClick={redirectTo}
            src={getIconByTitle(option.title, "gray")}
            alt=""
          />
          <img
            className="img-blue"
            title={option.subscriptionName}
            data-url={option.subscriptionRoute}
            onClick={redirectTo}
            src={getIconByTitle(option.title, "blue")}
            alt=""
          />
          {factoryMenuOptions(option, "side-span")}
          {factoryMenuOptions(option, "external-span")}
          {option.items.map((item, index) => {
            return (
              <div
                key={"sub-external-" + index}
                className="sub-external"
                id={"span-" + item.subscriptionName.replaceAll(" ", "")}
                style={{
                  marginTop: (option.items.length - index) * -43 + "px",
                }}
                data-url={item.subscriptionRoute}
                onClick={redirectTo}
              >
                {item.subscriptionName}
              </div>
            );
          })}
        </SidebarItemOptionContainer>
      </div>
    );
  };

  const factoryMenuOptions = (option, clas) => {
    return (
      <span className={clas} onClick={openDropdown}>
        <p
          data-url={option.subscriptionRoute}
          onClick={redirectTo}
          title={option.title}
          style={{ userSelect: "none" }}
        >
          {option.title}
        </p>
        <ul
          className={
            option.items.length === 0 ? "sub-side-list void" : "sub-side-list"
          }
        >
          {option.items.map((item, index) =>
            factorySubItems(item, index, clas)
          )}
        </ul>
      </span>
    );
  };

  const redirectTo = (ev) => {
    if (history.location.pathname === ev.target.dataset["url"]) {
      return window.location.reload(true);
    }

    if (ev.target.dataset["url"] === "/administration/users") {
      const subAdmin = subscriptions.find(
        (subs) => subs.subscriptionName === "ADMIN LAB IA"
      );

      const subscriptionId = subAdmin ? subAdmin.subscriptionId : null;

      if (!subscriptionId) {
        return toast.error(
          "Desculpe, você não tem acesso à esse caso de uso. Se você acredita que isso seja um erro, contate o administrador do sistema.",
          {
            autoClose: 1500,
          }
        );
      }
      return history.push(
        ev.target.dataset["url"] + `?subscription_id=${subscriptionId}`
      );
    }

    if (ev.target.dataset["url"] === "/dashboards") {
      return toast.info(
        "Ops, nossos dashboards ainda estão em fase de construção :)",
        {
          autoClose: 1500,
        }
      );
    }

    if (ev.target.dataset["url"] !== "/") {
      return history.push(
        ev.target.dataset["url"] + `?subscription_id=${ev.target.id}`
      );
    } else {
      return history.push(ev.target.dataset["url"]);
    }
  };

  const handleMouseEnter = (ev) => {
    const sidebarActive = document
      .getElementsByClassName("sidebar")[0]
      .classList.value.includes("active");
    if (!sidebarActive) {
      const id = ev.currentTarget.id;
      if (id && document.getElementById("span-" + id.split("-")[1]))
        document.getElementById("span-" + id.split("-")[1]).style.display =
          "block";
    }
  };
  const handleMouseLeave = (ev) => {
    const sidebarActive = document
      .getElementsByClassName("sidebar")[0]
      .classList.value.includes("active");
    if (!sidebarActive) {
      const id = ev.currentTarget.id;
      if (id && document.getElementById("span-" + id.split("-")[1]))
        document.getElementById("span-" + id.split("-")[1]).style.display =
          "none";
    }
  };

  const factorySubItems = (subItem, idx, clas) => {
    const regex = /[?&]subscription_id=([^&#]*)/;
    const match = regex.exec(history.location.search);
    const subscription_id = match && match[1];
    const isSubItemSelected = window.location.pathname.includes(
      subItem.subscriptionRoute
    )
      ? subscription_id === subItem.subscriptionId
        ? "open"
        : ""
      : ""; // fechado
    // const isSubItemSelected = window.location.pathname.includes(subItem.url) ? "open" : "open"; // aberto
    let configListitem = {};

    if (clas === "side-span") {
      configListitem = {
        onMouseEnter: (ev) => handleMouseEnter(ev),
        onMouseLeave: (ev) => handleMouseLeave(ev),
        id: "hover-" + subItem.subscriptionName.replaceAll(" ", ""),
      };
    }

    return (
      <li
        {...configListitem}
        onClick={redirectTo}
        key={subItem.subscriptionId}
        id={subItem.subscriptionId}
        data-url={subItem.subscriptionRoute}
        className={"side-sub-item " + isSubItemSelected}
      >
        {/* <img
          className="img-gray sub-item-img"
          data-url={subItem.url}
          onClick={redirectTo}
          src={getIconByTitle(subItem.title, 'gray')}
          alt=""
        />*/}
        <img
          className="img-blue sub-item-img"
          data-url={subItem.subscriptionRoute}
          // onClick={redirectTo}
          src={getIconByTitle(subItem.subscriptionName)}
          alt=""
        />
        <span
          className="sub-item-title"
          data-url={subItem.subscriptionRoute}
          id={subItem.subscriptionId}
          // onClick={redirectTo}
        >
          {subItem.subscriptionName}
        </span>
      </li>
    );
  };

  return (
    <aside className={sidebarClass}>
      {sideMenuOptions && sideMenuOptions.map(factoryMenuContainer)}
    </aside>
  );
};

Sidebar.propTypes = {
  active: PropTypes.bool,
  sideMenuOptions: PropTypes.arrayOf(PropTypes.object),
};

Sidebar.defaultProps = {
  active: false,
  sideMenuOptions: [],
};

export default Sidebar;
