import styled from 'styled-components';
import { IconSendFilled } from '@telefonica/mistica';
import { ContentContainer } from '.';



export const AnswerScreen = styled.div`
    overflow-y: auto;
    max-height: 44.525rem;
    width: 100%;
    transition: all 0.5s;
    padding-right: 1.25rem;
    height: 100%;

    @media (max-width: 767px) {
        padding-right: initial;
    }
    flex-direction: column-reverse;

`

export const IconSendVivo = styled(IconSendFilled)`
    path {
        fill: ${props => props.loading ? '#ccd6db' : '#660099'};
    }
`
export const Paragraph = styled.p`
    font-family: Telefonica;
    font-size: ${(props) => props.fontSize ? props.fontSize : '1rem'};
    white-space: pre-wrap;
    color: ${props => props.color};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    width: ${(props) => props.width};
    background: ${(props) => props.background};
`

export const ClientBoxAnswer = styled.div`
    box-sizing: border-box;
    position: relative;
    background: rgb(249,249,249);
    border-radius: 8px;
    box-shadow: 0 0 4px 2px rgba(0,0,0,0.1);
    margin: .5rem .25rem .625rem;
    width: fit-content;
    min-height: 2.8125rem;
    display: flex;
    align-items: center;
    padding: .9375rem;
    word-wrap: break-word;

    & ${Paragraph} {
        margin: 0;
    }
`

export const GptBoxAnswer = styled(ClientBoxAnswer)`
    background-color: rgb(102 102 102);
    float: right;
    color: #fff;
    justify-content: space-between;
    `
// & ${Paragraph} {
//     margin-top: -1.9375rem;
//     margin-bottom: 0.9375rem;
// }


export const AnswerClientAlign = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
`

export const AnswerGptAlign = styled(AnswerClientAlign)`
    justify-content: start;
`

export const Button = styled.a`
    outline: none;
    text-decoration: none;
    margin: 0;
    cursor: ${props => props.loading ? 'not-allowed' : 'pointer'};
    position: ${(props) => props.position};
    top: ${(props) => props.top};
    right: ${(props) => props.right};
    transform: ${(props) => props.transform};

    svg {
        path {
            fill: ${(props) => props.fill};
        }
    }
`

export const WrapperFlex = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    height: 87vh;
    
    @media (max-width: 767px) {
        padding: 0 .9375rem;
    }

    @media (min-width: 1140px) and (max-width: 1600px) {
        height: 82vh;
    }

    ${ContentContainer} {
        margin: 15px 0 0 ${props => props.active ? "220px" : "50px"};

        @media (max-width: 1400px) {
            width: ${(props) => (props.width1400 ? props.width1400 : `calc(80% - ${props.active ? '220px' : '50px'})`)};
        }
    }
`

export const ContainerChatGPT = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2.1875rem;
`
export const BoxChatGPT = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .9375rem 1.25rem;
    max-width: 14.6875rem;
    min-width: 14.6875rem;
    height: 4.75rem;
    margin: .625rem 0;
    border-radius: 8px;
    border: 2px solid rgb(221, 221, 221);
    color: rgb(102, 102, 102);
    background: #ffffff;

    ${props =>
        props.linkActive ? ` cursor: pointer; ` : ''
    }

    @media (max-width: 1400px) {
        margin: 0.225rem 0;
        padding: .6375rem 1.25rem;
    }
`

export const ColumnChatGPT = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: .875rem;
    align-items: center;

    p {
        color: #660099;
        font-size: 18px;
        font-weight: 700;

        @media (max-width: 1400px) {
            font-size: 14px;
        }
    }

    ${BoxChatGPT} {
        box-shadow: 0px 0px .5rem 0px #d5d5d5;
    }
`


export const LoadingAwnser = styled.div`
    position: relative;
    left: 40px;
    top: -10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;

    &::before, &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
    }

    &::before {
        left: -15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #9880ff;
        color: #9880ff;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 0s;
    }

    &::after {
        left: 15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #9880ff;
        color: #9880ff;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 1s;
    }

    @keyframes dot-flashing {
        0% {
            background-color: #9880ff;
        }
        50%, 100% {
            background-color: rgba(152, 128, 255, 0.2);
        }
    }
 
`


export const ColumnChatGPTInfo = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: .875rem;
    align-items: center;

    p {
        color: #660099;
        font-size: 18px;
        font-weight: 700;

        @media (max-width: 1400px) {
            font-size: 14px;
        }
    }

    ${BoxChatGPT} {
        background-color: rgb(235 235 235);
        border-color: rgb(235 235 235);
        /* max-width: 193px; */
    }
`

export const FeedBackWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: .3125rem;
    margin-left: 1.25rem;
    height: 100%;
    width: ${(props) => props.width};

    svg {
        cursor: pointer;
    }

    .active {
        path {
            cursor: pointer;
            fill: #fff;
        }
    }
`;

export const FeedBackWrapDark = styled.div`
    display: flex;
    flex-direction: row;
    gap: .3125rem;
    margin-left: 1.25rem;
    justify-content: ${(props) => props.justifyContent};

    path {
        cursor: pointer;
        fill: #666;
    }

    .active {
        path {
            cursor: pointer;
            fill: #000;
        }
    }
`;