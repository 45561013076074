
import React, { useState } from "react";
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import useTable from "./hooks/useTable";
import { FormContainer } from './styles'
import TableFooter from "./AcessReportTablePaginationFooter";


const AcessReportTablePagination = ({ data, rowsPerPage }) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);
  return (
    <FormContainer>

      <table>
        <thead>
          <tr>
                <th>user_id</th>
                <th>user_name</th>
                <th>user_email</th>
                <th>user_profile</th>
                {/* <th>oam_matricula</th> */}
                {/* <th>oam_gestor</th> */}
                <th>oam_ccusto</th>
                <th>session_id</th>
                <th>created_at</th>
            </tr>
        </thead>
        <tbody>
          {slice.map((el) => (
            <tr key={el._id}>
              <td>{el.user_id}</td>
              <td>{el.user_name}</td>
              <td>{el.user_email}</td>
              <td>{el.user_profile}</td>
              {/* <td>{el.oam_matricula}</td> */}
              {/* <td>{el.oam_gestor}</td> */}
              <td>{el.oam_ccusto}</td>
              <td>{el.session_id}</td>
              <td>{format(new Date(el.created_at), 'HH:mm:ss dd/LL/yyyy', { locale: ptBR })}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </FormContainer>
  );
};

export default AcessReportTablePagination;
