import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../../components/Layout";
import { setSidebarOpen } from "providers/reducers/header";
import { setSubscription } from "providers/reducers/user";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ContentGeneratorService from "application/services/ContentGeneratorService";
import TextInputWithAttachment from "../../components/TextInputWithAttachment";
import ChatResponseGpt from "../../components/ChatResponseGpt";
import brandToneMock from "application/utils/mocks/BrandTone.mock";
import {
  Box,
  IconSearchFileRegular,
  Text3,
  skinVars,
} from "@telefonica/mistica";
import { SelectsTone } from "application/components/SelectsTone";
import {
  MainContainer,
  Container,
  InlineCards,
  Main,
  SelectsInline,
  SelectsToneWrapper,
} from "./styles";
import { EmptyStateCard } from "application/components/EmptyStateCard";
import MenuChatSideBar from "application/components/MenuChatSideBar";
import ModalFeedback from "application/components/ModalFeedbackContentGenerator";
import { useAuthenticationContentGenerator } from "application/routes/hooks/useAuthentication";

function getInitials(fullName) {
  const words = fullName.split(" ");
  let initials = "";
  for (let i = 0; i < Math.min(2, words.length); i++) {
    const initial = words[i].charAt(0).toUpperCase();
    initials += initial;
  }
  return initials;
}
export const ContentGenerator = () => {
  const [isModalText, setIsModalText] = useState("");
  const [modal, setModal] = useState(false);
  const [selectedTones, setSelectedTones] = useState({});
  const [emptyStateCardText, setEmptyStateCardText] = useState("");
  const [textArray, setTextArray] = useState([]);
  const [responseArray, setResponseArray] = useState([]);
  const [correlator, setCorrelator] = useState("");
  const [talkId, setTalkId] = useState("");
  const [files, setFiles] = useState([]);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  const [isPulsingLike, setIsPulsingLike] = useState(false);
  const [isPulsingDislike, setIsPulsingDislike] = useState(false);

  const categoriesToDisplay = [
    "Personalidade",
    "Tom",
    "Linguagem",
    "Estilo",
    "Proposito",
    "Tipodeconteudo",
  ];
  const dispatch = useDispatch();
  const history = useHistory();

  const regex = /[?&]subscription_id=([^&#]*)/;
  const match = regex.exec(history.location.search);
  const subscription_id = match && match[1];
  dispatch(setSubscription(subscription_id));

  let expanded = useSelector((state) => state.header.sidebarOpen);
  const userDataState = useSelector((state) => state.user);
  const sidebar = useSelector((state) => state.sidebar);

  const user = userDataState && userDataState.user_id;
  const { subscriptionName } = userDataState.subscriptions.find(
    (subs) => subs.subscriptionId === subscription_id
  );

  function handleSidebar(state) {
    dispatch(setSidebarOpen(state));
  }

  useAuthenticationContentGenerator(userDataState);
  useEffect(() => {
    if (!userDataState.user_profile_permissions.includes("READ")) {
      history.push("/?unauthorized_access");
    }
  });

  const textInputChat = (text) => {
    const newTextArray = [...textArray, text];
    setTextArray(newTextArray);
  };
  const textHistoryChat = (textHistory) => {
    setTextArray(textHistory.map((history) => history.user));
    const arrayResponse = []
    textHistory.map((history) => arrayResponse.push({ response: history.ai, correlator: history.correlator, hasFeedback : history.has_feedback, feedback: history.feedback? history.feedback : "" }));
    setResponseArray(arrayResponse)
  };
  const handleLikeFeedback = (textFeedback, correlator) => {
    setIsModalText(textFeedback);
    setModal(!textFeedback == "");
    setCorrelator(correlator);
  };
  const handleEmptyStateCard = (text) => {
    setEmptyStateCardText(text);
  };

  const handleToneChange = (categoryName, value) => {
    setSelectedTones({
      ...selectedTones,
      [categoryName]: value,
    });
  };

  const sendPrompt = async (text, talkId) => {
    const tipoConteudo =
      selectedTones && selectedTones.Tipodeconteudo
        ? selectedTones.Tipodeconteudo.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        : "";
    const data = {
      prompt_choice: "Prompt sem documento",
      Personalidade: selectedTones.Personalidade,
      Tom: selectedTones.Tom,
      Linguagem: selectedTones.Linguagem,
      Estilo: selectedTones.Estilo,
      Propósito: selectedTones.Proposito,
      prompt: text,
      tipo_conteudo: tipoConteudo,
      hash: "",
    };

    try {
      if (files.length > 0) {
        const form = new FormData();
        files.forEach((file) => {
          form.append("file", file);
        })
        const response = await ContentGeneratorService.uploadFile(
          userDataState,
          subscriptionName,
          form,
          talkId
        );

        if (response.file_hash) {
          const prompHash = "Prompt baseado por documento";
          data.prompt_choice = prompHash;
          data.hash = response.file_hash;
        }
      }

      const res = await ContentGeneratorService.ask(
        userDataState,
        subscriptionName,
        talkId,
        data
      );
      setResponseArray([...responseArray, {response: res.response, correlator: res.correlator, hasFeedback : res.has_feedback, feedback: res.feedback? res.feedback : ""}]);
      setFiles([]);
    } catch (error) {
      toast.error("Falha!", {
        autoClose: 1500,
      });
    }
  };

  const createTalkId = async (text, selectedTones) => {
    try {
      const response = await ContentGeneratorService.createTalk(
        userDataState,
        subscriptionName,
        user,
        selectedTones
      );
      setTalkId(response.talk_id);
      sendPrompt(text, response.talk_id);
    } catch (error) {
      toast.error("Falha ao criar uma conversa", {
        autoClose: 1500,
      });
    }
  };

  const ask = (text) => {
    if (talkId === "") {
      createTalkId(text, selectedTones);
    } else {
      sendPrompt(text, talkId);
    }
  };

  const handleSetTonesByTalkId = async (tones) => { 
    console.log(tones)
    const tonesToBeSet = {
      Personalidade: tones.personalidade,
      Tom: tones.tom,
      Linguagem: tones.linguagem,
      Estilo: tones.estilo,
      Proposito: tones.proposito,
      Tipodeconteudo: tones.tipo_conteudo,
    }
    setSelectedTones(tonesToBeSet)

  }

  const updateMessageByFeedback = (message) => {
    const messageFeedbackedIndex = responseArray.findIndex(res => res.correlator === message.correlator)
    responseArray[messageFeedbackedIndex].hasFeedback = message.has_feedback
    responseArray[messageFeedbackedIndex].feedback = message.feedback
  }
  const initials = getInitials(userDataState.user_name);

  useEffect(() => {
    if (files.length > 3) {
      toast.error("Você pode enviar no máximo 3 arquivos.", {
        autoClose: 1500,
      });
      setFiles([]);
    }
  }, [files])

  return (
    <>
      {modal && (
        <ModalFeedback
          isModalText={isModalText}
          handleLikeFeedback={handleLikeFeedback}
          userDataState={userDataState}
          correlator={correlator}
          handleCloseFeedback={() => setModal(false)}
          talkId={talkId}
          subscriptionName={subscriptionName}
          updateMessageByFeedback={updateMessageByFeedback}
        />
      )}

      <Layout
        expanded={expanded}
        setExpanded={handleSidebar}
        username={userDataState.user_name}
        sidebarOptions={JSON.parse(sidebar.pages)}
        position={"relative"}
        height={"100%"}
        mobileWidth={"100%"}
        mobileMargin={"61px 0 0 0"}
      >
        <Container>
          <MenuChatSideBar
            textHistoryChat={textHistoryChat}
            setTonesByTalkId={handleSetTonesByTalkId}
            responseArray={responseArray}
            setTextArray={setTextArray}
            setResponseArray={setResponseArray}
            user={user}
            talkId={talkId}
            setTalkId={setTalkId}
            isOpenSidebar={isOpenSidebar}
            setIsOpenSidebar={setIsOpenSidebar}
          />
          <MainContainer isOpenSidebar={isOpenSidebar}>
            <Box paddingX={101} paddingTop={42}>
              <Text3
                as="h2"
                weight="700"
                color={skinVars.colors.backgroundBrand}
              >
                Gerador de conteúdo
              </Text3>
            </Box>
            <Main>
              {textArray.length < 1 && (
                <>
                  <IconSearchFileRegular
                    size={48}
                    color={skinVars.colors.backgroundBrand}
                  />
                  <Text3 light as="h3" color={skinVars.colors.textAppBar}>
                    Dicas de prompt para Geração de Conteúdo
                  </Text3>
                  <InlineCards>
                    <EmptyStateCard
                      text="Faça um artigo sobre os riscos e malefícios da automedicação."
                      onClick={handleEmptyStateCard}
                    />
                    <EmptyStateCard
                      text="Crie um SMS alertando sobre a campanha XXX do conteúdo em anexo, direcionando para saber mais no site XXX."
                      onClick={handleEmptyStateCard}
                    />
                    <EmptyStateCard
                      text="Gere um script de Vendas para o produto XXX considerando as características do produto em anexo."
                      onClick={handleEmptyStateCard}
                    />
                  </InlineCards>
                  <SelectsInline>
                    <Text3 color={skinVars.colors.borderHigh} textAlign="left">
                      Por favor, selecione o tom de marca
                    </Text3>
                    <SelectsToneWrapper>
                      {brandToneMock.map((category, index) => {
                        const categoryName = Object.keys(category)[0];
                        const options = category[categoryName];
                        if (categoriesToDisplay.includes(categoryName)) {
                          return (
                            <SelectsTone
                              key={index}
                              options={options}
                              categoryName={categoryName}
                              selectedTone={selectedTones[categoryName]}
                              setSelectedTone={(value) =>
                                handleToneChange(categoryName, value)
                              }
                            />
                          );
                        }

                        return null;
                      })}
                    </SelectsToneWrapper>
                    
                  </SelectsInline>
                </>
              )}
              <TextInputWithAttachment
                sendPrompt={ask}
                emptyStateCardText={emptyStateCardText}
                textInputChat={textInputChat}
                selectedTones={selectedTones}
                setFiles={setFiles}
                files={files}
              />
              </Main>
            {textArray &&
              textArray.map((textArrayText, index) => {
                return (
                  <ChatResponseGpt
                    initials={initials}
                    handleLikeFeedback={handleLikeFeedback}
                    key={index}
                    index={index}
                    question={textArrayText}
                    responseArray={responseArray[index]}
                    isLast={index === textArray.length - 1}
                    isFirstMessage={index === 0}
                    isPulsingLike={isPulsingLike}
                    isPulsingDislike={isPulsingDislike}
                    tones={selectedTones}
                  />
                );
              })}
          </MainContainer>
        </Container>
      </Layout>
    </>
  );
};
