import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Select from "react-select";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Image } from "application/styled-components";
import iconImport from "../../../../../assets/svg/icon-import.svg";
import iconMsgSuccess from "../../../../../assets/svg/ic-msg-success.svg";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSubscription } from "providers/reducers/user";
import PrecificadorService from "application/services/PrecificadorService";
import { toast } from "react-toastify";
import Stack from "@mui/material/Stack";


export const FormProduto = () => {
  const query = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const history = useHistory();
  const regex = /[?&]subscription_id=([^&#]*)/;
  const match = regex.exec(history.location.search);
  const subscription_id = match && match[1];
  dispatch(setSubscription(subscription_id));
  const userDataState = useSelector((state) => state.user);
  const { subscriptionName } = userDataState.subscriptions.find(
    (subs) => subs.subscriptionId === subscription_id
  );


  //categoria_id, vertical_id,subcategoria_id, modelo_id, dpgc
  const prodId = query.get('id');
  const categoria_edit = query.get('categoria');
  const vertical_edit = query.get('vertical');
  const subcategoria_edit = query.get('subcategoria');
  const cor_edit = query.get('cor');

  const [isEditing, setIsEditing] = useState(prodId !== null);


  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [selectedNomeComercial, setSelectedNomeComercial] = useState(query.get('nome_comercial'));
  const [selectedSAP, setSelectedSAP] = useState(query.get('cod_sap'));
  const [selectedDPGC, setSelectedDPGC] = useState(query.get('dpgc'));
  const [selectedModelo, setSelectedModelo] = useState(query.get('modelo'));
  const [selectedCategoria, setSelectedCategoria] = useState(null);
  const [optionCategoria, setOptionCategoria] = useState(null);
  const [selectedVertical, setSelectedVertical] = useState(null);
  const [optionVertical, setOptionVertical] = useState(null);
  const [selectedSubCategoria, setSelectedSubCategoria] = useState(null);
  const [optionSubCategoria, setOptionSubCategoria] = useState(null);
  const [selectedCaracteristicas, setSelectedCaracteristicas] = useState(['']);
  const [optionCaracteristicas, setOptionCaracteristicas] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await PrecificadorService.getProdutosByFilter(userDataState, subscriptionName, "categorias");
        const options = response.map(item => ({
          label: item.nome,
          value: item.id
        }));
        setOptionCategoria(options);

        // Define o nome da categoria selecionada com base no categoria_id
        if (isEditing) {
          const opcao = options.find((categoria) => categoria.label === categoria_edit);
          if (opcao) {
            setSelectedCategoria(opcao);
          }
        }
      } catch (error) {

      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await PrecificadorService.getProdutosByFilter(userDataState, subscriptionName, "verticals");
        const options = response.map(item => ({
          label: item.nome,
          value: item.id
        }));
        setOptionVertical(options);

        if (isEditing) {
          const opcao = options.find((item) => item.label === vertical_edit);
          if (opcao) {
            setSelectedVertical(opcao);
          }
        }
      } catch (error) {

      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await PrecificadorService.getProdutosByFilter(userDataState, subscriptionName, "categorias_com_subcategorias");
        let options = [];
        response.forEach(categoria => {
          categoria.subcategorias.forEach(subcategoria => {
            options.push({
              label: subcategoria.nome,
              value: subcategoria.id
            });
          });
        });
        setOptionSubCategoria(options);

        if (isEditing) {
          const opcao = options.find((item) => item.label === subcategoria_edit);
          if (opcao) {
            setSelectedSubCategoria(opcao);
          }
        }
      } catch (error) {

      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await PrecificadorService.getProdutosByFilter(userDataState, subscriptionName, "cores");
        const options = response.map(item => ({
          label: item.nome,
          value: item.id
        }));
        setOptionCaracteristicas(options);

        if (isEditing) {
          const opcao = options.find((item) => item.label === cor_edit);
          if (opcao) {
            setSelectedCaracteristicas(opcao);
          }
        }
      } catch (error) {

      }
    }
    fetchData();
  }, []);

  const handleSubmit = async () => {
    if (
      !selectedNomeComercial ||
      !selectedCategoria ||
      !selectedVertical ||
      !selectedSubCategoria ||
      !selectedModelo ||
      !selectedSAP ||
      !selectedDPGC
    ) {
      toast.error("Por favor, preencha todos os campos obrigatórios.");
      return;
    }
    const formData = {
      nome_comercial: selectedNomeComercial,
      categoria: selectedCategoria.label,
      vertical: selectedVertical.label,
      subcategoria: selectedSubCategoria.label,
      modelo: selectedModelo,
      modelo_com_cor: selectedNomeComercial + " " + selectedCaracteristicas.label,
      cor: selectedCaracteristicas.label,
      cod_sap: selectedSAP,
      dpgc: selectedDPGC
    };
    setIsSubmitting(true);
    if (isEditing) {
      try {
        formData.id = prodId
        const response = await PrecificadorService.crudOperation("put", userDataState, subscriptionName, `cadastro_produto`, 1, 10, formData);
        if (response.hasOwnProperty('error')) {
          toast.error(response.error);
        }
        else {
          toast.success("Produto editado com sucesso");
        }
      } catch (error) {
        console.error("Erro ao editar Produto:", error);
        toast.error("Erro ao realizar edição");
      } finally {
        setIsSubmitting(false);
      }
    }
    else {
      try {
        const response = await PrecificadorService.crudOperation("post", userDataState, subscriptionName, `cadastro_produto`, 1, 10, formData);
        setSubmitSuccess(true);
        console.log("Produto criado com sucesso:", response);
        // Limpar os campos do formulário após o envio bem-sucedido
        setSelectedNomeComercial("");
        setSelectedCategoria(null);
        setSelectedVertical(null);
        setSelectedSubCategoria(null);
        setSelectedModelo("");
        setSelectedCaracteristicas([]);
        setSelectedSAP("");
        setSelectedDPGC("");
      } catch (error) {
        console.error("Erro ao criar produto:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleNewProduct = () => {
    setSubmitSuccess(false);
  };


  return (
    <div className="CadastroProduto" >

      <div className={submitSuccess ? 'hide-table' : 'show-table'}>


        <div className="box-cadastra-produto" >
          <div className="row">
            <div className="column">
              <div className="form">
                <h3>Dados do produto</h3>
                <form>
                  <Box sx={{ minWidth: 120 }}>
                    <p >
                      <TextField
                        name="nomer_comercial"
                        color="secondary"
                        id="outlined-basic"
                        label="Nome comercial"
                        variant="outlined"
                        value={selectedNomeComercial}
                        onChange={(e) => setSelectedNomeComercial(e.target.value)}

                      />
                    </p>



                    <Select
                      name="vertical"
                      id="selectedVertical"
                      value={selectedVertical}
                      onChange={setSelectedVertical}
                      options={optionVertical}
                      placeholder="Vertical"
                      className="widthSelect"
                    />

                    <p>
                      <Select
                        name="categoria"
                        id="selectedCategoria"
                        value={selectedCategoria}
                        onChange={setSelectedCategoria}
                        options={optionCategoria}
                        placeholder="Categoria"
                        className="widthSelect"
                      />
                    </p>

                    <Select
                      name="subcategoria"
                      id="selectedSubCategoria"
                      value={selectedSubCategoria}
                      onChange={setSelectedSubCategoria}
                      options={optionSubCategoria}
                      placeholder="Sub categoria"
                      className="widthSelect"
                    />
                    <p>
                      <TextField
                        name="modelo"
                        color="secondary"
                        id="selectedModelo"
                        label="Modelo"
                        variant="outlined"
                        value={selectedModelo}
                        onChange={(e) => setSelectedModelo(e.target.value)}
                      />
                    </p>

                    <Select
                      name="cor"
                      id="selectedCaracteristicas"
                      value={selectedCaracteristicas}
                      onChange={setSelectedCaracteristicas}
                      options={optionCaracteristicas}
                      placeholder="Cor"
                      className="widthSelect"
                    />
                  </Box>
                </form>

              </div>
            </div>

            <div className="column fifty">
              <div className="form">
                <h3>Dados internos Vivo</h3>
                <p>
                  <TextField
                    color="secondary"
                    id="SelectedSAP"
                    label="Digite o código SAP do produto"
                    variant="outlined"
                    value={selectedSAP}
                    onChange={(e) => setSelectedSAP(e.target.value)}
                  />
                </p>

                <TextField
                  color="secondary"
                  id="selectedDPGC"
                  label="Digite o ID DPGC do produto"
                  variant="outlined"
                  value={selectedDPGC}
                  onChange={(e) => setSelectedDPGC(e.target.value)}
                />
              </div>
              <div className="bottom-button" style={{ textAlign: "right" }}>
                <Button variant="contained" color="secondary" onClick={handleSubmit}>
                  {isEditing ? "Salvar" : "Cadastrar"}
                </Button>
                {isSubmitting && <p>Aguarde... Enviando produto.</p>}
                {submitSuccess && (
                  <div>
                    <p>Produto criado com sucesso!</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>


      </div>

      <div className={submitSuccess ? 'show-table' : 'hide-table'}>
        <div className="box-file">
          <div >
            <p>
              <Image src={iconMsgSuccess} width={45} />
            </p>
            <div className="both"></div>

            <p> <h3>Cadastro realizado!</h3> </p>
            <p>
              <div className="op2">
                <Stack direction="row" spacing={3}>
                  <Button
                    onClick={() => history.goBack()}
                    color="secondary"
                    variant="contained"
                  >
                    {'Visualizar Produtos'}
                  </Button>
                  <Button
                    onClick={handleNewProduct}
                    color="secondary"
                    variant="outlined"
                  >
                    {'Novo cadastro'}
                  </Button>
                </Stack >
              </div>
            </p>
          </div>

        </div>
      </div>



    </div>
  );
};
