import { Text, StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';

type tableHeaders<T> = {
  title: string;
  value: keyof T | string;
  render?: (item: any, rowData?: T, index?: number) => JSX.Element;
};

interface PDFTable<
  T extends {
    [value: string | number]: any;
  }
> {
  data?: T[] | string[] |null;
  tableHeaders?: tableHeaders<T>[];
  extraStyle?: boolean;
}

export const AudioPDFTable = <
  T extends {
    [key: string]: any;
  }
>({
  data,
  extraStyle,
}: PDFTable<T>) => {
  const styles = StyleSheet.create({
    table: {
      width: "100%",
      marginBottom: 20,
      borderRadius: 4,
      marginTop: 10,
      border: extraStyle ? "" : "1px solid #e0e0e0",
    },
    row: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#e0e0e0",
      alignItems: "center",
      minHeight: 30,
      flexWrap: "wrap",
      ":not(:last-child)": {
        borderBottom: "none",
      },
    },
    rowHead: {
      flexDirection: "row",
    },
    cell: {
      flex: 1,
      padding: 8,
      fontSize: 11,
      color: "#424242",
      textTransform: "capitalize",
      alignItems: "center",
    },
    snRow: {
      flex: 0.4,
      padding: "8px 4px",
      fontSize: 11,
      color: "#424242",
      fontWeight: "bold",
    },  innerHeading: {
      fontSize: 16,
      fontWeight: 700,
      marginBottom: 12,
      marginTop: 12,
    },
  });
  return (
    <View wrap={true}>
      <View style={styles.table}>
        {data?.map((item, dataIndex) => (
            <View key={dataIndex}>
              <View style={styles.row}>
                <Text style={styles.snRow}>{item}</Text>
              </View>
            </View>
          ))}
      </View>
    </View>
  );
};
