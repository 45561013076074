import { getVivoSkin } from '@telefonica/mistica';

export const Theme = {
    skin: getVivoSkin(),
    i18n: {
        locale: 'pt-BR',
        phoneNumberFormattingRegionCode: 'BR',
    },
    platformOverrides: {
        platform: 'desktop',
        insideNovumNativeApp: false,
    }
} 