import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

export const TableHeadComponent = ({ name }) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#660099",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  return (
    <>
      {(() => {
        if (name === "produto") {
          return (
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Categoria</StyledTableCell>
                <StyledTableCell align="center">Produto</StyledTableCell>
                <StyledTableCell align="center">Marca</StyledTableCell>
                <StyledTableCell align="center">Loja</StyledTableCell>
                <StyledTableCell align="center">Seller</StyledTableCell>
                <StyledTableCell align="center">Menor preço à vista</StyledTableCell>
                <StyledTableCell align="center">Preço a prazo</StyledTableCell>
              </TableRow>
            </TableHead>
          );
        } else if (name === "competitividade") {
          return (
            <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Produto</StyledTableCell>
                  <StyledTableCell align="center">Loja</StyledTableCell>
                  <StyledTableCell align="center">Meu Ranking</StyledTableCell>
                  <StyledTableCell align="center">Preço vivo</StyledTableCell>
                  <StyledTableCell align="center"> Preço mercado à vista</StyledTableCell>
                  <StyledTableCell align="center">Preço mercado a prazo</StyledTableCell>
                  <StyledTableCell align="center">Comparativo</StyledTableCell>
                </TableRow>
              </TableHead>
          );
        } else if (name === "atualizacoes") { {/* Dados do Produto */}
          return (
            <TableHead>
                <TableRow>
                  <StyledTableCell align="center" width={200}>Produto</StyledTableCell>
                  <StyledTableCell align="center">Marca</StyledTableCell>
                  <StyledTableCell align="center">Loja</StyledTableCell>
                  <StyledTableCell align="center">Seller</StyledTableCell>
                  <StyledTableCell align="center" width={250}>
                    Preço antigo à vista
                  </StyledTableCell>
                  <StyledTableCell align="center" width={250}>
                    Preço antigo a prazo
                  </StyledTableCell>
                  <StyledTableCell align="center" width={200}>
                    Novo preço à vista
                  </StyledTableCell>
                  <StyledTableCell align="center" width={200}>
                    Novo preço a prazo
                  </StyledTableCell>
                  <StyledTableCell align="center" width={200}>
                    Novo preço vivo
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Comparativo
                  </StyledTableCell>
                </TableRow>
              </TableHead>
          );
        } else if (name === "atualizacoes_comparativo") {  {/* Comparativo */}
          return (
            <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    Preço antigo à vista
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Preço antigo a prazo
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Novo preço à vista
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Novo preço a prazo
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Novo preço vivo
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Comparativo
                  </StyledTableCell>
                </TableRow>
              </TableHead>
          );
        } else if (name === "historico_de_precos") {
          return (
            <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Produto</StyledTableCell>
                  <StyledTableCell align="center">Loja</StyledTableCell>
                  <StyledTableCell align="center">Seller</StyledTableCell>
                  <StyledTableCell align="center">Preço vivo</StyledTableCell>
                  <StyledTableCell align="center"> Preço mercado</StyledTableCell>
                </TableRow>
              </TableHead>
          );
        } else if (name === "produtos_cadastrados_manual") {
          return (
            <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Data Cadastro</StyledTableCell>
                    <StyledTableCell align="center">Categoria</StyledTableCell>
                    <StyledTableCell align="center">Vertical</StyledTableCell>
                    <StyledTableCell align="center">Subcategoria</StyledTableCell>
                    <StyledTableCell align="center">Nome comercial</StyledTableCell>
                    <StyledTableCell align="center">Ações</StyledTableCell>
                  </TableRow>
                </TableHead>
          );
        } else if (name === "cep_cadastrados_manual") {
          return (
            <TableHead>
            <TableRow>
              <StyledTableCell align="center">Data Cadastro</StyledTableCell>
              <StyledTableCell align="center">CEP</StyledTableCell>
              <StyledTableCell align="center">Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          );
        }
      })()}
    </>
  );
};
