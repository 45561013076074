// import ServiceHelperLogin from "../helpers/ServiceHelperLogin";
import axios from 'axios'
import { User } from './interfaces/User'
import { HandlerError } from './HandlerError'
class ChangePasswordService {
  private static readonly API_URL = process.env.REACT_APP_API_URL_SERVICE_LOGIN

  private static getConfig = ({
    user_id,
    session_id,
    token,
    user_email,
    user_name,
  }: User) => {
    return {
      headers: {
        session_id: session_id,
        user_id: user_id,
        token: token,
      },
    }
  }

  static async changePassWord(
    userData: User,
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string,
  ) {
    try {
      const CONFIG = this.getConfig(userData)
      const response = await axios.post(
        `${this.API_URL}/user/changePassword`,
        {
          currentPassword,
          newPassword,
          confirmNewPassword,
        },
        CONFIG,
      )
      return response.data
    } catch (error: any) {
      return HandlerError(error.response)
    }
  }
}

export default ChangePasswordService
