export const validatePayload = (subscriptionRoute?: string) => {
    const queryParameters = new URLSearchParams(window.location.search)
    const payload = queryParameters.get("payload")
    const subscriptionId = queryParameters.get("subscription_id")
    const pathBase = subscriptionRoute ? subscriptionRoute : '' 

    if (payload && subscriptionId) {
        const BASENAME = process.env.REACT_APP_API_URL_BASENAME
        if (pathBase) {
            const subRoute = BASENAME ? ("/" + pathBase?.replace(/^\/.+?\//, '')) : pathBase
    
            console.log(BASENAME)
            if (subRoute) {
                localStorage.setItem('redirectSubscriptionRoute', subRoute)
            }
        }

        return { code: payload, subscriptionSelected: subscriptionId }
    }

}
