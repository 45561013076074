import { DefaultInput } from "application/components/DefaultInput"
import { TabPanel } from "application/components/TabPanel"
import { FormContainer, SearchContainerRow } from "application/styled-components"
import { Button, IconSendVivo } from "application/styled-components/chatGPT"
import { useEffect, useState } from "react"
import { DirectlineService } from '../../../services/DirectLine'
import { useSelector } from "react-redux"
import { LoadSpinner } from "../../../styled-components";
import { Switch } from "@telefonica/mistica"
import { Label } from "application/styled-components/layout"

export const Aura = () => {
    const [prompt, setPrompt] = useState("")
    const [question, setQuestion] = useState("")
    const [conversationId, setConversationId] = useState("")
    const [token, setToken] = useState("")
    const [response, setResponse] = useState("")
    const [loadingAwnser, setLoadingAwnser] = useState(false)
    const [phone, setPhone] = useState(5511999999999)
    const [checked, setChecked] = useState(false)
    const userDataState = useSelector((state) => state.user)

    const handleSendMessage = async () => {
        setLoadingAwnser(true)
        const response = await DirectlineService.sendMessage(question, conversationId, token, userDataState, phone, prompt)
        console.log('Travel send message', response)
        setLoadingAwnser(false)
    }

    const handleReciveMessage = async () => {
        const { activities, watermark } = await DirectlineService.getMessage(conversationId, token)
        if (watermark && activities[watermark] && activities[watermark].from.name) {
            setResponse(activities[watermark].text)
            setLoadingAwnser(false)
        }
        return activities
    }

    const handleStartConversation = async () => {
        const auraAuth = await DirectlineService.setToken()
        const { token, conversationId } = await DirectlineService.startConversation(auraAuth.token)
        console.log('Travel conversationId', conversationId)
        setConversationId(conversationId)
        setToken(token)
    }

    const handleTips = () => {
        !checked ? setPrompt(process.env.REACT_APP_AURA_VIVO_TRAVEL_DEFAULT_TIP) : setPrompt("")
        setChecked(!checked)
    }

    useEffect(() => {
        handleStartConversation()
    }, [])


    useEffect(()=>{
        const intervalId = setInterval(handleReciveMessage, 2000);
        return () => clearInterval(intervalId);
    })
    
    return (
        <TabPanel active={true}>
        <FormContainer>
        <SearchContainerRow>
            <DefaultInput
                label="Telefone" change={setPhone} 
                width="100%"
                require={true} 
                value={phone} 
                placeholder="Número de telefone para contexto"
                >
            </DefaultInput>
        </SearchContainerRow>
        <SearchContainerRow>
            <DefaultInput
                label="Pergunte sobre o Vivo Travel" change={setQuestion} 
                width="100%"    
                require={true} 
                value={question} 
                placeholder="Pergunte algo sobre o vivo travel..."
            >
            </DefaultInput>
            <Button 
                position={'absolute'} 
                top={'59%'} 
                right={'33px'} 
                transform={'translateY(-50%)'} 
                onClick={handleSendMessage}
                >
                <IconSendVivo />
            </Button>
        </SearchContainerRow>
        <SearchContainerRow  style={{ marginLeft: '15px', marginBottom: '15px'}} >
           <Switch name="save-cc"checked={checked} onChange={handleTips}> <Label>Ativar Dicas</Label> </Switch>
        </SearchContainerRow>
        <SearchContainerRow>
            {checked &&<DefaultInput
                change={setPrompt} 
                width="100%"
                value={prompt}
                placeholder="Adicione dicas para customizar a experiência"
            >
            </DefaultInput>}
        </SearchContainerRow>
      </FormContainer>
      {loadingAwnser &&<LoadSpinner />}
      {!loadingAwnser &&
        <FormContainer>
         <div dangerouslySetInnerHTML={{ __html: response }} className="pre-wrap" />
        </FormContainer>
      }
      </TabPanel>
    )
}
