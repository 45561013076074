import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../../components/Layout";
import { setSidebarOpen } from "providers/reducers/header";
import { setSubscription } from "providers/reducers/user";
import { ContentContainer, Title, AbsoluteBox, FormContainer, Image } from "../../styled-components";
import { AnswerClientAlign, AnswerGptAlign, AnswerScreen, ClientBoxAnswer, GptBoxAnswer, Paragraph, LoadingAwnser, Button, IconSendVivo, WrapperFlex, BoxChatGPT, ColumnChatGPT, ContainerChatGPT, ColumnChatGPTInfo, FeedBackWrap } from "application/styled-components/chatGPT";
import { DefaultInputChat } from "../../components/DefaultInputChat";
import { useHistory } from 'react-router-dom'
import Bell from "../../../assets/img/bell.png"
import Light from "../../../assets/img/light.png"
import Talk from "../../../assets/img/talk.png"
import { toast } from "react-toastify";
import FeedBackModal from "application/components/FeedBackModal";
import {ask} from 'application/services/ChatGPTService';
import FeedBackService from "application/services/FeedBackService";
import { useAuthenticationGPT } from "application/routes/hooks/useAuthentication";

/* like icons */
import likewhitetransparent from 'assets/img/likewhitetransparent.png'
import likewhitefilled from 'assets/img/likewhitefilled.png'
import dislikewhitetransparent from 'assets/img/dislikewhitetransparent.png'
import dislikewhitefilled from 'assets/img/dislikewhitefilled.png'
/*  */

export const ChatGPT = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  
  const regex = /[?&]subscription_id=([^&#]*)/;
  const match = regex.exec(history.location.search);
  const subscription_id = match && match[1];
  dispatch(setSubscription(subscription_id));

  let expanded = useSelector((state) => state.header.sidebarOpen);
  const userDataState = useSelector((state) => state.user);
  const sidebar = useSelector((state) => state.sidebar);
  
  function handleSidebar(state) {
    dispatch(setSidebarOpen(state));
  }

  const [messages, setMessages] = useState([])
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [feedbackmessage, setFeedbackmessage] = useState('')
  const [isLikeFeedback, setIsLikeFeedback] = useState(null)
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [messageCorrelatorFeedback, setMessageCorrelatorFeedback] = useState('');

  useAuthenticationGPT(userDataState);

  /* FEEDBACK */

  const valueMessage = (v) => {
    setFeedbackmessage(v.target.value)
  }

  const openModal = () => {
    setIsOpen(true);
    setFeedbackmessage('');
  };


  const closeModal = () => {
    setIsOpen(false);
  };

  const handleLikeAction = (index, correlatorMessage) => {
    setIsLikeFeedback(true)
    setSelectedAnswerIndex(index);
    setMessageCorrelatorFeedback(correlatorMessage)
    openModal();
  };

  const dislikeAction = (index, correlatorMessage) => {
    setIsLikeFeedback(false)
    setSelectedAnswerIndex(index);
    openModal();
    setMessageCorrelatorFeedback(correlatorMessage)
  };

  const handleFeedback = async (isLike, message) => {
    const feedback = isLike ? 'positive' : 'negative';
    const correlator = messageCorrelatorFeedback

    try {
      await FeedBackService.feedbackChatGPT(userDataState, feedback, correlator, message);
      toast.success("Feedback enviado com sucesso!",{
        autoClose: 1500
      });
      const messagesCopy = [...messages]
      messagesCopy[selectedAnswerIndex].hasFeedback = true
      messagesCopy[selectedAnswerIndex].feedback = feedback
      setMessages(messagesCopy)

    } catch (error) {
      console.log("Failed to send feedback:", error);
      toast.error("Falha ao enviar feedback!", {
        autoClose: 1500
      });
    }
    closeModal();
  };

  const handleModalSubmit = async () => {
    const message = feedbackmessage.trim();
    
    if (message !== '') {
      await handleFeedback(isLikeFeedback, message, );
    } else {
      await handleFeedback(isLikeFeedback, '');
    }
      
  };

  const handleAnswer = (newMessage, correlator) => {
    setMessages((messages) => [...messages, {
      text: newMessage,
      author: 'bot',
      correlator,
      hasFeedback: false,
      feedback: '',
    }]);
  };

  const handleMessage = async (message) => {
    setMessages((messages) => [...messages, { text: message, author: 'user' }]);
    getMessage(message);
  };

  /* Working with "enter" press */
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleQuestionClick();
    }
  };

  /* asking a question if has what ask and erasing the input */
  const handleQuestionClick = () => {
    const message = value;
    if (message !== '') {
      handleMessage(message);
      setValue('');
    }
  }

  const getMessage = async (message) => {
    const role = { role: "system", content: "You are an AI assistant that helps people find information. You current language is Portuguese from Brazil." };
    const messagesStore = localStorage.getItem('messages')
    const messages = [
      ...messagesStore ? JSON.parse(messagesStore) : [],
      { "role": "user", "content": message }
    ]
    try {
      setLoading(true)
      const response = await ask(userDataState, [role, ...messages], message);
      handleAnswer(response.choices[0].message.content.toString(), response.correlator);
      const newMessages = [...messages]
      newMessages.push({ role: 'assistant', content: response.choices[0].message.content.toString() })
      localStorage.setItem('messages', JSON.stringify(newMessages))
      setLoading(false)
    } catch (error) {
        console.log('catch chat gpt');
        console.log(error);
        switch (error.status) {
          case 400:
            toast.error('Desculpe, houve um erro ao processar a sua pergunta. Por favor, tente novamente e, se o problema persistir, contate o administrador do sistema.', {
              autoClose: 1500
            })
            break;
          case 401:
            toast.error('Desculpe, você não tem acesso à esse caso de uso. Se você acredita que isso seja um erro, contate o administrador do sistema.', {
              autoClose: 1500
            })
            break;
          case 429:
            toast.error('Você atingiu o limite de perguntas em um curto período. Por favor, aguarde um momento e pergunte novamente. ', {
              autoClose: 1500
            })
            break;
          case 500:
          case 502:
            toast.error('Desculpe, ocorreu um erro interno. Por favor, entre em contato com o administrador do sistema.', {
              autoClose: 1500
            })
            break;
          default:
            toast.error('Desculpe, houve um erro ao enviar sua pergunta. Recarregue a página e tente novamente. Se o problema persistir, contate o administrador do sistema.', {
              autoClose: 1500
            })
      }
      setLoading(false)
    }
  };

  const handleSendMessageByClickButton = (messageByBox) => {
    const message = messageByBox;
    if (message !== '') {
      handleMessage(message);
      setValue('');
    }
  }

  useEffect(() => {
    const element = document.getElementById("scroller");
    element.scrollTop = element.scrollHeight;
  }, [messages])

  useEffect(() => {
    localStorage.removeItem('messages')
  }, [])

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27 && isOpen) {
        closeModal();
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen]);

  useEffect(() => { 
    if (!userDataState.user_profile_permissions.includes('READ')) {
      history.push('/?unauthorized_access')
    }
  })

  return (<>
    <Layout
      expanded={expanded}
      setExpanded={handleSidebar}
      username={userDataState.user_name}
      sidebarOptions={JSON.parse(sidebar.pages)}
      position={'relative'}
      height={'100%'}
      mobileWidth={'100%'}
      mobileMargin={'61px 0 0 0'}
    >
      <Title>VivoGPT</Title>
      <WrapperFlex>
        <ContentContainer display={'flex'} justifyContent={'space-between'} flexDirection={'column'} width={'calc(80% - 50px)'} mobileWidth={'100%'} mobileMargin={'3.1875rem auto 0 auto'}>
          <AnswerScreen id="scroller">
            {messages.length === 0 ?
              <ContainerChatGPT>
                <ColumnChatGPT>
                  <Image src={Talk} width="initial" height="35px" objectFit="contain" />
                  <p>Exemplos de texto</p>
                  <BoxChatGPT linkActive={true} onClick={() => handleSendMessageByClickButton('Fale um pouco sobre a empresa Vivo')}>
                    "Fale um pouco sobre a empresa Vivo"
                  </BoxChatGPT>
                  <BoxChatGPT linkActive={true} onClick={() => handleSendMessageByClickButton('Liste os 10 desafios de telecom no Brasil')}>
                    “Liste os 10 desafios de telecom no Brasil"
                  </BoxChatGPT>
                  <BoxChatGPT linkActive={true} onClick={() => handleSendMessageByClickButton('Crie uma proposta de e-mail marketing')}>
                    "Crie uma proposta de e-mail marketing"
                  </BoxChatGPT>
                </ColumnChatGPT>

                <ColumnChatGPTInfo>
                  <Image src={Light} width="initial" height="35px" objectFit="contain" />
                  <p>Dicas para o VivoGPT</p>
                  <BoxChatGPT>
                    Seja claro e específico ao formular suas perguntas.
                  </BoxChatGPT>
                  <BoxChatGPT>
                    Use palavras-chave em perguntas breves para obter respostas precisas e compreensíveis.
                  </BoxChatGPT>
                  <BoxChatGPT>
                    Leia atentamente as respostas para garantir relevância ao seu contexto.
                  </BoxChatGPT>
                </ColumnChatGPTInfo>

                <ColumnChatGPTInfo>
                  <Image src={Bell} width="initial" height="35px" objectFit="contain" />
                  <p>Dicas de segurança</p>
                  <BoxChatGPT>
                    Garanta a privacidade dos usuários. Use de forma crítica e consciente.
                  </BoxChatGPT>
                  <BoxChatGPT>
                    As informações fornecidas pelo VivoGPT podem ser provenientes de fontes desconhecidas, desconfie.
                  </BoxChatGPT>
                  <BoxChatGPT>
                    Ocasionalmente, pode produzir instruções prejudiciais, conteúdo tendencioso ou incorreto
                  </BoxChatGPT>
                </ColumnChatGPTInfo>

              </ContainerChatGPT>
              :
              messages.map(
                (question, index) => (
                  question.author === 'user' ? (
                    <AnswerClientAlign key={index}>
                      <ClientBoxAnswer>
                        <Paragraph fontSize={'20px'}>{question.text}</Paragraph>
                      </ClientBoxAnswer>
                    </AnswerClientAlign>


                  ) : (
                    <AnswerGptAlign key={index}>
                      <GptBoxAnswer>
                        <Paragraph width={'83%'} fontSize={'20px'}>{question.text}</Paragraph>
                          <FeedBackWrap width={'10%'}>
                            {
                              question.hasFeedback ?
                                question.feedback === 'positive' ?
                                  <Image width={'40%'} height={'fit-content'} objectFit={'fill'} src={likewhitefilled} className="active" />
                                : <Image width={'40%'} height={'fit-content'} objectFit={'fill'} src={dislikewhitefilled} className="active" />
                              :
                                <>
                                  <Image cursor={'pointer'} width={'40%'} height={'fit-content'} objectFit={'fill'} src={likewhitetransparent} onClick={() => handleLikeAction(index, question.correlator)} className="active" />
                                  <Image cursor={'pointer'} width={'40%'} height={'fit-content'} objectFit={'fill'} src={dislikewhitetransparent} onClick={() => dislikeAction(index, question.correlator)} className="active" />
                                </>   
                            }
                          {/* {
                            likes[index] ? (
                              <Image cursor={'pointer'} width={'61%'} height={'fit-content'} objectFit={'fill'} src={likewhitefilled} onClick={() => handleLikeAction(index, question.correlator)} className="active" />
                            ) : (
                              <Image cursor={'pointer'} width={'61%'} height={'fit-content'} objectFit={'fill'} src={likewhitetransparent} onClick={() => handleLikeAction(index, question.correlator)} className="active" />
                            )
                          }
                          {
                            dislikes[index] ? (
                              <Image cursor={'pointer'} width={'61%'} height={'fit-content'} objectFit={'fill'} src={dislikewhitefilled} onClick={() => dislikeAction(index, question.correlator)} className="active" />
                            ) : (
                              <Image cursor={'pointer'} width={'61%'} height={'fit-content'} objectFit={'fill'} src={dislikewhitetransparent} onClick={() => dislikeAction(index, question.correlator)} className="active" />
                            )
                          } */}
                        </FeedBackWrap>
                      </GptBoxAnswer>
                    </AnswerGptAlign>
                  )
                )
              )
            }
          </AnswerScreen>
          {loading && <LoadingAwnser style={{ marginTop: 'auto' }} />}
          <FormContainer position={'relative'} margin={'0 0 1.25rem 0'}>
            <DefaultInputChat
              width={"98%"}
              marginBottom={'0'}
              displayLabel={'none'}
              placeholder={'Em que posso ajudar?'}
              border={'none'}
              change={(e) => setValue(e.target.value)}
              ref={inputRef}
              value={value}
              onKeyDown={handleKeyDown}
              loading={loading}
            />
            <AbsoluteBox>
              <Button onClick={handleQuestionClick} loading={loading}>
                <IconSendVivo loading={loading} />
              </Button>
            </AbsoluteBox>
          </FormContainer>

        </ContentContainer>
        <ContentContainer width={'calc(50% - 50px)'} display={'none'}>
          <Title>Chat GPT</Title>
          <Title>PDF is going to be here</Title>

        </ContentContainer>
      </WrapperFlex>
    </Layout>

    {isOpen && (
      <FeedBackModal
        closeModal={closeModal}
        isLike={isLikeFeedback}
        change={valueMessage}
        submit={handleModalSubmit}
      />
    )}
  </>);
};
