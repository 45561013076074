import { useEffect } from "react";
import { useAuthenticationPrecificador } from "application/routes/hooks/useAuthentication";
import Layout from "../../components/Layout";

import { setSidebarOpen } from "providers/reducers/header";
import { setSubscription } from "providers/reducers/user";
import { useSelector, useDispatch } from "react-redux";
import { Title } from "../../styled-components";

import { useHistory } from "react-router-dom";

export const MonitoramentoPrecificador = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const regex = /[?&]subscription_id=([^&#]*)/;
  const match = regex.exec(history.location.search);
  const subscription_id = match && match[1];
  dispatch(setSubscription(subscription_id));

  let expanded = useSelector((state) => state.header.sidebarOpen);
  const userDataState = useSelector((state) => state.user);
  const sidebar = useSelector((state) => state.sidebar);

  function handleSidebar(state) {
    dispatch(setSidebarOpen(state));
  }

  useAuthenticationPrecificador(userDataState)
  useEffect(() => {
    if (!userDataState.user_profile_permissions.includes("READ")) {
      history.push("/?unauthorized_access");
    }
  });

  return (
    <Layout
      expanded={expanded}
      setExpanded={handleSidebar}
      username={userDataState.user_name}
      sidebarOptions={JSON.parse(sidebar.pages)}
      position={"relative"}
      height={"100%"}
      mobileWidth={"100%"}
      mobileMargin={"61px 0 0 0"}
    >
      <Title>Monitoramento - Consulta de Valores</Title>
        <iframe
          title="Qualidade dos Dados"
          width="100%"
          height="560"
          src="https://app.powerbi.com/reportEmbed?reportId=40d74ac3-51db-49c3-9e3b-a8f082eecd6b&autoAuth=true&ctid=9744600e-3e04-492e-baa1-25ec245c6f10"
          frameborder="0"
          allowFullScreen="true"
        />
    </Layout>
  );
}
