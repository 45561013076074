import React from "react";
import { ModalHeader, ModalOverLay, ModalWrap, Wrapper } from "application/styled-components/Perfil";
import { Button, Paragraph } from "application/styled-components/chatGPT";
import { IconCloseRegular, IconFilePdfRegular } from "@telefonica/mistica";
import "react-toastify/dist/ReactToastify.css";
import { PdfWrap } from "application/styled-components";
import AdminPdfViewer from "../AdminPdfViewer";

interface ModalPdfViewerProps {
    closeModal: () => void;
    pdfUrl: string;
}

const ModalPdfViewer: React.FC<ModalPdfViewerProps> = ({ closeModal, pdfUrl }) => {
    return (
        <>
            <ModalOverLay>
                <ModalWrap>
                    <ModalHeader>
                        <Wrapper fill={"#660099"}>
                            <IconFilePdfRegular />
                            <Paragraph margin={0} fontSize="1.25rem">
                                Visualizador do PDF
                            </Paragraph>
                        </Wrapper>
                        <Button onClick={closeModal}>
                            <IconCloseRegular />
                        </Button>
                    </ModalHeader>
                    <PdfWrap>
                        <AdminPdfViewer url={pdfUrl} />
                    </PdfWrap>
                </ModalWrap>
            </ModalOverLay>
        </>
    );
};

export default ModalPdfViewer;
