import logoBaseBlue from "../../../assets/svg/ic-menu-answer-blue.svg";
import logoBaseGray from "../../../assets/svg/ic-menu-answer-gray.svg";
import logoDashBlue from "../../../assets/svg/ic-menu-dashs-blue.svg";
import logoDashGray from "../../../assets/svg/ic-menu-dashs-gray.svg";
import logoHomeBlue from "../../../assets/svg/ic-menu-home-blue.svg";
import logoHomeGray from "../../../assets/svg/ic-menu-home-gray.svg";
import logoCuraBlue from "../../../assets/svg/ic-menu-ia-assert-blue.svg";
import logoCuraGray from "../../../assets/svg/ic-menu-ia-assert-gray.svg";
import logoToolBlue from "../../../assets/svg/ic-menu-tools-blue.svg";
import logoToolGray from "../../../assets/svg/ic-menu-tools-gray.svg";
import logoAnalysisBlue from "../../../assets/svg/ic-analysis-blue.svg";
import logoAnalysisGray from "../../../assets/svg/ic-analysis-gray.svg";
import logoRelatorioBlue from "../../../assets/svg/ic-relatorios-blue.svg";
import logoRelatorioGray from "../../../assets/svg/ic-relatorios-gray.svg";
import logoAbTestBlue from "../../../assets/svg/ic-menu-tools-ab-test-blue.svg";
import logoAbTestGray from "../../../assets/svg/ic-menu-tools-ab-test-gray.svg";
import logoHandoverBlue from "../../../assets/svg/ic-menu-tools-handover-blue.svg";
import logoHandoverGray from "../../../assets/svg/ic-menu-tools-handover-gray.svg";
import logoSandboxGray from "../../../assets/svg/icon-send-regular-gray.svg";
import logoSandboxBlue from "../../../assets/svg/icon-send-regular-blue.svg";
import logoUCAssessmentBlue from "../../../assets/svg/ic-menu-tools-uc-blue.svg";
import logoUCAssessmentGray from "../../../assets/svg/ic-menu-tools-uc-gray.svg";
import logoUserInteractionGray from "../../../assets/svg/ic-menu-analysis-chat-gray.svg";
import logoUserInteractionBlue from "../../../assets/svg/ic-menu-analysis-chat-blue.svg";
import logoConversationHistoryBlue from "../../../assets/svg/ic-menu-report-blue.svg";
import logoConversationHistoryGray from "../../../assets/svg/ic-menu-report-gray.svg";
import logoIndicatorsBlue from "../../../assets/svg/ic-menu-dashs-indicator-blue.svg";
import logoIndicatorsGray from "../../../assets/svg/ic-menu-dashs-indicator-gray.svg";
import logoCuraAssertBlue from "../../../assets/svg/ic-menu-curatorship-assertiviness-blue.svg";
import logoCuraAssertGray from "../../../assets/svg/ic-menu-curatorship-assertiviness-gray.svg";
import logoCuraMonitorBlue from "../../../assets/svg/ic-menu-curatorship-monitor-blue.svg";
import logoCuraMonitorGray from "../../../assets/svg/ic-menu-curatorship-monitor-gray.svg";
import logoAnswerIntentionsBlue from "../../../assets/svg/ic-menu-answer-intentions-blue.svg";
import logoAnswerIntentionsGray from "../../../assets/svg/ic-menu-answer-intentions-gray.svg";
import logoAnswerNotificationsBlue from "../../../assets/svg/ic-menu-answer-notifications-blue.svg";
import logoAnswerNotificationsGray from "../../../assets/svg/ic-menu-answer-notifications-gray.svg";
import icMenuToolsProfilesBlue from "../../../assets/svg/ic-menu-tools-profiles-blue.svg";
import icMenuToolsProfilesGray from "../../../assets/svg/ic-menu-tools-profiles-gray.svg";
import IcMenuBoxFilledBlue from "../../../assets/svg/ic-menu-box-filled-blue.svg";
import IcMenuBoxFilledGray from "../../../assets/svg/ic-menu-box-filled-gray.svg";

import ArgProdIcon from "../../../assets/img/ai-icon-purple.png";
import ArgProdADMIcon from "../../../assets/img/tool.png";
import ResumoEmpresarialIcon from "../../../assets/img/box-icon.png";
import ChatGPTIcon from "../../../assets/img/chat-icon.png";
import ADMIcon from "../../../assets/img/admin.png";
import B2CIcon from "../../../assets/img/b2c.png";
import B2BIcon from "../../../assets/img/b2b.png";
// import GeradorConteudoIcon from "../../../assets/img/light.png";

export function getIconByTitle(title, color) {
  switch (title) {
    case "Home":
      return color === "blue" ? logoHomeBlue : logoHomeGray;
    case "Base de Respostas":
      return color === "blue" ? logoBaseBlue : logoBaseGray;
    case "VIVO GPT":
      return ChatGPTIcon;
    case "B2B ONE RESUMO EMPRESARIAL":
      return ResumoEmpresarialIcon;
    case "ADMIN LAB IA":
      return ADMIcon;
    case "B2B MOBILE PRODUCTS":
      return ArgProdIcon;
    case "IA DO AGENTE B2C":
      return B2CIcon;
    case "IA DO AGENTE B2B":
      return B2BIcon;
    case "Gerador de Conteúdo":
      return IcMenuBoxFilledBlue;
    case "Admin Produtos Móvel B2B":
      return ArgProdADMIcon;
    case "Dashboards":
      return color === "blue" ? logoDashBlue : logoDashGray;
    case "Relatórios":
      return color === "blue" ? logoRelatorioBlue : logoRelatorioGray;
    case "Sala de Análises":
      return color === "blue" ? logoAnalysisBlue : logoAnalysisGray;
    case "Produtos":
      return color === "blue" ? logoCuraBlue : logoCuraGray;
    case "Assertividade":
      return color === "blue" ? logoCuraAssertBlue : logoCuraAssertGray;
    case "Monitor & Insights":
      return color === "blue" ? logoCuraMonitorBlue : logoCuraMonitorGray;
    case "Ferramentas":
      return color === "blue" ? logoToolBlue : logoToolGray;
    case "Teste A/B":
      return color === "blue" ? logoAbTestBlue : logoAbTestGray;
    case "Handover":
      return color === "blue" ? logoHandoverBlue : logoHandoverGray;
    case "NPS":
      return color === "blue" ? logoUCAssessmentBlue : logoUCAssessmentGray;
    case "Envio de Push Test":
      return color === "blue" ? logoSandboxBlue : logoSandboxGray;
    case "Interações":
      return color === "blue"
        ? logoUserInteractionBlue
        : logoUserInteractionGray;
    case "Histórico":
      return color === "blue"
        ? logoConversationHistoryBlue
        : logoConversationHistoryGray;
    case "Indicadores":
      return color === "blue" ? logoIndicatorsBlue : logoIndicatorsGray;
    case "Intenções e Canais":
      return color === "blue"
        ? logoAnswerIntentionsBlue
        : logoAnswerIntentionsGray;
    case "Notificações":
      return color === "blue"
        ? logoAnswerNotificationsBlue
        : logoAnswerNotificationsGray;
    case "Perfis":
      return color === "blue"
        ? icMenuToolsProfilesBlue
        : icMenuToolsProfilesGray;
    default:
      return color === "blue" ? logoToolBlue : logoToolGray;
  }
}
