import React from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function AzureBlobDownloadButton({
  guid,
  subscriptionId,
  flowId,
  callTreeFlow,
  setSecondFlow
}) {
  const history = useHistory()
  const { user_id, token, session_id } = useSelector((state) => state.user);
  const onDownload = async () => {
    await callTreeFlow();
    try {
      const response = await fetch(
        `${process.env.PUBLIC_URL}/flow/download/${guid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            user_id: user_id,
            token: token,
            session_id: session_id,
            subscription_id: subscriptionId,
            flow_id: flowId,
          },
        }
      );

      const responseJson = await response.json();
      const link = document.createElement("a");
      link.href = responseJson;
      link.download = "documento_atualizado.docx";
      link.click();
      URL.revokeObjectURL(responseJson);
      setSecondFlow(false)
      history.push(
        `${process.env.PUBLIC_URL}/chat?flow_id=${flowId}&subscription_id=${subscriptionId}`
      );
      //downloadFileFromBuffer(responseJson.data, fileName);
    } catch (err) {}
  };

  return (
    <button className="input-file-regenerate-download" onClick={onDownload}>
      <p className="input-file-text">Fazer o download das propostas</p>
    </button>
  );
}

export default AzureBlobDownloadButton;
