import { createSlice } from '@reduxjs/toolkit';

export const form = createSlice({
	name: 'form',
	initialState: {
		dashboard: {
			title: "",
			imageName: "",
			dashboards: []
		}
    },
    reducers: {
        setDashboard: (state, action) => {
			state.dashboard = action.payload;
		},
        clearDashboard:  (state, action) => {
			state.dashboard = {};
		},
    },
});

export const { setDashboard, clearDashboard } = form.actions;

export default form.reducer;
