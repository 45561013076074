import React from "react";
import Avatar from "./Avatar";
import BotResponse from "./BotResponse";
import Error from "./Error";
import IntroSection from "./IntroSection";
import Loading from "./Loading";
import "../../pages/FlowChat/Home.css";

const ChatBox = ({
  chatLog,
  showModal,
  handleSubmit,
  setInputPrompt,
  inputPrompt,
  chatLogRef,
  flowName,
  err,
}) => {
  return (
    <div className="chatBox">
      {chatLog?.length > 0 ? (
        <div className="chatLogWrapper">
          {chatLog?.length > 0 &&
            chatLog.map((chat, idx) => (
              <div
                className="chatLog"
                key={idx}
                ref={chatLogRef}
                id={`navPrompt-${chat?.message?.replace(/[^a-zA-Z0-9]/g, "-")}`}
              >
                {chat.type === "user" ? (
                  <div className="chatPromptMainContainer">
                    <div className="chatPromptWrapper">
                      <Avatar bg="#F89501" className="userSVG">
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth={1.9}
                          viewBox="0 0 24 24"
                          // strokeLinecap="round"
                          // strokeLinejoin="round"
                          className="h-6 w-6"
                          height={40}
                          width={40}
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>
                      </Avatar>
                      <div id="chatPrompt">{chat?.message}</div>
                    </div>
                  </div>
                ) : (
                  <div className="botMessageMainContainer">
                    <div className="botMessageWrapper">
                      <Avatar bg="#fff" className="openaiSVG">
                        <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          width="60.000000pt"
                          height="60.000000pt"
                          viewBox="0 0 278.000000 278.000000"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <g
                            transform="translate(0.000000,278.000000) scale(0.100000,-0.100000)"
                            fill="#650098"
                            stroke="none"
                          >
                            <path
                              d="M1220 2760 c-316 -44 -573 -167 -794 -381 -206 -198 -336 -431 -398
              -717 -31 -139 -31 -405 0 -544 30 -137 66 -237 127 -356 75 -143 153 -247 271
              -361 196 -190 413 -304 687 -363 145 -31 399 -31 544 0 274 59 491 173 687
              363 206 198 336 431 398 717 31 139 31 405 0 544 -30 137 -66 237 -127 356
              -75 143 -153 247 -271 361 -197 190 -418 306 -687 362 -116 24 -335 33 -437
              19z m313 -643 c183 -108 198 -363 29 -491 -54 -40 -69 -78 -36 -90 9 -3 83 10
              165 29 138 33 151 35 183 21 44 -18 62 -46 76 -122 11 -53 10 -66 -5 -97 -20
              -43 -46 -54 -209 -93 -204 -48 -205 -61 -20 -291 155 -191 170 -218 154 -269
              -13 -38 -94 -110 -136 -120 -60 -15 -79 1 -241 196 -47 56 -87 96 -100 98 -25
              4 -35 -6 -159 -159 -57 -71 -106 -121 -124 -129 -47 -19 -91 -5 -151 49 -50
              45 -54 52 -54 93 0 42 7 53 136 214 75 94 141 180 148 191 34 65 0 93 -162
              132 -70 17 -140 37 -156 45 -44 23 -57 66 -42 140 16 76 33 104 77 122 32 14
              45 12 183 -21 82 -19 156 -32 165 -29 32 12 18 51 -29 84 -85 59 -125 137
              -125 247 0 94 38 170 115 229 56 43 101 55 191 51 66 -3 89 -8 127 -30z"
                            />
                          </g>
                        </svg>
                      </Avatar>
                      {chat.type === "system" ? (
                        <div id="botMessage">
                          <BotResponse
                            response={chat.message}
                            chatLogRef={chatLogRef}
                          />
                        </div>
                      ) : err ? (
                        <Error err={err} />
                      ) : (
                        <Loading />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      ) : (
        <IntroSection flowName={flowName} />
      )}

      {/*<button className="button-file" onClick={showModal}>
        <div className="container-attach-icon">
          <div className="attach-icon">
            <AttachFileIcon />
          </div>
          <div>
            <p>Traduzir arquivo</p>
            <p className="files-description">.pdf, .docx, .pptx</p>
          </div>
        </div>
      </button>*/}
      <form onSubmit={handleSubmit}>
        <div className="inputPromptWrapper">
          <input
            name="inputPrompt"
            id=""
            className="inputPrompttTextarea"
            type="text"
            rows="1"
            value={inputPrompt}
            onChange={(e) => setInputPrompt(e.target.value)}
            autoFocus
            placeholder="Em que posso ajudar?"
          ></input>
          <button type="submit" className="button-form-chat">
            <svg
              fill="#650098"
              width={20}
              height={25}
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#212023"
              strokeWidth={0}
            >
              <title>{"submit form"}</title>
              <path
                d="m30.669 1.665-.014-.019a.73.73 0 0 0-.16-.21h-.001c-.013-.011-.032-.005-.046-.015-.02-.016-.028-.041-.05-.055a.713.713 0 0 0-.374-.106l-.05.002h.002a.628.628 0 0 0-.095.024l.005-.001a.76.76 0 0 0-.264.067l.005-.002-27.999 16a.753.753 0 0 0 .053 1.331l.005.002 9.564 4.414v6.904a.75.75 0 0 0 1.164.625l-.003.002 6.259-4.106 9.015 4.161c.092.043.2.068.314.068H28a.75.75 0 0 0 .747-.695v-.002l2-27.999c.001-.014-.008-.025-.008-.039l.001-.032a.739.739 0 0 0-.073-.322l.002.004zm-4.174 3.202-14.716 16.82-8.143-3.758zM12.75 28.611v-4.823l4.315 1.992zm14.58.254-8.32-3.841c-.024-.015-.038-.042-.064-.054l-5.722-2.656 15.87-18.139z"
                stroke="none"
              />
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatBox;
