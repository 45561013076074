import styled from 'styled-components';

export const DragAndDropWrap = styled.div`
    text-align: center;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: dashed 4px #d6d6d6;
    cursor: pointer;
    transition: all ease .3s;

    i {
        color: #660099bd;
    }

    &:hover {
        border-color: #660099bd;
    }

    &.limit {
        pointer-events: none;
        cursor: not-allowed;
        border-color: #d6d6d6;
        background-color: #d6d6d6;
    }
`

export const FileList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    & li {
        display: flex;
        background-color: rgba(102, 0, 153, 0.1);
        border-radius: 4px;
        outline: inset 1px rgba(102, 0, 153, 0.2);
        padding: 0.625rem 1rem;
        margin: 0 0 1rem 0;
        gap: 1rem;
    }

    & .file-info {
        flex: 1;
        display: flex;
        align-items: center;

        & h3 {
            display: inline-block;
            margin: 0;
            padding: 0;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.1;
            color: #660099;
        }
    }

    & .file-remove {
        flex: 0;

        & button {
            all: unset;
            cursor: pointer;
            padding: 4px;
        }

        & path {
            fill: #660099 !important;
        }
    }
`

export const Paragraph = styled.p`
    font-family: Telefonica;
    font-size: ${(props) => props.fontSize ? props.fontSize : '1rem'};
    color: ${props => props.color};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    width: ${(props) => props.width};
    background: ${(props) => props.background};
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    
    div {
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    svg {
        width: 24%;
        height: 35px;
        padding: 0;
        margin-left: -24px;

        path {
            fill: ${(props) => props.fill};
        }
    }


    ${Paragraph} {
        font-weight: bold;
        font-size: 1.25rem;
        margin-left: -10px;
    }

    span {
        margin-top: 4px;
        font-size: 0.86rem;
        color: #57375D;
    }

`;

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    margin-top: ${(props) => props.marginTop};
`;

