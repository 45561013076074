import React from "react";
import Layout from "../../components/Layout";
import "./Precificador.css";

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSidebarOpen } from "providers/reducers/header";
import { setSubscription } from "providers/reducers/user";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";

import { HeadTitleComponent } from "./components";
import { MultiSelectComponent } from "./components";
import { CadastroProduto, CadastraCep, CompetitividadeDetalhes, Main } from "./pages";

import { useAuthenticationPrecificador } from "application/routes/hooks/useAuthentication";

export const Precificador = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const regex = /[?&]subscription_id=([^&#]*)/;
  const match = regex.exec(history.location.search);
  const subscription_id = match && match[1];
  dispatch(setSubscription(subscription_id));

  let expanded = useSelector((state) => state.header.sidebarOpen);
  const userDataState = useSelector((state) => state.user);
  const sidebar = useSelector((state) => state.sidebar);

  const [selectedFilters, setSelectedFilters] = useState({});
  const handleSearch = (selectedValues) => {
    setSelectedFilters(selectedValues);
  };

  function handleSidebar(state) {
    dispatch(setSidebarOpen(state));
  }

  useAuthenticationPrecificador(userDataState)
  useEffect(() => {
    if (!userDataState.user_profile_permissions.includes("READ")) {
      history.push("/?unauthorized_access");
    }
  });

  const BASENAME = process.env.REACT_APP_API_URL_BASENAME

  return (
    <Layout
      expanded={expanded}
      setExpanded={handleSidebar}
      username={userDataState.user_name}
      sidebarOptions={JSON.parse(sidebar.pages)}
      position={"relative"}
      height={"100%"}
      mobileWidth={"100%"}
      mobileMargin={"61px 0 0 0"}
    >
      <div className="parent">
        <div className="main">
          <MultiSelectComponent handleSearch={handleSearch} />
          <div className="child content">
            
            <BrowserRouter basename={BASENAME ? BASENAME : "/"}>
              <Switch>
                <Route path="/precificador">
                  <HeadTitleComponent />
                  <Main selectedFilters={selectedFilters} />
                </Route>
                <Route path="/cadastra-cep">
                  <CadastraCep />
                </Route>
                <Route path="/cadastra-produto">
                  <CadastroProduto />
                </Route>
                <Route path="/competitividade-detalhes">
                  <CompetitividadeDetalhes />
                </Route>
              </Switch>
            </BrowserRouter>
          </div>
        </div>
      </div>
    </Layout>
  );
};
