import React, { useState, useEffect, useRef } from "react";
import chatBubbleOutline from "../../../assets/svg/chat-bubble-outline.svg";
import trashCanRegular from "../../../assets/svg/trash-can-regular.svg";
import trashCanRegularGrey from "../../../assets/svg/trash-can-regular-grey.svg";
import * as S from "./styles";
import { IconAddMoreRegular, skinVars } from "@telefonica/mistica";
import { useSelector } from "react-redux";
import ContentGeneratorService from "application/services/ContentGeneratorService";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const MenuChatSideBar = ({
  textHistoryChat,
  responseArray,
  setTextArray,
  setResponseArray,
  user,
  talkId,
  setTalkId,
  isOpenSidebar,
  setIsOpenSidebar,
  setTonesByTalkId
}) => {
  const history = useHistory();
  const userDataState = useSelector((state) => state.user);
  const regex = /[?&]subscription_id=([^&#]*)/;
  const match = regex.exec(history.location.search);
  const subscription_id = match && match[1];
  const { subscriptionName } = userDataState.subscriptions.find(
    (subs) => subs.subscriptionId === subscription_id
  );
  const menuRef = useRef(null);
  const [chatArray, setChatArray] = useState([]);

  const handleNewChat = async () => {
    window.location.reload();
  };

  const handleLoadChat = async ({ talkIdHistory }) => {
    setTalkId(talkIdHistory);
    try {
      const res = await ContentGeneratorService.loadOneChat(
        userDataState,
        subscriptionName,
        talkIdHistory
      );
      textHistoryChat(res.talk.history);
      const tones = {
        estilo: res.talk.estilo,
        linguagem: res.talk.linguagem,
        personalidade: res.talk.personalidade,
        proposito: res.talk.proposito,
        tipo_conteudo: res.talk.tipo_conteudo,
        tom: res.talk.tom,
      }
      setTonesByTalkId(tones)
    } catch (error) {
      toast.error("Falha!", {
        autoClose: 1500,
      });
    }
  };

  const handleClearChatHistory = async () => {
    
    for (let i = 0; i < chatArray.length; i++) {
      if (chatArray[i].history) {
        await handleClearChat(chatArray[i]._id);
      }
    }
  };

  const handleClearChat = async (talkId) => {
    try {
      if (!talkId) {
        console.error("ID de conversa inválido");
        return;
      }
      await ContentGeneratorService.clearChatHistory(
        userDataState,
        subscriptionName,
        talkId
      );
      toast.success("Chat deletado!", {
        autoClose: 1500,
      });
      setTextArray([]);
      setResponseArray([]);
      setTalkId("");
    } catch (error) {
      toast.error("Falha!", {
        autoClose: 1500,
      });
    }
  };

  useEffect(() => {
    handleLoadAllChats();
  }, [responseArray]);

  const handleLoadAllChats = async () => {
    try {
      const response = await ContentGeneratorService.loadAllChats(
        userDataState,
        subscriptionName,
        user
      );
      setChatArray(response.talks);
      setIsOpenSidebar(!!response.talks.find((chat) => chat.history));
    } catch (error) {
      toast.error("Falha!", {
        autoClose: 1500,
      });
    }
  };

  return (
    <S.WrapperSidebar
      ref={menuRef}
      isOpen={isOpenSidebar}
      isOpenSidebarAnimation={isOpenSidebar}
    >
      <S.LeftMenuContainer>
        <S.Button onClick={handleNewChat}>
          <IconAddMoreRegular
            className="icon-add-more"
            color={skinVars.colors.backgroundBrand}
          />
          <S.TextButton>Novo chat</S.TextButton>
        </S.Button>
        <S.ChatHistoryContainer>
          {chatArray
            .slice()
            .reverse()
            .map((chat) => {
              if (Array.isArray(chat.history) && chat.history.length > 0) {
                return <>
                  <S.MenuItem
                    key={chat._id}
                  >
                    <S.Icon src={chatBubbleOutline} alt="Chat icon" onClick={() => handleLoadChat({ talkIdHistory: chat._id })} />
                    <S.TextItem onClick={() => handleLoadChat({ talkIdHistory: chat._id })}>
                      {chat.history[chat.history.length - 1].user}
                    </S.TextItem>
                    <S.IconTrash src={trashCanRegularGrey} alt="Delete icon" onClick={() => handleClearChat(chat._id)} />
                  </S.MenuItem>
                </>;
              }
            })}
        </S.ChatHistoryContainer>
      </S.LeftMenuContainer>
      <S.BottomContainer>
        <S.MenuItem>
          <S.Icon src={trashCanRegular} alt="Chat icon" />
          <S.TextItem onClick={handleClearChatHistory} className="text-trash">
            Limpar histórico
          </S.TextItem>
        </S.MenuItem>
      </S.BottomContainer>
    </S.WrapperSidebar>
  );
};

export default MenuChatSideBar;
