import styled from 'styled-components';
import { shade } from 'polished'

export const FormContainer = styled.div`
    position: ${(props) => props.position};
    width: ${(props) => props.width};
    bottom: ${(props) => props.bottom};
    padding: 12px 8px;
    background-color: #fff;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
    margin: ${(props) => props.margin ? props.margin : "8px 40px 25px;"};
    overflow: hidden;
    height: 60vh;
    display: flex;
    align-items: stretch;
`

export const Content = styled.div`
  display: flex; 
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 100%;
  margin: 0 16px;

  .rdp {
    border-radius: 10px;
    padding-bottom: 0;
    
  }
  .rdp-months,
  .rdp-month {
    width: 100%;
  }

  .rdp-month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px;
  }

  .rdp-day {
    width: 40px;
    height: 40px;
  }
  
  .rdp-day_today{
    font-weight: normal;
  }

  .rdp-day_selected{
    background: #5243AA !important;
    color: #fff !important;
  }
  
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  button {
      cursor: pointer;
      background-color: #5243AA;
      height: 56px;
      border-radius: 10px;
      border: 0;
      padding: 0 16px;
      color: #ffff;
      width: 180px;
      font-weight: bold;
      transition: background-color 0.2s;
      margin-top: 16px;

      &:hover {
        background: ${shade(0.2, '#5243AA')};
      }
  }
`