import React, { useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { Button, Dropdown, Image, Modal } from "antd";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import files from "../../../assets/img/files.png";
import { checkFile, checkFileType } from "../../utils/checkFiles";
import { dataLocations } from "../../utils/mock";
import "../../pages/FlowChat/Home.css"

const ModalUploadFile = ({
  isModalOpen,
  setIsModalOpen,
  inputData,
  chatLog,
}) => {
  const [typeFile, setTypeFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [formatVerify, setFormatVerify] = useState(false);
  const [uploadFile, setUploadFile] = useState();
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onVerify = (type) => {
    setLoading(true);
    setFormatVerify(false);
    return checkFile(type);
  };
  const onError = () => {
    setUploadFile(undefined);
    setFormatVerify(true);
    setLoading(false);
  };

  const onDrop = (files) => {
    if (onVerify(files[0].type)) {
      const type = checkFileType(files[0]);
      const formData = new FormData();
      formData.append("file", files[0]);
      const objFormatted = {
        formato: type,
        file: formData,
        name: files[0].name,
      };

      setTypeFile(type);
      setUploadFile(files[0]);
      setLoading(false);
    } else {
      onError();
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      className="modal"
      okButtonProps={null}
    >
      <div className="inputs-modal">
        {inputData &&
          chatLog?.length > 0 &&
          Object.entries(inputData).map(([key, value], index) => (
            <>
              {value.type === "str" && (
                <>
                  <Dropdown
                    key={index}
                    menu={{
                      items: dataLocations.locations,
                      selectable: true,
                      disabledOverflow: false,
                      onClick: (e) =>
                        console.log(dataLocations.locations[e.key.slice("4")]),
                      height: "800px",
                    }}
                    placement="top"
                    className="dropdown"
                    onChange={(e) => console.log(e.target.value)}
                  >
                    <Button>Idioma</Button>
                  </Dropdown>
                  {value.label === "input_language" && (
                    <ArrowForwardIcon className="icon-arrow-modal" />
                  )}
                </>
              )}
            </>
          ))}
      </div>
      <Dropzone onDrop={onDrop} multiple={false} maxSize={157286400}>
        {() => (
          <div {...getRootProps({ className: "dropzone" })}>
            <input
              {...getInputProps()}
              className="input-upload"
              data-testId="input-file"
            />
            <Image src={files} />
            {uploadFile ? (
              <div className="flex-description">
                <p className="description" data-testid="description">
                  {uploadFile.name}
                </p>
                <p className="files">{typeFile}</p>
              </div>
            ) : (
              <div className="flex-description">
                <p>
                  Arraste até aqui o seu arquivo PDF, Word (.docx) ou PowerPoint
                  (.pptx)
                </p>
                <p>ou</p>
                <div className="input-file">
                  <p className="input-file-text">
                    Selecionar no seu computador
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </Dropzone>
    </Modal>
  );
};

export default ModalUploadFile;
