import React from "react";
import NavLinks from "./NavLink";
import "../../pages/FlowChat/Home.css";

const NavLinksContainer = ({ chatLog, setChatLog, deleteChat }) => {
  return (
    <div className="navLinks" style={{ position: "absolute", bottom: "10px" }}>
      {chatLog.length > 0 && (
        <NavLinks
          svg={
            <svg
              fill="#650098"
              viewBox="0 0 24 24"
              data-name="Flat Line"
              xmlns="http://www.w3.org/2000/svg"
              className="icon flat-line"
              stroke="#650098"
              width={23}
              height={23}
            >
              <path
                d="M5 8h13a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H5V8Z"
                transform="rotate(90 12 14)"
                style={{
                  fill: "#fff202022",
                  strokeWidth: 2,
                }}
              />
              <path
                d="M16 7V4a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v3"
                style={{
                  fill: "none",
                  stroke: "#fff202022000000",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: 2,
                }}
              />
              <path
                data-name="primary"
                d="M10 11v6m4-6v6M4 7h16m-2 13V7H6v13a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1Z"
                style={{
                  fill: "none",
                  stroke: "#fff202022000000",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: 2,
                }}
              />
            </svg>
          }
          text="Limpar conversas"
          setChatLog={setChatLog}
          deleteChat={deleteChat}
        />
      )}
      <NavLinks
        svg={
          <svg viewBox="0 0 512 512" fill="currentColor" width={23} height={23}>
            <path
              fill="none"
              stroke="currentColor"
              strokeLinejoin="round"
              strokeWidth={32}
              d="M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z"
            />
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={32}
              d="M256 56v120a32 32 0 0032 32h120M176 288h160M176 368h160"
            />
          </svg>
        }
        text="Documentos"
        setChatLog={setChatLog}
        deleteChat={deleteChat}
      />
    </div>
  );
};

export default NavLinksContainer;
