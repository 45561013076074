import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeContextProvider } from '@telefonica/mistica';
import { ModalProvider } from './application/context/modal/ModalContext';
import { Theme } from './application/utils/theme';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider } from '@tanstack/react-query'

import { Routes } from './application/routes/route';
import store from './providers/store';
import { queryClient } from './lib/react-query.ts'
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <ThemeContextProvider theme={Theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ModalProvider>
            <QueryClientProvider client={queryClient}>
              <Routes />
            </QueryClientProvider>
          </ModalProvider>
        </PersistGate>
      </Provider>
    </ThemeContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);