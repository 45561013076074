import React from "react";
import FeedBackService from "application/services/FeedBackService";
import ContentGeneratorService from "application/services/ContentGeneratorService";
import { toast } from "react-toastify";
import {
  BackdropModal,
  ButtonSendFeedback,
  InlineLabel,
  ModalFeedbackBox,
  TextAreaFeedback,
  TextAreaForm,
} from "./styles";
import { IconCloseRegular, Text3, skinVars } from "@telefonica/mistica";

const ModalFeedback = ({
  isModalText,
  handleLikeFeedback,
  userDataState,
  correlator,
  handleCloseFeedback,
  talkId,
  subscriptionName,
  updateMessageByFeedback
}) => {
  const [textForm, setTextForm] = React.useState("");

  const handleInputChange = (event) => {
    setTextForm(event.target.value);
  };

  const handleFeedback = async (messageFeedback) => {
    const feedback = isModalText === "positivo" ? "positive" : "negative";
    try {
      await FeedBackService.feedbackContentGenerator(
        userDataState,
        feedback,
        correlator,
        messageFeedback
      );
      const { message } = await ContentGeneratorService.updateMessageFeedback(userDataState, subscriptionName, feedback, correlator, talkId)
      updateMessageByFeedback(message)
      toast.success("Feedback enviado com sucesso!", {
        autoClose: 1500,
      });
    } catch (error) {
      toast.error("Falha ao enviar feedback!", {
        autoClose: 1500,
      });
    }
    handleCloseFeedback();
  };

  return (
    <>
      <BackdropModal>
        <ModalFeedbackBox>
          <TextAreaForm>
            <InlineLabel>
              <Text3
                as="h3"
                medium
                weight="700"
                color={skinVars.colors.backgroundBrand}
              >
                {`Feedback ${isModalText}`}
              </Text3>
              <IconCloseRegular
                onClick={() => handleCloseFeedback()}
                color={skinVars.colors.backgroundBrand}
              />
            </InlineLabel>
            <TextAreaFeedback
              placeholder={
                isModalText === "positivo"
                  ? "O que você gosta na resposta?"
                  : "O que você não gosta na resposta?"
              }
              value={textForm}
              onChange={handleInputChange}
            />
            <ButtonSendFeedback
              // disabled={!textForm}
              onClick={() => handleFeedback(textForm)}
            >
              Enviar
            </ButtonSendFeedback>
          </TextAreaForm>
        </ModalFeedbackBox>
      </BackdropModal>
    </>
  );
};

export default ModalFeedback;
