import React, { useRef, useState } from "react";
import { ButtonsWrap, DragAndDropWrap, Form, ModalHeader, ModalOverLay, ModalWrap, Wrapper } from "application/styled-components/Perfil";
import { Button, Paragraph } from "application/styled-components/chatGPT";
import { IconCloseRegular, IconFilePdfRegular } from "@telefonica/mistica";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ModalPdfEdit = ({ closeModal, onFileSelect, initialFile }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleUploadClick = async () => {
        setIsLoading(true);

        try {
            await onFileSelect(selectedFile, initialFile.filename);
            closeModal();
        } catch (error) {
            console.log("Error updating PDF:", error);
            toast.error("Ocorreu um erro ao atualizar o arquivo.", {
                autoClose: 1500
            });
        }

        setIsLoading(false);
    };

    const openFileInput = () => {
        const fileInput = fileInputRef.current;
        if (fileInput) {
            fileInput.click();
        }
    };

    const handleFileInputChange = (e) => { 
        const file = e.target.files[0];

        if (file && file.type !== "application/pdf") {
            toast.error("Apenas PDFs são permitidos.", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500
            });
            return;
        }
        setSelectedFile(file);
    };

    return (
        <>
            <ModalOverLay>
                <ModalWrap>
                    <ModalHeader>
                        <Wrapper fill={"#660099"}>
                            <IconFilePdfRegular />
                            <Paragraph margin={0} fontSize="1.25rem">
                                Editar PDF
                            </Paragraph>
                        </Wrapper>
                        <Button onClick={closeModal} disabled={isLoading}>
                            <IconCloseRegular />
                        </Button>
                    </ModalHeader>
                    <Form>
                        <DragAndDropWrap onClick={openFileInput}>
                            <input
                                type="file"
                                id="fileInput"
                                style={{ display: "none" }}
                                accept="application/pdf"
                                ref={fileInputRef}
                                onChange={handleFileInputChange}
                                disabled={isLoading}
                            />
                            <p>
                                {selectedFile ? selectedFile.name : ' Clique aqui para selecionar um novo PDF para substituir o existente.'}
                            </p>
                        </DragAndDropWrap>
                        <ButtonsWrap marginTop={".9375rem"} width={"95%"}>
                            <Button onClick={handleUploadClick} disabled={isLoading}>
                                {isLoading ? "Carregando..." : "Fazer upload"}
                            </Button>
                        </ButtonsWrap>
                    </Form>
                </ModalWrap>
            </ModalOverLay>
        </>
    );
};
